import React, { useContext } from "react"

export const InputContext = React.createContext()

export function useInputContext() {
  const context = useContext(InputContext)
  if (!context) {
    throw new Error(
      `Input compound components cannot be rendered outside the Input component`,
    )
  }
  return context
}
