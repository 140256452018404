import { i18n } from "@lingui/core"
import { plural } from "@lingui/macro"
import { useNavigation } from "@react-navigation/native"
import { clamp } from "lodash"
import React, { useState } from "react"
import { useWindowDimensions } from "react-native"
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query"
import { useTheme } from "styled-components/native"
import {
  ActivityIndicator,
  Button,
  Chip,
  Container,
  ErrorBody,
  FastImage,
  FlatList,
  Icon,
  ImagePreview,
  NavBar,
  Pressable,
  RefreshControl,
  Row,
  Text,
  TopNavBar,
  View,
  useDialog,
} from "../../components/ui"
import { Breakpoint } from "../../hooks"
import {
  ADMIN_ROLE,
  ALL_TEST_OPTIONS,
  BUSINESS_TEST,
  DATING_TEST_SCORE_OPTIONS,
  DATING_TEST_WOULD_DATE_OPTION,
  ERROR_TEST_OPTION,
  MALE,
  TEST_STATE_OPTIONS,
} from "../../lib/constants"
import { formatRelativeDate, getErrorMessage } from "../../lib/helpers"
import { Sentry } from "../../lib/sentry"
import { useAlert, useApi } from "../../providers"

export function AdminTestDetailsScreen({ navigation, route }) {
  const alert = useAlert()
  const api = useApi()
  const theme = useTheme()
  const { width } = useWindowDimensions()
  const queryClient = useQueryClient()
  const openTestDeleteDialog = useDialog()

  const [imageVisible, setImageIsVisible] = useState(false)

  const { data: user } = useQuery("currentUser", api.getUser)

  const {
    data: test,
    isError: isTestError,
    error: testError,
    isLoading: isTestLoading,
    refetch: refetchTest,
  } = useQuery(["adminTest", route.params.id], () =>
    api.getAdminTest(route.params.id),
  )

  const { data: testStatistics, refetch: refetchTestStatistics } = useQuery(
    ["adminTest", route.params.id, "statistics"],
    () => api.getAdminTestStatistics(route.params.id),
  )

  const {
    fetchNextPage,
    hasNextPage,
    refetch: refetchTestReviews,
    data: testReviewsData,
    isError: testReviewsIsError,
    error: testReviewsError,
    isLoading: testReviewsIsLoading,
  } = useInfiniteQuery(
    ["adminTest", route.params.id, "reviews"],
    ({ pageParam = 1 }) =>
      api.getAdminReviewList({
        page: pageParam,
        query: {
          testIdEq: route.params.id,
        },
      }),
    {
      getNextPageParam: (lastPage, allPages) => lastPage.meta.next ?? undefined,
      getPreviousPageParam: (firstPage, allPages) =>
        firstPage.meta.prev ?? undefined,
    },
  )

  const deleteTestMutation = useMutation(api.deleteAdminTest, {
    onError: (err, variables, context) => {
      const { title, description } = getErrorMessage(err)
      alert.current.showNotification({ title, description })

      if (!err.isAxiosError) {
        Sentry.captureException(err)
      }
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("adminTestList")
      navigation.goBack()
    },
  })

  const testReviewsCount = testReviewsData?.pages[0]?.meta?.count ?? 0
  const allTestReviews = testReviewsData?.pages?.flatMap((p) => p.data) ?? []

  const imageSize = clamp(width, 0, theme.breakpoints.sm) - theme.space.xl * 2

  return (
    <>
      <Breakpoint values={["lg", "xl"]}>
        <TopNavBar />
      </Breakpoint>

      <Container breakpoint="xl">
        <Row>
          <NavBar title="Test">
            {user?.role === ADMIN_ROLE && (
              <Button
                end
                size="xs"
                variant="navbar"
                label="Delete"
                onPress={() =>
                  openTestDeleteDialog({
                    title: "Are you sure?",
                    description: "Test will be deleted and can't be restored.",
                    buttons: [
                      {
                        label: "Delete",
                        onPress: (value) => deleteTestMutation.mutate(test.id),
                      },
                      {
                        label: "Cancel",
                      },
                    ],
                  })
                }
              />
            )}
          </NavBar>
        </Row>
      </Container>

      <Container flex={1}>
        <Row flex={1}>
          <View flex={1} justifyContent="center">
            {isTestLoading ? (
              <ActivityIndicator />
            ) : isTestError ? (
              <ErrorBody
                error={testError}
                reload={() => {
                  refetchTest()
                  refetchTestStatistics()
                  refetchTestReviews()
                }}
              />
            ) : (
              <View flex={1}>
                <FlatList
                  onEndReached={() => hasNextPage && fetchNextPage()}
                  refreshControl={
                    <RefreshControl
                      onRefresh={() => {
                        refetchTest()
                        refetchTestStatistics()
                        refetchTestReviews()
                      }}
                      refreshing={false}
                    />
                  }
                  data={allTestReviews}
                  keyExtractor={(item) => item.id}
                  renderItem={({ item, index }) => {
                    const mb = allTestReviews.length - 1 === index ? "xl" : 0

                    return (
                      <ReviewItem
                        user={user}
                        mb={mb}
                        review={item}
                        test={test}
                        reload={() => {
                          refetchTest()
                          refetchTestStatistics()
                          refetchTestReviews()
                        }}
                      />
                    )
                  }}
                  ListEmptyComponent={
                    <View flex={1} mt="xxl">
                      {testReviewsIsLoading ? (
                        <ActivityIndicator />
                      ) : testReviewsIsError ? (
                        <ErrorBody
                          error={testReviewsError}
                          reload={refetchTestReviews}
                        />
                      ) : testReviewsCount === 0 ? (
                        <Text
                          fontFamily="heading"
                          textAlign="center"
                          fontSize={4}
                        >
                          No reviews yet
                        </Text>
                      ) : null}
                    </View>
                  }
                  ListHeaderComponent={
                    <View mx="xl">
                      <Pressable onPress={() => setImageIsVisible(true)}>
                        <FastImage
                          testID="image"
                          dataSet={{ private: true }}
                          style={{ width: imageSize, height: imageSize }}
                          source={{ uri: test.photo.url }}
                          borderRadius={3}
                          resizeMode={FastImage.resizeMode.cover}
                        />

                        <ImagePreview
                          uri={test.photo.url}
                          isOpen={imageVisible}
                          onClose={() => setImageIsVisible(false)}
                        />
                        <View
                          width="100%"
                          position="absolute"
                          top={0}
                          left={0}
                          bg="background"
                          opacity={0.7}
                          py="md"
                          px="xl"
                          flexDirection="row"
                        >
                          <View alignItems="flex-start" flex={1.2}>
                            <Text fontFamily="bold" fontSize={2}>
                              {testStatistics?.reviewCount >= 0 &&
                                plural(testStatistics.reviewCount, {
                                  zero: "# reviews",
                                  one: "# review",
                                  two: "# reviews",
                                  few: "# reviews",
                                  many: "# reviews",
                                  other: "# reviews",
                                })}
                            </Text>
                          </View>
                          <View alignItems="center" flex={1}>
                            <Text fontFamily="bold" fontSize={2}>
                              {i18n._(
                                TEST_STATE_OPTIONS.find(
                                  (t) => t.value === test.state,
                                ).label,
                              )}
                            </Text>
                          </View>
                          <View
                            alignItems="flex-end"
                            justifyContent="center"
                            flex={1}
                          >
                            <Button
                              color="text"
                              variant="link"
                              iconFontSize={4}
                              size="xs"
                              icon="expand-arrows-alt"
                              onPress={() => setImageIsVisible(true)}
                            />
                          </View>
                        </View>
                        <View
                          position="absolute"
                          bottom={0}
                          left={0}
                          bg="background"
                          width="100%"
                          opacity={0.7}
                          py="md"
                          px="xl"
                          flexDirection="row"
                        >
                          <View alignItems="flex-start" flex={1}>
                            <Text fontFamily="bold" fontSize={2}>
                              ID: {test.id}
                            </Text>
                          </View>
                          <View alignItems="flex-end" flex={1}>
                            <Text fontFamily="bold" fontSize={2}>
                              {formatRelativeDate(test.createdAt)}
                            </Text>
                          </View>
                        </View>
                        {test.type === BUSINESS_TEST && (
                          <View
                            width="100%"
                            position="absolute"
                            bottom={38}
                            left={0}
                            py="md"
                            px="md"
                            bg="background"
                            opacity={0.7}
                          >
                            <Text
                              testID="bio"
                              dataSet={{ private: true }}
                              numberOfLines={1}
                              fontFamily="heading"
                              fontSize={3}
                            >
                              {test.bio}
                            </Text>
                          </View>
                        )}
                      </Pressable>

                      {user?.role === ADMIN_ROLE && (
                        <>
                          <Button
                            mt="xl"
                            variant="primary-outline"
                            label={`User (${test.userId})`}
                            onPress={() =>
                              navigation.push("AdminUserDetails", {
                                id: test.userId,
                              })
                            }
                          />

                          <Button
                            mt="xl"
                            variant="primary-outline"
                            label={`Review (${test.userId})`}
                            onPress={() =>
                              navigation.push("AdminReview", {
                                testId: test.id,
                              })
                            }
                          />
                        </>
                      )}

                      {test.reviewsAvailable > 0 && (
                        <Text
                          mt="xl"
                          fontFamily="bold"
                          color="grayPrimary"
                          fontSize={3}
                        >
                          Reviews available: {test.reviewsAvailable}
                        </Text>
                      )}
                    </View>
                  }
                />
              </View>
            )}
          </View>
        </Row>
      </Container>
    </>
  )
}

function ReviewItem({ review, test, reload, user, ...props }) {
  const api = useApi()
  const navigation = useNavigation()
  const openReviewDeleteDialog = useDialog()

  const deleteReviewMutation = useMutation(api.deleteAdminReview, {
    onError: (err, variables, context) => {
      const { title, description } = getErrorMessage(err)
      alert.current.showNotification({ title, description })

      if (!err.isAxiosError) {
        Sentry.captureException(err)
      }
    },
    onSuccess: (data, variables, context) => {
      reload()
    },
  })

  const datingFeedback = review.feedbacks.find((f) =>
    f.value.includes("score_"),
  )
  let datingScore

  if (datingFeedback) {
    datingScore = parseInt(datingFeedback.value.split("score_")[1], 10)
  }

  return (
    <View mt="xl" mx="xl" bg="soft" px="xl" pb="xl" borderRadius={3} {...props}>
      {user?.role === ADMIN_ROLE && (
        <View flexDirection="row" justifyContent="space-between" mt="lg">
          {review.userId && (
            <Button
              size="xs"
              variant="link"
              label={`User (${review.userId})`}
              onPress={() =>
                navigation.push("AdminUserDetails", { id: review.userId })
              }
            />
          )}

          <Button
            size="xs"
            variant="link"
            label="Delete"
            onPress={() =>
              openReviewDeleteDialog({
                title: "Are you sure?",
                description: "Review will be deleted and can't be restored",
                buttons: [
                  {
                    label: "Delete",
                    onPress: (value) =>
                      deleteReviewMutation.mutate({ id: review.id }),
                  },
                  {
                    label: "Cancel",
                    variant: "primary-outline",
                  },
                ],
              })
            }
          />
        </View>
      )}

      {Boolean(review.comment) && (
        <Text testID="comment" dataSet={{ private: true }} mt="xl">
          {review.comment}
        </Text>
      )}

      {Boolean(datingScore) && (
        <View flexDirection="row" mt="xl">
          {DATING_TEST_SCORE_OPTIONS.map((o, idx) => {
            const index = idx + 1

            return (
              <Icon
                key={o.value}
                name="star"
                solid={datingScore >= index}
                color="business"
                fontSize={6}
              />
            )
          })}
        </View>
      )}

      <View pt="xl" flexDirection="row" flexWrap="wrap" flexGap="lg">
        {review.feedbacks.map((f) => {
          const option =
            ALL_TEST_OPTIONS.find((o) => o.value === f.value) ??
            ERROR_TEST_OPTION

          return f.value.includes("score_") ? null : (
            <Chip key={f.id} px="lg" py="sm" bg="translucent">
              <Text fontFamily="bold">
                {i18n._(option.label, {
                  gender:
                    option.value === DATING_TEST_WOULD_DATE_OPTION
                      ? test.reviewerGender ?? test.gender ?? MALE
                      : test.user.gender ?? MALE,
                })}
              </Text>
            </Chip>
          )
        })}
      </View>
      <View mt="xl" flexDirection="row" justifyContent="space-between">
        <Text color="grayPrimary">ID {review.id}</Text>
        <Text color="grayPrimary">{formatRelativeDate(review.createdAt)}</Text>
      </View>
    </View>
  )
}
