import React from "react"
import { t } from "@lingui/macro"
import { Modal, Platform, StatusBar } from "react-native"
import { useTheme } from "styled-components/native"
import { Pressable } from "./pressable"
import { View } from "./view"
import { useSafeAreaOrDefaultInsets } from "../../hooks"
import { Button } from "./button"
import { AppBar } from "./app-bar"
import { Container, Row } from "./container"

export function BottomSheet({
  isOpen,
  disabled,
  title,
  onClose,
  onDone,
  children,
  ...props
}) {
  const theme = useTheme()
  const insets = useSafeAreaOrDefaultInsets({ bottom: theme.space.xl })

  return (
    <Modal
      statusBarTranslucent
      transparent
      animationType="slide"
      visible={isOpen}
    >
      {Platform.OS === "android" ? (
        <StatusBar
          backgroundColor={theme.colors.primary}
          barStyle={"dark-content"}
        />
      ) : null}
      <Container flex={1}>
        <Row flex={1}>
          <Pressable
            pt={insets.top}
            flex={1}
            onPress={onClose}
            disabled={disabled}
          />

          <View
            bg="background"
            {...props}
            borderTopLeftRadius={3}
            borderTopRightRadius={3}
            overflow="hidden"
          >
            <AppBar bg="soft" title={title}>
              <Button
                start
                disabled={disabled}
                label={t`Cancel`}
                variant="navbar"
                size="xs"
                onPress={onClose}
              />
              {onDone && (
                <Button
                  end
                  disabled={disabled}
                  label={t`Done`}
                  variant="navbar"
                  size="xs"
                  onPress={onDone}
                />
              )}
            </AppBar>

            {children}
          </View>
        </Row>
      </Container>
    </Modal>
  )
}

BottomSheet.defaultProps = {
  isOpen: false,
  onClose: () => {},
}
