import { useSafeAreaInsets } from "react-native-safe-area-context"

export function useSafeAreaOrDefaultInsets(defaultInsets) {
  defaultInsets = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    ...defaultInsets,
  }
  const insets = useSafeAreaInsets()

  return {
    top: insets.top || defaultInsets.top,
    right: insets.right || defaultInsets.right,
    bottom: insets.bottom || defaultInsets.bottom,
    left: insets.left || defaultInsets.left,
  }
}
