import { t, Trans } from "@lingui/macro"
import delay from "delay"
import React, { useEffect } from "react"
import { Platform } from "react-native"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useTheme } from "styled-components/native"
import {
  ActivityIndicator,
  Container,
  ErrorBody,
  FlatList,
  NavBar,
  RefreshControl,
  Row,
  Text,
  TopNavBar,
  TouchableOpacity,
  View,
} from "../components/ui"
import { Breakpoint } from "../hooks"
import { getErrorMessage } from "../lib/helpers"
import { mixpanel } from "../lib/mixpanel"
import Purchases from "../lib/purchases"
import { Sentry } from "../lib/sentry"
import { loadStripe } from "../lib/stripe"
import { useAlert, useApi } from "../providers"

export function CoinsPurchaseFormScreen({ navigation, route }) {
  const queryClient = useQueryClient()
  const alert = useAlert()
  const theme = useTheme()
  const api = useApi()

  useEffect(() => {
    if (route.params?.canceled === "true") {
      alert.current.showNotification({
        title: t`Coins purchase`,
        description: t`Coins purchase canceled.`,
      })

      navigation.goBack()

      if (route.params?.testActivationId) {
        navigation.navigate("TestActivationForm", {
          id: route.params?.testActivationId,
        })
      }

      navigation.navigate("CoinsPurchaseForm")
    } else if (route.params?.success === "true") {
      alert.current.showNotification({
        title: t`Coins purchase`,
        description: t`Coins successfully purchased.`,
        componentProps: {
          alertType: "success",
        },
      })

      navigation.goBack()

      if (route.params?.testActivationId) {
        navigation.navigate("TestActivationForm", {
          id: route.params?.testActivationId,
        })
      }

      async function updateCoins() {
        try {
          const user = await api.getUser()

          for (let i = 1; i <= 10; i++) {
            const newUser = await api.updateCoins()

            if (newUser.coins > user.coins) {
              queryClient.setQueryData("currentUser", newUser)

              const coins = newUser.coins - user.coins
              mixpanel.track("Coins Purchased", {
                amount: coins,
              })

              break
            }

            await delay(1000 * i)
          }
        } catch (err) {
          const { title, description } = getErrorMessage(err)
          alert.current.showNotification({ title, description })

          if (!err.isAxiosError) {
            throw err
          }
        }
      }

      updateCoins()
    }
  }, [route, alert, navigation, api, queryClient])

  const {
    data: availablePackages,
    isError,
    error,
    isLoading,
    refetch,
  } = useQuery("availablePackages", async () => {
    const offerings = await Purchases.getOfferings()
    return offerings.current?.availablePackages ?? []
  })

  const {
    data: stripe,
    isError: isStripeError,
    error: stripeError,
    isLoading: isStripeLoading,
    refetch: stripeRefetch,
  } = useQuery("stripe", () => loadStripe())

  const purchaseMutation = useMutation(
    async (data) => {
      if (Platform.OS === "web") {
        const session = await api.createStripeCheckoutSession({
          stripeCheckoutSessionDto: {
            productId: data.product.identifier,
            testActivationId: route.params?.testActivationId,
          },
        })
        return stripe.redirectToCheckout({ sessionId: session.id })
      } else {
        await Purchases.purchasePackage(data)

        const parts = data.identifier.split("coins-")
        const coins = parseInt(parts[1], 10)

        if (Number.isFinite(coins)) {
          mixpanel.track("Coins Purchased", {
            amount: coins,
          })
        }

        const user = await api.getUser()

        for (let i = 1; i <= 10; i++) {
          const newUser = await api.updateCoins()

          if (newUser.coins > user.coins) {
            return newUser
          }

          await delay(1000 * i)
        }

        return api.updateCoins()
      }
    },
    {
      onError: (err, variables, context) => {
        if (err.userCancelled) {
          return
        }

        const { title, description } = getErrorMessage(err)
        alert.current.showNotification({ title, description })

        if (!err.isAxiosError) {
          Sentry.captureException(err)
        }
      },
      onSuccess: (data, variables, context) => {
        if (Platform.OS === "web") {
          return
        }

        queryClient.setQueryData("currentUser", data)
        navigation.goBack()

        alert.current.showNotification({
          title: t`Coins purchase`,
          description: t`Coins successfully purchased.`,
          componentProps: {
            alertType: "success",
          },
        })
      },
    },
  )

  return (
    <>
      <Breakpoint values={["lg", "xl"]}>
        <TopNavBar />
      </Breakpoint>

      <Container breakpoint="xl">
        <Row>
          <NavBar title={t`Coins`} />
        </Row>
      </Container>

      <Container flex={1}>
        <Row flex={1}>
          <View flex={1} justifyContent="center">
            {isLoading || (Platform.OS === "web" && isStripeLoading) ? (
              <ActivityIndicator />
            ) : isError || (Platform.OS === "web" && isStripeError) ? (
              <ErrorBody
                error={error || stripeError}
                reload={Platform.OS === "web" ? stripeRefetch : refetch}
              />
            ) : (
              <FlatList
                px="xl"
                refreshControl={
                  <RefreshControl
                    onRefresh={() => refetch()}
                    refreshing={false}
                  />
                }
                ListHeaderComponent={
                  <>
                    <Text fontSize={6} fontFamily="heading">
                      <Trans>How many coins do you need?</Trans>
                    </Text>

                    <Text mt="md" fontSize={3} fontFamily="heading">
                      <Trans>
                        Buy coins to get a guaranteed number of reviews faster.
                        With coins you don't need energy and your test will be a
                        priority. One review costs one coin.
                      </Trans>
                    </Text>
                  </>
                }
                contentContainerStyle={{
                  paddingBottom: theme.space.xl,
                  flexGrow: 1,
                }}
                data={availablePackages}
                renderItem={({ item, index }) => {
                  return (
                    <PackageItem
                      index={index}
                      availablePackage={item}
                      purchaseMutation={purchaseMutation}
                    />
                  )
                }}
                keyExtractor={(item) => item.identifier}
              />
            )}
          </View>
        </Row>
      </Container>
    </>
  )
}

function PackageItem({ availablePackage, purchaseMutation, index }) {
  return (
    <TouchableOpacity
      borderRadius={3}
      borderColor="border"
      borderWidth="md"
      mt="xl"
      p="xl"
      bg="soft"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      disabled={purchaseMutation.isLoading}
      onPress={() => purchaseMutation.mutate(availablePackage)}
    >
      <View>
        <Text fontSize={4} fontFamily="heading" color="primary">
          {availablePackage.product.description.id ? (
            <Trans id={availablePackage.product.description.id} />
          ) : (
            availablePackage.product.description
          )}
        </Text>
      </View>
      <View>
        <Text fontSize={4} fontFamily="heading">
          {availablePackage.product.priceString}
        </Text>
      </View>
      {index === 2 && (
        <View
          position="absolute"
          right={-8}
          top={-8}
          bg="secondary"
          borderRadius={4}
          px={7}
          py={1}
        >
          <Text fontWeight="heading" color="white">
            <Trans>Most purchased</Trans>
          </Text>
        </View>
      )}
      {index === 4 && (
        <View
          position="absolute"
          right={-8}
          top={-8}
          bg="social"
          borderRadius={4}
          px={7}
          py={1}
        >
          <Text fontWeight="heading" color="white">
            <Trans>Cost-effective</Trans>
          </Text>
        </View>
      )}
    </TouchableOpacity>
  )
}
