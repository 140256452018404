import React from "react"
import { StatusBar, Modal, StyleSheet, Platform } from "react-native"
import { useTheme } from "styled-components/native"
import { Pressable } from "./pressable"
import { useSafeAreaOrDefaultInsets } from "../../hooks"
import { FastImage } from "./fast-image"
import { Icon } from "./icon"
import { TouchableOpacity } from "./touchable-opacity"

export function ImagePreview({ isOpen, uri, disabled, onClose, ...props }) {
  const theme = useTheme()
  const insets = useSafeAreaOrDefaultInsets({ bottom: theme.space.xl })

  return (
    <Modal transparent animationType="fade" visible={isOpen}>
      {Platform.OS === "android" ? (
        <StatusBar backgroundColor="black" barStyle={"dark-content"} />
      ) : null}

      <Pressable
        flex={1}
        bg="black"
        onPress={onClose}
        disabled={disabled}
        {...props}
      >
        <FastImage
          testID="image"
          dataSet={{ private: true }}
          style={styles.image}
          source={{ uri }}
          resizeMode={FastImage.resizeMode.contain}
        />

        <TouchableOpacity
          position="absolute"
          right="xl"
          onPress={onClose}
          top={insets.top + theme.space.md}
        >
          <Icon name="times-circle" color="white" />
        </TouchableOpacity>
      </Pressable>
    </Modal>
  )
}

ImagePreview.defaultProps = {
  isOpen: false,
  onClose: () => {},
}

const styles = StyleSheet.create({
  image: { width: "100%", height: "100%" },
})
