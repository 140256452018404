export function requestSanitizer(request) {
  if (request.headers.Authorization) {
    request.headers.Authorization = ""
  }

  if (request.body) {
    request.body = undefined
  }

  return request
}

export function responseSanitizer(response) {
  if (response.body) {
    response.body = undefined
  }

  return response
}

export function urlSanitizer(url) {
  let sanitizedUrl = url

  sanitizedUrl = sanitizedUrl.replace(
    /secret_key=([^&]*)/,
    "secret_key=**redacted**",
  )
  sanitizedUrl = sanitizedUrl.replace(/email=([^&]*)/, "email=**redacted**")
  sanitizedUrl = sanitizedUrl.replace(
    /password=([^&]*)/,
    "password=**redacted**",
  )
  sanitizedUrl = sanitizedUrl.replace(/idToken=([^&]*)/, "idToken=**redacted**")
  sanitizedUrl = sanitizedUrl.replace(/name=([^&]*)/, "name=**redacted**")
  sanitizedUrl = sanitizedUrl.replace(
    /inviteCode=([^&]*)/,
    "inviteCode=**redacted**",
  )
  sanitizedUrl = sanitizedUrl.replace(/userId=([^&]*)/, "userId=**redacted**")

  return sanitizedUrl
}
