import { t, Trans } from "@lingui/macro"
import React, { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { Linking, Platform } from "react-native"
import { useMutation, useQuery, useQueryClient } from "react-query"
import {
  ActivityIndicator,
  Button,
  Container,
  ErrorBody,
  Input,
  NavBar,
  Row,
  Text,
  TopNavBar,
  View,
} from "../components/ui"
import { Breakpoint } from "../hooks"
import { getErrorMessage } from "../lib/helpers"
import OneSignal from "../lib/onesignal"
import { Sentry } from "../lib/sentry"
import { useAlert, useApi } from "../providers"

export function NotificationSettingsForm({
  notificationSettings,
  deviceState,
  refetchDeviceState,
}) {
  const queryClient = useQueryClient()
  const alert = useAlert()
  const api = useApi()

  const updateMutation = useMutation(api.updateNotificationSettings, {
    onError: (err, variables, context) => {
      const { title, description } = getErrorMessage(err)
      alert.current.showNotification({ title, description })

      if (!err.isAxiosError) {
        Sentry.captureException(err)
      }
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("notificationSettings")

      alert.current.showNotification({
        title: t`Notifications update`,
        description: t`Notification settings successfully updated.`,
        componentProps: {
          alertType: "success",
        },
      })
    },
  })

  const form = useForm({
    defaultValues: {
      review: notificationSettings.review,
      energy: notificationSettings.energy,
      marketing: notificationSettings.marketing,
    },
  })

  function onSubmit(data) {
    return updateMutation.mutate({
      notificationSettingsDto: data,
    })
  }

  return (
    <View px="xl" pb="xl" flex={1}>
      {((Platform.OS === "ios" &&
        deviceState.notificationPermissionStatus === 0) ||
        (Platform.OS === "web" && deviceState === "default")) && (
        <View flex={1} justifyContent="center">
          <Text fontSize={6} fontFamily="heading">
            <Trans>Enable notifications to get new reviews instantly</Trans>
          </Text>
          <Button
            mt="xl"
            label={t`Allow notifications`}
            onPress={() =>
              (Platform.OS === "web"
                ? (cb) => OneSignal.showNativePrompt().then(cb)
                : OneSignal.promptForPushNotificationsWithUserResponse)(() =>
                refetchDeviceState(),
              )
            }
          />
        </View>
      )}
      {((Platform.OS === "ios" &&
        deviceState.notificationPermissionStatus === 1) ||
        (Platform.OS === "android" &&
          deviceState.hasNotificationPermission === false) ||
        (Platform.OS === "web" && deviceState === "denied")) && (
        <View flex={1} justifyContent="center">
          <Text fontSize={6} fontFamily="heading">
            {Platform.OS === "web" ? (
              <Trans>Notifications blocked in your browser settings</Trans>
            ) : (
              <Trans>Enable notifications to get new reviews instantly</Trans>
            )}
          </Text>
          {Platform.OS !== "web" && (
            <Button
              mt="xl"
              label={t`Open settings`}
              onPress={() => {
                Linking.openSettings()
              }}
            />
          )}
        </View>
      )}

      {(Platform.OS === "web"
        ? deviceState === "granted" || deviceState === undefined
        : ![0, 1].includes(deviceState.notificationPermissionStatus) &&
          deviceState.hasNotificationPermission) && (
        <>
          <View flex={1}>
            <Controller
              name="review"
              control={form.control}
              render={({
                field: { onChange, onBlur, onFocus, value, name },
              }) => (
                <Input error={form.formState.errors[name]?.message}>
                  <Input.Switch
                    label={t`New reviews`}
                    onValueChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    value={value}
                  />
                  <Input.Error />
                </Input>
              )}
            />

            <Controller
              name="energy"
              control={form.control}
              render={({
                field: { onChange, onBlur, onFocus, value, name },
              }) => (
                <Input mt="xl" error={form.formState.errors[name]?.message}>
                  <Input.Switch
                    label={t`Out of energy`}
                    onValueChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    value={value}
                  />
                  <Input.Error />
                </Input>
              )}
            />

            <Controller
              name="marketing"
              control={form.control}
              render={({
                field: { onChange, onBlur, onFocus, value, name },
              }) => (
                <Input mt="xl" error={form.formState.errors[name]?.message}>
                  <Input.Switch
                    label={t`Marketing`}
                    onValueChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    value={value}
                  />
                  <Input.Error />
                </Input>
              )}
            />
          </View>

          <Button
            loading={updateMutation.isLoading}
            disabled={updateMutation.isLoading}
            onPress={form.handleSubmit(onSubmit)}
            label={t`Save`}
            mt="xl"
          />
        </>
      )}
    </View>
  )
}

export function NotificationSettingsFormScreen({ navigation }) {
  const api = useApi()
  const queryClient = useQueryClient()

  const {
    data: deviceState,
    isError: isDeviceStateError,
    error: deviceStateError,
    isLoading: isDeviceStateLoading,
    refetch: refetchDeviceState,
  } = useQuery(
    "OneSignal.getDeviceState",
    Platform.OS === "web"
      ? () => OneSignal.getNotificationPermission()
      : OneSignal.getDeviceState,
  )

  useEffect(() => {
    const timeout = setTimeout(
      () => queryClient.cancelQueries("OneSignal.getDeviceState"),
      3000,
    )
    return () => clearTimeout(timeout)
  }, [queryClient])

  const {
    data: notificationSettings,
    isError,
    error,
    isLoading,
    refetch,
  } = useQuery("notificationSettings", api.getNotificationSettings)

  return (
    <>
      <Breakpoint values={["lg", "xl"]}>
        <TopNavBar />
      </Breakpoint>

      <Container breakpoint="xl">
        <Row>
          <NavBar title={t`Notifications`} />
        </Row>
      </Container>

      <Container flex={1}>
        <Row flex={1}>
          <View flex={1} justifyContent="center">
            {isLoading || isDeviceStateLoading ? (
              <ActivityIndicator />
            ) : isError || isDeviceStateError ? (
              <ErrorBody
                error={error || deviceStateError}
                reload={() => {
                  refetch()
                  refetchDeviceState()
                }}
              />
            ) : (
              <NotificationSettingsForm
                notificationSettings={notificationSettings}
                deviceState={deviceState}
                refetchDeviceState={refetchDeviceState}
              />
            )}
          </View>
        </Row>
      </Container>
    </>
  )
}
