import React from "react"
import seapig, { OPTIONAL } from "seapig"
import { LinkContainer } from "./link-button"
import { TouchableOpacity } from "./touchable-opacity"
import { View } from "./view"

export function ListItem({ children, to, onPress, containerProps, ...props }) {
  const { startChildren, middleChildren, endChildren } = seapig(children, {
    start: OPTIONAL,
    middle: OPTIONAL,
    end: OPTIONAL,
  })

  const ContainerComponent = to
    ? LinkContainer
    : onPress
    ? TouchableOpacity
    : View

  return (
    <ContainerComponent
      px="xl"
      justifyContent="center"
      onPress={onPress}
      to={to}
      {...containerProps}
    >
      <View
        py="lg"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        borderBottomWidth="hairline"
        borderColor="grey"
        {...props}
      >
        <View justifyContent="center" flex={1}>
          <View position="absolute">{startChildren}</View>
          <View ml="xxl">{middleChildren}</View>
        </View>
        {endChildren}
      </View>
    </ContainerComponent>
  )
}
