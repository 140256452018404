import branch from "branch-sdk"

if (!__DEV__) {
  branch.init(
    __DEV__
      ? "key_test_gf1KKh3UGTir3tPAd45MGloiuFjsw791"
      : "key_live_hc7KRo0QIUdv7AJDj39RRkdiAEgst5XH",
    {
      tracking_disabled: true,
    },
  )
}

export default branch
