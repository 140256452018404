import { t, Trans } from "@lingui/macro"
import AsyncStorage from "@react-native-async-storage/async-storage"
import React, { useEffect, useState } from "react"
import { isAndroid } from "react-device-detect"
import { Linking, Platform, StyleSheet } from "react-native"
import { useQuery } from "react-query"
import { useTheme } from "styled-components/native"
import logoLight from "../assets/logo-light.png"
import logo from "../assets/logo.png"
import { DeveloperAuthForm } from "../components/auth/developer-auth-form"
import {
  Button,
  Container,
  HideKeyboard,
  Image,
  KeyboardAvoidingView,
  LinkButton,
  Pressable,
  Row,
  Text,
  View,
} from "../components/ui"
import { Breakpoint, useSafeAreaOrDefaultInsets } from "../hooks"
import { appleAuth, AppleButton } from "../lib/apple-authentication"
import { config } from "../lib/config"
import { DARK_APPEARANCE } from "../lib/constants"
import {
  GoogleSignin,
  GoogleSigninButton,
  statusCodes,
} from "../lib/google-signin"
import {
  getErrorMessage,
  getInviteCode,
  validateInviteCode,
} from "../lib/helpers"
import { mixpanel } from "../lib/mixpanel"
import { light } from "../lib/theme"
import { useAlert, useApi, useLaunchCount, useStore } from "../providers"

export function AuthScreen({ navigation, route }) {
  const alert = useAlert()
  const theme = useTheme()
  const insets = useSafeAreaOrDefaultInsets({ bottom: theme.space.xl })
  const [authInProcess, setAuthInProcess] = useState(false)
  const { scheme, storeMutation } = useStore()
  const { launchCount } = useLaunchCount()
  const api = useApi()

  const [devCounter, setDevCounter] = useState(0)

  let { data: inviteCode } = useQuery("inviteCode", getInviteCode, {
    enabled: !config.detox && launchCount === 1,
  })

  inviteCode = validateInviteCode(route.params?.inviteCode ?? inviteCode)

  useEffect(() => {
    if (devCounter >= 10) return

    const timeout = setTimeout(() => setDevCounter(0), 3000)

    return () => clearTimeout(timeout)
  }, [devCounter])

  return (
    <Container flex={1}>
      <Row flex={1}>
        <HideKeyboard flex={1}>
          <KeyboardAvoidingView flex={1}>
            <View flex={1} px="xl" pt={insets.top} justifyContent="center">
              <Image
                mx="auto"
                style={styles.logo}
                source={scheme === DARK_APPEARANCE ? logo : logoLight}
              />
              <Pressable
                testID="devAuthButton"
                onPress={() => setDevCounter((c) => (c >= 10 ? c : c + 1))}
              >
                <Text
                  mt="xl"
                  textAlign="center"
                  fontSize={6}
                  fontFamily="heading"
                >
                  <Trans>Welcome to Testframe!</Trans>
                </Text>
              </Pressable>
              <Text
                textAlign="center"
                mt="md"
                fontSize={3}
                fontFamily="heading"
              >
                <Trans>Find your best photo.</Trans>
              </Text>

              {inviteCode && (
                <View mt="xl" mx="auto">
                  <Text fontSize={4} fontFamily="heading">
                    <Trans>Your are invited 🎉</Trans>
                  </Text>
                  <Text fontSize={2} fontFamily="heading">
                    <Trans>How to get reward?</Trans>
                  </Text>
                  <Text fontSize={2} fontFamily="heading">
                    1. <Trans>Sign in</Trans>
                  </Text>
                  <Text fontSize={2} fontFamily="heading">
                    2. <Trans>Create first test</Trans>
                  </Text>
                </View>
              )}

              {(config.detox || devCounter >= 10) && (
                <DeveloperAuthForm mt="xl" />
              )}

              {Platform.OS === "ios" && (
                <AppleButton
                  buttonStyle={
                    scheme === DARK_APPEARANCE
                      ? AppleButton.Style.WHITE
                      : AppleButton.Style.BLACK
                  }
                  buttonType={AppleButton.Type.SIGN_IN}
                  style={styles.appleButton}
                  onPress={async () => {
                    if (authInProcess) return

                    let user

                    try {
                      setAuthInProcess(true)

                      const appleAuthRequestResponse =
                        await appleAuth.performRequest({
                          requestedOperation: appleAuth.Operation.LOGIN,
                          requestedScopes: [
                            appleAuth.Scope.EMAIL,
                            appleAuth.Scope.FULL_NAME,
                          ],
                        })

                      if (appleAuthRequestResponse.email) {
                        await AsyncStorage.setItem(
                          "appleUserEmail",
                          appleAuthRequestResponse.email,
                        )
                      }

                      const email =
                        appleAuthRequestResponse.email ??
                        (await AsyncStorage.getItem("appleUserEmail"))

                      let name = ""

                      if (appleAuthRequestResponse.fullName?.familyName) {
                        name =
                          name + appleAuthRequestResponse.fullName.familyName
                      }
                      if (appleAuthRequestResponse.fullName?.givenName) {
                        name =
                          name +
                          " " +
                          appleAuthRequestResponse.fullName.givenName
                      }

                      if (name) {
                        await AsyncStorage.setItem("appleUserName", name)
                      } else {
                        name =
                          (await AsyncStorage.getItem("appleUserName")) ?? ""
                      }

                      user = {
                        userId: appleAuthRequestResponse.user,
                        idToken: appleAuthRequestResponse.identityToken,
                        email,
                        name,
                        inviteCode,
                      }

                      const {
                        data: { token, id },
                        isNew,
                      } = await api.createAppleUser({
                        userDto: user,
                      })

                      if (isNew) {
                        mixpanel.alias(
                          String(id),
                          await (Platform.OS === "web"
                            ? mixpanel.get_distinct_id()
                            : mixpanel.getDistinctId()),
                        )
                        mixpanel.track("Signed Up", {
                          platform: Platform.OS,
                          isInvited: Boolean(inviteCode),
                        })
                      } else {
                        mixpanel.track("Signed In", {
                          platform: Platform.OS,
                        })
                      }

                      storeMutation.mutate({ authToken: token })
                    } catch (err) {
                      if (err.code === "1001" || err.code === "1000") {
                        return
                      }

                      const { title, description } = getErrorMessage(err)
                      alert.current.showNotification({
                        title,
                        description,
                      })

                      if (!err.isAxiosError) {
                        throw err
                      }
                    } finally {
                      setAuthInProcess(false)
                    }
                  }}
                />
              )}
              {Platform.OS === "android" && (
                <Pressable
                  mt="xl"
                  disabled={authInProcess}
                  onPress={async () => {
                    if (authInProcess) return

                    let user

                    try {
                      setAuthInProcess(true)

                      GoogleSignin.configure({
                        webClientId:
                          "462342386016-sec39cnar8jjikvf5vtf0nhrgabl9nsg.apps.googleusercontent.com",
                      })

                      await GoogleSignin.hasPlayServices()
                      const userInfo = await GoogleSignin.signIn()

                      let name = ""

                      if (userInfo.user.givenName) {
                        name = name + userInfo.user.givenName
                      }

                      if (userInfo.user.familyName) {
                        name = name + " " + userInfo.user.familyName
                      }

                      user = {
                        userId: userInfo.user.id,
                        idToken: userInfo.idToken,
                        email: userInfo.user.email,
                        name,
                        inviteCode,
                      }

                      const {
                        data: { token, id },
                        isNew,
                      } = await api.createGoogleUser({
                        userDto: user,
                      })

                      if (isNew) {
                        mixpanel.alias(
                          String(id),
                          await (Platform.OS === "web"
                            ? mixpanel.get_distinct_id()
                            : mixpanel.getDistinctId()),
                        )
                        mixpanel.track("Signed Up", {
                          platform: Platform.OS,
                          isInvited: Boolean(inviteCode),
                        })
                      } else {
                        mixpanel.track("Signed In", {
                          platform: Platform.OS,
                        })
                      }

                      storeMutation.mutate({ authToken: token })
                    } catch (err) {
                      if (err.code === statusCodes.SIGN_IN_CANCELLED) {
                        return
                      }
                      if (err.code === statusCodes.IN_PROGRESS) {
                        return
                      }

                      // if (err.code === statusCodes.PLAY_SERVICES_NOT_AVAILABLE) {
                      //   return
                      // }

                      const { title, description } = getErrorMessage(err)
                      alert.current.showNotification({ title, description })

                      if (!err.isAxiosError) {
                        throw err
                      }
                    } finally {
                      setAuthInProcess(false)
                    }
                  }}
                >
                  <GoogleSigninButton
                    style={styles.googleButton}
                    size={GoogleSigninButton.Size.Wide}
                    color={
                      scheme === DARK_APPEARANCE
                        ? GoogleSigninButton.Color.Light
                        : GoogleSigninButton.Color.Dark
                    }
                    disabled={authInProcess}
                  />
                </Pressable>
              )}

              <Breakpoint values={["sm"]}>
                {Platform.OS === "web" && (
                  <Button
                    mt="xl"
                    size="lg"
                    variant="primary"
                    label={t`Download Mobile App`}
                    onPress={async () => {
                      if (isAndroid) {
                        window.open(
                          inviteCode
                            ? `https://play.google.com/store/apps/details?id=com.dimonnwc3.testframe&referrer=utm_source%3Dtestframe%26utm_medium%3Dinvite%26utm_campaign%3D${inviteCode}`
                            : "https://play.google.com/store/apps/details?id=com.dimonnwc3.testframe",
                          "_blank",
                        )
                        return
                      }

                      if (inviteCode) {
                        await navigator.clipboard.writeText(inviteCode)
                      }

                      window.open(
                        inviteCode
                          ? "https://apps.apple.com/app/apple-store/id1603039736?pt=123481498&ct=testframe-invite&mt=8"
                          : "https://apps.apple.com/app/apple-store/id1603039736",
                        "_blank",
                      )
                    }}
                  />
                )}
              </Breakpoint>

              <LinkButton
                variant="primary-outline"
                mt="xl"
                size="md"
                label={t`Sign in with E-Mail`}
                to={{ screen: "EmailAuth", params: { inviteCode } }}
              />
            </View>

            <View pb={insets.bottom} mx="xl" justifyContent="center">
              <Text textAlign="center" mt="xl">
                <Trans>
                  By signing in you agree to our{" "}
                  <Text
                    color="primary"
                    onPress={() => Linking.openURL(`${config.web.url}/terms`)}
                  >
                    Terms
                  </Text>{" "}
                  and{" "}
                  <Text
                    color="primary"
                    onPress={() => Linking.openURL(`${config.web.url}/privacy`)}
                  >
                    Privacy Policy
                  </Text>
                  .
                </Trans>
              </Text>
            </View>
          </KeyboardAvoidingView>
        </HideKeyboard>
      </Row>
    </Container>
  )
}

const styles = StyleSheet.create({
  appleButton: { width: "100%", height: 45, marginTop: light.space.xl },
  googleButton: { width: "100%", height: 58 },
  logo: { width: 128, height: 128 },
})
