import { Trans, t } from "@lingui/macro"
import React from "react"
import { useStore } from "../../providers"
import { View, Container, Row, Text, Button, LinkButton } from "../ui"

export function CookieBanner() {
  const { allowThirdPartyTracking, storeMutation } = useStore()

  if (allowThirdPartyTracking !== null) {
    return null
  }

  return (
    <View position="absolute" bottom={16} mx="xl">
      <Container
        breakpoint="xl"
        bg="soft"
        borderRadius={3}
        borderWidth="hairline"
      >
        <Row p="xl">
          <Text>
            <Trans>
              We use cookies to improve user experience and analyze website
              traffic. By clicking "Accept", you agree to our website's cookies.
              You can change your cookie settings at any time by clicking
              "Preferences".
            </Trans>
          </Text>

          <View mt="xl" flexDirection="row" justifyContent="center">
            <Button
              size="sm"
              px="xl"
              variant="primary-outline"
              label={t`Reject`}
              onPress={() =>
                storeMutation.mutate({
                  allowThirdPartyTracking: false,
                })
              }
            />

            <LinkButton
              variant="primary-outline"
              size="sm"
              px="xl"
              ml="xl"
              label={t`Preferences`}
              to={{ screen: "CookieSettingsForm" }}
            />

            <Button
              size="sm"
              px="xl"
              ml="xl"
              label={t`Accept`}
              onPress={() =>
                storeMutation.mutate({
                  allowThirdPartyTracking: true,
                })
              }
            />
          </View>
        </Row>
      </Container>
    </View>
  )
}
