import { useNavigation } from "@react-navigation/native"
import { useMutation, useQueryClient } from "react-query"
import { BUSINESS_TEST, DATING_TEST, SOCIAL_TEST } from "../lib/constants"
import { getErrorMessage } from "../lib/helpers"
import { mixpanel } from "../lib/mixpanel"
import { Sentry } from "../lib/sentry"
import { useAlert, useApi } from "../providers"

export function useCreateTest(testType) {
  const api = useApi()
  const navigation = useNavigation()
  const alert = useAlert()
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (...args) => {
      switch (testType) {
        case DATING_TEST:
          return api.createDatingTest(...args)
        case BUSINESS_TEST:
          return api.createBusinessTest(...args)
        case SOCIAL_TEST:
          return api.createSocialTest(...args)
      }
    },
    {
      onError: (err, variables, context) => {
        const { title, description } = getErrorMessage(err)
        alert.current.showNotification({ title, description })

        if (!err.isAxiosError) {
          Sentry.captureException(err)
        }
      },
      onSuccess: (test, variables, context) => {
        queryClient.invalidateQueries("testList")
        navigation.navigate("TestList")
        navigation.navigate("TestActivationForm", {
          id: test.id,
          firstActivation: true,
        })

        mixpanel.track("Test Created", {
          type: test.type,
        })
      },
    },
  )

  return mutation
}
