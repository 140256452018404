import "./lib/polyfills"
import { AppRegistry } from "react-native"
import { gestureHandlerRootHOC } from "react-native-gesture-handler"
import "./styles.css"
import { Root } from "./components/root"

AppRegistry.registerComponent("Testframe", () => gestureHandlerRootHOC(Root))

AppRegistry.runApplication("Testframe", {
  rootTag: document.getElementById("root"),
})

