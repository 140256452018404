import React from "react"
import styled from "styled-components/native"
import { color, space, variant } from "styled-system"
import { ActivityIndicator } from "./activity-indicator"
import { Icon } from "./icon"
import { Text } from "./text"
import { TouchableOpacity } from "./touchable-opacity"

export function ButtonComponent({
  loading,
  disabled,
  children,
  style,
  size,
  label,
  icon,
  iconFontSize,
  labelProps,
  ...passThroughProps
}) {
  const color = style[0].color
  const fontFamily = style[0].fontFamily
  const fontSize = style[0].fontSize

  return (
    <TouchableOpacity
      disabled={disabled}
      {...passThroughProps}
      activeOpacity={0.7}
      opacity={disabled ? 0.7 : 1}
      style={style}
    >
      {loading ? (
        <ActivityIndicator size="small" color={color} />
      ) : (
        <>
          {icon && (
            <Icon
              mr={label ? "sm" : ""}
              fontSize={iconFontSize}
              name={icon}
              color={color}
            />
          )}
          {label && (
            <Text
              {...labelProps}
              color={color}
              fontSize={fontSize}
              fontFamily={fontFamily}
              numberOfLines={1}
            >
              {label}
            </Text>
          )}
        </>
      )}
    </TouchableOpacity>
  )
}

export const Button = styled(ButtonComponent)`
  ${space}
  ${variant({
    variants: {
      primary: {
        color: "white",
        bg: "primary",
        fontFamily: "bold",
        fontSize: 3,
      },
      secondary: {
        color: "white",
        bg: "secondary",
        fontFamily: "bold",
        fontSize: 3,
      },
      "primary-outline": {
        color: "primary",
        borderColor: "primary",
        borderWidth: 1,
        fontFamily: "bold",
        fontSize: 3,
      },
      "secondary-outline": {
        color: "secondary",
        borderColor: "secondary",
        borderWidth: 1,
        fontFamily: "bold",
        fontSize: 3,
      },
      link: {
        color: "primary",
        fontFamily: "bold",
        fontSize: 3,
      },
      "link-secondary": {
        color: "graySecondary",
        fontFamily: "bold",
        fontSize: 3,
      },
      navbar: {
        color: "primary",
        fontFamily: "bold",
        fontSize: 2,
      },
    },
  })}
  ${variant({
    prop: "size",
    variants: {
      xs: {
        fontSize: 2,
      },
      sm: {
        height: 28,
        fontSize: 2,
      },
      md: {
        height: 38,
        fontSize: 3,
      },
      lg: {
        height: 48,
        fontSize: 3,
      },
    },
  })}
  ${color}
`

Button.defaultProps = {
  variant: "primary",
  size: "lg",
  borderRadius: 3,
  iconFontSize: 6,
  disabled: false,
  loading: false,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
}
