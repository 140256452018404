import { i18n } from "@lingui/core"
import { clamp } from "lodash"
import React, { useState } from "react"
import { useWindowDimensions } from "react-native"
import { useInfiniteQuery, useMutation, useQuery } from "react-query"
import { useTheme } from "styled-components/native"
import {
  ActivityIndicator,
  Button,
  Container,
  ErrorBody,
  FastImage,
  FlatList,
  ImagePreview,
  NavBar,
  Pressable,
  RefreshControl,
  Row,
  Text,
  TopNavBar,
  TouchableOpacity,
  useDialog,
  View,
} from "../../components/ui"
import { Breakpoint } from "../../hooks"
import {
  BUSINESS_TEST,
  ERROR_TEST_OPTION,
  TEST_REPORT_REASONS_OPTIONS,
  TEST_STATE_OPTIONS,
  TEST_TYPE_OPTIONS,
} from "../../lib/constants"
import { formatRelativeDate, getErrorMessage } from "../../lib/helpers"
import { Sentry } from "../../lib/sentry"
import { useAlert, useApi } from "../../providers"

export function AdminTestReportDetailsScreen({ navigation, route }) {
  const alert = useAlert()
  const api = useApi()
  const theme = useTheme()
  const { width } = useWindowDimensions()

  const openReportTestsDeleteDialog = useDialog()

  const [imageVisible, setImageIsVisible] = useState(false)

  const {
    data: report,
    isError: isReportError,
    error: reportError,
    isLoading: isReportLoading,
    refetch: refetchReport,
  } = useQuery(["testReport", route.params.id], () =>
    api.getAdminTestReport(route.params.id),
  )

  const {
    fetchNextPage,
    hasNextPage,
    refetch: refetchReportTests,
    data: reportTestsData,
    isError: reportTestsIsError,
    error: reportTestsError,
    isLoading: reportTestsIsLoading,
  } = useInfiniteQuery(
    ["testReport", route.params.id, "tests"],
    ({ pageParam = 1 }) =>
      api.getAdminTestList({
        page: pageParam,
        query: {
          photoIdEq: report.test.photo.id,
        },
      }),
    {
      enabled: Boolean(report),
      getNextPageParam: (lastPage, allPages) => lastPage.meta.next ?? undefined,
      getPreviousPageParam: (firstPage, allPages) =>
        firstPage.meta.prev ?? undefined,
    },
  )

  const reportTestsCount = reportTestsData?.pages[0]?.meta?.count ?? 0
  const allReportTests = reportTestsData?.pages?.flatMap((p) => p.data) ?? []

  const deleteReportTestsMutation = useMutation(
    async () => {
      for (const test of allReportTests) {
        await api.deleteAdminTest(test.id)
      }
    },
    {
      onError: (err, variables, context) => {
        const { title, description } = getErrorMessage(err)
        alert.current.showNotification({ title, description })

        if (!err.isAxiosError) {
          Sentry.captureException(err)
        }
      },
      onSuccess: (data, variables, context) => {
        refetchReport()
        refetchReportTests()
      },
    },
  )

  const imageSize = clamp(width, 0, theme.breakpoints.sm) - theme.space.xl * 2

  return (
    <>
      <Breakpoint values={["lg", "xl"]}>
        <TopNavBar />
      </Breakpoint>

      <Container breakpoint="xl">
        <Row>
          <NavBar title="Test Report" />
        </Row>
      </Container>

      <Container flex={1}>
        <Row flex={1}>
          <View flex={1} justifyContent="center">
            {isReportLoading ? (
              <ActivityIndicator />
            ) : isReportError ? (
              <ErrorBody
                error={reportError}
                reload={() => {
                  refetchReport()
                  refetchReportTests()
                }}
              />
            ) : (
              <View flex={1}>
                <FlatList
                  onEndReached={() => hasNextPage && fetchNextPage()}
                  refreshControl={
                    <RefreshControl
                      onRefresh={() => {
                        refetchReport()
                        refetchReportTests()
                      }}
                      refreshing={false}
                    />
                  }
                  data={allReportTests}
                  keyExtractor={(item) => item.id}
                  renderItem={({ item, index }) => {
                    const mb = allReportTests.length - 1 === index ? "xl" : 0

                    return (
                      <TestItem
                        mb={mb}
                        test={item}
                        report={report}
                        onPress={() =>
                          navigation.push("AdminTestDetails", {
                            id: item.id,
                          })
                        }
                      />
                    )
                  }}
                  ListEmptyComponent={
                    <View flex={1} mt="xxl">
                      {reportTestsIsLoading ? (
                        <ActivityIndicator />
                      ) : reportTestsIsError ? (
                        <ErrorBody
                          error={reportTestsError}
                          reload={refetchReportTests}
                        />
                      ) : reportTestsCount === 0 ? (
                        <Text
                          fontFamily="heading"
                          textAlign="center"
                          fontSize={4}
                        >
                          No tests yet
                        </Text>
                      ) : null}
                    </View>
                  }
                  ListHeaderComponent={
                    <View mx="xl">
                      <Pressable onPress={() => setImageIsVisible(true)}>
                        <FastImage
                          testID="image"
                          dataSet={{ private: true }}
                          style={{ width: imageSize, height: imageSize }}
                          source={{ uri: report.test.photo.url }}
                          borderRadius={3}
                          resizeMode={FastImage.resizeMode.cover}
                        />

                        <ImagePreview
                          uri={report.test.photo.url}
                          isOpen={imageVisible}
                          onClose={() => setImageIsVisible(false)}
                        />
                        <View
                          position="absolute"
                          width="100%"
                          top={0}
                          left={0}
                          bg="background"
                          opacity={0.7}
                          py="md"
                          px="xl"
                          flexDirection="row"
                        >
                          <View alignItems="flex-start" flex={1.2}>
                            <Text fontFamily="bold" fontSize={2}>
                              {i18n._(
                                TEST_REPORT_REASONS_OPTIONS.find(
                                  (t) => t.value === report.reason,
                                ).label,
                              )}
                            </Text>
                          </View>
                          <View
                            alignItems="flex-end"
                            justifyContent="center"
                            flex={1}
                          >
                            <Button
                              color="text"
                              variant="link"
                              iconFontSize={4}
                              size="xs"
                              icon="expand-arrows-alt"
                              onPress={() => setImageIsVisible(true)}
                            />
                          </View>
                        </View>
                        <View
                          position="absolute"
                          width="100%"
                          bottom={0}
                          left={0}
                          bg="background"
                          opacity={0.7}
                          py="md"
                          px="xl"
                          flexDirection="row"
                        >
                          <View alignItems="flex-start" flex={1}>
                            <Text fontFamily="bold" fontSize={2}>
                              ID: {report.id}
                            </Text>
                          </View>
                          <View alignItems="flex-end" flex={1}>
                            <Text fontFamily="bold" fontSize={2}>
                              {formatRelativeDate(report.createdAt)}
                            </Text>
                          </View>
                        </View>
                        {report.type === BUSINESS_TEST && (
                          <View
                            width="100%"
                            position="absolute"
                            bottom={38}
                            left={0}
                            py="md"
                            px="md"
                            bg="background"
                            opacity={0.7}
                          >
                            <Text
                              testID="bio"
                              dataSet={{ private: true }}
                              numberOfLines={1}
                              fontFamily="heading"
                              fontSize={3}
                            >
                              {report.bio}
                            </Text>
                          </View>
                        )}
                      </Pressable>

                      <Button
                        mt="xl"
                        variant="primary-outline"
                        label={`User (${report.userId})`}
                        onPress={() =>
                          navigation.push("AdminUserDetails", {
                            id: report.userId,
                          })
                        }
                      />

                      <Button
                        mt="xl"
                        variant="primary-outline"
                        label={`Test (${report.testId})`}
                        onPress={() =>
                          navigation.push("AdminTestDetails", {
                            id: report.testId,
                          })
                        }
                      />

                      {allReportTests.length > 0 && (
                        <>
                          <Text mt="xl" fontFamily="bold" fontSize={4}>
                            Same photo tests
                          </Text>

                          <Button
                            mt="xl"
                            label="Delete all tests"
                            loading={deleteReportTestsMutation.isLoading}
                            disabled={deleteReportTestsMutation.isLoading}
                            onPress={() =>
                              openReportTestsDeleteDialog({
                                title: "Are you sure?",
                                description:
                                  "All tests will be deleted and can't be restored",
                                buttons: [
                                  {
                                    label: "Delete",
                                    onPress: (value) =>
                                      deleteReportTestsMutation.mutate(),
                                  },
                                  {
                                    label: "Cancel",
                                    variant: "primary-outline",
                                  },
                                ],
                              })
                            }
                          />
                        </>
                      )}
                    </View>
                  }
                />
              </View>
            )}
          </View>
        </Row>
      </Container>
    </>
  )
}

function TestItem({ test, onPress, ...props }) {
  return (
    <TouchableOpacity
      {...props}
      mt="xl"
      mx="xl"
      borderRadius={3}
      overflow="hidden"
      onPress={() => onPress(test)}
    >
      <View bg="soft" px="xl" pb="xl">
        <View flexDirection="row" justifyContent="space-between" mt="lg">
          <Text fontFamily="bold">
            {i18n._(
              (
                TEST_TYPE_OPTIONS.find((t) => t.value === test.type) ??
                ERROR_TEST_OPTION
              ).label,
            )}
          </Text>
          <Text fontFamily="bold" fontSize={2}>
            {i18n._(
              TEST_STATE_OPTIONS.find((t) => t.value === test.state).label,
            )}
          </Text>
        </View>
        {Boolean(test.bio) && <Text mt="xl">{test.bio}</Text>}
        <View mt="xl" flexDirection="row" justifyContent="space-between">
          <Text color="grayPrimary">ID {test.id}</Text>
          <Text color="grayPrimary">{formatRelativeDate(test.createdAt)}</Text>
        </View>
      </View>
    </TouchableOpacity>
  )
}
