import { t, Trans } from "@lingui/macro"
import { omit } from "lodash"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from "react-query"
import {
  ActivityIndicator,
  Button,
  Container,
  ErrorBody,
  KeyboardAvoidingView,
  NavBar,
  Row,
  ScrollView,
  TopNavBar,
  useDialog,
  View,
} from "../components/ui"
import { Input } from "../components/ui/input"
import { Breakpoint } from "../hooks"
import { formNumberTransformer, getErrorMessage } from "../lib/helpers"
import { mixpanel } from "../lib/mixpanel"
import { Sentry } from "../lib/sentry"
import { useAlert, useApi } from "../providers"

function UserProfileForm({ user }) {
  const alert = useAlert()
  const api = useApi()
  const queryClient = useQueryClient()
  const openProfileDeleteDialog = useDialog()

  const updateMutation = useMutation(api.updateUser, {
    onError: (err, variables, context) => {
      const { title, description } = getErrorMessage(err)
      alert.current.showNotification({ title, description })

      if (!err.isAxiosError) {
        Sentry.captureException(err)
      }
    },
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData("currentUser", data)
      queryClient.invalidateQueries("nextTest")

      alert.current.showNotification({
        title: t`Profile update`,
        description: t`Profile successfully updated.`,
        componentProps: {
          alertType: "success",
        },
      })

      mixpanel.track("Profile Updated")
    },
  })

  const deleteMutation = useMutation(api.deleteUser, {
    onError: (err, variables, context) => {
      const { title, description } = getErrorMessage(err)
      alert.current.showNotification({ title, description })

      if (!err.isAxiosError) {
        Sentry.captureException(err)
      }
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("currentUser")

      alert.current.showNotification({
        title: t`Profile deletion`,
        description: t`Profile successfully deleted.`,
        componentProps: {
          alertType: "success",
        },
      })

      mixpanel.track("Profile Deleted")
    },
  })

  const form = useForm({
    defaultValues: {
      email: user.email,
      name: user.name,
      age: user.age,
      // gender: user.gender,
      // orientation: user.orientation,
    },
  })

  function onSubmit(data) {
    return updateMutation.mutate({ userDto: omit(data, ["email"]) })
  }

  function onDeletePress() {
    return openProfileDeleteDialog({
      title: t`Are you sure?`,
      description: t`Your data will be deleted and can't be restored.`,
      buttons: [
        {
          label: t`Delete`,
          onPress: (value) => deleteMutation.mutate(),
        },
        {
          label: t`Cancel`,
          variant: "primary-outline",
        },
      ],
    })
  }

  return (
    <>
      <ScrollView px="xl" keyboardShouldPersistTaps="handled">
        <Controller
          name="email"
          control={form.control}
          render={({ field: { onChange, onBlur, onFocus, value, name } }) => (
            <Input mt="xl" error={form.formState.errors[name]?.message}>
              <Input.Title>
                <Trans>E-Mail</Trans>
              </Input.Title>
              <Input.Text
                testID="email"
                dataSet={{ private: true }}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                value={value}
                editable={false}
              />
              <Input.Error />
            </Input>
          )}
        />

        <Controller
          name="name"
          control={form.control}
          render={({ field: { onChange, onBlur, onFocus, value, name } }) => (
            <Input mt="xl" error={form.formState.errors[name]?.message}>
              <Input.Title>
                <Trans>Name</Trans>
              </Input.Title>
              <Input.Text
                testID="name"
                dataSet={{ private: true }}
                maxLength={50}
                onFocus={onFocus}
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
                autoComplete="name"
                textContentType="name"
              />
              <Input.Error />
            </Input>
          )}
        />

        <Controller
          name="age"
          control={form.control}
          rules={{
            min: {
              value: 0,
              message: t`Value should be greater or equal than ${0}`,
            },
            max: {
              value: 99,
              message: t`Value should be less or equal than ${99}`,
            },
            validate: {
              number: (value) => {
                if (isNaN(value)) {
                  return t`Value should be a number`
                }

                return true
              },
            },
          }}
          render={({ field: { onChange, onBlur, onFocus, value, name } }) => (
            <Input mt="xl" error={form.formState.errors[name]?.message}>
              <Input.Title>
                <Trans>Age</Trans>
              </Input.Title>
              <Input.Text
                maxLength={3}
                onFocus={onFocus}
                onBlur={onBlur}
                onChangeText={(value) =>
                  onChange(formNumberTransformer.output(value))
                }
                value={formNumberTransformer.input(value)}
                keyboardType="number-pad"
              />
              <Input.Error />
            </Input>
          )}
        />

        {/* <Controller
          name="gender"
          control={form.control}
          render={({ field: { onChange, onBlur, onFocus, value, name } }) => {
            return (
              <Input mt="xl" error={form.formState.errors[name]?.message}>
                <Input.Title>
                  <Trans>Gender</Trans>
                </Input.Title>
                <Input.Segment
                  items={GENDER_OPTIONS.map((o) => ({
                    ...o,
                    label: i18n._(o.label),
                  }))}
                  onChange={onChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  value={value}
                />
                <Input.Error />
              </Input>
            )
          }}
        /> */}

        {/* <Controller
          name="orientation"
          control={form.control}
          render={({ field: { onChange, onBlur, onFocus, value, name } }) => {
            return (
              <Input mt="xl" error={form.formState.errors[name]?.message}>
                <Input.Title>
                  <Trans>Orientation</Trans>
                </Input.Title>

                <Input.Picker
                  title={t`Orientation`}
                  items={ORIENTATION_OPTIONS.map((o) => ({
                    ...o,
                    label: i18n._(o.label),
                  }))}
                  onChange={onChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  value={value}
                />
                <Input.Error />
              </Input>
            )
          }}
        /> */}

        {/* <Text fontSize={2} fontFamily="heading" color="grayPrimary">
          <Trans>
            Age, Gender and Orientation are used to target dating tests.
          </Trans>
        </Text> */}

        <Button
          loading={deleteMutation.isLoading}
          disabled={updateMutation.isLoading}
          variant="link"
          color="grayPrimary"
          label={t`Delete profile`}
          mt="xl"
          size="xs"
          onPress={onDeletePress}
        />
      </ScrollView>

      <Button
        m="xl"
        loading={updateMutation.isLoading}
        disabled={updateMutation.isLoading || deleteMutation.isLoading}
        onPress={form.handleSubmit(onSubmit)}
        label={t`Save`}
      />
    </>
  )
}

export function UserProfileFormScreen({ navigation }) {
  const api = useApi()
  const {
    data: user,
    isError,
    error,
    isLoading,
    refetch,
  } = useQuery("currentUser", api.getUser)

  return (
    <>
      <Breakpoint values={["lg", "xl"]}>
        <TopNavBar />
      </Breakpoint>

      <Container breakpoint="xl">
        <Row>
          <NavBar title={t`Profile`} />
        </Row>
      </Container>
      <KeyboardAvoidingView flex={1}>
        <Container flex={1}>
          <Row flex={1}>
            <View flex={1} justifyContent="center">
              {isLoading ? (
                <ActivityIndicator />
              ) : isError ? (
                <ErrorBody error={error} reload={refetch} />
              ) : (
                <UserProfileForm user={user} />
              )}
            </View>
          </Row>
        </Container>
      </KeyboardAvoidingView>
    </>
  )
}
