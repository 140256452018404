import React from "react"
import { Link as RNLink } from "@react-navigation/native"
import styled from "styled-components/native"
import {
  color,
  typography,
  layout,
  shadow,
  position,
  space,
  border,
  flexbox,
} from "styled-system"

const LinkComponent = styled(RNLink)`
  ${border}
  ${color}
  ${typography}
  ${layout}
  ${flexbox}
  ${shadow}
  ${position}
  ${space}
`

export function Link({ textTransform, ...props }) {
  const style = {}
  if (textTransform) {
    style.textTransform = textTransform
  }

  return <LinkComponent style={style} {...props} />
}

Link.defaultProps = {
  color: "primary",
  fontSize: 1,
  fontFamily: "regular",
}
