import React from "react"
import { Trans } from "@lingui/macro"
import { useQuery } from "react-query"
import { useApi } from "../../providers"
import { View, Text } from "../ui"
import { useModal } from "../modal-stack"

export function SpamWarningBar({ ...props }) {
  const api = useApi()
  const { openModal } = useModal()

  const { data: user } = useQuery("currentUser", api.getUser)

  if (!user || !user.spamWarnedAt) return null

  return (
    <View
      p="md"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      borderColor="error"
      borderWidth={2}
      borderRadius={3}
      {...props}
    >
      <Text fontFamily="heading" fontSize={2}>
        <Trans>
          Spam activity detected{" "}
          <Text
            fontFamily="heading"
            color="primary"
            fontSize={2}
            onPress={() => openModal({ name: "SpamActivityModal" })}
          >
            (read more)
          </Text>
        </Trans>
      </Text>
    </View>
  )
}
