import { i18n } from "@lingui/core"
import { t, Trans } from "@lingui/macro"
import { useFocusEffect } from "@react-navigation/native"
import { differenceInDays } from "date-fns"
import { clamp, shuffle } from "lodash"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { Keyboard, StyleSheet, useWindowDimensions } from "react-native"
import InAppReview from "react-native-in-app-review"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { TourGuideZone, useTourGuideController } from "rn-tourguide"
import { useTheme } from "styled-components/native"
import {
  callIfShouldAskPushNotificationsPermissions,
  useModal,
} from "../components/modal-stack"
import {
  ActivityIndicator,
  Button,
  Chip,
  Container,
  ErrorBody,
  FastImage,
  Icon,
  ImagePreview,
  Input,
  KeyboardAvoidingView,
  Link,
  LinkButton,
  NavBar,
  Pressable,
  Row,
  ScrollView,
  Text,
  TopNavBar,
  TouchableOpacity,
  useActionSheet,
  useDialog,
  View,
} from "../components/ui"
import {
  CompleteUserProfileForm,
  EnergyBar,
  SpamWarningBar,
} from "../components/user"
import { Breakpoint, useBreakpoints } from "../hooks"
import { config } from "../lib/config"
import {
  ALL_IMPRESSIONS_TEST_OPTIONS_BY_TYPE,
  ALL_RECOMMENDATIONS_TEST_OPTIONS_BY_TYPE,
  BUSINESS_TEST,
  DATING_TEST,
  DATING_TEST_SCORE_OPTIONS,
  DATING_TEST_WOULD_DATE_OPTION,
  ERROR_TEST_OPTION,
  MALE,
  PERSON_POSITION_ALONE,
  PERSON_POSITION_OPTIONS,
  SOCIAL_TEST,
  TEST_TYPE_OPTIONS,
} from "../lib/constants"
import {
  getErrorMessage,
  getTestCategoryColor,
  getTranslationOrOriginalText,
  isUserCompleteToRateDating,
} from "../lib/helpers"
import { mixpanel } from "../lib/mixpanel"
import { Sentry } from "../lib/sentry"
import { allBubbleColors } from "../lib/theme"
import { appStoreReviewPassed, useAlert, useApi, useStore } from "../providers"

const testLockTime = config.detox ? 0 : __DEV__ ? 10 : 10

export function ReviewScreen() {
  const scrollRef = useRef()
  const [buttonLockValue, setButtonLockValue] = useState(testLockTime)
  const [reviewCount, setReviewCount] = useState(0)
  const [comment, setComment] = useState("")
  const [datingScore, setDatingScore] = useState()
  const [selectedOptions, setSelectedOptions] = useState([])
  const [currentBubbleColors, setCurrentBubbleColors] = useState(
    shuffle(allBubbleColors),
  )

  const { width } = useWindowDimensions()
  const { breakpoints, currentBreakpointIndex, getBreakpointValue } =
    useBreakpoints()

  const [imageVisible, setImageIsVisible] = useState(false)

  const reviewScreenTourGuideKey = "reviewScreenTourGuideKey"

  const {
    canStart: canStartTourGuide,
    start: startTourGuide,
    eventEmitter: tourGuideEventEmitter,
  } = useTourGuideController(reviewScreenTourGuideKey)

  const { openModal } = useModal()
  const openTestSkipDialog = useDialog()
  const queryClient = useQueryClient()
  const openTypeSheet = useActionSheet()
  const {
    languageCode,
    selectedTestType,
    autoTranslate,
    storeMutation,
    lastPushNotificationPermissionsAskedAt,
    hadReviewWalkthrough,
    lastAppReviewPromptAt,
  } = useStore()

  const theme = useTheme()
  const alert = useAlert()
  const api = useApi()

  useEffect(() => {
    function handleStop() {
      return storeMutation.mutate({ hadReviewWalkthrough: true })
    }

    function handleStepChange(step) {
      if (step?.order === 7) {
        // scrollRef.current.scrollTo({ x: 0, y: 100, animated: true })
      }
    }

    function handleStart() {
      // scrollRef?.current?.scrollTo({ x: 0, y: 0, animated: true })
    }

    tourGuideEventEmitter?.on("start", handleStart)
    tourGuideEventEmitter?.on("stop", handleStop)
    tourGuideEventEmitter?.on("stepChange", handleStepChange)
    return () => {
      tourGuideEventEmitter?.off("start", handleStart)
      tourGuideEventEmitter?.off("stop", handleStop)
      tourGuideEventEmitter?.off("stepChange", handleStepChange)
    }
  }, [tourGuideEventEmitter, storeMutation])

  useFocusEffect(
    useCallback(() => {
      if (
        !config.detox &&
        canStartTourGuide &&
        hadReviewWalkthrough === false &&
        Boolean(selectedTestType)
      ) {
        startTourGuide()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canStartTourGuide, hadReviewWalkthrough, selectedTestType]),
  )

  const { data: user } = useQuery("currentUser", api.getUser)

  const {
    data: test,
    isError,
    error,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    ["nextTest", selectedTestType],
    async () => {
      const nextTest = await api.getNextTest(selectedTestType)

      if (test?.id !== nextTest?.id) {
        setButtonLockValue(testLockTime)
        resetState()
      }

      return nextTest
    },
    {
      enabled:
        Boolean(selectedTestType) &&
        (selectedTestType !== DATING_TEST ||
          (user && isUserCompleteToRateDating(user))),
    },
  )

  const { data: testBusinessTitleTranslation } = useQuery(
    ["testBusinessTitleTranslation", test?.id],
    () =>
      api.createTranslation({
        translationDto: { text: test.bio, language: languageCode },
      }),
    {
      enabled: autoTranslate && test?.type === BUSINESS_TEST,
    },
  )

  const createReviewMutation = useMutation(
    () => {
      const feedbacksAttributes = [
        ...selectedOptions.map((o) => ({ value: o })),
      ]

      if (test.type === DATING_TEST && appStoreReviewPassed) {
        feedbacksAttributes.push({ value: `score_${datingScore}` })
      }

      return api.createReview({
        reviewDto: {
          testId: test.id,
          comment,
          feedbacksAttributes,
        },
      })
    },
    {
      onError: (err, variables, context) => {
        if (err?.response?.status === 404) {
          loadNextTest()
          return
        } else {
          const { title, description } = getErrorMessage(err)
          alert.current.showNotification({ title, description })
        }

        if (!err.isAxiosError) {
          Sentry.captureException(err)
        }
      },
      onSuccess: async (data, variables, context) => {
        mixpanel.track("Review Created", {
          type: test.type,
        })

        const newReviewCount = reviewCount + 1
        setReviewCount(newReviewCount)

        loadNextTest()
        ;(async () => {
          if (newReviewCount % 5 === 0) {
            await callIfShouldAskPushNotificationsPermissions(
              { lastPushNotificationPermissionsAskedAt, storeMutation },
              () => openModal({ name: "PushNotificationsPermissionsModal" }),
            )
          }

          if (
            newReviewCount === 3 &&
            InAppReview.isAvailable() &&
            differenceInDays(new Date(), lastAppReviewPromptAt) > 14
          ) {
            const testsData = await api.getTestList({ page: 1, items: 99 })

            let reviewCount = 0

            for (const test of testsData.data) {
              const testStatistics = await api.getTestStatistics(test.id)

              reviewCount = reviewCount + testStatistics.reviewCount

              if (reviewCount >= 3) {
                const hasFlowFinishedSuccessfully =
                  await InAppReview.RequestInAppReview()

                if (hasFlowFinishedSuccessfully) {
                  storeMutation.mutate({ lastAppReviewPromptAt: new Date() })
                }

                break
              }
            }
          }
        })()
      },
    },
  )

  const createTestSkipMutation = useMutation(api.createTestSkip, {
    onError: (err, variables, context) => {
      if (err?.response?.status === 404) {
        loadNextTest()
        return
      } else {
        const { title, description } = getErrorMessage(err)
        alert.current.showNotification({ title, description })
      }

      if (!err.isAxiosError) {
        Sentry.captureException(err)
      }
    },
    onSuccess: (data, variables, context) => {
      loadNextTest()
      mixpanel.track("TestSkip Created")
    },
  })

  const handleSubmitPress = useCallback(() => {
    if (test?.type === DATING_TEST && appStoreReviewPassed && !datingScore) {
      alert.current.showNotification({
        title: t`Rate photo`,
        description: t`Dating photos must be rated 1-5.`,
        componentProps: {
          alertType: "info",
        },
      })
      return
    }

    createReviewMutation.mutate()
  }, [alert, test, datingScore, createReviewMutation])

  function loadNextTest() {
    queryClient.invalidateQueries("nextTest")
    queryClient.invalidateQueries("currentUser")
  }

  function resetState() {
    setComment("")
    setDatingScore(undefined)
    setSelectedOptions([])
    Keyboard.dismiss()
    setCurrentBubbleColors(shuffle(allBubbleColors))
    scrollRef?.current?.scrollTo({ x: 0, y: 0, animated: true })
  }

  useEffect(() => {
    // TODO move this to separate component to avoid whole review rerender
    const interval = setInterval(() => {
      setButtonLockValue((val) => {
        if (val < 2) {
          clearInterval(interval)
        }
        return val - 1
      })
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [test, isFetching])

  const buttonDisabled = selectedOptions.length < 1

  const energy = <EnergyBar />
  // const inviteFriend = <InviteFriendBar mb="xl" />
  // const rateUs = <RateUsBar mb="xl" />

  const spamWarning = <SpamWarningBar mb="xl" />

  const testTypeButton = selectedTestType ? (
    <Button
      start
      size="xs"
      variant="navbar"
      color={getTestCategoryColor(theme, selectedTestType)}
      label={i18n._(
        (
          TEST_TYPE_OPTIONS.find((t) => t.value === selectedTestType) ??
          ERROR_TEST_OPTION
        ).label,
      )}
      onPress={() => {
        return openTypeSheet({
          items: TEST_TYPE_OPTIONS.map((o) => ({
            ...o,
            label: i18n._(o.label),
            onPress: () => storeMutation.mutate({ selectedTestType: o.value }),
          })),
        })
      }}
    />
  ) : null

  const horizontalSpaceSize =
    clamp(width, 0, theme.breakpoints.sm) - theme.space.xl * 2

  const imageSize =
    (((breakpoints[currentBreakpointIndex - 1] ?? width) - theme.space.xl * 2) *
      getBreakpointValue([100, 100, 40, 40])) /
      100 -
    getBreakpointValue([
      theme.space.xs,
      theme.space.xs,
      theme.space.md,
      theme.space.md,
    ])

  return (
    <>
      <Breakpoint values={["lg", "xl"]}>
        <TopNavBar />
      </Breakpoint>

      <Container breakpoint="xl">
        <Row>
          <NavBar showBack={false} title={t`Rate`}>
            {selectedTestType && test && !isError ? (
              <TourGuideZone
                start
                text={t`You can select which type of tests to review: Dating, Business or Social.`}
                zone={3}
                tourKey={reviewScreenTourGuideKey}
              >
                {testTypeButton}
              </TourGuideZone>
            ) : (
              testTypeButton
            )}

            {selectedTestType &&
            test &&
            !isError &&
            getBreakpointValue([false, false, true, true]) ? (
              <View flex={3} middle>
                <TourGuideZone
                  text={t`This is your energy bar. Energy is used to get reviews on your photos. To earn energy, review others.`}
                  zone={2}
                  tourKey={reviewScreenTourGuideKey}
                >
                  {energy}
                </TourGuideZone>
              </View>
            ) : null}

            {selectedTestType && test && !isError && (
              <TourGuideZone
                end
                text={t`You can report a photo if it has inapropriate content or isn't displaying correctly.`}
                zone={4}
                tourKey={reviewScreenTourGuideKey}
              >
                <LinkButton
                  variant="navbar"
                  label={t`Report`}
                  to={{ screen: "TestReport", params: { testId: test.id } }}
                />
              </TourGuideZone>
            )}
          </NavBar>
        </Row>
      </Container>

      <KeyboardAvoidingView flex={1} justifyContent="center">
        {isLoading ? (
          <ActivityIndicator />
        ) : selectedTestType === DATING_TEST &&
          user &&
          !isUserCompleteToRateDating(user) ? (
          <Container flex={1}>
            <Row flex={1}>
              <View mx="xl">
                <Breakpoint values={["sm", "md"]}>
                  <View mb="xl">{energy}</View>
                </Breakpoint>
                {/* {inviteFriend} */}
                {/* {rateUs} */}
                {spamWarning}
              </View>
              <View flex={1} mx="xl">
                <CompleteUserProfileForm user={user} />
              </View>
            </Row>
          </Container>
        ) : isError ? (
          error?.response?.status === 404 ? (
            <Container breakpoint="xl" flex={1}>
              <Row flex={1}>
                <View mx="xl">
                  <Breakpoint values={["sm", "md"]}>
                    <View mb="xl">{energy}</View>
                  </Breakpoint>
                  {/* {inviteFriend} */}
                  {/* {rateUs} */}
                  {spamWarning}
                </View>
                <View
                  flex={1}
                  alignItems="center"
                  justifyContent="center"
                  mx="xl"
                >
                  <Text fontSize={5} fontFamily="bold">
                    <Trans>Come back later</Trans>
                  </Text>
                  <Text mt="md" fontSize={2}>
                    <Trans>No tests to review at the moment.</Trans>
                  </Text>
                  <Text mt="md" fontSize={2}>
                    {selectedTestType === DATING_TEST && (
                      <Trans>
                        Maybe no one else is targeting your gender, age or
                        orientation for the Dating category photo rating.{" "}
                        <Link
                          fontFamily="bold"
                          to={{ screen: "UserProfileForm" }}
                        >
                          Check your profile
                        </Link>{" "}
                        for incorrect data.
                      </Trans>
                    )}
                    {selectedTestType === DATING_TEST && " "}
                    <Trans>
                      You can also rate photos in{" "}
                      <Text
                        color="primary"
                        fontFamily="bold"
                        onPress={() =>
                          storeMutation.mutate({
                            selectedTestType:
                              selectedTestType === DATING_TEST
                                ? BUSINESS_TEST
                                : DATING_TEST,
                          })
                        }
                      >
                        {selectedTestType === DATING_TEST
                          ? t`Business`
                          : t`Dating`}
                      </Text>{" "}
                      or{" "}
                      <Text
                        color="primary"
                        fontFamily="bold"
                        onPress={() =>
                          storeMutation.mutate({
                            selectedTestType:
                              selectedTestType === DATING_TEST
                                ? SOCIAL_TEST
                                : selectedTestType === SOCIAL_TEST
                                ? BUSINESS_TEST
                                : SOCIAL_TEST,
                          })
                        }
                      >
                        {selectedTestType === DATING_TEST
                          ? t`Social`
                          : selectedTestType === SOCIAL_TEST
                          ? t`Business`
                          : t`Social`}
                      </Text>{" "}
                      to earn energy.
                    </Trans>
                  </Text>

                  <Button label={t`Reload`} variant="link" onPress={refetch} />
                </View>
              </Row>
            </Container>
          ) : (
            <ErrorBody error={error} reload={refetch} />
          )
        ) : selectedTestType ? (
          <>
            <ScrollView keyboardShouldPersistTaps="handled" ref={scrollRef}>
              <Container breakpoint="xl">
                <Row px="xl">
                  <Breakpoint values={["sm", "md"]}>
                    <View mb="xl">
                      <TourGuideZone
                        text={t`This is your energy bar. Energy is used to get reviews on your photos. To earn energy, review others.`}
                        zone={2}
                        tourKey={reviewScreenTourGuideKey}
                      >
                        {energy}
                      </TourGuideZone>
                    </View>
                  </Breakpoint>

                  {/* {inviteFriend} */}
                  {/* {rateUs} */}
                  {spamWarning}

                  <View
                    flexDirection={getBreakpointValue([
                      "column",
                      "column",
                      "row",
                      "row",
                    ])}
                  >
                    <View
                      flex={getBreakpointValue([
                        undefined,
                        undefined,
                        0.4,
                        0.4,
                      ])}
                    >
                      <TourGuideZone
                        text={t`This is a photo for review. You earn energy by reviewing others.`}
                        zone={1}
                        tourKey={reviewScreenTourGuideKey}
                      >
                        <View borderRadius={3}>
                          <Pressable
                            onPress={() => {
                              Keyboard.dismiss()
                              setImageIsVisible(true)
                            }}
                          >
                            <FastImage
                              testID="image"
                              dataSet={{ private: true }}
                              style={{ width: imageSize, height: imageSize }}
                              source={{ uri: test.photo.url }}
                              borderRadius={3}
                              resizeMode={FastImage.resizeMode.cover}
                            />
                          </Pressable>

                          <ImagePreview
                            uri={test.photo.url}
                            isOpen={imageVisible}
                            onClose={() => setImageIsVisible(false)}
                          />

                          {isFetching && (
                            <View
                              position="absolute"
                              width="100%"
                              height="100%"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <ActivityIndicator size="large" />
                            </View>
                          )}

                          {test.type === DATING_TEST &&
                            appStoreReviewPassed && (
                              <View
                                width="100%"
                                position={getBreakpointValue([
                                  "absolute",
                                  "absolute",
                                  "relative",
                                  "relative",
                                ])}
                                bottom={0}
                                left={0}
                              >
                                <TourGuideZone
                                  text={t`Score photo from 1 to 5.`}
                                  zone={6}
                                  tourKey={reviewScreenTourGuideKey}
                                >
                                  <View
                                    py="lg"
                                    px="xxl"
                                    bg="background"
                                    opacity={0.9}
                                    flexDirection="row"
                                    alignItems="center"
                                    justifyContent="space-around"
                                  >
                                    {DATING_TEST_SCORE_OPTIONS.map((o, idx) => {
                                      const index = idx + 1

                                      return (
                                        <TouchableOpacity
                                          key={o.value}
                                          onPress={() => {
                                            Keyboard.dismiss()
                                            setDatingScore(o.value)
                                          }}
                                        >
                                          <Icon
                                            name="star"
                                            solid={
                                              datingScore &&
                                              datingScore >= index
                                            }
                                            color="business"
                                            fontSize={7}
                                          />
                                        </TouchableOpacity>
                                      )
                                    })}
                                  </View>
                                </TourGuideZone>
                              </View>
                            )}

                          {test.type === BUSINESS_TEST && (
                            <View
                              width="100%"
                              position={getBreakpointValue([
                                "absolute",
                                "absolute",
                                "relative",
                                "relative",
                              ])}
                              bottom={0}
                              left={0}
                              py="md"
                              px="md"
                              bg="background"
                              opacity={0.7}
                              alignItems="center"
                            >
                              <Text
                                testID="bio"
                                dataSet={{ private: true }}
                                numberOfLines={1}
                                fontSize={3}
                              >
                                {
                                  getTranslationOrOriginalText({
                                    translation: testBusinessTitleTranslation,
                                    language: languageCode,
                                    text: test.bio,
                                  }).text
                                }
                              </Text>
                            </View>
                          )}

                          <View style={styles.lightbox} opacity={0.4}>
                            <Button
                              width={38}
                              color="text"
                              bg="background"
                              iconFontSize={4}
                              size="md"
                              icon="expand-arrows-alt"
                              onPress={() => setImageIsVisible(true)}
                            />
                          </View>

                          {test.photo.position !== PERSON_POSITION_ALONE && (
                            <View
                              position="absolute"
                              top={8}
                              left={8}
                              py="md"
                              px="md"
                              bg="background"
                              opacity={0.7}
                              alignItems="center"
                              borderRadius={3}
                            >
                              <Text fontFamily="heading">
                                {i18n._(
                                  (
                                    PERSON_POSITION_OPTIONS.find(
                                      (t) => t.value === test.photo.position,
                                    ) ?? ERROR_TEST_OPTION
                                  ).label,
                                )}
                              </Text>
                            </View>
                          )}
                        </View>
                      </TourGuideZone>

                      <View mt="xl">
                        <TourGuideZone
                          text={t`Leave some comment.`}
                          zone={7}
                          tourKey={reviewScreenTourGuideKey}
                        >
                          <Input
                            px="lg"
                            py="sm"
                            borderColor="graySecondary"
                            borderRadius={3}
                            borderWidth="hairline"
                          >
                            <Input.Text
                              {...getBreakpointValue([
                                {},
                                {},
                                { numberOfLines: 6 },
                                { numberOfLines: 6 },
                              ])}
                              testID="comment"
                              dataSet={{ private: true }}
                              multiline
                              maxLength={1000}
                              borderBottomWidth={0}
                              placeholder={t`Write your comment (optional)`}
                              onChangeText={(value) => setComment(value)}
                              value={comment}
                              scrollEnabled={false}
                            />
                          </Input>
                        </TourGuideZone>
                      </View>
                    </View>

                    <View
                      flex={getBreakpointValue([
                        undefined,
                        undefined,
                        0.6,
                        0.6,
                      ])}
                      ml={getBreakpointValue(["xs", "xs", "xl", "xl"])}
                    >
                      {[DATING_TEST, BUSINESS_TEST, SOCIAL_TEST].includes(
                        test.type,
                      ) && (
                        <>
                          <TourGuideZone
                            text={t`Select some options.`}
                            zone={8}
                            tourKey={reviewScreenTourGuideKey}
                          >
                            <View>
                              <View
                                mt={getBreakpointValue([
                                  "xl",
                                  "xl",
                                  "xs",
                                  "xs",
                                ])}
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Text fontSize={3} fontFamily="heading">
                                  <Trans>Impressions</Trans>
                                </Text>
                                <TourGuideZone
                                  text={t`You can skip a photo if you wish.`}
                                  zone={5}
                                  tourKey={reviewScreenTourGuideKey}
                                >
                                  <Button
                                    variant="link-secondary"
                                    size="xs"
                                    label={t`Skip`}
                                    disabled={
                                      createReviewMutation.isLoading ||
                                      createTestSkipMutation.isLoading ||
                                      buttonLockValue > 0
                                    }
                                    onPress={() =>
                                      openTestSkipDialog({
                                        title: t`Skip`,
                                        description: t`Are you sure?`,
                                        buttons: [
                                          {
                                            label: t`Skip`,
                                            onPress: (value) =>
                                              createTestSkipMutation.mutateAsync(
                                                {
                                                  testSkipDto: {
                                                    testId: test.id,
                                                  },
                                                },
                                              ),
                                          },
                                          {
                                            label: t`Cancel`,
                                            variant: "primary-outline",
                                          },
                                        ],
                                      })
                                    }
                                  />
                                </TourGuideZone>
                              </View>
                              <View
                                mt="xl"
                                flexDirection="row"
                                flexWrap="wrap"
                                flexGap="xl"
                              >
                                {(
                                  ALL_IMPRESSIONS_TEST_OPTIONS_BY_TYPE[
                                    test.type
                                  ] ?? []
                                ).map((o, idx) => (
                                  <Chip
                                    key={o.value}
                                    px={getBreakpointValue([
                                      "lg",
                                      "lg",
                                      "md",
                                      "md",
                                    ])}
                                    py={getBreakpointValue([
                                      "md",
                                      "md",
                                      "sm",
                                      "sm",
                                    ])}
                                    testID={`rateImpression${o.value}`}
                                    onPress={() => {
                                      Keyboard.dismiss()

                                      return selectedOptions.includes(o.value)
                                        ? setSelectedOptions(
                                            selectedOptions.filter(
                                              (s) => s !== o.value,
                                            ),
                                          )
                                        : setSelectedOptions([
                                            ...selectedOptions,
                                            o.value,
                                          ])
                                    }}
                                    bg={
                                      selectedOptions.includes(o.value)
                                        ? currentBubbleColors[idx]
                                        : "border"
                                    }
                                  >
                                    <Text
                                      color={
                                        selectedOptions.includes(o.value)
                                          ? "white"
                                          : "text"
                                      }
                                      fontFamily="bold"
                                    >
                                      {i18n._(o.label, {
                                        gender:
                                          o.value ===
                                          DATING_TEST_WOULD_DATE_OPTION
                                            ? user?.gender ?? MALE
                                            : test.gender ?? MALE,
                                      })}
                                    </Text>
                                  </Chip>
                                ))}
                              </View>
                            </View>
                          </TourGuideZone>

                          <View>
                            <Text fontSize={3} fontFamily="heading" mt="xl">
                              <Trans>Recommendations</Trans>
                            </Text>
                            <View
                              mt="xl"
                              flexDirection="row"
                              flexWrap="wrap"
                              flexGap="xl"
                            >
                              {(
                                ALL_RECOMMENDATIONS_TEST_OPTIONS_BY_TYPE[
                                  test.type
                                ] ?? []
                              ).map((o, idx) => (
                                <Chip
                                  key={o.value}
                                  px={getBreakpointValue([
                                    "lg",
                                    "lg",
                                    "md",
                                    "md",
                                  ])}
                                  py={getBreakpointValue([
                                    "md",
                                    "md",
                                    "sm",
                                    "sm",
                                  ])}
                                  onPress={() => {
                                    Keyboard.dismiss()

                                    return selectedOptions.includes(o.value)
                                      ? setSelectedOptions(
                                          selectedOptions.filter(
                                            (s) => s !== o.value,
                                          ),
                                        )
                                      : setSelectedOptions([
                                          ...selectedOptions,
                                          o.value,
                                        ])
                                  }}
                                  bg={
                                    selectedOptions.includes(o.value)
                                      ? [...currentBubbleColors].reverse()[idx]
                                      : "border"
                                  }
                                >
                                  <Text
                                    color={
                                      selectedOptions.includes(o.value)
                                        ? "white"
                                        : "text"
                                    }
                                    fontFamily="bold"
                                  >
                                    {i18n._(o.label, {
                                      gender:
                                        o.value ===
                                        DATING_TEST_WOULD_DATE_OPTION
                                          ? user?.gender ?? MALE
                                          : test.gender ?? MALE,
                                    })}
                                  </Text>
                                </Chip>
                              ))}
                            </View>
                          </View>
                        </>
                      )}
                    </View>
                  </View>
                </Row>
              </Container>
            </ScrollView>

            <Container breakpoint="xl">
              <Row>
                <View m="xl">
                  <TourGuideZone
                    text={t`Let's rate a few pictures and then upload your own.`}
                    zone={9}
                    tourKey={reviewScreenTourGuideKey}
                  >
                    <Button
                      disabled={
                        buttonDisabled ||
                        createReviewMutation.isLoading ||
                        createTestSkipMutation.isLoading ||
                        buttonLockValue > 0
                      }
                      loading={createReviewMutation.isLoading}
                      label={
                        t`Submit` +
                        (buttonLockValue > 0 ? ` (${buttonLockValue})` : "")
                      }
                      onPress={handleSubmitPress}
                    />
                  </TourGuideZone>
                </View>
              </Row>
            </Container>
          </>
        ) : (
          <Container flex={1} breakpoint="xl">
            <Row flex={1}>
              <View mx="xl">
                <Breakpoint values={["sm", "md"]}>
                  <View mb="xl">{energy}</View>
                </Breakpoint>

                <Text fontSize={6} fontFamily="heading">
                  <Trans>Select a category to rate</Trans>
                </Text>
                <Text mt="md" fontSize={3} fontFamily="heading">
                  <Trans>Each category tests different traits.</Trans>
                </Text>
              </View>

              <View flex={1} justifyContent="center">
                <View
                  flex={1}
                  maxHeight={imageSize}
                  mx="xl"
                  pb="xl"
                  flexDirection={getBreakpointValue([
                    "column",
                    "column",
                    "row",
                    "row",
                  ])}
                >
                  {TEST_TYPE_OPTIONS.map((o, idx) => {
                    return (
                      <TouchableOpacity
                        flex={1}
                        testID={`select${o.value}Type`}
                        justifyContent="center"
                        key={o.value}
                        mt="xl"
                        ml={
                          idx === 0
                            ? "xs"
                            : getBreakpointValue(["xs", "xs", "xl", "xl"])
                        }
                        bg={getTestCategoryColor(theme, o.value)}
                        alignItems="center"
                        borderRadius={3}
                        height={(horizontalSpaceSize - theme.space.xl * 2) / 3}
                        onPress={() =>
                          storeMutation.mutate({
                            selectedTestType: o.value,
                          })
                        }
                      >
                        <Icon mb="md" color="white" name={o.icon} />
                        <Text fontFamily="bold" color="white">
                          {i18n._(o.label)}
                        </Text>
                      </TouchableOpacity>
                    )
                  })}
                </View>
              </View>
            </Row>
          </Container>
        )}
      </KeyboardAvoidingView>
    </>
  )
}

const styles = StyleSheet.create({
  lightbox: { position: "absolute", top: 8, right: 8 },
})
