import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import { config } from "./config"

export const routingInstrumentation = {
  registerNavigationContainer: () => {},
}

if (!__DEV__) {
  Sentry.init({
    dsn: "https://c3f2e0f9153944aab4698fb907d366c9@o132808.ingest.sentry.io/6643836",
    integrations: [new BrowserTracing()],
    release: config.version,
    tracesSampleRate: 0.3,
    ignoreErrors: ["OneSignal: This web push config can only be used on"],
  })
}

export { Sentry }

export function handleRenderError(err) {
  Sentry.withScope(() => {
    Sentry.captureException(err)
  })
}
