import React from "react"
import { RefreshControl as RNRefreshControl } from "react-native"
import { useTheme } from "styled-components/native"

export const RefreshControl = ({
  tintColor,
  colors,
  progressBackgroundColor,
  ...props
}) => {
  const theme = useTheme()
  tintColor = theme.colors[tintColor] ?? tintColor
  progressBackgroundColor =
    theme.colors[progressBackgroundColor] ?? progressBackgroundColor
  colors = colors.map((c) => theme.colors[c] ?? c)

  return (
    <RNRefreshControl
      {...props}
      progressBackgroundColor={progressBackgroundColor}
      tintColor={tintColor}
      colors={colors}
    />
  )
}

RefreshControl.defaultProps = {
  colors: ["primary"],
  tintColor: "text",
  progressBackgroundColor: "background",
  size: "default",
}
