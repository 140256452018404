import React from "react"
import { Trans, t } from "@lingui/macro"
import { useQuery } from "react-query"
import { MAX_ENERGY } from "../../lib/constants"
import { useApi, useAlert } from "../../providers"
import { View, Text, ProgressBar, Button } from "../ui"

export function EnergyBar({ ...props }) {
  const api = useApi()
  const alert = useAlert()

  const { data: user, isError: isUserError } = useQuery(
    "currentUser",
    api.getUser,
  )

  const percent = ((user?.energy ?? 0) / MAX_ENERGY) * 100

  return (
    <View flexDirection="row" alignItems="center" {...props}>
      <Text fontFamily="heading" fontSize={4}>
        <Trans>Energy</Trans>
      </Text>
      {isUserError ? null : (
        <ProgressBar flex={1} size="lg" ml="xl" percent={percent} />
      )}
      <Button
        position="absolute"
        right="md"
        variant="navbar"
        size="xs"
        color="primary"
        iconFontSize={3}
        icon="question-circle"
        onPress={() =>
          alert.current.showNotification({
            title: t`Energy`,
            description: t`Energy is used to get reviews on your photos. To earn energy, review others.
              Buy coins to get a guaranteed number of reviews faster.`,
            duration: 20000,
            componentProps: {
              alertType: "info",
            },
          })
        }
      />
    </View>
  )
}
