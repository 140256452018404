import { i18n } from "@lingui/core"
import { t, Trans } from "@lingui/macro"
import { shuffle } from "lodash"
import React, { useRef, useState } from "react"
import { Keyboard, StyleSheet, useWindowDimensions } from "react-native"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useTheme } from "styled-components/native"
import {
  ActivityIndicator,
  BottomSheet,
  Button,
  Chip,
  Container,
  ErrorBody,
  FastImage,
  Icon,
  ImagePreview,
  Input,
  KeyboardAvoidingView,
  NavBar,
  Pressable,
  Row,
  ScrollView,
  Text,
  TopNavBar,
  TouchableOpacity,
  View,
} from "../../components/ui"
import {
  Breakpoint,
  useBreakpoints,
  useSafeAreaOrDefaultInsets,
} from "../../hooks"
import {
  ALL_IMPRESSIONS_TEST_OPTIONS_BY_TYPE,
  ALL_RECOMMENDATIONS_TEST_OPTIONS_BY_TYPE,
  BUSINESS_TEST,
  DATING_TEST,
  DATING_TEST_SCORE_OPTIONS,
  DATING_TEST_WOULD_DATE_OPTION,
  ERROR_TEST_OPTION,
  GENDER_OPTIONS,
  MALE,
  MANAGER_ROLE,
  PERSON_POSITION_ALONE,
  PERSON_POSITION_OPTIONS,
  SOCIAL_TEST,
  TEST_TYPE_OPTIONS,
  TEST_TYPES,
  TESTER_ENERGY_COST,
} from "../../lib/constants"
import {
  getErrorMessage,
  getTranslationOrOriginalText,
} from "../../lib/helpers"
import { Sentry } from "../../lib/sentry"
import { allBubbleColors } from "../../lib/theme"
import { useAlert, useApi, useStore } from "../../providers"

export function AdminReviewScreen({ navigation, route }) {
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const scrollRef = useRef()
  const [comment, setComment] = useState("")
  const theme = useTheme()
  const [datingScore, setDatingScore] = useState()
  const [selectedOptions, setSelectedOptions] = useState([])
  const insets = useSafeAreaOrDefaultInsets({ bottom: theme.space.xl })
  const { languageCode, autoTranslate } = useStore()

  const [filter, setFilter] = useState({
    reviewsAvailableGt: 0,
    typeIn: TEST_TYPES,
    userGenderIn: [],
    sorts: "last_review_at asc",
  })

  const [imageVisible, setImageIsVisible] = useState(false)

  const queryClient = useQueryClient()

  const [currentBubbleColors, setCurrentBubbleColors] = useState(
    shuffle(allBubbleColors),
  )

  const { width } = useWindowDimensions()
  const { breakpoints, currentBreakpointIndex, getBreakpointValue } =
    useBreakpoints()

  const alert = useAlert()
  const api = useApi()

  const { data: user } = useQuery("currentUser", api.getUser)

  const {
    data: test,
    isError,
    error,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    [
      "adminNextTest",
      {
        userId: route.params?.userId,
        idEq: route.params?.testId,
        filter: !route.params?.testId ? filter : undefined,
      },
    ],
    () => {
      resetState()
      return api.getAdminNextTest({
        query: {
          idEq: route.params?.testId,
          userIdEq: route.params?.userId,
          ...(!route.params?.testId ? filter : {}),
        },
      })
    },
  )

  const { data: testBusinessTitleTranslation } = useQuery(
    ["testBusinessTitleTranslation", test?.id],
    () =>
      api.createTranslation({
        translationDto: { text: test.bio, language: languageCode },
      }),
    {
      enabled: autoTranslate && test?.type === BUSINESS_TEST,
    },
  )

  const createReviewMutation = useMutation(
    () => {
      const feedbacksAttributes = [
        ...selectedOptions.map((o) => ({ value: o })),
      ]

      if (test.type === DATING_TEST) {
        feedbacksAttributes.push({ value: `score_${datingScore}` })
      }

      return api.createAdminReview({
        reviewDto: {
          testId: test.id,
          comment,
          feedbacksAttributes,
        },
      })
    },
    {
      onError: (err, variables, context) => {
        if (err?.response?.status === 404) {
          loadNextTest()
          return
        } else {
          const { title, description } = getErrorMessage(err)
          alert.current.showNotification({ title, description })
        }

        if (!err.isAxiosError) {
          Sentry.captureException(err)
        }
      },
      onSuccess: (data, variables, context) => {
        loadNextTest()
      },
    },
  )

  function loadNextTest() {
    queryClient.invalidateQueries("adminNextTest")
    queryClient.invalidateQueries("currentUser")
  }

  function resetState() {
    setComment("")
    setCurrentBubbleColors(shuffle(allBubbleColors))
    setDatingScore(undefined)
    setSelectedOptions([])
    scrollRef?.current?.scrollTo({ x: 0, y: 0, animated: true })
  }

  let buttonDisabled = selectedOptions.length < 1
  if (test?.type === DATING_TEST) {
    buttonDisabled = !datingScore
  }

  const imageSize =
    (((breakpoints[currentBreakpointIndex - 1] ?? width) - theme.space.xl * 2) *
      getBreakpointValue([100, 100, 40, 40])) /
      100 -
    getBreakpointValue([
      theme.space.xs,
      theme.space.xs,
      theme.space.md,
      theme.space.md,
    ])

  return (
    <>
      <Breakpoint values={["lg", "xl"]}>
        <TopNavBar />
      </Breakpoint>

      <Container breakpoint="xl">
        <Row>
          <NavBar showBack={user?.role !== MANAGER_ROLE}>
            {!route.params?.testId && (
              <Button
                end={user?.role !== MANAGER_ROLE}
                start={user?.role === MANAGER_ROLE}
                ml={user?.role === MANAGER_ROLE ? "xs" : "xl"}
                size="xs"
                variant="navbar"
                label={
                  filter.reviewsAvailableGt === 0 ? "Show free" : "Show paid"
                }
                onPress={() =>
                  filter.reviewsAvailableGt === 0
                    ? setFilter({
                        reviewsAvailableEq: 0,
                        userEnergyGteq: TESTER_ENERGY_COST,
                        sorts: "last_review_at asc",
                        typeIn: filter.typeIn,
                        userGenderIn: filter.userGenderIn,
                      })
                    : setFilter({
                        reviewsAvailableGt: 0,
                        sorts: "last_review_at asc",
                        typeIn: filter.typeIn,
                        userGenderIn: filter.userGenderIn,
                      })
                }
              />
            )}

            {!route.params?.testId && (
              <Button
                end
                ml="xl"
                size="xs"
                variant="navbar"
                label="Filter"
                onPress={() => setIsFilterOpen(true)}
              />
            )}

            {test && !isError && (
              <Button
                end
                ml="xl"
                size="xs"
                variant="navbar"
                label="Test"
                onPress={() =>
                  navigation.push("AdminTestDetails", { id: test.id })
                }
              />
            )}
          </NavBar>
        </Row>
      </Container>

      {!route.params?.testId && (
        <BottomSheet
          isOpen={isFilterOpen}
          onClose={() => setIsFilterOpen(false)}
          onDone={() => setIsFilterOpen(false)}
          title="Filter"
        >
          <View
            style={{
              paddingHorizontal: theme.space.xl,
              paddingBottom: insets.bottom,
            }}
          >
            <Input mt="xl">
              <Input.Title>Type</Input.Title>
              <Input.Picker
                multi
                items={TEST_TYPE_OPTIONS.map((o) => ({
                  ...o,
                  label: i18n._(o.label),
                }))}
                onChange={(val) =>
                  setFilter({
                    ...filter,
                    typeIn: val,
                  })
                }
                value={filter.typeIn}
              />
              <Input.Error />
            </Input>

            <Input mt="xl">
              <Input.Title>Gender</Input.Title>
              <Input.Picker
                multi
                items={GENDER_OPTIONS.map((o) => ({
                  ...o,
                  label: i18n._(o.label),
                }))}
                onChange={(val) =>
                  setFilter({
                    ...filter,
                    userGenderIn: val,
                  })
                }
                value={filter.userGenderIn}
              />
              <Input.Error />
            </Input>

            <Input mt="xl">
              <Input.Title>Sorts</Input.Title>
              <Input.Picker
                items={[
                  {
                    value: "created_at desc",
                    label: "Created Desc",
                  },
                  {
                    value: "created_at asc",
                    label: "Created Asc",
                  },
                  {
                    value: "last_review_at asc",
                    label: "Last review asc",
                  },
                  {
                    value: "last_review_at desc",
                    label: "Last review desc",
                  },
                ]}
                onChange={(val) =>
                  setFilter({
                    ...filter,
                    sorts: val,
                  })
                }
                value={filter.sorts}
              />
              <Input.Error />
            </Input>
          </View>
        </BottomSheet>
      )}

      <KeyboardAvoidingView flex={1} justifyContent="center">
        {isLoading ? (
          <ActivityIndicator />
        ) : isError ? (
          error?.response?.status === 404 ? (
            <Container breakpoint="xl" flex={1}>
              <Row flex={1}>
                <View
                  flex={1}
                  alignItems="center"
                  justifyContent="center"
                  px="lg"
                >
                  <Text fontSize={5} fontFamily="bold">
                    <Trans>Come back later</Trans>
                  </Text>
                  <Text mt="md" fontSize={2}>
                    <Trans>No tests to review at the moment</Trans>
                  </Text>
                  <Button label={t`Reload`} variant="link" onPress={refetch} />
                </View>
              </Row>
            </Container>
          ) : (
            <ErrorBody error={error} reload={refetch} />
          )
        ) : (
          <>
            <ScrollView keyboardShouldPersistTaps="handled" ref={scrollRef}>
              <Container breakpoint="xl">
                <Row px="xl">
                  <View
                    flexDirection={getBreakpointValue([
                      "column",
                      "column",
                      "row",
                      "row",
                    ])}
                  >
                    <View
                      flex={getBreakpointValue([
                        undefined,
                        undefined,
                        0.4,
                        0.4,
                      ])}
                    >
                      <View borderRadius={3}>
                        <Pressable
                          onPress={() => {
                            Keyboard.dismiss()
                            setImageIsVisible(true)
                          }}
                        >
                          <FastImage
                            testID="image"
                            dataSet={{ private: true }}
                            style={{ width: imageSize, height: imageSize }}
                            source={{ uri: test.photo.url }}
                            borderRadius={3}
                            resizeMode={FastImage.resizeMode.cover}
                          />
                        </Pressable>

                        <ImagePreview
                          uri={test.photo.url}
                          isOpen={imageVisible}
                          onClose={() => setImageIsVisible(false)}
                        />

                        {isFetching && (
                          <View
                            position="absolute"
                            width="100%"
                            height="100%"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <ActivityIndicator size="large" />
                          </View>
                        )}

                        {test.type === DATING_TEST && (
                          <View
                            width="100%"
                            position={getBreakpointValue([
                              "absolute",
                              "absolute",
                              "relative",
                              "relative",
                            ])}
                            bottom={0}
                            left={0}
                          >
                            <View
                              py="lg"
                              px="xxl"
                              bg="background"
                              opacity={0.9}
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="space-around"
                            >
                              {DATING_TEST_SCORE_OPTIONS.map((o, idx) => {
                                const index = idx + 1

                                return (
                                  <TouchableOpacity
                                    key={o.value}
                                    onPress={() => {
                                      Keyboard.dismiss()
                                      setDatingScore(o.value)
                                    }}
                                  >
                                    <Icon
                                      name="star"
                                      solid={
                                        datingScore && datingScore >= index
                                      }
                                      color="business"
                                      fontSize={7}
                                    />
                                  </TouchableOpacity>
                                )
                              })}
                            </View>
                          </View>
                        )}

                        {test.type === BUSINESS_TEST && (
                          <View
                            width="100%"
                            position={getBreakpointValue([
                              "absolute",
                              "absolute",
                              "relative",
                              "relative",
                            ])}
                            bottom={0}
                            left={0}
                            py="md"
                            px="md"
                            bg="background"
                            opacity={0.7}
                            alignItems="center"
                          >
                            <Text
                              testID="bio"
                              dataSet={{ private: true }}
                              numberOfLines={1}
                              fontSize={3}
                            >
                              {
                                getTranslationOrOriginalText({
                                  translation: testBusinessTitleTranslation,
                                  language: languageCode,
                                  text: test.bio,
                                }).text
                              }
                            </Text>
                          </View>
                        )}

                        <View style={styles.lightbox} opacity={0.4}>
                          <Button
                            width={38}
                            color="text"
                            bg="background"
                            iconFontSize={4}
                            size="md"
                            icon="expand-arrows-alt"
                            onPress={() => setImageIsVisible(true)}
                          />
                        </View>

                        {test.photo.position !== PERSON_POSITION_ALONE && (
                          <View
                            position="absolute"
                            top={8}
                            left={8}
                            py="md"
                            px="md"
                            bg="background"
                            opacity={0.7}
                            alignItems="center"
                            borderRadius={3}
                          >
                            <Text fontFamily="heading">
                              {i18n._(
                                (
                                  PERSON_POSITION_OPTIONS.find(
                                    (t) => t.value === test.photo.position,
                                  ) ?? ERROR_TEST_OPTION
                                ).label,
                              )}
                            </Text>
                          </View>
                        )}
                      </View>

                      <Input
                        mt="xl"
                        px="lg"
                        py="sm"
                        borderColor="graySecondary"
                        borderRadius={3}
                        borderWidth="hairline"
                      >
                        <Input.Text
                          {...getBreakpointValue([
                            {},
                            {},
                            { numberOfLines: 6 },
                            { numberOfLines: 6 },
                          ])}
                          testID="comment"
                          dataSet={{ private: true }}
                          multiline
                          maxLength={1000}
                          borderBottomWidth={0}
                          placeholder={t`Write your comment (optional)`}
                          onChangeText={(value) => setComment(value)}
                          value={comment}
                          scrollEnabled={false}
                        />
                      </Input>
                    </View>

                    <View
                      flex={getBreakpointValue([
                        undefined,
                        undefined,
                        0.6,
                        0.6,
                      ])}
                      ml={getBreakpointValue(["xs", "xs", "xl", "xl"])}
                    >
                      {[DATING_TEST, BUSINESS_TEST, SOCIAL_TEST].includes(
                        test.type,
                      ) && (
                        <>
                          <View>
                            <Text
                              fontSize={3}
                              fontFamily="heading"
                              mt={getBreakpointValue(["xl", "xl", "xs", "xs"])}
                            >
                              <Trans>Impressions</Trans>
                            </Text>
                            <View
                              flexDirection="row"
                              flexWrap="wrap"
                              mt="xl"
                              flexGap="xl"
                            >
                              {(
                                ALL_IMPRESSIONS_TEST_OPTIONS_BY_TYPE[
                                  test.type
                                ] ?? []
                              ).map((o, idx) => (
                                <Chip
                                  key={o.value}
                                  px={getBreakpointValue([
                                    "lg",
                                    "lg",
                                    "md",
                                    "md",
                                  ])}
                                  py={getBreakpointValue([
                                    "md",
                                    "md",
                                    "sm",
                                    "sm",
                                  ])}
                                  onPress={() => {
                                    Keyboard.dismiss()

                                    return selectedOptions.includes(o.value)
                                      ? setSelectedOptions(
                                          selectedOptions.filter(
                                            (s) => s !== o.value,
                                          ),
                                        )
                                      : setSelectedOptions([
                                          ...selectedOptions,
                                          o.value,
                                        ])
                                  }}
                                  bg={
                                    selectedOptions.includes(o.value)
                                      ? currentBubbleColors[idx]
                                      : "border"
                                  }
                                >
                                  <Text
                                    color={
                                      selectedOptions.includes(o.value)
                                        ? "white"
                                        : "text"
                                    }
                                    fontFamily="bold"
                                  >
                                    {i18n._(o.label, {
                                      gender:
                                        o.value ===
                                        DATING_TEST_WOULD_DATE_OPTION
                                          ? user?.gender ?? MALE
                                          : test.gender ?? MALE,
                                    })}
                                  </Text>
                                </Chip>
                              ))}
                            </View>
                          </View>

                          <View>
                            <Text fontSize={3} fontFamily="heading" mt="xl">
                              <Trans>Recommendations</Trans>
                            </Text>
                            <View
                              flexDirection="row"
                              flexWrap="wrap"
                              mt="xl"
                              flexGap="xl"
                            >
                              {(
                                ALL_RECOMMENDATIONS_TEST_OPTIONS_BY_TYPE[
                                  test.type
                                ] ?? []
                              ).map((o, idx) => (
                                <Chip
                                  key={o.value}
                                  px={getBreakpointValue([
                                    "lg",
                                    "lg",
                                    "md",
                                    "md",
                                  ])}
                                  py={getBreakpointValue([
                                    "md",
                                    "md",
                                    "sm",
                                    "sm",
                                  ])}
                                  onPress={() => {
                                    Keyboard.dismiss()

                                    return selectedOptions.includes(o.value)
                                      ? setSelectedOptions(
                                          selectedOptions.filter(
                                            (s) => s !== o.value,
                                          ),
                                        )
                                      : setSelectedOptions([
                                          ...selectedOptions,
                                          o.value,
                                        ])
                                  }}
                                  bg={
                                    selectedOptions.includes(o.value)
                                      ? [...currentBubbleColors].reverse()[idx]
                                      : "border"
                                  }
                                >
                                  <Text
                                    color={
                                      selectedOptions.includes(o.value)
                                        ? "white"
                                        : "text"
                                    }
                                    fontFamily="bold"
                                  >
                                    {i18n._(o.label, {
                                      gender:
                                        o.value ===
                                        DATING_TEST_WOULD_DATE_OPTION
                                          ? user?.gender ?? MALE
                                          : test.gender ?? MALE,
                                    })}
                                  </Text>
                                </Chip>
                              ))}
                            </View>
                          </View>
                        </>
                      )}
                    </View>
                  </View>
                </Row>
              </Container>
            </ScrollView>

            <Container breakpoint="xl">
              <Row>
                <Button
                  m="xl"
                  disabled={buttonDisabled || createReviewMutation.isLoading}
                  loading={createReviewMutation.isLoading}
                  label={t`Submit`}
                  onPress={createReviewMutation.mutate}
                />
              </Row>
            </Container>
          </>
        )}
      </KeyboardAvoidingView>
    </>
  )
}

const styles = StyleSheet.create({
  lightbox: { position: "absolute", top: 8, right: 8 },
})
