import styled from "styled-components/native"
import { SafeAreaView as RNSafeAreaView } from "react-native-safe-area-context"
import {
  space,
  layout,
  color,
  flexbox,
  border,
  position,
  shadow,
} from "styled-system"

export const SafeAreaView = styled(RNSafeAreaView)`
  ${space}
  ${layout}
  ${color}
  ${flexbox}
  ${border}
  ${position}
  ${shadow}
`
