import React from "react"
import { useTheme } from "styled-components/native"
import { useSafeAreaOrDefaultInsets } from "../../../hooks"
import { ActivityIndicator } from "../activity-indicator"
import { BottomSheet } from "../bottom-sheet"
import { Text } from "../text"
import { TouchableHighlight } from "../touchable-highlight"
import { View } from "../view"

function ActionSheetItem({
  item,
  index,
  items,
  onPress,
  disabled,
  loading,
  ...props
}) {
  return (
    <TouchableHighlight
      {...props}
      disabled={disabled}
      overflow="hidden"
      borderTopRightRadius={index === 0 ? 3 : 0}
      borderTopLeftRadius={index === 0 ? 3 : 0}
      borderBottomRightRadius={index === items.length - 1 ? 3 : 0}
      borderBottomLeftRadius={index === items.length - 1 ? 3 : 0}
      underlayColor="primary"
      onPress={() => onPress(item.value)}
    >
      <View
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        p="lg"
        px="xl"
        bg="soft"
      >
        <Text fontSize={2} numberOfLines={1}>
          {item.label}
        </Text>
        {loading && <ActivityIndicator size="small" />}
      </View>
    </TouchableHighlight>
  )
}

export function ActionSheet({
  isOpen,
  pendingId,
  isClosing,
  title,
  items,
  handleItemPress,
  onClose,
}) {
  const theme = useTheme()
  const insets = useSafeAreaOrDefaultInsets({ bottom: theme.space.xl })

  const disabled = Boolean(isClosing || pendingId)

  return (
    <BottomSheet
      isOpen={isOpen}
      title={title}
      disabled={disabled}
      onClose={onClose}
    >
      <View
        borderLeftWidth={2}
        borderRightWidth={2}
        borderColor="soft"
        style={{
          paddingHorizontal: theme.space.xl,
          paddingBottom: insets.bottom,
          paddingTop: theme.space.xl,
        }}
      >
        {items?.map((i, idx) => (
          <ActionSheetItem
            disabled={disabled}
            key={i.value}
            item={i}
            index={idx}
            items={items}
            onPress={handleItemPress}
            testID={`actionSheet${i.value}`}
            loading={pendingId === i.value}
          />
        ))}
      </View>
    </BottomSheet>
  )
}

ActionSheet.defaultProps = {
  isOpen: false,
  isPending: false,
  isClosing: false,
  handleItemPress: () => {},
  onClose: () => {},
}
