import { t, Trans } from "@lingui/macro"
import { isNil, omitBy } from "lodash"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"
import { formNumberTransformer, getErrorMessage } from "../../lib/helpers"
import { mixpanel } from "../../lib/mixpanel"
import { Sentry } from "../../lib/sentry"
import { useAlert, useApi } from "../../providers"
import { Button, Input, ScrollView, Text, View } from "../ui"

export function CompleteUserProfileForm({ user }) {
  const alert = useAlert()

  const api = useApi()
  const queryClient = useQueryClient()

  const updateMutation = useMutation(api.updateUser, {
    onError: (err, variables, context) => {
      const { title, description } = getErrorMessage(err)
      alert.current.showNotification({ title, description })

      if (!err.isAxiosError) {
        Sentry.captureException(err)
      }
    },
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData("currentUser", data)
      queryClient.invalidateQueries("nextTest")

      mixpanel.track("Profile Updated")
    },
  })

  const form = useForm({
    defaultValues: {
      age: user.age,
      // gender: user.gender,
      // orientation: user.orientation,
    },
  })

  function onSubmit(data) {
    return updateMutation.mutate({ userDto: omitBy(data, isNil) })
  }

  return (
    <View flex={1} justifyContent="space-between">
      <ScrollView keyboardShouldPersistTaps="handled">
        <Text fontSize={5} fontFamily="heading">
          <Trans>Complete profile</Trans>
        </Text>
        <Text mt="md" fontSize={3} fontFamily="heading">
          <Trans>Add required details to rate dating photos.</Trans>
        </Text>

        {!user.age && (
          <Controller
            name="age"
            control={form.control}
            rules={{
              required: {
                value: true,
                message: t`Value is required`,
              },
              min: {
                value: 0,
                message: t`Value should be greater or equal than ${0}`,
              },
              max: {
                value: 99,
                message: t`Value should be less or equal than ${99}`,
              },
              validate: {
                number: (value) => {
                  if (isNaN(value)) {
                    return t`Value should be a number`
                  }

                  return true
                },
              },
            }}
            render={({ field: { onChange, onBlur, onFocus, value, name } }) => (
              <Input mt="xl" error={form.formState.errors[name]?.message}>
                <Input.Title>
                  <Trans>Age</Trans>
                </Input.Title>
                <Input.Text
                  maxLength={3}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onChangeText={(value) =>
                    onChange(formNumberTransformer.output(value))
                  }
                  value={formNumberTransformer.input(value)}
                  keyboardType="number-pad"
                />
                <Input.Error />
              </Input>
            )}
          />
        )}

        {/* {!user.gender && (
          <Controller
            name="gender"
            control={form.control}
            rules={{
              required: {
                value: true,
                message: t`Value is required`,
              },
            }}
            render={({ field: { onChange, onBlur, onFocus, value, name } }) => {
              return (
                <Input mt="xl" error={form.formState.errors[name]?.message}>
                  <Input.Title>
                    <Trans>Gender</Trans>
                  </Input.Title>
                  <Input.Segment
                    items={GENDER_OPTIONS.map((o) => ({
                      ...o,
                      label: i18n._(o.label),
                    }))}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    value={value}
                  />
                  <Input.Error />
                </Input>
              )
            }}
          />
        )} */}

        {/* {!user.orientation && (
          <Controller
            name="orientation"
            control={form.control}
            rules={{
              required: {
                value: true,
                message: t`Value is required`,
              },
            }}
            render={({ field: { onChange, onBlur, onFocus, value, name } }) => {
              return (
                <Input mt="xl" error={form.formState.errors[name]?.message}>
                  <Input.Title>
                    <Trans>Orientation</Trans>
                  </Input.Title>

                  <Input.Picker
                    title={t`Orientation`}
                    items={ORIENTATION_OPTIONS.map((o) => ({
                      ...o,
                      label: i18n._(o.label),
                    }))}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    value={value}
                  />
                  <Input.Error />
                </Input>
              )
            }}
          />
        )} */}

        {/* <Text fontSize={2} fontFamily="heading" color="grayPrimary">
          <Trans>
            Age, Gender and Orientation are used to target dating tests.
          </Trans>
        </Text> */}
      </ScrollView>

      <Button
        my="xl"
        loading={updateMutation.isLoading}
        disabled={updateMutation.isLoading}
        onPress={form.handleSubmit(onSubmit)}
        label={t`Save`}
      />
    </View>
  )
}
