import { i18n } from "@lingui/core"
import { plural, t, Trans } from "@lingui/macro"
import { useFocusEffect } from "@react-navigation/native"
import { differenceInDays } from "date-fns"
import { shuffle, sortBy } from "lodash"
import React, { useCallback, useMemo, useState } from "react"
import { StyleSheet, useWindowDimensions } from "react-native"
import InAppReview from "react-native-in-app-review"
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query"
import { useTheme } from "styled-components/native"
import {
  ActivityIndicator,
  Button,
  Chip,
  Container,
  ErrorBody,
  FastImage,
  FlatList,
  Icon,
  ImagePreview,
  LinkButton,
  NavBar,
  Pressable,
  ProgressBar,
  RefreshControl,
  Row,
  Text,
  TopNavBar,
  TouchableOpacity,
  useDialog,
  View,
} from "../components/ui"
import { Breakpoint, useBreakpoints } from "../hooks"
import {
  formatDate,
  formatNumber,
  getDatingScoreFromFeedbacksCount,
  getErrorMessage,
  getStarProps,
  getTranslationOrOriginalText,
} from "../lib/helpers"
import { allBubbleColors } from "../lib/theme"
import { useAlert, useApi, useStore } from "../providers"

import {
  ACTIVE_TEST,
  ALL_IMPRESSIONS_TEST_OPTIONS,
  ALL_RECOMMENDATIONS_TEST_OPTIONS,
  ALL_TEST_OPTIONS,
  BUSINESS_TEST,
  DATING_TEST,
  DATING_TEST_SCORE_OPTIONS,
  DATING_TEST_WOULD_DATE_OPTION,
  INACTIVE_TEST,
  MALE,
  TEST_STATE_OPTIONS,
  TEST_TYPE_OPTIONS,
} from "../lib/constants"
import { mixpanel } from "../lib/mixpanel"
import { Sentry } from "../lib/sentry"

export function TestDetailsScreen({ navigation, route }) {
  const alert = useAlert()
  const api = useApi()
  const theme = useTheme()
  const { width } = useWindowDimensions()
  const queryClient = useQueryClient()
  const openTestDeleteDialog = useDialog()
  const openReviewReportDialog = useDialog()
  const { breakpoints, currentBreakpointIndex, getBreakpointValue } =
    useBreakpoints()

  const { storeMutation, lastAppReviewPromptAt } = useStore()

  const shuffledBubbleColors = useMemo(() => shuffle(allBubbleColors), [])

  const [imageVisible, setImageIsVisible] = useState(false)

  const {
    data: test,
    isError: isTestError,
    error: testError,
    isLoading: isTestLoading,
    refetch: refetchTest,
  } = useQuery(["test", route.params.id], () => api.getTest(route.params.id))

  const { data: testStatistics, refetch: refetchTestStatistics } = useQuery(
    ["test", route.params.id, "statistics"],
    () => api.getTestStatistics(route.params.id),
  )

  const {
    fetchNextPage,
    hasNextPage,
    refetch: refetchTestReviews,
    data: testReviewsData,
    isError: testReviewsIsError,
    error: testReviewsError,
    isLoading: testReviewsIsLoading,
  } = useInfiniteQuery(
    ["test", route.params.id, "reviews"],
    ({ pageParam = 1 }) =>
      api.getTestReviewList({ testId: route.params.id, page: pageParam }),
    {
      getNextPageParam: (lastPage, allPages) => lastPage.meta.next ?? undefined,
      getPreviousPageParam: (firstPage, allPages) =>
        firstPage.meta.prev ?? undefined,
    },
  )

  const deactivateTestMutation = useMutation(api.deactivateTest, {
    onError: (err, variables, context) => {
      const { title, description } = getErrorMessage(err)
      alert.current.showNotification({ title, description })

      if (!err.isAxiosError) {
        Sentry.captureException(err)
      }
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("currentUser")
      queryClient.setQueryData(["test", route.params.id], data)
      queryClient.setQueriesData(["testList"], (queryData) => {
        return {
          ...queryData,
          pages: queryData.pages.map((page) => ({
            ...page,
            data: page.data.map((t) => (t.id === data.id ? data : t)),
          })),
        }
      })

      alert.current.showNotification({
        title: t`Test deactivation`,
        description: t`Test successfully paused.`,
        componentProps: {
          alertType: "success",
        },
      })

      mixpanel.track("Test Paused")
    },
  })

  const deleteTestMutation = useMutation(api.deleteTest, {
    onError: (err, variables, context) => {
      const { title, description } = getErrorMessage(err)
      alert.current.showNotification({ title, description })

      if (!err.isAxiosError) {
        Sentry.captureException(err)
      }
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("testList")
      queryClient.invalidateQueries("currentUser")
      navigation.goBack()

      alert.current.showNotification({
        title: t`Test deletion`,
        description: t`Test successfully deleted.`,
        componentProps: {
          alertType: "success",
        },
      })

      mixpanel.track("Test Deleted")
    },
  })

  const reportReviewMutation = useMutation(api.createReviewReport, {
    onError: (err, variables, context) => {
      const { title, description } = getErrorMessage(err)
      alert.current.showNotification({ title, description })

      if (!err.isAxiosError) {
        Sentry.captureException(err)
      }
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["test", route.params.id])
      queryClient.invalidateQueries(["test", route.params.id, "statistics"])
      queryClient.invalidateQueries(["test", route.params.id, "reviews"])

      alert.current.showNotification({
        title: t`Review report`,
        description: t`Review successfully reported.`,
        componentProps: {
          alertType: "success",
        },
      })

      mixpanel.track("ReviewReport Created")
    },
  })

  useFocusEffect(
    useCallback(() => {
      if (!InAppReview.isAvailable()) return
      if (differenceInDays(new Date(), lastAppReviewPromptAt) <= 14) return

      const timeout = setTimeout(async () => {
        const testsData = await api.getTestList({ page: 1, items: 99 })

        let reviewCount = 0

        for (const test of testsData.data) {
          const testStatistics = await api.getTestStatistics(test.id)

          reviewCount = reviewCount + testStatistics.reviewCount

          if (reviewCount >= 3) {
            const hasFlowFinishedSuccessfully =
              await InAppReview.RequestInAppReview()

            if (hasFlowFinishedSuccessfully) {
              storeMutation.mutate({ lastAppReviewPromptAt: new Date() })
            }

            break
          }
        }
      }, 5000)

      return () => clearTimeout(timeout)
    }, [lastAppReviewPromptAt, storeMutation, api]),
  )

  const datingScore = testStatistics
    ? getDatingScoreFromFeedbacksCount(testStatistics)
    : 0

  const allTestReviews = testReviewsData?.pages?.flatMap((p) => p.data) ?? []

  const numColumns = getBreakpointValue([1, 1, 2, 2])

  const imageSize =
    (((breakpoints[currentBreakpointIndex - 1] ?? width) - theme.space.xl * 2) *
      getBreakpointValue([100, 100, 50, 50])) /
      100 -
    getBreakpointValue([
      theme.space.xs,
      theme.space.xs,
      theme.space.md,
      theme.space.md,
    ])

  const reviewCardSize =
    ((breakpoints[currentBreakpointIndex - 1] ?? width) -
      theme.space.xl * 2 -
      theme.space.md * (numColumns - 1)) /
    numColumns

  const feedbacks = sortBy(
    Object.entries(testStatistics?.feedbackCounts ?? [])
      .map(([value, count]) => ({ value, count }))
      .filter((value) => !value.value.includes("score_")),
    ["count"],
  ).reverse()

  const impressionsFeedbackOptions = feedbacks
    .map(({ value, count }) => ({
      option: ALL_IMPRESSIONS_TEST_OPTIONS.find((o) => o.value === value),
      value,
      count,
    }))
    .filter(({ option }) => Boolean(option))

  const recomendationsFeedbackOptions = feedbacks
    .map(({ value, count }) => ({
      option: ALL_RECOMMENDATIONS_TEST_OPTIONS.find((o) => o.value === value),
      value,
      count,
    }))
    .filter(({ option }) => Boolean(option))

  return (
    <>
      <Breakpoint values={["lg", "xl"]}>
        <TopNavBar />
      </Breakpoint>

      <Container breakpoint="xl">
        <Row>
          <NavBar
            title={i18n._(
              TEST_TYPE_OPTIONS.find((t) => t.value === test?.type)?.label ??
                "",
            )}
          >
            {Boolean(test) && (
              <Button
                end
                size="xs"
                variant="navbar"
                label={t`Delete`}
                onPress={() =>
                  openTestDeleteDialog({
                    title: t`Are you sure?`,
                    description: t`Test will be deleted and can't be restored.`,
                    buttons: [
                      {
                        label: t`Delete`,
                        onPress: (value) => deleteTestMutation.mutate(test.id),
                      },
                      {
                        label: t`Cancel`,
                        variant: "primary-outline",
                      },
                    ],
                  })
                }
              />
            )}
          </NavBar>
        </Row>
      </Container>

      <View flex={1} justifyContent="center">
        {isTestLoading ? (
          <ActivityIndicator />
        ) : isTestError ? (
          <ErrorBody
            error={testError}
            reload={() => {
              refetchTest()
              refetchTestStatistics()
              refetchTestReviews()
            }}
          />
        ) : (
          <FlatList
            key={numColumns}
            contentContainerStyle={{
              paddingBottom: theme.space.xl,
              width: breakpoints[currentBreakpointIndex - 1] ?? "100%",
              ...styles.list.contentContainerStyle,
            }}
            onEndReached={() => hasNextPage && fetchNextPage()}
            refreshControl={
              <RefreshControl
                onRefresh={() => {
                  refetchTest()
                  refetchTestStatistics()
                  refetchTestReviews()
                }}
                refreshing={false}
              />
            }
            numColumns={numColumns}
            data={allTestReviews}
            keyExtractor={(item) => item.id}
            renderItem={({ item, index }) => {
              const ml = index % numColumns !== 0 ? "md" : "xl"

              return (
                <ReviewItem
                  ml={ml}
                  review={item}
                  test={test}
                  reviewCardSize={reviewCardSize}
                  onReviewReportPress={() =>
                    openReviewReportDialog({
                      title: t`Are you sure?`,
                      description: t`Review will be reported and can't be restored.`,
                      buttons: [
                        {
                          label: t`Report`,
                          onPress: (value) =>
                            reportReviewMutation.mutate({
                              reviewReportDto: { reviewId: item.id },
                            }),
                        },
                        {
                          label: t`Cancel`,
                          variant: "primary-outline",
                        },
                      ],
                    })
                  }
                />
              )
            }}
            ListEmptyComponent={
              <View mt="xl" alignItems="center" justifyContent="center">
                {testReviewsIsLoading ? (
                  <ActivityIndicator />
                ) : testReviewsIsError ? (
                  <ErrorBody
                    flex={undefined}
                    error={testReviewsError}
                    reload={refetchTestReviews}
                  />
                ) : (
                  <Text fontFamily="heading" fontSize={4}>
                    <Trans>No reviews yet</Trans>
                  </Text>
                )}
              </View>
            }
            ListHeaderComponent={
              <>
                <Container breakpoint="xl">
                  <Row px="xl">
                    <View
                      flexDirection={getBreakpointValue([
                        "column",
                        "column",
                        "row",
                        "row",
                      ])}
                    >
                      <View
                        flex={getBreakpointValue([undefined, undefined, 1, 1])}
                      >
                        <View borderRadius={3}>
                          <Pressable onPress={() => setImageIsVisible(true)}>
                            <FastImage
                              testID="image"
                              dataSet={{ private: true }}
                              style={{ width: imageSize, height: imageSize }}
                              source={{ uri: test.photo.url }}
                              borderRadius={3}
                              resizeMode={FastImage.resizeMode.cover}
                            />

                            <ImagePreview
                              uri={test.photo.url}
                              isOpen={imageVisible}
                              onClose={() => setImageIsVisible(false)}
                            />
                          </Pressable>

                          <View
                            position="absolute"
                            top={0}
                            left={0}
                            width="100%"
                            bg="background"
                            opacity={0.7}
                            py="md"
                            px="xl"
                            flexDirection="row"
                          >
                            <View alignItems="flex-start" flex={1.2}>
                              <Text fontFamily="heading" fontSize={2}>
                                {testStatistics?.reviewCount >= 0 &&
                                  plural(testStatistics.reviewCount, {
                                    zero: "# reviews",
                                    one: "# review",
                                    two: "# reviews",
                                    few: "# reviews",
                                    many: "# reviews",
                                    other: "# reviews",
                                  })}
                              </Text>
                            </View>
                            <View alignItems="center" flex={1}>
                              <Text fontFamily="heading" fontSize={2}>
                                {i18n._(
                                  TEST_STATE_OPTIONS.find(
                                    (t) => t.value === test.state,
                                  ).label,
                                )}
                              </Text>
                            </View>
                            <View
                              alignItems="flex-end"
                              justifyContent="center"
                              flex={1}
                            >
                              <Button
                                color="text"
                                variant="link"
                                iconFontSize={4}
                                size="xs"
                                icon="expand-arrows-alt"
                                onPress={() => setImageIsVisible(true)}
                              />
                            </View>
                          </View>
                          {test.type === BUSINESS_TEST && (
                            <View
                              width="100%"
                              position={getBreakpointValue([
                                "absolute",
                                "absolute",
                                "relative",
                                "relative",
                              ])}
                              bottom={0}
                              left={0}
                              py="md"
                              px="md"
                              bg="background"
                              opacity={0.7}
                              alignItems="center"
                            >
                              <Text
                                testID="bio"
                                dataSet={{ private: true }}
                                numberOfLines={1}
                                fontFamily="heading"
                                fontSize={3}
                              >
                                {test.bio}
                              </Text>
                            </View>
                          )}

                          {test.type === DATING_TEST && (
                            <View
                              width="100%"
                              position={getBreakpointValue([
                                "absolute",
                                "absolute",
                                "relative",
                                "relative",
                              ])}
                              bottom={0}
                              left={0}
                              py="md"
                              px="xxl"
                              bg="background"
                              opacity={0.7}
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="space-around"
                            >
                              {DATING_TEST_SCORE_OPTIONS.map((o, idx) => {
                                const index = idx + 1

                                return (
                                  <View key={o.value}>
                                    <Icon
                                      name="star"
                                      color="business"
                                      fontSize={7}
                                    />
                                    <Icon
                                      {...getStarProps(datingScore, index)}
                                      position="absolute"
                                      color="business"
                                      fontSize={7}
                                    />
                                  </View>
                                )
                              })}
                            </View>
                          )}
                        </View>

                        {test.type === DATING_TEST && (
                          <>
                            {/* <View mt="xl">
                              <Text fontFamily="heading" fontSize={3}>
                                <Trans>Reviewer gender</Trans>:{" "}
                                {i18n._(
                                  GENDER_OPTIONS.find(
                                    (o) => o.value === test.reviewerGender,
                                  ).label,
                                )}
                              </Text>
                            </View>
                            <View mt="md">
                              <Text fontFamily="heading" fontSize={3}>
                                <Trans>Reviewer orientation</Trans>:{" "}
                                {i18n._(
                                  ORIENTATION_OPTIONS.find(
                                    (o) => o.value === test.reviewerOrientation,
                                  ).label,
                                )}
                              </Text>
                            </View> */}
                            <View mt="xl">
                              <Text fontFamily="heading" fontSize={3}>
                                <Trans>Reviewer age</Trans>:{" "}
                                {test.reviewerMinAge}-{test.reviewerMaxAge}
                              </Text>
                            </View>
                          </>
                        )}

                        {test.reviewsAvailable > 0 && (
                          <Text mt="md" fontFamily="heading" fontSize={3}>
                            <Trans>Reviews available</Trans>:{" "}
                            {test.reviewsAvailable}
                          </Text>
                        )}

                        {test.state === ACTIVE_TEST && (
                          <>
                            <Button
                              mt="xl"
                              variant="primary-outline"
                              disabled={deactivateTestMutation.isLoading}
                              loading={deactivateTestMutation.isLoading}
                              onPress={() =>
                                deactivateTestMutation.mutate(test.id)
                              }
                              label={t`Pause`}
                            />
                            <LinkButton
                              mt="xl"
                              size="lg"
                              to={{
                                screen: "TestActivationForm",
                                params: { id: test.id },
                              }}
                              label={t`Add reviews`}
                            />
                          </>
                        )}

                        {test.state === INACTIVE_TEST && (
                          <LinkButton
                            mt="xl"
                            size="lg"
                            to={{
                              screen: "TestActivationForm",
                              params: { id: test.id },
                            }}
                            label={t`Activate`}
                          />
                        )}
                      </View>

                      <View
                        flex={getBreakpointValue([undefined, undefined, 1, 1])}
                        ml={getBreakpointValue(["xs", "xs", "xl", "xl"])}
                      >
                        {testStatistics ? (
                          testStatistics.reviewCount > 0 ? (
                            <View>
                              {impressionsFeedbackOptions.length > 0 && (
                                <Text
                                  fontSize={4}
                                  fontFamily="heading"
                                  mt={getBreakpointValue([
                                    "xxl",
                                    "xxl",
                                    "xs",
                                    "xs",
                                  ])}
                                >
                                  <Trans>Impressions</Trans>
                                </Text>
                              )}

                              {impressionsFeedbackOptions.map(
                                ({ value, count, option }, idx) => {
                                  return (
                                    <View mt="xl" key={value}>
                                      <Text fontSize={3}>
                                        {i18n._(option.label, {
                                          gender:
                                            option.value ===
                                            DATING_TEST_WOULD_DATE_OPTION
                                              ? test.reviewerGender ??
                                                test.gender ??
                                                MALE
                                              : test.gender ?? MALE,
                                        })}{" "}
                                        {formatNumber(
                                          count / testStatistics.reviewCount,
                                          {
                                            maximumFractionDigits: 0,
                                            style: "percent",
                                          },
                                        )}{" "}
                                        (
                                        {formatNumber(count, {
                                          notation: "compact",
                                        })}{" "}
                                        {plural(testStatistics.reviewCount, {
                                          one: "out of # review",
                                          other: "out of # reviews",
                                        })}
                                        )
                                      </Text>
                                      <ProgressBar
                                        color={shuffledBubbleColors[idx]}
                                        mt="md"
                                        size="md"
                                        percent={
                                          (count / testStatistics.reviewCount) *
                                          100
                                        }
                                      />
                                    </View>
                                  )
                                },
                              )}

                              {recomendationsFeedbackOptions.length > 0 && (
                                <Text
                                  fontSize={4}
                                  fontFamily="heading"
                                  mt="xxl"
                                >
                                  <Trans>Recommendations</Trans>
                                </Text>
                              )}

                              {recomendationsFeedbackOptions.map(
                                ({ value, count, option }, idx) => {
                                  return (
                                    <View mt="xl" key={value}>
                                      <Text fontSize={3}>
                                        {i18n._(option.label, {
                                          gender: test.gender ?? MALE,
                                        })}{" "}
                                        {formatNumber(
                                          count / testStatistics.reviewCount,
                                          {
                                            maximumFractionDigits: 0,
                                            style: "percent",
                                          },
                                        )}{" "}
                                        (
                                        {formatNumber(count, {
                                          notation: "compact",
                                        })}{" "}
                                        {plural(testStatistics.reviewCount, {
                                          one: "out of # review",
                                          other: "out of # reviews",
                                        })}
                                        )
                                      </Text>
                                      <ProgressBar
                                        color={
                                          [...shuffledBubbleColors].reverse()[
                                            idx
                                          ]
                                        }
                                        mt="md"
                                        size="md"
                                        percent={
                                          (count / testStatistics.reviewCount) *
                                          100
                                        }
                                      />
                                    </View>
                                  )
                                },
                              )}
                            </View>
                          ) : (
                            <Breakpoint values={["lg", "xl"]}>
                              <View
                                flex={1}
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Text fontFamily="heading" fontSize={4}>
                                  <Trans>No reviews yet</Trans>
                                </Text>
                              </View>
                            </Breakpoint>
                          )
                        ) : null}
                      </View>
                    </View>

                    {allTestReviews.length > 0 && (
                      <Text fontSize={4} fontFamily="heading" mt="xxl">
                        <Trans>Reviews</Trans>
                      </Text>
                    )}
                  </Row>
                </Container>
              </>
            }
          />
        )}
      </View>
    </>
  )
}

function ReviewItem({
  review,
  test,
  reviewCardSize,
  onReviewReportPress,
  ...props
}) {
  const api = useApi()
  const [showTranslation, setShowTranslation] = useState(true)
  const { languageCode, autoTranslate } = useStore()
  const shuffledBubbleColors = useMemo(() => shuffle(allBubbleColors), [])

  const { data: reviewCommentTranslation } = useQuery(
    ["reviewCommentTranslation", review.id],
    () =>
      api.createTranslation({
        translationDto: { text: review.comment, language: languageCode },
      }),
    {
      enabled: autoTranslate && Boolean(review.comment),
    },
  )

  const translatedComment = getTranslationOrOriginalText({
    translation: reviewCommentTranslation,
    language: languageCode,
    text: review.comment,
  })

  const datingFeedback = review.feedbacks.find((f) =>
    f.value.includes("score_"),
  )
  let datingScore

  if (datingFeedback) {
    datingScore = parseInt(datingFeedback.value.split("score_")[1], 10)
  }

  return (
    <View
      mt="xl"
      bg="soft"
      px="xl"
      pb="xl"
      borderRadius={3}
      width={reviewCardSize}
      {...props}
    >
      {Boolean(review.comment) && (
        <View mt="xl">
          <Text testID="comment" dataSet={{ private: true }}>
            {autoTranslate && showTranslation
              ? translatedComment.text
              : review.comment}
          </Text>

          {autoTranslate && translatedComment.translated && (
            <View flexDirection="row" alignItems="center" flexWrap="wrap">
              <Text color="grayPrimary">
                <Trans>Translated automatically</Trans>{" "}
              </Text>
              <TouchableOpacity
                p="md"
                pl="xs"
                onPress={() => setShowTranslation(!showTranslation)}
              >
                <Text color="primary" fontFamily="heading">
                  (
                  {showTranslation ? (
                    <Trans>show original</Trans>
                  ) : (
                    <Trans>show translation</Trans>
                  )}
                  )
                </Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
      )}

      {Boolean(datingScore) && (
        <View
          flexDirection="row"
          mt={translatedComment.translated ? "md" : "xl"}
        >
          {DATING_TEST_SCORE_OPTIONS.map((o, idx) => {
            const index = idx + 1

            return (
              <Icon
                key={o.value}
                name="star"
                solid={datingScore >= index}
                color="business"
                fontSize={6}
              />
            )
          })}
        </View>
      )}

      <View
        pt={translatedComment.translated && !datingScore ? "md" : "xl"}
        flexDirection="row"
        flexWrap="wrap"
        flexGap="lg"
      >
        {review.feedbacks.map((f, idx) => {
          const option = ALL_TEST_OPTIONS.find((o) => o.value === f.value)

          if (!option) return null

          return f.value.includes("score_") ? null : (
            <Chip
              key={f.id}
              px="lg"
              py="sm"
              color="white"
              bg={shuffledBubbleColors[idx]}
            >
              <Text fontFamily="heading" color="white">
                {i18n._(option.label, {
                  gender:
                    option.value === DATING_TEST_WOULD_DATE_OPTION
                      ? test.reviewerGender ?? test.gender ?? MALE
                      : test.gender ?? MALE,
                })}
              </Text>
            </Chip>
          )
        })}
      </View>
      <View
        mt="xl"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Text color="grayPrimary">{formatDate(review.createdAt)}</Text>
        <Button
          size="xs"
          variant="link"
          label={t`Report`}
          onPress={onReviewReportPress}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  list: {
    contentContainerStyle: {
      flexGrow: 1,
      maxWidth: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
})
