import { t } from "@lingui/macro"
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import React from "react"
import { Platform, StatusBar } from "react-native"
import { useQuery } from "react-query"
import { useTheme } from "styled-components/native"
import aiheadshotgeneratorIcon from "../assets/aiheadshotgenerator/rounded-icon.png"
import { CookieBanner } from "../components/cookie/cookie-banner"
import { Icon, Image, View } from "../components/ui"
import { useBreakpoints } from "../hooks"
import { ADMIN_ROLE, MANAGER_ROLE } from "../lib/constants"
import { getNativeStackNatigatorOptions } from "../lib/theme"
import { appStoreReviewPassed, useApi, useStore } from "../providers"
import { AIHeadshotGeneratorScreen } from "../screens/aiheadshotgenerator-screen"
import { AuthScreen } from "../screens/auth-screen"
import { CoinsPurchaseFormScreen } from "../screens/coins-purchase-form-screen"
import { CookieSettingsFormScreen } from "../screens/cookie-settings-form-screen"
import { EmailAuthScreen } from "../screens/email-auth-screen"
import { InviteNewScreen } from "../screens/invite-new-screen"
import { MagicLinkAuthScreen } from "../screens/magic-link-auth-screen"
import { MoreScreen } from "../screens/more-screen"
import { NotificationSettingsFormScreen } from "../screens/notification-settings-form-screen"
import { OurOtherAppsScreen } from "../screens/out-other-apps-screen"
import { PhotoListScreen } from "../screens/photo-list-screen"
import { PrivacySettingsFormScreen } from "../screens/privacy-settings-form-screen"
import { RateUsScreen } from "../screens/rate-us-screen"
import { RateUsV2 } from "../screens/rate-us-screen-v2"
import { ReviewScreen } from "../screens/review-screen"
import { TestActivationFormScreen } from "../screens/test-activation-form-screen"
import { TestDetailsScreen } from "../screens/test-details-screen"
import { TestListScreen } from "../screens/test-list-screen"
import {
  SelectCategoryScreen,
  SelectPhotoScreen,
  TestFormScreen,
} from "../screens/test-new-screen"
import { TestReportFormScreen } from "../screens/test-report-form-screen"
import { UserProfileFormScreen } from "../screens/user-profile-form-screen"
import { AdminStackNavigator } from "./admin-stack-navigator"

const ReviewStack = createNativeStackNavigator()

export function ReviewStackNavigator() {
  const theme = useTheme()

  return (
    <ReviewStack.Navigator
      screenOptions={getNativeStackNatigatorOptions(theme)}
    >
      <ReviewStack.Screen name="Review" component={ReviewScreen} />
      <ReviewStack.Screen name="TestReport" component={TestReportFormScreen} />
      <ReviewStack.Screen
        name="UserProfileForm"
        component={UserProfileFormScreen}
      />
    </ReviewStack.Navigator>
  )
}

const TestsStack = createNativeStackNavigator()

export function TestsStackNavigator() {
  const theme = useTheme()

  return (
    <TestsStack.Navigator screenOptions={getNativeStackNatigatorOptions(theme)}>
      <TestsStack.Screen name="TestList" component={TestListScreen} />
      <TestsStack.Screen name="TestDetails" component={TestDetailsScreen} />
      <TestsStack.Screen name="SelectPhoto" component={SelectPhotoScreen} />
      <TestsStack.Screen
        name="SelectCategory"
        component={SelectCategoryScreen}
      />
      <TestsStack.Screen name="TestForm" component={TestFormScreen} />
      <TestsStack.Screen
        name="TestActivationForm"
        component={TestActivationFormScreen}
      />
      <TestsStack.Screen
        name="CoinsPurchaseForm"
        component={CoinsPurchaseFormScreen}
      />
    </TestsStack.Navigator>
  )
}

const MoreStack = createNativeStackNavigator()

export function MoreStackNavigator() {
  const theme = useTheme()

  return (
    <MoreStack.Navigator screenOptions={getNativeStackNatigatorOptions(theme)}>
      <MoreStack.Screen name="More" component={MoreScreen} />
      <MoreStack.Screen
        name="UserProfileForm"
        component={UserProfileFormScreen}
      />
      <MoreStack.Screen name="PhotoList" component={PhotoListScreen} />
      <MoreStack.Screen
        name="NotificationSettingsForm"
        component={NotificationSettingsFormScreen}
      />
      <MoreStack.Screen
        name="PrivacySettingsForm"
        component={PrivacySettingsFormScreen}
      />
      <MoreStack.Screen name="OurOtherApps" component={OurOtherAppsScreen} />
    </MoreStack.Navigator>
  )
}

const Tab = createBottomTabNavigator()

export default function BottomTabNavigator() {
  const api = useApi()
  const { breakpoints, currentBreakpoint } = useBreakpoints()
  const { data: user } = useQuery("currentUser", api.getUser)

  const tabBarProps = {}

  if (breakpoints.lg <= currentBreakpoint) {
    tabBarProps.tabBar = () => null
  }

  return (
    <Tab.Navigator screenOptions={{ headerShown: false }} {...tabBarProps}>
      <Tab.Screen
        name="TestsStack"
        options={{
          tabBarLabel: t`Tests`,
          tabBarIcon: ({ focused, color, size }) => (
            <Icon name="images" color={color} fontSize={size} />
          ),
          tabBarTestID: "TestsStack",
        }}
        component={TestsStackNavigator}
      />
      <Tab.Screen
        name="ReviewStack"
        options={{
          tabBarLabel: t({
            message: "Rate",
            comment: "Tabbar review",
          }),
          tabBarIcon: ({ focused, color, size }) => (
            <Icon name="star" color={color} fontSize={size} />
          ),
          tabBarTestID: "ReviewStack",
        }}
        component={ReviewStackNavigator}
      />
      {appStoreReviewPassed && (
        <Tab.Screen
          name="AIHeadshotGenerator"
          options={{
            tabBarLabel: t({
              message: "AI Headshot",
              comment: "Generate Professional Photos",
            }),
            tabBarIcon: ({ focused, color, size }) => (
              <Image
                style={{ width: size + 6, height: size + 6 }}
                source={aiheadshotgeneratorIcon}
              />
            ),
            tabBarTestID: "AIHeadshotGenerator",
          }}
          component={AIHeadshotGeneratorScreen}
        />
      )}
      {(user?.role === ADMIN_ROLE || user?.role === MANAGER_ROLE) && (
        <Tab.Screen
          name="AdminStack"
          options={{
            tabBarLabel: "Admin",
            tabBarIcon: ({ focused, color, size }) => (
              <Icon name="users-cog" color={color} fontSize={size} />
            ),
            tabBarTestID: "AdminStack",
          }}
          component={AdminStackNavigator}
        />
      )}
      <Tab.Screen
        name="MoreStack"
        options={{
          tabBarLabel: t`More`,
          tabBarIcon: ({ focused, color, size }) => (
            <Icon name="bars" color={color} fontSize={size} />
          ),
          tabBarTestID: "MoreStack",
        }}
        component={MoreStackNavigator}
      />
    </Tab.Navigator>
  )
}

const RootStack = createNativeStackNavigator()

export function RootStackNavigator() {
  const theme = useTheme()
  const { scheme, authToken, showRateUsV2 } = useStore()
  const { getBreakpointValue } = useBreakpoints()

  const barStyle = scheme === "dark" ? "light-content" : "dark-content"
  const barBgColor = getBreakpointValue([
    theme.colors.background,
    theme.colors.background,
    theme.colors.navigation,
    theme.colors.navigation,
  ])

  return (
    <View flex={1}>
      <StatusBar
        backgroundColor={barBgColor}
        barStyle={getBreakpointValue([
          barStyle,
          barStyle,
          "light-content",
          "light-content",
        ])}
      />

      <RootStack.Navigator
        screenOptions={getNativeStackNatigatorOptions(theme)}
      >
        {authToken ? (
          showRateUsV2 && appStoreReviewPassed && Platform.OS !== "web" ? (
            <RootStack.Group>
              <RootStack.Screen name="RateUsV2" component={RateUsV2} />
            </RootStack.Group>
          ) : (
            <>
              <RootStack.Group>
                <RootStack.Screen name="Home" component={BottomTabNavigator} />
              </RootStack.Group>
              <RootStack.Group screenOptions={{ presentation: "modal" }}>
                <RootStack.Screen
                  name="InviteNew"
                  component={InviteNewScreen}
                />
                <RootStack.Screen name="RateUs" component={RateUsScreen} />
                {Platform.OS === "web" && (
                  <RootStack.Screen
                    name="CookieSettingsForm"
                    component={CookieSettingsFormScreen}
                  />
                )}
              </RootStack.Group>
            </>
          )
        ) : (
          <>
            <RootStack.Group>
              <RootStack.Screen name="Auth" component={AuthScreen} />
              <RootStack.Screen name="EmailAuth" component={EmailAuthScreen} />
              <RootStack.Screen
                name="MagicLinkAuth"
                component={MagicLinkAuthScreen}
              />
            </RootStack.Group>
            <RootStack.Group screenOptions={{ presentation: "modal" }}>
              {Platform.OS === "web" && (
                <RootStack.Screen
                  name="CookieSettingsForm"
                  component={CookieSettingsFormScreen}
                />
              )}
            </RootStack.Group>
          </>
        )}
      </RootStack.Navigator>

      {Platform.OS === "web" && <CookieBanner />}
    </View>
  )
}
