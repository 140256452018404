import React from "react"
import {
  Platform,
  ActivityIndicator as RNActivityIndicator,
} from "react-native"
import styled from "styled-components/native"
import {
  space,
  color,
  typography,
  flexbox,
  border,
  position,
  shadow,
} from "styled-system"

export const ActivityIndicator = styled(({ style, ...props }) => {
  const color = style[0].color
  return <RNActivityIndicator {...props} style={style} color={color} />
})`
  ${color}
  ${typography}
  ${border}
  ${position}
  ${flexbox}
  ${space}
  ${shadow}
`

ActivityIndicator.defaultProps = {
  color: Platform.OS === "android" ? "primary" : "text",
  size: "large",
}
