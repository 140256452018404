import NetInfo from "@react-native-community/netinfo"
import { Platform } from "react-native"
import { onlineManager, QueryClient } from "react-query"

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: !__DEV__ && Platform.OS !== "web",
    },
  },
})

onlineManager.setEventListener((setOnline) => {
  return NetInfo.addEventListener((state) => {
    setOnline(state.isConnected)
  })
})
