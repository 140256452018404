import React from "react"
import { Modal } from "react-native"
import { Button, Container, Input, Pressable, Row, Text, View } from "../"

export function Dialog({
  isOpen,
  pendingId,
  isClosing,
  title,
  description,
  input,
  inputValue,
  setInputValue,
  buttons,
  handleButtonPress,
  onClose,
  ...props
}) {
  const disabled = Boolean(isClosing || pendingId)

  return (
    <>
      <Modal
        statusBarTranslucent
        transparent
        animationType="fade"
        visible={isOpen}
      >
        <Pressable
          position="absolute"
          bg="rgba(0,0,0,0.8)"
          width="100%"
          height="100%"
        />

        <Container flex={1}>
          <Row flex={1}>
            <Pressable
              flex={1}
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              onPress={onClose}
              disabled={disabled}
            >
              <Pressable
                bg="soft"
                p="xl"
                borderRadius={3}
                mx="xl"
                flex={1}
                {...props}
              >
                {title && (
                  <View borderBottomWidth="hairline" borderColor="border">
                    <Text fontFamily="bold" fontSize={3}>
                      {title}
                    </Text>
                  </View>
                )}

                {description && (
                  <Text mt="md" fontSize={2}>
                    {description}
                  </Text>
                )}

                {input && (
                  <Input mt="xl">
                    <Input.Text
                      testID="dialog"
                      dataSet={{ private: true }}
                      onChangeText={setInputValue}
                      value={inputValue}
                      {...input}
                    />
                  </Input>
                )}

                <View mt="xl" flexGap="xl" flexDirection="row">
                  {buttons.map((b) => (
                    <Button
                      flex={0.5}
                      key={b.label}
                      label={b.label}
                      variant={b.variant ?? "primary"}
                      onPress={() => handleButtonPress(b.label)}
                      size="md"
                      disabled={disabled}
                    />
                  ))}
                </View>
              </Pressable>
            </Pressable>
          </Row>
        </Container>
      </Modal>
    </>
  )
}

Dialog.defaultProps = {
  isOpen: false,
  isPending: false,
  isClosing: false,
  buttons: [],
  handleButtonPress: () => {},
  onClose: () => {},
}
