import { omit } from "lodash"
import React from "react"
import styled from "styled-components/native"
import {
  border,
  color,
  layout,
  position,
  shadow,
  space,
  typography,
} from "styled-system"

const TextComponent = styled.Text`
  ${border}
  ${color}
  ${typography}
  ${shadow}
  ${position}
  ${space}
  ${layout}
`

export function Text({ textTransform, ...props }) {
  const style = {}
  if (textTransform) {
    style.textTransform = textTransform
  }

  return (
    <TextComponent style={style} {...omit(props, ["start", "middle", "end"])} />
  )
}

Text.defaultProps = {
  color: "text",
  fontSize: 1,
  fontFamily: "regular",
  maxFontSizeMultiplier: 1.3,
}
