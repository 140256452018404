import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useRef,
} from "react"
import { Modal } from "react-native"

import { PushNotificationsPermissionsModal } from "./push-notifications-permissions-modal"
import { ObjectifyPeopleModal } from "./objectify-people-modal"
import { SpamActivityModal } from "./spam-activity-modal"
import { PhotoNewModal } from "./photo-new-modal"
import { Container, Pressable, Row } from "../ui"

const modals = {
  PushNotificationsPermissionsModal,
  ObjectifyPeopleModal,
  SpamActivityModal,
  PhotoNewModal,
}

const ModalStackContext = createContext(null)

export const useModal = () => useContext(ModalStackContext)

export const ModalStackProvider = ({ children }) => {
  const [modalState, setModalState] = useState(null)
  const [isClosing, setIsClosing] = useState(false)

  const awaitingPromiseRef = useRef()

  const openModal = useCallback(
    (options) => {
      setModalState(options)
      return new Promise((resolve, reject) => {
        awaitingPromiseRef.current = { resolve, reject }
      })
    },
    [setModalState, awaitingPromiseRef],
  )

  const handleClose = async (...args) => {
    if (modalState.onClose) {
      try {
        setIsClosing(true)
        await modalState.onClose(...args)
      } finally {
        setIsClosing(false)
      }
    }

    if (modalState.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject()
    }

    if (!modalState.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(...args)
    }

    setModalState(null)
  }

  const ModalComponent = modals[modalState?.name]

  return (
    <>
      <ModalStackContext.Provider value={{ openModal }} children={children} />

      <Modal transparent animationType="slide" visible={Boolean(modalState)}>
        <Pressable
          position="absolute"
          bg="rgba(0,0,0,0.8)"
          width="100%"
          height="100%"
        />

        <Container flex={1}>
          <Row flex={1}>
            {Boolean(modalState) && (
              <ModalComponent
                {...modalState}
                isClosing={isClosing}
                onClose={handleClose}
              />
            )}
          </Row>
        </Container>
      </Modal>
    </>
  )
}

ModalStackProvider.defaultProps = {}
