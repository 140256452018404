import React from "react"
import { omit } from "lodash"
import { Platform } from "react-native"
import styled from "styled-components/native"
import {
  space,
  layout,
  color,
  flexbox,
  border,
  position,
  shadow,
} from "styled-system"

export const FlatListComponent = styled.FlatList`
  ${space}
  ${layout}
  ${color}
  ${flexbox}
  ${border}
  ${position}
  ${shadow}
`

export const FlatList = React.forwardRef(({ ...props }, ref) => {
  let newProps

  if (Platform.OS === "web") {
    newProps = omit(props, ["refreshControl"])
  } else {
    newProps = props
  }

  return <FlatListComponent {...newProps} ref={ref} />
})
