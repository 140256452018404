import jwtDecode from "jwt-decode"
import { useEffect, useMemo, useState } from "react"
import { Platform } from "react-native"
import branch from "../lib/branch"
import { config } from "../lib/config"
import LogRocket from "../lib/logrocket"
import {
  requestSanitizer,
  responseSanitizer,
  urlSanitizer,
} from "../lib/logrocket-sanitizers"
import { mixpanel } from "../lib/mixpanel"
import OneSignal from "../lib/onesignal"
import Purchases from "../lib/purchases"
import { Sentry } from "../lib/sentry"
import { useStore } from "../providers"

const logrocketConfig = {
  release: config.version,
  enableIPCapture: false, // mobile
  shouldCaptureIP: false, // web
  shouldDebugLog: false,
  redactionTags: [
    "email",
    "name",
    "image",
    "bio",
    "comment",
    "dialog",
    "password",
  ],
  network: {
    requestSanitizer,
    responseSanitizer,
  },
  browser: {
    urlSanitizer,
  },
}

export function useInitLibs() {
  const { authToken, allowThirdPartyTracking } = useStore()
  const [isInitialised, setIsInitialised] = useState(false)
  const [isLogrocketInitialised, setIsLogrocketInitialised] = useState(false)

  const userId = useMemo(() => {
    if (!authToken) return null

    try {
      const data = jwtDecode(authToken)
      return data?.user_id ?? null
    } catch (err) {
      Sentry.captureException(err)
      return null
    }
  }, [authToken])

  useEffect(() => {
    ;(async () => {
      if (Platform.OS === "web") {
        await OneSignal.init({
          appId: "c76f89d4-13d8-401e-a529-f561c50a4543",
          safari_web_id:
            "web.onesignal.auto.3d9f0610-6ae1-419f-862e-705396ff3ef1",
          welcomeNotification: {
            disable: true,
          },
        })
      } else if (Platform.OS === "ios") {
        await Purchases.configure({
          apiKey: "appl_xuaUTMxRFsvnbYJbsSDHxpEdRLI",
        })
        await Purchases.enableAdServicesAttributionTokenCollection()
        await Purchases.collectDeviceIdentifiers()
      } else if (Platform.OS === "android") {
        await Purchases.configure({
          apiKey: "goog_kspsJDprshePvOIllRuXHWutRBV",
        })
        await Purchases.collectDeviceIdentifiers()
      }

      setIsInitialised(true)
    })()
  }, [])

  useEffect(() => {
    if (!isInitialised) return

    if (userId) {
      Sentry.setUser({ id: userId })

      if (Platform.OS !== "web") {
        Purchases.logIn(String(userId))
      }

      branch.setIdentity(String(userId))

      OneSignal.setExternalUserId(String(userId))

      mixpanel.identify(String(userId))

      LogRocket.identify(String(userId))
      LogRocket.getSessionURL((sessionURL) => {
        Sentry.configureScope((scope) => {
          scope.setExtra("sessionURL", sessionURL)
        })
      })
    } else {
      if (Platform.OS !== "web") {
        ;(async () => {
          if (!(await Purchases.isAnonymous())) await Purchases.logOut()
        })()
      }

      branch.logout()

      OneSignal.removeExternalUserId()
    }
  }, [isInitialised, userId])

  useEffect(() => {
    if (__DEV__ || !isInitialised) return
    ;(async () => {
      if (allowThirdPartyTracking) {
        branch.disableTracking(false)

        if (Platform.OS === "web") {
          if (await mixpanel.has_opted_out_tracking()) {
            mixpanel.opt_in_tracking()
          }
        } else {
          if (await mixpanel.hasOptedOutTracking()) {
            mixpanel.optInTracking()
          }
        }
      } else if (!allowThirdPartyTracking) {
        branch.disableTracking(true)

        if (Platform.OS === "web") {
          mixpanel.opt_out_tracking()
        } else {
          mixpanel.flush()
          mixpanel.optOutTracking()
        }
      }
    })()
  }, [isInitialised, allowThirdPartyTracking])

  useEffect(() => {
    if (__DEV__ || !isInitialised) return

    if (
      !allowThirdPartyTracking &&
      isLogrocketInitialised &&
      Platform.OS !== "web"
    ) {
      LogRocket.shutdown()
    }

    if (userId) return

    if (allowThirdPartyTracking && !isLogrocketInitialised) {
      LogRocket.init(
        Platform.OS === "web" ? "yj9wpm/testframe-web" : "yj9wpm/testframe",
        logrocketConfig,
      )
      setIsLogrocketInitialised(true)
    }
  }, [isInitialised, isLogrocketInitialised, userId, allowThirdPartyTracking])
}
