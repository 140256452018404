import React from "react"
import { useQuery } from "react-query"
import {
  Container,
  Icon,
  ListItem,
  NavBar,
  Row,
  ScrollView,
  Text,
  TopNavBar,
} from "../../components/ui"
import { Breakpoint } from "../../hooks"
import { ADMIN_ROLE, MANAGER_ROLE } from "../../lib/constants"
import { useApi } from "../../providers"
import { AdminReviewScreen } from "./review-screen"

export function AdminScreen({ ...props }) {
  const api = useApi()
  const { data: user } = useQuery("currentUser", api.getUser)

  if (user?.role === MANAGER_ROLE) {
    return <AdminReviewScreen {...props} />
  }

  return (
    <>
      <Breakpoint values={["lg", "xl"]}>
        <TopNavBar />
      </Breakpoint>

      <NavBar title="Admin" showBack={false} />

      <Container flex={1} breakpoint="xl">
        <Row flex={1}>
          <ScrollView>
            {user?.role === ADMIN_ROLE && (
              <>
                <ListItem to={{ screen: "AdminUserList" }}>
                  <Icon start name="users" />
                  <Text middle fontSize={2} ml="xl">
                    Users
                  </Text>
                  <Icon end fontSize={2} name="chevron-right" color="grey" />
                </ListItem>
                <ListItem to={{ screen: "AdminTestList" }}>
                  <Icon start name="images" />
                  <Text middle fontSize={2} ml="xl">
                    Tests
                  </Text>
                  <Icon end fontSize={2} name="chevron-right" color="grey" />
                </ListItem>
                <ListItem to={{ screen: "AdminReviewList" }}>
                  <Icon start name="star" />
                  <Text middle fontSize={2} ml="xl">
                    Reviews
                  </Text>
                  <Icon end fontSize={2} name="chevron-right" color="grey" />
                </ListItem>
                <ListItem to={{ screen: "AdminTestReportList" }}>
                  <Icon start name="flag" />
                  <Text middle fontSize={2} ml="xl">
                    Test Reports
                  </Text>
                  <Icon end fontSize={2} name="chevron-right" color="grey" />
                </ListItem>
                <ListItem to={{ screen: "AdminReviewReportList" }}>
                  <Icon start name="flag" />
                  <Text middle fontSize={2} ml="xl">
                    Review Reports
                  </Text>
                  <Icon end fontSize={2} name="chevron-right" color="grey" />
                </ListItem>
              </>
            )}

            <ListItem to={{ screen: "AdminReview" }}>
              <Icon start name="star" />
              <Text middle fontSize={2} ml="xl">
                Review
              </Text>
              <Icon end fontSize={2} name="chevron-right" color="grey" />
            </ListItem>
          </ScrollView>
        </Row>
      </Container>
    </>
  )
}
