import { i18n } from "@lingui/core"
import { useNavigation } from "@react-navigation/native"
import React from "react"
import { useWindowDimensions } from "react-native"
import { useInfiniteQuery } from "react-query"
import { useTheme } from "styled-components/native"
import {
  ActivityIndicator,
  Button,
  Chip,
  Container,
  ErrorBody,
  FlatList,
  Icon,
  NavBar,
  RefreshControl,
  Row,
  Text,
  TopNavBar,
  View,
} from "../../components/ui"
import { Breakpoint, useBreakpoints } from "../../hooks"
import {
  ALL_TEST_OPTIONS,
  DATING_TEST_SCORE_OPTIONS,
  ERROR_TEST_OPTION,
  MALE,
} from "../../lib/constants"
import { formatRelativeDate } from "../../lib/helpers"
import { useApi } from "../../providers"

export function AdminReviewReportListScreen({ navigation, route }) {
  const api = useApi()
  const { breakpoints, currentBreakpointIndex, getBreakpointValue } =
    useBreakpoints()
  const theme = useTheme()
  const { width } = useWindowDimensions()

  const {
    fetchNextPage,
    hasNextPage,
    refetch: refetchReports,
    data: reportsData,
    isError: reportsIsError,
    error: reportsError,
    isLoading: reportsIsLoading,
  } = useInfiniteQuery(
    [
      "adminReviewReportList",
      { userId: route.params?.userId, reviewId: route.params?.reviewId },
    ],
    ({ pageParam = 1 }) =>
      api.getAdminReviewReportList({
        page: pageParam,
        query: {
          userIdEq: route.params?.userId,
          reviewIdEq: route.params?.reviewId,
        },
      }),
    {
      getNextPageParam: (lastPage, allPages) => lastPage.meta.next ?? undefined,
      getPreviousPageParam: (firstPage, allPages) =>
        firstPage.meta.prev ?? undefined,
    },
  )

  const reportsCount = reportsData?.pages[0]?.meta?.count ?? 0
  const allReports = reportsData?.pages?.flatMap((p) => p.data) ?? []

  const numColumns = getBreakpointValue([1, 1, 2, 2])

  const reviewCardSize =
    ((breakpoints[currentBreakpointIndex - 1] ?? width) -
      theme.space.xl * 2 -
      theme.space.md * (numColumns - 1)) /
    numColumns

  return (
    <>
      <Breakpoint values={["lg", "xl"]}>
        <TopNavBar />
      </Breakpoint>

      <Container breakpoint="xl">
        <Row>
          <NavBar title="Review Reports" />
        </Row>
      </Container>

      <Container flex={1} breakpoint="xl">
        <Row flex={1}>
          <View flex={1} justifyContent="center">
            {reportsIsLoading ? (
              <ActivityIndicator />
            ) : reportsIsError ? (
              <ErrorBody error={reportsError} reload={refetchReports} />
            ) : (
              <FlatList
                key={numColumns}
                onEndReached={() => hasNextPage && fetchNextPage()}
                refreshControl={
                  <RefreshControl
                    onRefresh={() => refetchReports()}
                    refreshing={false}
                  />
                }
                numColumns={numColumns}
                data={allReports}
                keyExtractor={(item) => item.id}
                renderItem={({ item, index }) => {
                  const mb = allReports.length - 1 === index ? "xl" : 0
                  const ml = index % numColumns !== 0 ? "md" : "xl"

                  return (
                    <ReviewReportItem
                      ml={ml}
                      mb={mb}
                      report={item}
                      reviewCardSize={reviewCardSize}
                      onPress={() =>
                        navigation.push("AdminReviewReportDetails", {
                          id: item.id,
                        })
                      }
                    />
                  )
                }}
                ListHeaderComponent={
                  <View mx="xl">
                    <Text fontSize={4} fontFamily="heading">
                      Total: {reportsCount}
                    </Text>
                  </View>
                }
              />
            )}
          </View>
        </Row>
      </Container>
    </>
  )
}

function ReviewReportItem({ report, onPress, reviewCardSize, ...props }) {
  const navigation = useNavigation()

  const datingFeedback = report.review.feedbacks.find((f) =>
    f.value.includes("score_"),
  )
  let datingScore

  if (datingFeedback) {
    datingScore = parseInt(datingFeedback.value.split("score_")[1], 10)
  }

  return (
    <View
      mt="xl"
      mx="xl"
      bg="soft"
      px="xl"
      pb="xl"
      borderRadius={3}
      width={reviewCardSize}
      {...props}
    >
      <View flexDirection="row" justifyContent="space-between" mt="lg">
        {report.review.userId && (
          <Button
            size="xs"
            variant="link"
            label={`Reviewer (${report.review.userId})`}
            onPress={() =>
              navigation.push("AdminUserDetails", {
                id: report.review.userId,
              })
            }
          />
        )}

        <Button
          size="xs"
          variant="link"
          label={`Test (${report.review.testId})`}
          onPress={() =>
            navigation.push("AdminTestDetails", {
              id: report.review.testId,
            })
          }
        />
      </View>

      {Boolean(report.review.comment) && (
        <Text testID="comment" dataSet={{ private: true }} mt="xl">
          {report.review.comment}
        </Text>
      )}

      {Boolean(datingScore) && (
        <View flexDirection="row" mt="xl">
          {DATING_TEST_SCORE_OPTIONS.map((o, idx) => {
            const index = idx + 1

            return (
              <Icon
                key={idx}
                name="star"
                solid={datingScore >= index}
                color="business"
                fontSize={6}
              />
            )
          })}
        </View>
      )}

      <View pt="xl" flexDirection="row" flexWrap="wrap" flexGap="lg">
        {report.review.feedbacks.map((f, idx) => {
          const option =
            ALL_TEST_OPTIONS.find((o) => o.value === f.value) ??
            ERROR_TEST_OPTION

          return f.value.includes("score_") ? null : (
            <Chip key={idx} px="lg" py="sm" bg="translucent">
              <Text fontFamily="bold">
                {i18n._(option.label, { gender: MALE })}
              </Text>
            </Chip>
          )
        })}
      </View>
      <View mt="xl" flexDirection="row" justifyContent="space-between">
        <Text color="grayPrimary">ID {report.review.id}</Text>
        <Text color="grayPrimary">
          {formatRelativeDate(report.review.createdAt)}
        </Text>
      </View>
    </View>
  )
}
