import { i18n } from "@lingui/core"
import React, { useState } from "react"
import { useInfiniteQuery } from "react-query"
import { useTheme } from "styled-components/native"
import {
  ActivityIndicator,
  BottomSheet,
  Button,
  Container,
  ErrorBody,
  FlatList,
  Input,
  NavBar,
  RefreshControl,
  Row,
  Text,
  TopNavBar,
  TouchableOpacity,
  View,
} from "../../components/ui"
import { Breakpoint, useSafeAreaOrDefaultInsets } from "../../hooks"
import {
  GENDER_OPTIONS,
  ORIENTATION_OPTIONS,
  USER_ROLE,
  USER_ROLE_OPTIONS,
} from "../../lib/constants"
import { formatDate } from "../../lib/helpers"
import { useApi } from "../../providers"

export function AdminUserListScreen({ navigation }) {
  const api = useApi()
  const theme = useTheme()
  const insets = useSafeAreaOrDefaultInsets({ bottom: theme.space.xl })
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [filter, setFilter] = useState({
    roleIn: [USER_ROLE],
    sorts: "created_at desc",
  })

  const {
    fetchNextPage,
    hasNextPage,
    refetch,
    data: usersData,
    isError,
    error,
    isLoading,
  } = useInfiniteQuery(
    ["adminUserList", { filter }],
    ({ pageParam = 1 }) =>
      api.getAdminUserList({
        page: pageParam,
        query: {
          ...filter,
        },
      }),
    {
      getNextPageParam: (lastPage, allPages) => lastPage.meta.next ?? undefined,
      getPreviousPageParam: (firstPage, allPages) =>
        firstPage.meta.prev ?? undefined,
    },
  )

  const count = usersData?.pages[0]?.meta?.count ?? 0
  const allUsers = usersData?.pages?.flatMap((p) => p.data) ?? []

  return (
    <>
      <Breakpoint values={["lg", "xl"]}>
        <TopNavBar />
      </Breakpoint>

      <Container breakpoint="xl">
        <Row>
          <NavBar title="Users">
            <Button
              end
              size="xs"
              variant="navbar"
              label="Filter"
              onPress={() => setIsFilterOpen(true)}
            />
          </NavBar>
        </Row>
      </Container>

      <BottomSheet
        isOpen={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        onDone={() => setIsFilterOpen(false)}
        title="Filter"
      >
        <View
          style={{
            paddingHorizontal: theme.space.xl,
            paddingBottom: insets.bottom,
          }}
        >
          <Input mt="xl">
            <Input.Title>Role</Input.Title>
            <Input.Picker
              multi
              items={USER_ROLE_OPTIONS}
              onChange={(val) =>
                setFilter({
                  ...filter,
                  roleIn: val,
                })
              }
              value={filter.roleIn}
            />
            <Input.Error />
          </Input>

          <Input mt="xl">
            <Input.Switch
              label="Only spam warned"
              onValueChange={(val) =>
                setFilter({
                  ...filter,
                  spamWarnedAtNotNull: val === true ? true : undefined,
                })
              }
              value={Boolean(filter.spamWarnedAtNotNull)}
            />
            <Input.Error />
          </Input>

          <Input mt="xl">
            <Input.Title>Sorts</Input.Title>
            <Input.Picker
              items={[
                {
                  value: "created_at desc",
                  label: "Created Desc",
                },
                {
                  value: "created_at asc",
                  label: "Created Asc",
                },
                {
                  value: "coins desc",
                  label: "Coins desc",
                },
                {
                  value: "energy desc",
                  label: "Energy desc",
                },
                {
                  value: "spam_warned_at desc",
                  label: "Spam warned Desc",
                },
              ]}
              onChange={(val) =>
                setFilter({
                  ...filter,
                  sorts: val,
                })
              }
              value={filter.sorts}
            />
            <Input.Error />
          </Input>
        </View>
      </BottomSheet>

      <Container flex={1} breakpoint="xl">
        <Row flex={1}>
          <View flex={1} justifyContent="center">
            {isLoading ? (
              <ActivityIndicator />
            ) : isError ? (
              <ErrorBody error={error} reload={refetch} />
            ) : (
              <FlatList
                onEndReached={() => hasNextPage && fetchNextPage()}
                refreshControl={
                  <RefreshControl
                    onRefresh={() => refetch()}
                    refreshing={false}
                  />
                }
                ListHeaderComponent={
                  <View mx="xl">
                    <Text fontSize={4} fontFamily="heading">
                      Total: {count}
                    </Text>
                  </View>
                }
                data={allUsers}
                renderItem={({ item, index }) => {
                  const mb = allUsers.length - 1 === index ? "xl" : 0

                  return (
                    <UserItem
                      mb={mb}
                      user={item}
                      onPress={() => {
                        navigation.push("AdminUserDetails", { id: item.id })
                      }}
                    />
                  )
                }}
                keyExtractor={(item) => item.id}
              />
            )}
          </View>
        </Row>
      </Container>
    </>
  )
}

function UserItem({ user, onPress, ...props }) {
  return (
    <TouchableOpacity {...props} mt="xl" mx="xl" onPress={() => onPress(user)}>
      <View borderColor="border" borderWidth={2} borderRadius={3} p="lg">
        <Text
          numberOfLines={1}
          fontFamily="bold"
          fontSize={3}
          testID="name"
          dataSet={{ private: true }}
        >
          ({user.id}) {user.name}
        </Text>
        <Text mt="sm" numberOfLines={1}>
          <Text testID="email" dataSet={{ private: true }} fontFamily="heading">
            E-Mail:{" "}
          </Text>
          {user.email}
        </Text>

        <View mt="sm" flexDirection="row">
          {Boolean(user.age) && (
            <View flex={1} flexDirection="row">
              <Text fontFamily="heading">Age: </Text>
              <Text>{user.age}</Text>
            </View>
          )}

          <View flex={1} flexDirection="row">
            <Text fontFamily="heading">State: </Text>
            <Text>{user.state}</Text>
          </View>
        </View>

        <View mt="sm" flexDirection="row">
          {user.gender && (
            <View flex={1} flexDirection="row">
              <Text fontFamily="heading">Gender: </Text>
              <Text>
                {i18n._(
                  GENDER_OPTIONS.find((o) => o.value === user.gender).label,
                )}
              </Text>
            </View>
          )}

          {user.orientation && (
            <View flex={1} flexDirection="row">
              <Text fontFamily="heading">Orientation: </Text>
              <Text>
                {i18n._(
                  ORIENTATION_OPTIONS.find((o) => o.value === user.orientation)
                    .label,
                )}
              </Text>
            </View>
          )}
        </View>
        <View mt="sm" flexDirection="row">
          <View flex={1} flexDirection="row">
            <Text fontFamily="heading">Energy: </Text>
            <Text>{user.energy}</Text>
          </View>
          <View flex={1} flexDirection="row">
            <Text fontFamily="heading">Coins: </Text>
            <Text>{user.coins}</Text>
          </View>
        </View>
        <View mt="sm" flexDirection="row">
          <View flex={1} flexDirection="row">
            <Text fontFamily="heading">Tests: </Text>
            <Text>{user.testCount}</Text>
          </View>
          <View flex={1} flexDirection="row">
            <Text fontFamily="heading">Reviews: </Text>
            <Text>{user.reviewCount}</Text>
          </View>
        </View>
        <View mt="sm" flexDirection="row">
          <View flex={1} flexDirection="row" flexWrap="wrap">
            <Text fontFamily="heading">Created: </Text>
            <Text numberOfLines={1}>{formatDate(user.createdAt)}</Text>
          </View>
          {user.lastActivityAt && (
            <View flex={1} flexDirection="row" flexWrap="wrap">
              <Text fontFamily="heading">Last active: </Text>
              <Text numberOfLines={1}>{formatDate(user.lastActivityAt)}</Text>
            </View>
          )}
        </View>
        {user.spamWarnedAt && (
          <View mt="sm" flexDirection="row">
            {user.spamWarnedAt && (
              <View flex={1} flexDirection="row" flexWrap="wrap">
                <Text fontFamily="heading">Spam warned: </Text>
                <Text numberOfLines={1}>{formatDate(user.spamWarnedAt)}</Text>
              </View>
            )}
          </View>
        )}
      </View>
    </TouchableOpacity>
  )
}
