import { defineMessage } from "@lingui/macro"

const offerings = [
  {
    identifier: "coins-50",
    product: {
      identifier: __DEV__ ? "prod_MKoD3LHcJ3lPHi" : "prod_MKkB6X7YNtXodU",
      description: defineMessage({ message: "50 Coins" }),
      priceString: "$9.99",
    },
  },
  {
    identifier: "coins-110",
    product: {
      identifier: __DEV__ ? "prod_MKoEDtRAJFMgGW" : "prod_MKkKbIHQWvajrY",
      description: defineMessage({ message: "110 Coins" }),
      priceString: "$19.99",
    },
  },
  {
    identifier: "coins-250",
    product: {
      identifier: __DEV__ ? "prod_MKoGKhpl6qKQEF" : "prod_MKkL9m2GTw3dhv",
      description: defineMessage({ message: "250 Coins" }),
      priceString: "$39.99",
    },
  },
  {
    identifier: "coins-700",
    product: {
      identifier: __DEV__ ? "prod_MKoHfVADJyn5Gx" : "prod_MKkL5jCUpNZgrB",
      description: defineMessage({ message: "700 Coins" }),
      priceString: "$79.99",
    },
  },
  {
    identifier: "coins-1000",
    product: {
      identifier: __DEV__ ? "prod_MKoIpQQC3sorAK" : "prod_MKkLrOcu6dVYr8",
      description: defineMessage({ message: "1000 Coins" }),
      priceString: "$99.99",
    },
  },
]

const Purchases = {
  getOfferings: () => ({ current: { availablePackages: offerings } }),
}

export default Purchases
