import { i18n } from "@lingui/core"
import { t, Trans } from "@lingui/macro"
import React from "react"
import { Linking, Platform } from "react-native"
import { useMutation, useQueryClient } from "react-query"
import { useTheme } from "styled-components/native"
import {
  Container,
  Icon,
  Input,
  ListItem,
  NavBar,
  Row,
  ScrollView,
  Text,
  TopNavBar,
  useActionSheet,
  useDialog,
  View,
} from "../components/ui"
import { Breakpoint, useBreakpoints } from "../hooks"
import { config } from "../lib/config"
import { APPEARANCE_OPTIONS, LANGUAGE_OPTIONS } from "../lib/constants"
import { getErrorMessage, makeLanguageUrlPrefix } from "../lib/helpers"
import { mixpanel } from "../lib/mixpanel"
import { Sentry } from "../lib/sentry"
import { useAlert, useApi, useStore } from "../providers"

export function MoreScreen({ navigation }) {
  const queryClient = useQueryClient()
  const alert = useAlert()
  const theme = useTheme()
  const api = useApi()
  const { getBreakpointValue } = useBreakpoints()
  const { appearance, language, languageCode, autoTranslate, storeMutation } =
    useStore()

  const openAppearanceSheet = useActionSheet()
  const openLanguageSheet = useActionSheet()
  const openCouponDialog = useDialog()
  const openLogoutDialog = useDialog()

  const deleteCouponMutation = useMutation(api.deleteCoupon, {
    onError: (err, variables, context) => {
      if (err?.response?.status === 404) {
        alert.current.showNotification({
          title: t`Redemption error`,
          description: t`Invalid coupon.`,
        })
      } else {
        const { title, description } = getErrorMessage(err)
        alert.current.showNotification({ title, description })
      }

      if (!err.isAxiosError) {
        Sentry.captureException(err)
      }
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("currentUser")

      alert.current.showNotification({
        title: t`Coupon redemption`,
        description: t`Coupon successfully used.`,
        componentProps: {
          alertType: "success",
        },
      })
    },
  })

  const listItemContainerPropsTemplate = {
    width: "32%",
    p: "xl",
    mt: "xl",
    borderColor: "grey",
    borderWidth: 1,
    borderRadius: 3,
  }

  const listItemContainerProps = getBreakpointValue([
    {},
    {},
    { ...listItemContainerPropsTemplate, width: "49%" },
    { ...listItemContainerPropsTemplate },
  ])

  const listItemProps = {
    borderBottomWidth: getBreakpointValue([1, 1, 0, 0]),
  }

  const startIconProps = {
    fontSize: getBreakpointValue([6, 6, 7, 7]),
  }

  return (
    <>
      <Breakpoint values={["lg", "xl"]}>
        <TopNavBar />
      </Breakpoint>

      <Container flex={1} breakpoint="xl">
        <Row flex={1}>
          <NavBar title={t`More`} showBack={false} />

          <ScrollView>
            <View
              mt={getBreakpointValue([
                theme.space.xs,
                theme.space.xs,
                -theme.space.xl,
                -theme.space.xl,
              ])}
              px={getBreakpointValue(["xs", "xs", "xl", "xl"])}
              flexDirection={getBreakpointValue([
                "column",
                "column",
                "row",
                "row",
              ])}
              flexWrap={getBreakpointValue([
                "nowrap",
                "nowrap",
                "wrap",
                "wrap",
              ])}
              justifyContent="space-between"
            >
              <ListItem
                containerProps={listItemContainerProps}
                {...listItemProps}
                to={{ screen: "UserProfileForm" }}
              >
                <Icon start {...startIconProps} name="user-circle" />
                <Text middle fontSize={2} ml="xl">
                  <Trans>Profile</Trans>
                </Text>
                <Icon end fontSize={2} name="chevron-right" color="grey" />
              </ListItem>

              <ListItem
                containerProps={listItemContainerProps}
                {...listItemProps}
                to={{ screen: "PhotoList" }}
              >
                <Icon start {...startIconProps} name="images" />
                <Text middle fontSize={2} ml="xl">
                  <Trans>Photos</Trans>
                </Text>
                <Icon end fontSize={2} name="chevron-right" color="grey" />
              </ListItem>

              <ListItem
                containerProps={listItemContainerProps}
                {...listItemProps}
                to={{ screen: "InviteNew" }}
              >
                <Icon start {...startIconProps} name="users" />
                <Text middle fontSize={2} ml="xl">
                  <Trans>Invite friend</Trans>
                </Text>
                <Icon end fontSize={2} name="chevron-right" color="grey" />
              </ListItem>

              <ListItem
                containerProps={listItemContainerProps}
                {...listItemProps}
                onPress={() => {
                  storeMutation.mutate({
                    hadReviewWalkthrough: false,
                    hadTestsWalkthrough: false,
                  })

                  navigation.navigate("TestList")
                }}
              >
                <Icon start {...startIconProps} name="chalkboard-teacher" />
                <Text middle fontSize={2} ml="xl">
                  <Trans>Start tutorial</Trans>
                </Text>
              </ListItem>

              {Platform.OS !== "ios" && (
                <ListItem
                  containerProps={listItemContainerProps}
                  {...listItemProps}
                  onPress={() =>
                    openCouponDialog({
                      title: t`Redeem coupon`,
                      description: t`You can get coupons by participating in promotions.`,
                      input: {},
                      buttons: [
                        {
                          label: t`Cancel`,
                          variant: "primary-outline",
                        },
                        {
                          label: t`Ok`,
                          onPress: (value) =>
                            deleteCouponMutation.mutate(value),
                        },
                      ],
                    })
                  }
                >
                  <Icon start {...startIconProps} name="receipt" />
                  <Text middle fontSize={2} ml="xl">
                    <Trans>Redeem coupon</Trans>
                  </Text>
                </ListItem>
              )}

              <ListItem
                containerProps={listItemContainerProps}
                {...listItemProps}
                to={{ screen: "NotificationSettingsForm" }}
              >
                <Icon start {...startIconProps} name="bell" />
                <Text middle fontSize={2} ml="xl">
                  <Trans>Notifications</Trans>
                </Text>
                <Icon end fontSize={2} name="chevron-right" color="grey" />
              </ListItem>

              {Platform.OS !== "web" && (
                <ListItem
                  containerProps={listItemContainerProps}
                  {...listItemProps}
                  onPress={() =>
                    Linking.openURL(
                      Platform.OS === "android"
                        ? "https://play.google.com/store/apps/details?id=com.dimonnwc3.testframe&showAllReviews=true"
                        : "https://itunes.apple.com/app/id1603039736?action=write-review",
                    )
                  }
                >
                  <Icon start {...startIconProps} name="star" />
                  <Text middle fontSize={2} ml="xl">
                    <Trans>Rate Testframe</Trans>
                  </Text>
                </ListItem>
              )}

              <ListItem
                containerProps={{
                  ...listItemContainerProps,
                  testID: "appearancePicker",
                }}
                {...listItemProps}
                onPress={() => {
                  return openAppearanceSheet({
                    items: APPEARANCE_OPTIONS.map((o) => ({
                      ...o,
                      label: i18n._(o.label),
                      onPress: () =>
                        storeMutation.mutateAsync({ appearance: o.value }),
                    })),
                  })
                }}
              >
                <Icon start {...startIconProps} name="adjust" />
                <Text middle fontSize={2} ml="xl">
                  <Trans>Appearance</Trans>
                </Text>
                <Text end fontSize={2}>
                  {i18n._(
                    APPEARANCE_OPTIONS.find((a) => a.value === appearance)
                      .label,
                  )}
                </Text>
              </ListItem>

              <ListItem
                containerProps={{
                  ...listItemContainerProps,
                  testID: "languagePicker",
                }}
                {...listItemProps}
                onPress={() => {
                  return openLanguageSheet({
                    items: LANGUAGE_OPTIONS.map((o) => ({
                      ...o,
                      label: i18n._(o.label),
                      onPress: () =>
                        storeMutation.mutateAsync({ language: o.value }),
                    })),
                  })
                }}
              >
                <Icon start {...startIconProps} name="globe" />
                <Text middle fontSize={2} ml="xl">
                  <Trans>Language</Trans>
                </Text>
                <Text end fontSize={2}>
                  {i18n._(
                    LANGUAGE_OPTIONS.find((a) => a.value === language).label,
                  )}
                </Text>
              </ListItem>

              <ListItem
                containerProps={listItemContainerProps}
                {...listItemProps}
              >
                <Icon start {...startIconProps} name="language" />
                <Text middle fontSize={2} ml="xl">
                  <Trans>Translate reviews</Trans>
                </Text>
                <Input end>
                  <Input.Switch
                    py="xs"
                    {...listItemProps}
                    onValueChange={() =>
                      storeMutation.mutate({ autoTranslate: !autoTranslate })
                    }
                    value={autoTranslate}
                  />
                </Input>
              </ListItem>

              <ListItem
                containerProps={listItemContainerProps}
                {...listItemProps}
                to={{ screen: "OurOtherApps" }}
              >
                <Icon
                  start
                  {...startIconProps}
                  name={Platform.OS === "android" ? "google-play" : "app-store"}
                />
                <Text middle fontSize={2} ml="xl">
                  <Trans>Our applications</Trans>
                </Text>
                <Icon end fontSize={2} name="chevron-right" color="grey" />
              </ListItem>

              {Platform.OS === "web" && (
                <ListItem
                  containerProps={listItemContainerProps}
                  {...listItemProps}
                  to={{ screen: "CookieSettingsForm" }}
                >
                  <Icon start {...startIconProps} name="cookie" />
                  <Text middle fontSize={2} ml="xl">
                    <Trans>Cookie</Trans>
                  </Text>
                  <Icon end fontSize={2} name="chevron-right" color="grey" />
                </ListItem>
              )}

              <ListItem
                containerProps={listItemContainerProps}
                {...listItemProps}
                onPress={async () => {
                  try {
                    await Linking.openURL(
                      `mailto:support@testframe.app?body=
Device info: ${Platform.OS} ${Platform.Version ?? ""}
App version: ${config.version}`,
                    )
                  } catch (err) {
                    await Linking.openURL(
                      `${config.web.url}${makeLanguageUrlPrefix(
                        languageCode,
                      )}/support`,
                    )
                  }
                }}
              >
                <Icon start {...startIconProps} name="question-circle" />
                <Text middle fontSize={2} ml="xl">
                  <Trans>Support</Trans>
                </Text>
                <Icon end fontSize={2} name="envelope" color="grey" />
              </ListItem>

              <ListItem
                containerProps={listItemContainerProps}
                {...listItemProps}
                onPress={() => Linking.openURL(`${config.web.url}/privacy`)}
              >
                <Icon start {...startIconProps} name="user-shield" />
                <Text middle fontSize={2} ml="xl">
                  <Trans>Privacy Policy</Trans>
                </Text>
                <Icon end fontSize={2} name="chevron-right" color="grey" />
              </ListItem>

              <ListItem
                containerProps={listItemContainerProps}
                {...listItemProps}
              >
                <Icon start {...startIconProps} name="code-branch" />
                <Text middle fontSize={2} ml="xl">
                  <Trans>App Version</Trans>
                </Text>
                <Text end>{config.version}</Text>
              </ListItem>

              <ListItem
                containerProps={listItemContainerProps}
                {...listItemProps}
                onPress={() =>
                  openLogoutDialog({
                    title: t`Logout`,
                    description: t`Are you sure?`,
                    buttons: [
                      {
                        label: t`Logout`,
                        onPress: (value) => {
                          mixpanel.track("Signed Out", {
                            platform: Platform.OS,
                          })
                          storeMutation.mutate({ authToken: "" })
                        },
                      },
                      {
                        label: t`Cancel`,
                        variant: "primary-outline",
                      },
                    ],
                  })
                }
              >
                <Icon start {...startIconProps} name="sign-out-alt" />
                <Text middle fontSize={2} ml="xl">
                  <Trans>Logout</Trans>
                </Text>
              </ListItem>
            </View>
          </ScrollView>
        </Row>
      </Container>
    </>
  )
}
