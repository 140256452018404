import { t, Trans } from "@lingui/macro"
import React, { useCallback, useState } from "react"
import { useWindowDimensions } from "react-native"
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query"
import { useModal } from "../components/modal-stack"
import { ImagePickerContainer } from "../components/photo"
import {
  ActivityIndicator,
  Button,
  Container,
  ErrorBody,
  FastImage,
  FlatList,
  Icon,
  NavBar,
  RefreshControl,
  Row,
  Text,
  TopNavBar,
  TouchableOpacity,
  View,
} from "../components/ui"
import { Breakpoint, useBreakpoints } from "../hooks"
import { getErrorMessage } from "../lib/helpers"
import { mixpanel } from "../lib/mixpanel"
import { Sentry } from "../lib/sentry"
import { useAlert, useApi } from "../providers"

export function PhotoListScreen({ navigation }) {
  const api = useApi()
  const alert = useAlert()
  const { getBreakpointValue, breakpoints, currentBreakpointIndex } =
    useBreakpoints()
  const queryClient = useQueryClient()
  const [selectedPhotos, setSelectedPhotos] = useState([])
  const { openModal } = useModal()

  const {
    fetchNextPage,
    hasNextPage,
    refetch,
    data: photosData,
    isError,
    error,
    isLoading,
  } = useInfiniteQuery(
    "photoList",
    ({ pageParam = 1 }) => api.getPhotoList({ page: pageParam }),
    {
      getNextPageParam: (lastPage, allPages) => lastPage.meta.next ?? undefined,
      getPreviousPageParam: (firstPage, allPages) =>
        firstPage.meta.prev ?? undefined,
    },
  )

  const deleteMutation = useMutation(
    async () => {
      setSelectedPhotos([])
      for (const photoId of selectedPhotos) {
        await api.deletePhoto(photoId)
      }
    },
    {
      onError: (err, variables, context) => {
        const { title, description } = getErrorMessage(err)
        alert.current.showNotification({ title, description })

        if (!err.isAxiosError) {
          Sentry.captureException(err)
        }
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries("photoList")

        alert.current.showNotification({
          title: t`Photo deletion`,
          description: t`Photo successfully deleted.`,
          componentProps: {
            alertType: "success",
          },
        })
      },
    },
  )

  const { width } = useWindowDimensions()

  const numColumns = getBreakpointValue([3, 3, 4, 5])
  const pictureSize =
    (breakpoints[currentBreakpointIndex - 1] ?? width) / numColumns
  const allPhotos = photosData?.pages?.flatMap((p) => p.data) ?? []

  const onNewPhotoPress = useCallback(
    (getImage) => {
      return async () => {
        const image = await getImage()

        mixpanel.track("Photo Selected")

        if (image) {
          await openModal({ name: "PhotoNewModal", image })
        }
      }
    },
    [openModal],
  )

  return (
    <>
      <Breakpoint values={["lg", "xl"]}>
        <TopNavBar />
      </Breakpoint>

      <Container flex={1} breakpoint="xl">
        <Row flex={1}>
          <NavBar title={t`Photos`}>
            {selectedPhotos.length < 1 ? (
              <ImagePickerContainer end>
                {({ getImage }) => (
                  <Button
                    label={t`New photo`}
                    variant="navbar"
                    size="xs"
                    onPress={onNewPhotoPress(getImage)}
                  />
                )}
              </ImagePickerContainer>
            ) : (
              <Button
                end
                mr="lg"
                label={t`Delete`}
                variant="navbar"
                size="xs"
                disabled={deleteMutation.isLoading}
                onPress={() => deleteMutation.mutate()}
              />
            )}
          </NavBar>

          <View flex={1} justifyContent="center">
            {isLoading ? (
              <ActivityIndicator />
            ) : isError ? (
              <ErrorBody error={error} reload={refetch} />
            ) : (
              <FlatList
                key={numColumns}
                px={getBreakpointValue(["xs", "xs", "xl", "xl"])}
                contentContainerStyle={{
                  flexGrow: 1,
                }}
                ListEmptyComponent={
                  <View flex={1} justifyContent="center">
                    <ImagePickerContainer>
                      {({ getImage }) => (
                        <TouchableOpacity
                          mx="auto"
                          bg="primary"
                          alignItems="center"
                          justifyContent="center"
                          borderRadius={3}
                          height={150}
                          width={150}
                          onPress={onNewPhotoPress(getImage)}
                        >
                          <Icon mb="md" color="white" name="camera" />
                          <Text fontFamily="bold" fontSize={2} color="white">
                            <Trans>New photo</Trans>
                          </Text>
                        </TouchableOpacity>
                      )}
                    </ImagePickerContainer>
                  </View>
                }
                numColumns={numColumns}
                getItemLayout={(data, index) => ({
                  length: pictureSize,
                  offset: pictureSize * index,
                  index,
                })}
                onEndReached={() => hasNextPage && fetchNextPage()}
                refreshControl={
                  <RefreshControl
                    onRefresh={() => refetch()}
                    refreshing={false}
                  />
                }
                data={allPhotos}
                renderItem={({ item }) => {
                  return (
                    <PhotoItem
                      disabled={deleteMutation.isLoading}
                      photo={item}
                      pictureSize={pictureSize}
                      selectedPhotos={selectedPhotos}
                      setSelectedPhotos={setSelectedPhotos}
                    />
                  )
                }}
                keyExtractor={(item) => item.id}
              />
            )}
          </View>
        </Row>
      </Container>
    </>
  )
}

function PhotoItem({
  photo,
  pictureSize,
  selectedPhotos,
  setSelectedPhotos,
  disabled,
}) {
  return (
    <TouchableOpacity
      disabled={disabled}
      onPress={() =>
        selectedPhotos.includes(photo.id)
          ? setSelectedPhotos(selectedPhotos.filter((p) => p !== photo.id))
          : setSelectedPhotos([...selectedPhotos, photo.id])
      }
    >
      <FastImage
        testID="image"
        dataSet={{ private: true }}
        style={{ width: pictureSize, height: pictureSize }}
        source={{
          uri: photo.url,
        }}
        resizeMode={FastImage.resizeMode.cover}
      />
      {selectedPhotos.includes(photo.id) && (
        <View
          position="absolute"
          right={4}
          bottom={4}
          bg="background"
          borderRadius={4}
        >
          <Icon name="check-circle" color="primary" />
        </View>
      )}
    </TouchableOpacity>
  )
}
