import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useRef,
} from "react"
import { Dialog } from "./dialog"

const DialogServiceContext = createContext(Promise.reject)

export const useDialog = () => useContext(DialogServiceContext)

export const DialogProvider = ({ children }) => {
  const [dialogState, setDialogState] = useState(null)
  const [pendingId, setPendingId] = useState(null)
  const [inputValue, setInputValue] = useState("")
  const [isClosing, setIsClosing] = useState(false)

  const awaitingPromiseRef = useRef()

  const openDialog = useCallback(
    (options) => {
      setDialogState(options)

      if (options?.input?.defaultValue) {
        setInputValue(options.input.defaultValue)
      }

      return new Promise((resolve, reject) => {
        awaitingPromiseRef.current = { resolve, reject }
      })
    },
    [setDialogState, awaitingPromiseRef],
  )

  const handleClose = async () => {
    if (dialogState.onCancel) {
      try {
        setIsClosing(true)
        await dialogState.onCancel()
      } finally {
        setIsClosing(false)
      }
    }

    if (dialogState.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject()
    }

    setDialogState(null)
    setInputValue("")
  }

  const handleButtonPress = async (label) => {
    const button = dialogState.buttons.find((i) => i.label === label)

    if (button?.onPress) {
      try {
        setPendingId(button.label)
        await button.onPress(inputValue)
      } finally {
        setPendingId(null)
      }
    }

    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve()
    }

    setDialogState(null)
    setInputValue("")
  }

  return (
    <>
      <DialogServiceContext.Provider value={openDialog} children={children} />

      <Dialog
        isOpen={Boolean(dialogState)}
        pendingId={pendingId}
        isClosing={isClosing}
        handleButtonPress={handleButtonPress}
        onClose={handleClose}
        inputValue={inputValue}
        setInputValue={setInputValue}
        {...dialogState}
      />
    </>
  )
}

DialogProvider.defaultProps = {}
