import { Trans, t } from "@lingui/macro"
import { useNavigation, useRoute } from "@react-navigation/native"
import React from "react"
import { StyleSheet } from "react-native"
import { useQuery } from "react-query"
import seapig, { OPTIONALS } from "seapig"
import { useTheme } from "styled-components/native"
import aiheadshotgeneratorIcon from "../../assets/aiheadshotgenerator/rounded-icon.png"
import logoBlack from "../../assets/logo-black.png"
import logo from "../../assets/logo.png"
import { useBreakpoints, useSafeAreaOrDefaultInsets } from "../../hooks"
import { DARK_APPEARANCE } from "../../lib/constants"
import { appStoreReviewPassed, useApi, useStore } from "../../providers"
import { Button } from "./button"
import { Container, Row } from "./container"
import { Icon } from "./icon"
import { Image } from "./image"
import { Link } from "./link"
import { Text } from "./text"
import { View } from "./view"

export function AppBar({ children, title, showBack, navigation, ...props }) {
  const { startChildren, middleChildren, endChildren } = seapig(children, {
    start: OPTIONALS,
    middle: OPTIONALS,
    end: OPTIONALS,
  })

  return (
    <View p="xl" flexDirection="row" alignItems="center" {...props}>
      <View flex={1} flexDirection="row" justifyContent="flex-start">
        {startChildren.length < 1 && showBack && navigation?.canGoBack() ? (
          <Button
            label={t`Back`}
            icon="chevron-left"
            variant="navbar"
            size="xs"
            onPress={() => navigation?.goBack()}
          />
        ) : (
          startChildren
        )}
      </View>

      {middleChildren.length > 0 ? (
        middleChildren
      ) : (
        <View flexDirection="row" justifyContent="center">
          <Text
            fontSize={3}
            fontFamily="bold"
            color={title ? "text" : "background"}
          >
            {title ?? " "}
          </Text>
        </View>
      )}

      <View flex={1} flexDirection="row" justifyContent="flex-end">
        {endChildren}
      </View>
    </View>
  )
}

export function NavBar({ children, showBack = true, ...props }) {
  const navigation = useNavigation()
  const theme = useTheme()
  const insets = useSafeAreaOrDefaultInsets()
  const { getBreakpointValue } = useBreakpoints()

  return (
    <AppBar
      pt={
        getBreakpointValue([insets.top, insets.top, 0, 0]) +
        getBreakpointValue([
          insets.top > 0 ? theme.space.md : theme.space.xl,
          insets.top > 0 ? theme.space.md : theme.space.xl,
          theme.space.xl,
          theme.space.xl,
        ])
      }
      showBack={showBack}
      navigation={navigation}
      {...props}
    >
      {children}
    </AppBar>
  )
}

export function TopNavBar() {
  const api = useApi()
  const { scheme } = useStore()
  const insets = useSafeAreaOrDefaultInsets()
  const { data: user } = useQuery("currentUser", api.getUser)

  const currentRoute = useRoute()?.name

  return (
    <>
      {insets.top > 0 && <View bg="navigation" pt={insets.top} />}
      <View bg="navigation" height={56.5}>
        <Container bg="navigation" height="100%" breakpoint="xl">
          <Row
            flexDirection="row"
            height="100%"
            px="xl"
            justifyContent="space-between"
            alignItems="center"
          >
            <Link
              to={{ screen: "TestsStack", params: { screen: "TestList" } }}
              height={56.5}
            >
              <View height={56.5} flexDirection="row" alignItems="center">
                <Image
                  style={styles.logo}
                  source={scheme === DARK_APPEARANCE ? logoBlack : logo}
                />
                <Text fontSize={2} color="primary" ml="md" fontFamily="bold">
                  Testframe
                </Text>
              </View>
            </Link>

            <View flexDirection="row" height="100%">
              <Link
                opacity={currentRoute === "TestList" ? 1 : 0.7}
                mr="xl"
                fontFamily="bold"
                fontSize={2}
                to={{ screen: "TestsStack", params: { screen: "TestList" } }}
                testID="TestsStack"
              >
                <View height={56.5} flexDirection="row" alignItems="center">
                  <Icon mr="sm" name="images" color="primary" fontSize={2} />
                  <Text color="primary" fontFamily="bold" fontSize={2}>
                    <Trans>My tests</Trans>
                  </Text>
                </View>
              </Link>

              <Link
                opacity={currentRoute === "Review" ? 1 : 0.7}
                mr="xl"
                fontFamily="bold"
                fontSize={2}
                to={{ screen: "ReviewStack", params: { screen: "Review" } }}
                testID="ReviewStack"
              >
                <View height={56.5} flexDirection="row" alignItems="center">
                  <Icon mr="sm" name="star" color="primary" fontSize={2} />
                  <Text color="primary" fontFamily="bold" fontSize={2}>
                    <Trans>Rate</Trans>
                  </Text>
                </View>
              </Link>

              {appStoreReviewPassed && (
                <Link
                  opacity={currentRoute === "AIHeadshotGenerator" ? 1 : 0.7}
                  mr="xl"
                  fontFamily="bold"
                  fontSize={2}
                  to={{ screen: "AIHeadshotGenerator" }}
                  testID="AIHeadshots"
                >
                  <View height={56.5} flexDirection="row" alignItems="center">
                    <Image
                      mr="sm"
                      style={{ width: 20, height: 20 }}
                      source={aiheadshotgeneratorIcon}
                    />
                    <Text color="primary" fontFamily="bold" fontSize={2}>
                      AI Headshot
                    </Text>
                  </View>
                </Link>
              )}

              {user?.role === "admin" && (
                <Link
                  opacity={currentRoute === "Admin" ? 1 : 0.7}
                  mr="xl"
                  fontFamily="bold"
                  fontSize={2}
                  to={{ screen: "AdminStack", params: { screen: "Admin" } }}
                  testID="AdminStack"
                >
                  <View height={56.5} flexDirection="row" alignItems="center">
                    <Icon
                      mr="sm"
                      name="user-cog"
                      color="primary"
                      fontSize={2}
                    />
                    <Text color="primary" fontFamily="bold" fontSize={2}>
                      Admin
                    </Text>
                  </View>
                </Link>
              )}
              <Link
                opacity={currentRoute === "More" ? 1 : 0.7}
                fontFamily="bold"
                fontSize={2}
                to={{ screen: "MoreStack", params: { screen: "More" } }}
                testID="MoreStack"
              >
                <View height={56.5} flexDirection="row" alignItems="center">
                  <Icon mr="sm" name="bars" color="primary" fontSize={2} />
                  <Text color="primary" fontFamily="bold" fontSize={2}>
                    <Trans>More</Trans>
                  </Text>
                </View>
              </Link>
            </View>
          </Row>
        </Container>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  logo: {
    height: 56.5,
    width: 56.5,
  },
})
