import React, { useContext, useState } from "react"
import useAppState from "react-native-appstate-hook"
import AsyncStorage from "@react-native-async-storage/async-storage"

export const LaunchCountContext = React.createContext(null)

export function useLaunchCount() {
  return useContext(LaunchCountContext)
}

export function LaunchCountProvider({ children }) {
  const [launchCount, setLaunchCount] = useState()

  useAppState({
    onChange: async (state) => {
      if (state === "active") {
        const oldLaunchCount = (await AsyncStorage.getItem("launchCount")) ?? 0
        const newLaunchCount = parseInt(oldLaunchCount, 10) + 1
        await AsyncStorage.setItem("launchCount", String(newLaunchCount))
        setLaunchCount(newLaunchCount)
      }
    },
  })

  return (
    <LaunchCountContext.Provider
      value={{
        launchCount,
      }}
    >
      {children}
    </LaunchCountContext.Provider>
  )
}
