import React from "react"
import { Trans, t } from "@lingui/macro"
import { Button, ScrollView, Text, Pressable } from "../ui"

export function ObjectifyPeopleModal({ onClose }) {
  return (
    <Pressable onPress={onClose} flex={1} justifyContent="center">
      <Pressable bg="background" borderRadius={3} mx="xl">
        <Text m="xl" fontSize={5}>
          <Trans>Are Testframe reviews about people or photo?</Trans>
        </Text>
        <ScrollView px="xl">
          <Text fontSize={2}>
            <Trans>
              Testframe is a photo testing tool. It measures only impressions
              from photos and does not objectify real people. Impressions differ
              from reality and are often wrong.
            </Trans>
          </Text>
          <Text fontSize={2}>
            <Trans>
              Creating a good photo depends largely on skill. Different photos
              of the same person often produce completely different results. A
              photo in good light, from a good angle, and with attractive
              clothes will yield very different results than selfies in the
              bathroom.
            </Trans>
          </Text>
          <Text fontSize={2}>
            <Trans>
              For these reasons, reviewers cannot rate you as a person, only
              your photos.
            </Trans>
          </Text>
        </ScrollView>

        <Button
          m="xl"
          variant="primary-outline"
          label={t`Close`}
          onPress={onClose}
        />
      </Pressable>
    </Pressable>
  )
}

ObjectifyPeopleModal.modalOptions = {}
