import { t, Trans } from "@lingui/macro"
import delay from "delay"
import React from "react"
import { StyleSheet } from "react-native"
import InAppReview from "react-native-in-app-review"
import { useMutation } from "react-query"
import { useTheme } from "styled-components/native"
import logoLight from "../assets/logo-light.png"
import logo from "../assets/logo.png"
import {
  Button,
  Container,
  HideKeyboard,
  Image,
  KeyboardAvoidingView,
  Row,
  Text,
  View,
} from "../components/ui"
import { useSafeAreaOrDefaultInsets } from "../hooks"
import { DARK_APPEARANCE } from "../lib/constants"
import { getErrorMessage } from "../lib/helpers"
import { mixpanel } from "../lib/mixpanel"
import { Sentry } from "../lib/sentry"
import { light } from "../lib/theme"
import { useStore } from "../providers"

export function RateUsV2({ navigation, route }) {
  const theme = useTheme()
  const insets = useSafeAreaOrDefaultInsets({ bottom: theme.space.xl })
  const { scheme, storeMutation } = useStore()

  const rateUsMutation = useMutation(
    async () => {
      if (InAppReview.isAvailable()) {
        await InAppReview.RequestInAppReview()
        mixpanel.track("RateUs Pressed")
        await delay(2000)
      }

      storeMutation.mutate({ showRateUsV2: false })
    },
    {
      onError: (err, variables, context) => {
        const { title, description } = getErrorMessage(err)
        alert.current.showNotification({ title, description })

        if (!err.isAxiosError) {
          Sentry.captureException(err)
        }
      },
      onSuccess: async (data, variables, context) => {},
    },
  )

  return (
    <Container flex={1}>
      <Row flex={1}>
        <HideKeyboard flex={1}>
          <KeyboardAvoidingView flex={1}>
            <View flex={1} px="xl" pt={insets.top} justifyContent="center">
              <Image
                mx="auto"
                style={styles.logo}
                source={scheme === DARK_APPEARANCE ? logo : logoLight}
              />
              <Text
                mt="xl"
                textAlign="center"
                fontSize={6}
                fontFamily="heading"
              >
                ⭐️ ⭐️ ⭐️ ⭐️ ⭐️
              </Text>
              <Text
                mt="xl"
                textAlign="center"
                fontSize={6}
                fontFamily="heading"
              >
                <Trans>Your rating helps us</Trans>
              </Text>
              <Text
                textAlign="center"
                mt="md"
                fontSize={3}
                fontFamily="heading"
              >
                <Trans>
                  Please rate us as this keeps us motivated to work on this app
                  and keep improving it.
                </Trans>
              </Text>
            </View>

            <View pb={insets.bottom} mx="xl" justifyContent="center">
              <Button
                disabled={rateUsMutation.isLoading}
                loading={rateUsMutation.isLoading}
                mt="xl"
                onPress={rateUsMutation.mutate}
                label={t`Rate`}
              />
            </View>
          </KeyboardAvoidingView>
        </HideKeyboard>
      </Row>
    </Container>
  )
}

const styles = StyleSheet.create({
  appleButton: { width: "100%", height: 45, marginTop: light.space.xl },
  googleButton: { width: "100%", height: 58 },
  logo: { width: 128, height: 128 },
})
