import React from "react"
import { View } from "./view"

export function StepBar({ numberOfSteps, activeStep, ...props }) {
  return (
    <View {...props}>
      <View
        height={2}
        width="100%"
        bg="primary"
        position="absolute"
        top="50%"
        left={0}
      />

      <View flexDirection="row" justifyContent="space-between">
        {Array.from({ length: numberOfSteps }).map((i, idx) => (
          <View
            key={idx}
            bg={activeStep >= idx + 1 ? "primary" : "background"}
            borderWidth={2}
            borderColor="primary"
            height={16}
            width={16}
            borderRadius={3}
          />
        ))}
      </View>
    </View>
  )
}

StepBar.defaultProps = {
  numberOfSteps: 3,
  activeStep: 1,
}
