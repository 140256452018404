import { t, Trans } from "@lingui/macro"
import React from "react"
import { Linking, Platform, StyleSheet } from "react-native"
import { useTheme } from "styled-components/native"
import aiheadshotgeneratorLogo from "../assets/aiheadshotgenerator/rounded-icon.png"
import logoLight from "../assets/logo-light.png"
import logo from "../assets/logo.png"
import meterableLogo from "../assets/meterable-logo.png"
import {
  AppBar,
  Button,
  Container,
  Image,
  Row,
  Text,
  TopNavBar,
  View,
} from "../components/ui"
import { Breakpoint, useSafeAreaOrDefaultInsets } from "../hooks"
import { config } from "../lib/config"
import { DARK_APPEARANCE } from "../lib/constants"
import { makeLanguageUrlPrefix } from "../lib/helpers"
import { mixpanel } from "../lib/mixpanel"
import { useStore } from "../providers"

export function RateUsScreen({ navigation }) {
  const { languageCode } = useStore()
  const theme = useTheme()
  const { scheme } = useStore()
  const insets = useSafeAreaOrDefaultInsets({ bottom: theme.space.xl })

  const storeName = Platform.OS === "android" ? "Google Play" : "App Store"

  return (
    <>
      {Platform.OS !== "ios" && (
        <Breakpoint values={["lg", "xl"]}>
          <TopNavBar />
        </Breakpoint>
      )}

      <Container breakpoint="xl">
        <Row>
          <AppBar showBack={Platform.OS !== "ios"} navigation={navigation}>
            {Platform.OS === "ios" && (
              <Button
                start
                mr="lg"
                label={t`Close`}
                variant="navbar"
                size="xs"
                onPress={navigation.goBack}
              />
            )}
          </AppBar>
        </Row>
      </Container>

      <Container flex={1}>
        <Row flex={1}>
          <View pb={insets.bottom} flex={1}>
            <View px="xl" flex={1} alignItems="center" justifyContent="center">
              <Text fontSize={6} fontFamily="heading" textAlign="center">
                <Trans>
                  Earn up to 30 coins by rating us on the {storeName}
                </Trans>
              </Text>

              <Text fontSize={2} color="gray" mt="sm" textAlign="center">
                <Trans>10 coins are awarded for each application review.</Trans>
              </Text>

              <View mt="xl">
                <Text fontSize={5} textAlign="center">
                  <Trans>How to claim your reward</Trans>
                </Text>
                <Text fontSize={3} mt="sm">
                  1. <Trans>Leave a 5-star review on the {storeName}</Trans>
                </Text>
                <Text fontSize={3}>
                  2.{" "}
                  <Trans>
                    Mention what you love about the app in your review
                  </Trans>
                </Text>
                <Text fontSize={3}>
                  3. <Trans>Take a screenshot of your review</Trans>
                </Text>
                <Text fontSize={3}>
                  4.{" "}
                  <Trans>
                    Email the review screenshot to{" "}
                    <Text
                      fontSize={2}
                      fontFamily="heading"
                      color="primary"
                      onPress={async () => {
                        try {
                          await Linking.openURL(
                            `mailto:support@testframe.app?body=
Device info: ${Platform.OS} ${Platform.Version ?? ""}
App version: ${config.version}`,
                          )
                        } catch (err) {
                          await Linking.openURL(
                            `${config.web.url}${makeLanguageUrlPrefix(
                              languageCode,
                            )}/support`,
                          )
                        }
                      }}
                    >
                      support@testframe.app
                    </Text>
                  </Trans>
                </Text>
                <Text fontSize={3}>
                  5. <Trans>Receive your well-deserved reward!</Trans>
                </Text>
              </View>
            </View>

            <View
              mt="xl"
              flexDirection="row"
              justifyContent="space-between"
              px="xl"
            >
              <View>
                <Image
                  source={scheme === DARK_APPEARANCE ? logoLight : logo}
                  style={styles.logo}
                  resizeMode="contain"
                />
                <Button
                  mt="xl"
                  onPress={async () => {
                    Linking.openURL(
                      Platform.OS === "android"
                        ? "https://play.google.com/store/apps/details?id=com.dimonnwc3.testframe&showAllReviews=true"
                        : "https://itunes.apple.com/app/id1603039736?action=write-review",
                    )

                    mixpanel.track("RateUs Pressed")
                  }}
                  label={t`Rate`}
                />
              </View>
              <View>
                <Image
                  source={meterableLogo}
                  style={styles.logo}
                  resizeMode="contain"
                />
                <Button
                  mt="xl"
                  onPress={async () => {
                    Linking.openURL(
                      Platform.OS === "android"
                        ? "https://play.google.com/store/apps/details?id=com.dimonnwc3.meterable&showAllReviews=true"
                        : "https://itunes.apple.com/app/meterable/id1584696105?action=write-review",
                    )

                    mixpanel.track("RateUs Pressed")
                  }}
                  label={t`Rate`}
                />
              </View>
              <View>
                <Image
                  source={aiheadshotgeneratorLogo}
                  style={styles.logo}
                  resizeMode="contain"
                />
                <Button
                  mt="xl"
                  onPress={async () => {
                    Linking.openURL(
                      Platform.OS === "android"
                        ? "https://play.google.com/store/apps/details?id=com.dimonnwc3.ai.headshot.generator&showAllReviews=true"
                        : "https://apps.apple.com/app/aiheadshotgenerator/id6467744940?action=write-review",
                    )

                    mixpanel.track("RateUs Pressed")
                  }}
                  label={t`Rate`}
                />
              </View>
            </View>
          </View>
        </Row>
      </Container>
    </>
  )
}

const styles = StyleSheet.create({
  logo: { width: 96, height: 96 },
})
