import { t, Trans } from "@lingui/macro"
import Clipboard from "@react-native-clipboard/clipboard"
import React from "react"
import { Platform, Share, StyleSheet } from "react-native"
import { useQuery } from "react-query"
import { useTheme } from "styled-components/native"
import logoLight from "../assets/logo-light.png"
import logo from "../assets/logo.png"
import {
  AppBar,
  Button,
  Container,
  Image,
  Row,
  Text,
  TopNavBar,
  View,
} from "../components/ui"
import { Breakpoint, useSafeAreaOrDefaultInsets } from "../hooks"
import { DARK_APPEARANCE } from "../lib/constants"
import { makeUserInviteLink } from "../lib/helpers"
import { mixpanel } from "../lib/mixpanel"
import { useAlert, useApi, useStore } from "../providers"

export function InviteNewScreen({ navigation }) {
  const api = useApi()
  const alert = useAlert()
  const theme = useTheme()
  const { scheme } = useStore()
  const insets = useSafeAreaOrDefaultInsets({ bottom: theme.space.xl })
  const { data: user } = useQuery("currentUser", api.getUser)
  const { data: invitesData } = useQuery("inviteList", api.getInviteList)

  return (
    <>
      {Platform.OS !== "ios" && (
        <Breakpoint values={["lg", "xl"]}>
          <TopNavBar />
        </Breakpoint>
      )}

      <Container breakpoint="xl">
        <Row>
          <AppBar showBack={Platform.OS !== "ios"} navigation={navigation}>
            {Platform.OS === "ios" && (
              <Button
                start
                mr="lg"
                label={t`Close`}
                variant="navbar"
                size="xs"
                onPress={navigation.goBack}
              />
            )}
          </AppBar>
        </Row>
      </Container>

      <Container flex={1}>
        <Row flex={1}>
          <View pb={insets.bottom} flex={1}>
            <View px="xl" flex={1} justifyContent="center" alignItems="center">
              <Text fontSize={6} fontFamily="heading">
                {invitesData?.meta?.count < 10 ? (
                  <Trans>Invite friend to get 10 coins</Trans>
                ) : (
                  <Trans>Invite friend</Trans>
                )}
              </Text>

              <Image
                mt="xl"
                source={scheme === DARK_APPEARANCE ? logo : logoLight}
                style={styles.logo}
                resizeMode="contain"
              />

              {invitesData?.meta?.count < 10 && (
                <View mt="xl">
                  <Text fontSize={5}>
                    <Trans>How to get reward?</Trans>
                  </Text>
                  <Text fontSize={3}>
                    1. <Trans>Share invite link</Trans>
                  </Text>
                  <Text fontSize={3}>
                    2. <Trans>User registers</Trans>
                  </Text>
                  <Text fontSize={3}>
                    3. <Trans>User created first test</Trans>
                  </Text>
                  <Text fontSize={3}>
                    4. <Trans>Get your reward</Trans>
                  </Text>
                </View>
              )}
            </View>

            <Button
              mt="xl"
              mx="xl"
              onPress={async () => {
                const inviteLink = makeUserInviteLink(user)

                if (Platform.OS === "web") {
                  Clipboard.setString(inviteLink)

                  alert.current.showNotification({
                    title: t`Invite`,
                    description: t`Invite link successfully copied.`,
                    componentProps: {
                      alertType: "success",
                    },
                  })

                  mixpanel.track("InviteLink Copied")

                  return
                }

                const content = {}
                const options = {}

                if (Platform.OS === "ios") {
                  content.url = inviteLink
                  options.subject = t`Invite friend`
                } else {
                  content.message = inviteLink
                }

                if (Platform.OS === "android") {
                  content.title = t`Invite friend`
                  options.dialogTitle = t`Invite friend`
                }

                await Share.share(content, options)

                mixpanel.track("InviteLink Copied")
              }}
              label={
                Platform.OS === "web"
                  ? t`Copy invite link`
                  : t`Share invite link`
              }
            />
          </View>
        </Row>
      </Container>
    </>
  )
}

const styles = StyleSheet.create({
  logo: { width: 128, height: 128 },
})
