import { t, Trans } from "@lingui/macro"
import delay from "delay"
import { clamp } from "lodash"
import React, { Fragment, useMemo } from "react"
import { Platform } from "react-native"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useTourGuideController } from "rn-tourguide"
import { useTheme } from "styled-components/native"
import {
  callIfShouldAskPushNotificationsPermissions,
  useModal,
} from "../components/modal-stack"
import { Breakpoint } from "../hooks"
import { INACTIVE_TEST, SOCIAL_TEST } from "../lib/constants"
import { formatNumber, getErrorMessage } from "../lib/helpers"
import Purchases from "../lib/purchases"
import { loadStripe } from "../lib/stripe"
import { useAlert, useApi, useStore } from "../providers"

import {
  ActivityIndicator,
  Container,
  ErrorBody,
  Link,
  LinkButton,
  NavBar,
  Row,
  ScrollView,
  StepBar,
  Text,
  TopNavBar,
  TouchableOpacity,
  useDialog,
  View,
} from "../components/ui"
import { mixpanel } from "../lib/mixpanel"
import { Sentry } from "../lib/sentry"

const coinsPackages = {
  50: "coins-50",
  110: "coins-110",
  250: "coins-250",
  700: "coins-700",
  1000: "coins-1000",
}

export function TestActivationFormScreen({ navigation, route }) {
  const firstActivation = route.params?.firstActivation ?? false

  const testListScreenTourGuideKey = "testListScreenTourGuideKey"

  const { start: startTourGuide } = useTourGuideController(
    testListScreenTourGuideKey,
  )

  const queryClient = useQueryClient()
  const alert = useAlert()
  const theme = useTheme()
  const api = useApi()
  const {
    hadTestsWalkthrough,
    lastPushNotificationPermissionsAskedAt,
    storeMutation,
  } = useStore()
  const { openModal } = useModal()
  const openCustomAmountOfReviewsDialog = useDialog()

  const { data: user, refetch: refetchUser } = useQuery(
    "currentUser",
    api.getUser,
  )

  const {
    data: availablePackages,
    isPurchasesError,
    purchasesError,
    refetchPurchases,
  } = useQuery("availablePackages", async () => {
    const offerings = await Purchases.getOfferings()
    return offerings.current?.availablePackages ?? []
  })

  const {
    data: stripe,
    isError: isStripeError,
    error: stripeError,
    refetch: stripeRefetch,
  } = useQuery("stripe", () => loadStripe())

  const activateTestMutation = useMutation(
    async ({ id, coins }) => {
      if ((user?.coins ?? 0) < coins) {
        const coinsToPurchase = clamp(coins, 50, 1000)

        const closestCoinsAmount = Object.keys(coinsPackages).find(
          (v) => coinsToPurchase <= v,
        )

        const packageToPurchase =
          availablePackages.find(
            (p) => p.identifier === coinsPackages[closestCoinsAmount],
          ) ?? availablePackages[0]

        if (packageToPurchase) {
          if (Platform.OS === "web") {
            const session = await api.createStripeCheckoutSession({
              stripeCheckoutSessionDto: {
                productId: packageToPurchase.product.identifier,
                testActivationId: test.id,
              },
            })

            return stripe.redirectToCheckout({ sessionId: session.id })
          }

          await Purchases.purchasePackage(packageToPurchase)

          alert.current.showNotification({
            title: t`Coins purchase`,
            description: t`Coins successfully purchased.`,
            componentProps: {
              alertType: "success",
            },
          })

          mixpanel.track("Coins Purchased", {
            amount: coins,
          })

          const user = await api.getUser()

          for (let i = 1; i <= 10; i++) {
            const newUser = await api.updateCoins()

            if (newUser.coins > user.coins) {
              queryClient.setQueryData("currentUser", newUser)
              break
            }

            await delay(1000 * i)
          }
        }
      }

      return api.activateTest({ id, coins })
    },
    {
      onError: (err, variables, context) => {
        if (err.userCancelled) {
          return
        }

        const { title, description } = getErrorMessage(err)
        alert.current.showNotification({ title, description })

        if (!err.isAxiosError) {
          Sentry.captureException(err)
        }
      },
      onSuccess: async (data, variables, context) => {
        queryClient.invalidateQueries("currentUser")

        queryClient.setQueryData(["test", route.params.id], data)
        queryClient.setQueriesData(["testList"], (queryData) => {
          return {
            ...queryData,
            pages: queryData.pages.map((page) => ({
              ...page,
              data: page.data.map((t) => (t.id === data.id ? data : t)),
            })),
          }
        })

        navigation.goBack()

        if (firstActivation || test.state === INACTIVE_TEST) {
          alert.current.showNotification({
            title: t`Test activation`,
            description: t`Test successfully activated.`,
            componentProps: {
              alertType: "success",
            },
          })

          mixpanel.track("Test Actvated")
        } else {
          alert.current.showNotification({
            title: t`Available reviews`,
            description: t`Available reviews successfully added.`,
            componentProps: {
              alertType: "success",
            },
          })

          mixpanel.track("Test Reviews Added")
        }

        if (hadTestsWalkthrough) {
          await callIfShouldAskPushNotificationsPermissions(
            { lastPushNotificationPermissionsAskedAt, storeMutation },
            () => openModal({ name: "PushNotificationsPermissionsModal" }),
          )
        } else {
          setTimeout(() => startTourGuide(), 1000)
        }
      },
    },
  )

  const {
    data: test,
    isError: isTestError,
    error: testError,
    isLoading: isTestLoading,
    refetch: refetchTest,
  } = useQuery(["test", route.params.id], () => api.getTest(route.params.id))

  const testingTypes = useMemo(
    () =>
      [
        {
          title: t`Quick`,
          subtitle: t`10 Reviews`,
          cost: t`10 Coins`,
          onPress: () =>
            activateTestMutation.mutate({
              id: test.id,
              coins: 10,
            }),
        },
        {
          title: t`Standard`,
          subtitle: t`20 Reviews`,
          cost: t`20 Coins`,
          onPress: () =>
            activateTestMutation.mutate({
              id: test.id,
              coins: 20,
            }),
        },
        {
          title: t`Accurate`,
          subtitle: t`40 Reviews`,
          cost: t`40 Coins`,
          onPress: () =>
            activateTestMutation.mutate({
              id: test.id,
              coins: 40,
            }),
        },
        {
          title: t`Complete`,
          subtitle: t`60 Reviews`,
          cost: t`60 Coins`,
          onPress: () =>
            activateTestMutation.mutate({
              id: test.id,
              coins: 60,
            }),
        },
        {
          title: t`Custom`,
          subtitle: t`Any amount of reviews`,
          cost: t`N Coins`,
          onPress: () =>
            openCustomAmountOfReviewsDialog({
              title: t`Custom amount`,
              description: t`Type in amount of reviews.`,
              input: {
                defaultValue: "100",
                keyboardType: "numeric",
              },
              buttons: [
                {
                  label: t`Cancel`,
                  variant: "primary-outline",
                },
                {
                  label: t`Ok`,
                  onPress: (value) => {
                    const amount = parseInt(value, 10)
                    if (Number.isFinite(amount) && amount > 0) {
                      activateTestMutation.mutate({
                        id: test.id,
                        coins: amount,
                      })
                    }
                  },
                },
              ],
            }),
        },
        (firstActivation || test?.state === INACTIVE_TEST) && {
          title: t`Energy`,
          subtitle: t`Use energy for reviews`,
          // cost: t`Free`,
          onPress: () =>
            activateTestMutation.mutate({
              id: test.id,
              coins: 0,
            }),
        },
      ].filter(Boolean),
    [
      activateTestMutation,
      test,
      openCustomAmountOfReviewsDialog,
      firstActivation,
    ],
  )

  return (
    <>
      <Breakpoint values={["lg", "xl"]}>
        <TopNavBar />
      </Breakpoint>

      <Container breakpoint="xl">
        <Row>
          <NavBar
            title={
              firstActivation || test?.state === INACTIVE_TEST
                ? t`Activation`
                : t`Reviews`
            }
          >
            <LinkButton
              end
              label={
                t`Coins` +
                `: ${formatNumber(user?.coins ?? 0, {
                  notation: "compact",
                })}`
              }
              variant="navbar"
              to={{
                screen: "CoinsPurchaseForm",
                params: { testActivationId: test?.id },
              }}
            />
          </NavBar>
        </Row>
      </Container>

      <View flex={1} justifyContent="center">
        {isTestLoading ? (
          <ActivityIndicator />
        ) : isTestError ||
          isPurchasesError ||
          (Platform.OS === "web" && isStripeError) ? (
          <ErrorBody
            error={testError || purchasesError || stripeError}
            reload={() => {
              refetchTest()
              refetchUser()
              if (Platform.OS === "web") {
                stripeRefetch()
              } else {
                refetchPurchases()
              }
            }}
          />
        ) : (
          <>
            <ScrollView
              contentContainerStyle={{
                paddingBottom: firstActivation
                  ? theme.space.xs
                  : theme.space.xl,
              }}
            >
              <Container>
                <Row px="xl">
                  <Text fontSize={6} fontFamily="heading">
                    {firstActivation || test?.state === INACTIVE_TEST ? (
                      <Trans>Select testing type</Trans>
                    ) : (
                      <Trans>Select the number of reviews</Trans>
                    )}
                  </Text>
                  {testingTypes.map((t, idx) => (
                    <Fragment key={idx}>
                      <TouchableOpacity
                        borderRadius={3}
                        borderColor="border"
                        borderWidth="md"
                        mt="xl"
                        p="xl"
                        bg="soft"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        disabled={activateTestMutation.isLoading}
                        onPress={t.onPress}
                      >
                        <View flex={1}>
                          <Text fontSize={4} fontFamily="heading">
                            {t.title}
                          </Text>
                          <Text mt="sm" fontSize={2}>
                            {t.subtitle}
                          </Text>
                        </View>
                        <View>
                          {activateTestMutation.isLoading ? (
                            <ActivityIndicator size="small" />
                          ) : (
                            <Text
                              fontSize={3}
                              fontFamily="heading"
                              color="primary"
                            >
                              {t.cost}
                            </Text>
                          )}
                        </View>
                        {idx === 2 &&
                          (firstActivation ||
                            test?.state === INACTIVE_TEST) && (
                            <View
                              position="absolute"
                              right={-8}
                              top={-8}
                              bg="social"
                              borderRadius={4}
                              px={7}
                              py={1}
                            >
                              <Text fontWeight="heading" color="white">
                                <Trans>Recommended for dating</Trans>
                              </Text>
                            </View>
                          )}
                      </TouchableOpacity>

                      {idx === 4 &&
                        (firstActivation || test?.state === INACTIVE_TEST) && (
                          <Text mt="xl" fontSize={3} fontFamily="heading">
                            <Trans>
                              <Link
                                fontSize={3}
                                fontFamily="heading"
                                to={{
                                  screen: "CoinsPurchaseForm",
                                  params: { testActivationId: test?.id },
                                }}
                              >
                                Buy coins
                              </Link>{" "}
                              to get a guaranteed number of reviews faster. With
                              coins you don't need energy and your test will be
                              a priority. One review costs one coin.
                            </Trans>
                          </Text>
                        )}
                    </Fragment>
                  ))}
                </Row>
              </Container>
            </ScrollView>
            {firstActivation && (
              <Container breakpoint="xl">
                <Row>
                  <StepBar
                    activeStep={test?.type === SOCIAL_TEST ? 3 : 4}
                    numberOfSteps={test?.type === SOCIAL_TEST ? 3 : 4}
                    m="xl"
                  />
                </Row>
              </Container>
            )}
          </>
        )}
      </View>
    </>
  )
}
