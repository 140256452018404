import React, { useRef } from "react"
import { Animated, StyleSheet } from "react-native"
import styled from "styled-components/native"
import { variant, space, color } from "styled-system"
import { View } from "./view"

export function ProgressBarComponent({
  percent,
  children,
  style,
  size,
  ...props
}) {
  const barAnimation = useRef(new Animated.Value(percent)).current
  const lineAnimation = useRef(new Animated.Value(percent)).current

  React.useEffect(() => {
    Animated.timing(barAnimation, {
      useNativeDriver: false,
      toValue: percent,
      duration: 500,
      delay: 1000,
    }).start()
  }, [barAnimation, percent])

  React.useEffect(() => {
    Animated.timing(lineAnimation, {
      useNativeDriver: false,
      toValue: percent,
      duration: 500,
      delay: 500,
    }).start()
  }, [lineAnimation, percent])

  const color = style[0].color

  return (
    <View
      {...props}
      style={style}
      borderColor={color}
      borderWidth="md"
      borderRadius={4}
      overflow="hidden"
      justifyContent="center"
      alignItems="center"
    >
      <Animated.View
        style={[
          styles.barAnimatedView,
          {
            backgroundColor: color,
            width: barAnimation.interpolate({
              inputRange: [0, 100],
              outputRange: ["0%", "100%"],
            }),
          },
        ]}
      />
      <Animated.View
        style={[
          styles.lineAnimatedView,
          {
            backgroundColor: color,
            left: lineAnimation.interpolate({
              inputRange: [0, 100],
              outputRange: ["0%", "100%"],
            }),
          },
        ]}
      />
      {children}
    </View>
  )
}

export const ProgressBar = styled(ProgressBarComponent)`
  ${space}
  ${variant({
    prop: "size",
    variants: {
      xs: { height: 8 },
      sm: { height: 12 },
      md: { height: 16 },
      lg: { height: 28 },
    },
  })}
  ${color}
`

ProgressBar.defaultProps = {
  size: "xs",
  color: "primary",
}

const styles = StyleSheet.create({
  barAnimatedView: {
    position: "absolute",
    left: 0,
    top: 0,
    height: "100%",
  },
  lineAnimatedView: {
    position: "absolute",
    left: 0,
    top: 0,
    height: "100%",
    marginLeft: -2,
    width: 2,
  },
})
