import { NavigationContainer } from "@react-navigation/native"
import React, { useCallback, useRef } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { Linking, Platform } from "react-native"
import useAppState from "react-native-appstate-hook"
import RNBootSplash from "react-native-bootsplash"
import { focusManager } from "react-query"
import { TourGuideProvider } from "rn-tourguide"
import { ThemeProvider } from "styled-components/native"
import { useInitLibs } from "../hooks"
import branch from "../lib/branch"
import { config } from "../lib/config"
import { mixpanel } from "../lib/mixpanel"
import { handleRenderError, routingInstrumentation } from "../lib/sentry"
import { light } from "../lib/theme"
import { RootStackNavigator } from "../navigation/root-stack-navigator"
import { AlertProvider, ApiProvider, useStore } from "../providers"
import { ModalStackProvider } from "./modal-stack"
import {
  ActionSheetProvider,
  DialogProvider,
  ErrorBody,
  TourGuideTooltip,
} from "./ui"

const linking = {
  subscribe(listener) {
    let unsubscribeBranch

    if (Platform.OS !== "web") {
      unsubscribeBranch = branch.subscribe(({ error, params, uri }) => {
        if (error) {
          throw error
        }
        listener(uri)
      })
    }

    const linkingSubscription = Linking.addEventListener("url", ({ url }) => {
      listener(url)
    })

    return () => {
      if (unsubscribeBranch) {
        unsubscribeBranch()
      }

      linkingSubscription.remove()
    }
  },
  prefixes: [
    "testframe://",
    "https://testframe.app.link",
    "https://testframe-alternate.app.link",
    "https://testframe.test-app.link",
    "https://testframe-alternate.test-app.link",
    config.web.url,
    config.app.url,
  ],
  config: {
    initialRouteName: "Home",
    screens: {
      Auth: "login",
      EmailAuth: "email-login",
      MagicLinkAuth: "magic-links/:link",
      InviteNew: "invites/new",
      CookieSettingsForm: "cookie-settings",
      Home: {
        initialRouteName: "TestList",
        screens: {
          ReviewStack: {
            initialRouteName: "Review",
            screens: {
              Review: "rate",
              TestReport: "tests/:testId/reports/new",
              UserProfileForm: "profile",
            },
          },
          TestsStack: {
            initialRouteName: "TestList",
            screens: {
              TestList: "tests",
              TestDetails: "tests/:id",
              SelectPhoto: "tests/new/photo-select",
              SelectCategory: "tests/new/category-select",
              TestForm: "tests/new/details",
              TestActivationForm: "tests/:id/activation",
              CoinsPurchaseForm: "coins-purchase",
            },
          },
          MoreStack: {
            initialRouteName: "More",
            screens: {
              More: "more",
              UserProfileForm: "user",
              PhotoList: "photos",
              PrivacySettingsForm: "privacy-settings",
              NotificationSettingsForm: "notification-settings",
            },
          },
          AdminStack: {
            initialRouteName: "Admin",
            screens: {
              Admin: "admin",
              AdminUserList: "admin/users",
              AdminUserDetails: "admin/users/:id",
              AdminTestList: "admin/tests",
              AdminTestDetails: "admin/tests/:id",
              AdminReviewList: "admin/reviews",
              AdminReview: "admin/review",
              AdminReportList: "admin/reports",
              AdminReportDetails: "admin/reports/:id",
              AdminTestReportList: "admin/test-reports",
              AdminTestReportDetails: "admin/test-reports/:id",
              AdminReviewReportList: "admin/review-reports",
            },
          },
        },
      },
    },
  },
}

const notAuthenticatedLinking = {
  ...linking,
  config: {
    ...linking.config,
    initialRouteName: "Auth",
  },
}

function onAppStateChange(status) {
  if (Platform.OS !== "web") {
    focusManager.setFocused(status === "active")
  }

  if (status !== "active" && Platform.OS !== "web") {
    mixpanel.flush()
  }
}

export function App() {
  const { theme, navigationTheme, authToken } = useStore()
  const navigation = useRef()
  const routeNameRef = useRef()

  const handleNavigationReady = useCallback(() => {
    if (Platform.OS !== "web") {
      routingInstrumentation.registerNavigationContainer(navigation)

      routeNameRef.current = navigation.current.getCurrentRoute().name

      return RNBootSplash.hide()
    }
  }, [])

  const handleNavigationStateChange = useCallback(() => {
    const previousRouteName = routeNameRef.current
    const currentRouteName = navigation.current.getCurrentRoute().name

    if (previousRouteName !== currentRouteName) {
      routeNameRef.current = currentRouteName
      mixpanel.track(`Visited ${currentRouteName}`)
    }
  }, [])

  useAppState({
    onChange: onAppStateChange,
  })

  useInitLibs()

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary onError={handleRenderError} FallbackComponent={ErrorBody}>
        <AlertProvider>
          <ActionSheetProvider>
            <DialogProvider>
              <ApiProvider>
                <ModalStackProvider>
                  <TourGuideProvider
                    androidStatusBarVisible
                    tooltipComponent={TourGuideTooltip}
                    borderRadius={light.radii[3]}
                  >
                    <NavigationContainer
                      linking={authToken ? linking : notAuthenticatedLinking}
                      theme={navigationTheme}
                      ref={navigation}
                      onReady={handleNavigationReady}
                      onStateChange={handleNavigationStateChange}
                    >
                      <RootStackNavigator />
                    </NavigationContainer>
                  </TourGuideProvider>
                </ModalStackProvider>
              </ApiProvider>
            </DialogProvider>
          </ActionSheetProvider>
        </AlertProvider>
      </ErrorBoundary>
    </ThemeProvider>
  )
}
