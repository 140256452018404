import { i18n } from "@lingui/core"
import { t, Trans } from "@lingui/macro"
import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { StyleSheet, useWindowDimensions } from "react-native"
import { useQuery } from "react-query"
import { useTheme } from "styled-components/native"
import {
  ActivityIndicator,
  Button,
  Container,
  ErrorBody,
  FastImage,
  ImagePreview,
  Input,
  KeyboardAvoidingView,
  NavBar,
  Pressable,
  Row,
  ScrollView,
  StepBar,
  Text,
  TopNavBar,
  View,
} from "../../components/ui"
import { Breakpoint, useBreakpoints, useCreateTest } from "../../hooks"
import {
  BUSINESS_TEST,
  DATING_TEST,
  SOCIAL_TEST,
  TEST_TYPE_OPTIONS,
} from "../../lib/constants"
import { formNumberTransformer } from "../../lib/helpers"
import { useApi } from "../../providers"

function TestForm({ photo, testType }) {
  const theme = useTheme()
  // const api = useApi()
  // const queryClient = useQueryClient()
  const { width } = useWindowDimensions()
  const { breakpoints, currentBreakpointIndex, getBreakpointValue } =
    useBreakpoints()

  const [imageVisible, setImageIsVisible] = useState(false)

  // const { data: user } = useQuery("currentUser", api.getUser)

  // const updateUserMutation = useMutation(api.updateUser, {
  //   onError: (err, variables, context) => {
  //     if (!err.isAxiosError) {
  //       Sentry.captureException(err)
  //     }
  //   },
  //   onSuccess: (data, variables, context) => {
  //     queryClient.setQueryData("currentUser", data)
  //   },
  // })

  const form = useForm({
    defaultValues: {
      bio: "",
      // userGender: null,
      // reviewerGender: user.gender
      //   ? {
      //       [MALE]: FEMALE,
      //       [FEMALE]: MALE,
      //       [NON_BINARY]: NON_BINARY,
      //     }[user.gender] ?? FEMALE
      //   : null,
      // reviewerOrientation: user.orientation ?? STRAIGHT,
      reviewerMinAge: 18,
      reviewerMaxAge: 50,
    },
  })

  const createMutation = useCreateTest(testType)

  function onSubmit(data) {
    let testDto = {
      photoId: photo.id,
    }

    if (testType === DATING_TEST) {
      testDto = {
        ...testDto,
        // reviewerGender: data.reviewerGender,
        // reviewerOrientation: data.reviewerOrientation,
        reviewerMinAge: data.reviewerMinAge,
        reviewerMaxAge: data.reviewerMaxAge,
      }
    }

    if (testType === BUSINESS_TEST) {
      testDto = { ...testDto, bio: data.bio }
    }

    if (testType === SOCIAL_TEST) {
      testDto = { ...testDto }
    }

    // if (!user.gender) {
    //   updateUserMutation.mutate({
    //     userDto: {
    //       gender: data.userGender,
    //     },
    //   })
    // }

    return createMutation.mutate({ testDto })
  }

  const imageSize =
    (((breakpoints[currentBreakpointIndex - 1] ?? width) - theme.space.xl * 2) *
      getBreakpointValue([100, 100, 50, 50])) /
      100 -
    getBreakpointValue([
      theme.space.xs,
      theme.space.xs,
      theme.space.xl,
      theme.space.xl,
    ])

  return (
    <>
      <ScrollView px="xl" keyboardShouldPersistTaps="handled">
        <Text fontSize={6} fontFamily="heading">
          <Trans>Ok, let's add some details</Trans>
        </Text>

        {/* {testType === DATING_TEST && user.gender && (
          <Text mt="md" fontSize={3} fontFamily="heading">
            <Trans>Specify who can rate this photo.</Trans>
          </Text>
        )}

        {testType === BUSINESS_TEST && user.gender && (
          <Text mt="md" fontSize={3} fontFamily="heading">
            <Trans>Specify your job title.</Trans>
          </Text>
        )} */}

        <View
          flexGap="xl"
          mt="md"
          flexDirection={getBreakpointValue(["column", "column", "row", "row"])}
        >
          <View
            width={getBreakpointValue(["100%", "100%", "50%", "50%"])}
            borderColor="border"
            p="md"
            borderWidth={getBreakpointValue([0, 0, 2, 2])}
            borderRadius={3}
          >
            {/* {!user.gender && (
              <Controller
                name="userGender"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: t`Value is required`,
                  },
                }}
                render={({
                  field: { onChange, onBlur, onFocus, value, name },
                }) => {
                  return (
                    <Input mt="xl" error={form.formState.errors[name]?.message}>
                      <Input.Title>
                        <Trans>Your gender</Trans>
                      </Input.Title>
                      <Input.Segment
                        items={GENDER_OPTIONS.map((o) => ({
                          ...o,
                          label: i18n._(o.label),
                        }))}
                        onChange={onChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        value={value}
                      />
                      <Input.Error />
                    </Input>
                  )
                }}
              />
            )} */}

            {/* {testType === DATING_TEST && !user.gender && ( */}
            {testType === DATING_TEST && (
              <Text mt="xl" fontSize={3} fontFamily="heading">
                <Trans>Specify who can rate this photo.</Trans>
              </Text>
            )}

            {/* {testType === BUSINESS_TEST && !user.gender && ( */}
            {testType === BUSINESS_TEST && (
              <Text mt="xl" fontSize={3} fontFamily="heading">
                <Trans>Specify your job title.</Trans>
              </Text>
            )}

            {/* {testType === DATING_TEST && (
              <Controller
                name="reviewerGender"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: t`Value is required`,
                  },
                }}
                render={({
                  field: { onChange, onBlur, onFocus, value, name },
                }) => {
                  return (
                    <Input mt="xl" error={form.formState.errors[name]?.message}>
                      <Input.Title>
                        <Trans>Reviewer gender</Trans>
                      </Input.Title>
                      <Input.Segment
                        items={GENDER_OPTIONS.map((o) => ({
                          ...o,
                          label: i18n._(o.label),
                        }))}
                        onChange={onChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        value={value}
                      />
                      <Input.Error />
                    </Input>
                  )
                }}
              />
            )}

            {testType === DATING_TEST && (
              <Controller
                name="reviewerOrientation"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: t`Value is required`,
                  },
                }}
                render={({
                  field: { onChange, onBlur, onFocus, value, name },
                }) => {
                  return (
                    <Input mt="xl" error={form.formState.errors[name]?.message}>
                      <Input.Title>
                        <Trans>Reviewer orientation</Trans>
                      </Input.Title>
                      <Input.Picker
                        title={t`Orientation`}
                        items={ORIENTATION_OPTIONS.map((o) => ({
                          ...o,
                          label: i18n._(o.label),
                        }))}
                        onChange={onChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        value={value}
                      />
                      <Input.Error />
                    </Input>
                  )
                }}
              />
            )} */}

            {testType === DATING_TEST && (
              <Controller
                name="reviewerMinAge"
                control={form.control}
                rules={{
                  required: { value: true, message: t`Value is required` },
                  min: {
                    value: 0,
                    message: t`Value should be greater or equal than ${0}`,
                  },
                  max: {
                    value: 99,
                    message: t`Value should be less or equal than ${99}`,
                  },
                  validate: {
                    number: (value) => {
                      if (isNaN(value)) {
                        return t`Value should be a number`
                      }

                      return true
                    },
                  },
                }}
                render={({
                  field: { onChange, onBlur, onFocus, value, name },
                }) => (
                  <Input mt="xl" error={form.formState.errors[name]?.message}>
                    <Input.Title>
                      <Trans>Reviewer min age</Trans>
                    </Input.Title>
                    <Input.Text
                      maxLength={3}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      onChangeText={(value) =>
                        onChange(formNumberTransformer.output(value))
                      }
                      value={formNumberTransformer.input(value)}
                      keyboardType="number-pad"
                    />
                    <Input.Error />
                  </Input>
                )}
              />
            )}

            {testType === DATING_TEST && (
              <Controller
                name="reviewerMaxAge"
                control={form.control}
                rules={{
                  required: { value: true, message: t`Value is required` },
                  min: {
                    value: 0,
                    message: t`Value should be greater or equal than ${0}`,
                  },
                  max: {
                    value: 99,
                    message: t`Value should be less or equal than ${99}`,
                  },
                  validate: {
                    number: (value) => {
                      if (isNaN(value)) {
                        return t`Value should be a number`
                      }

                      return true
                    },
                  },
                }}
                render={({
                  field: { onChange, onBlur, onFocus, value, name },
                }) => (
                  <Input mt="xl" error={form.formState.errors[name]?.message}>
                    <Input.Title>
                      <Trans>Reviewer max age</Trans>
                    </Input.Title>
                    <Input.Text
                      maxLength={3}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      onChangeText={(value) =>
                        onChange(formNumberTransformer.output(value))
                      }
                      value={formNumberTransformer.input(value)}
                      keyboardType="number-pad"
                    />
                    <Input.Error />
                  </Input>
                )}
              />
            )}

            {testType === BUSINESS_TEST && (
              <Controller
                name="bio"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: t`Value is required`,
                  },
                }}
                render={({
                  field: { onChange, onBlur, onFocus, value, name },
                }) => (
                  <Input mt="xl" error={form.formState.errors[name]?.message}>
                    <Input.Title>
                      <Trans>Job title</Trans>
                    </Input.Title>
                    <Input.Text
                      testID="bio"
                      dataSet={{ private: true }}
                      maxLength={150}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value}
                    />
                    <Input.Error />
                  </Input>
                )}
              />
            )}
          </View>

          {photo && (
            <View alignItems="center" borderRadius={3}>
              <Pressable onPress={() => setImageIsVisible(true)}>
                <FastImage
                  testID="image"
                  dataSet={{ private: true }}
                  style={{ width: imageSize, height: imageSize }}
                  source={{ uri: photo.url }}
                  borderRadius={3}
                  resizeMode={FastImage.resizeMode.cover}
                />
              </Pressable>

              <ImagePreview
                uri={photo.url}
                isOpen={imageVisible}
                onClose={() => setImageIsVisible(false)}
              />
              <View style={styles.lightbox} opacity={0.4}>
                <Button
                  width={38}
                  color="text"
                  bg="background"
                  iconFontSize={4}
                  size="md"
                  icon="expand-arrows-alt"
                  onPress={() => setImageIsVisible(true)}
                />
              </View>
            </View>
          )}
        </View>
      </ScrollView>

      <Button
        m="xl"
        mb="xs"
        loading={createMutation.isLoading}
        disabled={createMutation.isLoading}
        onPress={form.handleSubmit(onSubmit)}
        label={t`Next`}
      />

      <StepBar activeStep={3} numberOfSteps={4} m="xl" />
    </>
  )
}

export function TestFormScreen({ route, navigation }) {
  const { testType } = route.params

  const api = useApi()

  const { data: photo } = useQuery(["photo", route.params.photoId], () =>
    api.getPhoto(route.params.photoId),
  )

  const {
    data: user,
    isError,
    error,
    isLoading,
    refetch,
  } = useQuery("currentUser", api.getUser)

  return (
    <>
      <Breakpoint values={["lg", "xl"]}>
        <TopNavBar />
      </Breakpoint>

      <KeyboardAvoidingView flex={1} justifyContent="center">
        <Container flex={1} breakpoint="xl">
          <Row flex={1}>
            <NavBar
              title={i18n._(
                TEST_TYPE_OPTIONS.find((t) => t.value === testType).label,
              )}
            />

            {isLoading ? (
              <ActivityIndicator />
            ) : isError ? (
              <ErrorBody error={error} reload={refetch} />
            ) : (
              <TestForm user={user} photo={photo} testType={testType} />
            )}
          </Row>
        </Container>
      </KeyboardAvoidingView>
    </>
  )
}

const styles = StyleSheet.create({
  lightbox: { position: "absolute", top: 8, right: 8 },
})
