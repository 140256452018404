import mixpanel from "mixpanel-browser"

mixpanel.init(
  __DEV__
    ? "4200919ea8b578682a1c753d5f8fd820"
    : "53132a998d722fb705626ffe22463788",
  { debug: __DEV__ },
)

export { mixpanel }
