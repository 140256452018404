import { i18n } from "@lingui/core"
import { t, Trans } from "@lingui/macro"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import { Linking, Platform } from "react-native"
import { useMutation, useQueryClient } from "react-query"
import {
  Button,
  Container,
  Icon,
  Input,
  NavBar,
  Row,
  ScrollView,
  Text,
  TopNavBar,
  TouchableOpacity,
  View,
} from "../components/ui"
import { Breakpoint } from "../hooks"
import { config } from "../lib/config"
import { TECHNICAL, TEST_REPORT_REASONS_OPTIONS } from "../lib/constants"
import { getErrorMessage, makeLanguageUrlPrefix } from "../lib/helpers"
import { mixpanel } from "../lib/mixpanel"
import { Sentry } from "../lib/sentry"
import { useAlert, useApi, useStore } from "../providers"

export function TestReportFormScreen({ route, navigation }) {
  const { testId } = route.params
  const queryClient = useQueryClient()
  const alert = useAlert()
  const api = useApi()
  const { languageCode } = useStore()

  const form = useForm({
    defaultValues: {
      reason: TECHNICAL,
    },
  })

  const createMutation = useMutation(api.createTestReport, {
    onError: (err, variables, context) => {
      if (err?.response?.status === 404) {
        queryClient.invalidateQueries("nextTest")
        navigation.goBack()
        return
      } else {
        const { title, description } = getErrorMessage(err)
        alert.current.showNotification({ title, description })
      }

      if (!err.isAxiosError) {
        Sentry.captureException(err)
      }
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("nextTest")
      navigation.goBack()
      mixpanel.track("TestReport Created")
    },
  })

  function onSubmit(data) {
    return createMutation.mutate({
      testReportDto: {
        ...data,
        testId,
      },
    })
  }

  return (
    <>
      <Breakpoint values={["lg", "xl"]}>
        <TopNavBar />
      </Breakpoint>

      <Container breakpoint="xl">
        <Row>
          <NavBar title={t`Report`} />
        </Row>
      </Container>

      <Container flex={1}>
        <Row flex={1}>
          <View flex={1}>
            <ScrollView px="xl">
              <Text fontSize={6} fontFamily="heading">
                <Trans>Select a report reason</Trans>
              </Text>

              <Controller
                name="reason"
                control={form.control}
                render={({
                  field: { onChange, onBlur, onFocus, value, name },
                }) => {
                  return (
                    <Input error={form.formState.errors[name]?.message}>
                      {TEST_REPORT_REASONS_OPTIONS.map((o) => {
                        const isSelected = o.value === value

                        return (
                          <TouchableOpacity
                            key={o.value}
                            borderRadius={3}
                            mt="xl"
                            p="xl"
                            bg="soft"
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="center"
                            onPress={() => onChange(o.value)}
                          >
                            <View flex={1}>
                              <Text fontSize={4} fontFamily="heading">
                                {i18n._(o.label)}
                              </Text>
                              <Text fontSize={2} mt="xl">
                                {i18n._(o.description)}
                              </Text>
                            </View>
                            <View>
                              <Icon
                                name="check"
                                color={isSelected ? "primary" : "text"}
                              />
                            </View>
                          </TouchableOpacity>
                        )
                      })}
                    </Input>
                  )
                }}
              />

              <Text
                mt="xl"
                fontSize={2}
                fontFamily="heading"
                color="grayPrimary"
              >
                <Trans>
                  Reports are reviewed within 24 hours. Please feel free to
                  contact us{" "}
                  <Text
                    fontSize={2}
                    fontFamily="heading"
                    color="primary"
                    onPress={async () => {
                      try {
                        await Linking.openURL(
                          `mailto:support@testframe.app?body=
Device info: ${Platform.OS} ${Platform.Version ?? ""}
App version: ${config.version}`,
                        )
                      } catch (err) {
                        await Linking.openURL(
                          `${config.web.url}${makeLanguageUrlPrefix(
                            languageCode,
                          )}/support`,
                        )
                      }
                    }}
                  >
                    support@testframe.app
                  </Text>
                </Trans>
              </Text>
            </ScrollView>

            <Button
              m="xl"
              loading={createMutation.isLoading}
              disabled={createMutation.isLoading}
              onPress={form.handleSubmit(onSubmit)}
              label={t`Submit`}
            />
          </View>
        </Row>
      </Container>
    </>
  )
}
