import { t } from "@lingui/macro"
import * as base64 from "js-base64"
import React, { useEffect, useMemo } from "react"
import { Platform } from "react-native"
import { useMutation } from "react-query"
import {
  ActivityIndicator,
  Container,
  NavBar,
  Row,
  View,
} from "../components/ui"
import { getErrorMessage } from "../lib/helpers"
import { mixpanel } from "../lib/mixpanel"
import { Sentry } from "../lib/sentry"
import { useAlert, useApi, useStore } from "../providers"

export function MagicLinkAuthScreen({ navigation, route }) {
  const alert = useAlert()
  const { storeMutation } = useStore()
  const api = useApi()

  const userData = useMemo(() => {
    try {
      return JSON.parse(base64.decode(route.params?.link))
    } catch {
      return null
    }
  }, [route.params?.link])

  const createUserMutation = useMutation(
    async (data) => {
      const requestData = await api.createUser(data)

      // TODO ask for passkey setup here

      return requestData
    },
    {
      onError: (err, variables, context) => {
        navigation.goBack()

        if (err?.response?.status === 401) {
          alert.current.showNotification({
            title: t`Authentication error`,
            description: t`Invalid magic link.`,
          })
        } else {
          const { title, description } = getErrorMessage(err)
          alert.current.showNotification({ title, description })
        }

        if (!err.isAxiosError) {
          Sentry.captureException(err)
        }
      },
      onSuccess: async (requestData, variables, context) => {
        if (requestData.isNew) {
          mixpanel.alias(
            String(requestData.data.id),
            await (Platform.OS === "web"
              ? mixpanel.get_distinct_id()
              : mixpanel.getDistinctId()),
          )
          mixpanel.track("Signed Up", {
            platform: Platform.OS,
            isInvited: Boolean(userData.invite),
          })
        } else {
          mixpanel.track("Signed In", {
            platform: Platform.OS,
          })
        }

        storeMutation.mutate({ authToken: requestData.data.token })
      },
    },
  )

  useEffect(() => {
    if (!userData) {
      navigation.goBack()

      alert.current.showNotification({
        title: t`Authentication error`,
        description: t`Invalid magic link.`,
      })
    } else {
      if (createUserMutation.isIdle) {
        createUserMutation.mutate({
          userDto: {
            email: userData.email,
            passwordDigest: userData.password,
            inviteCode: userData.invite,
          },
        })
      }
    }
  }, [userData, navigation, createUserMutation, alert])

  return (
    <Container flex={1}>
      <Row flex={1}>
        <NavBar />

        <View flex={1} alignItems="center" justifyContent="center">
          <ActivityIndicator />
        </View>
      </Row>
    </Container>
  )
}
