import React from "react"
import { Linking, Platform } from "react-native"
import { Trans, t } from "@lingui/macro"
import { makeLanguageUrlPrefix } from "../../lib/helpers"
import { config } from "../../lib/config"
import { Button, ScrollView, Text, Pressable } from "../ui"
import { useStore } from "../../providers"

export function SpamActivityModal({ onClose }) {
  const { languageCode } = useStore()

  return (
    <Pressable onPress={onClose} flex={1} justifyContent="center">
      <Pressable bg="background" borderRadius={3} mx="xl" flex={0.6}>
        <Text m="xl" fontSize={5}>
          <Trans>Spam activity detected on your account</Trans>
        </Text>
        <ScrollView px="xl">
          <Pressable>
            <Text fontSize={2}>
              <Trans>
                We found many similar ratings on your account. Such activity we
                consider as spam and do not encourage earning energy in this
                way.
              </Trans>
            </Text>
            <Text fontSize={2}>
              <Trans>
                Our team will review this case and remove warning if it's an
                error. If we continue to observe spam activity, we will we will
                block this account.
              </Trans>
            </Text>
            <Text fontSize={2}>
              <Trans>
                Please rate photos wisely and take your time to give appropriate
                feedback. Your honest opinion is very valuable.
              </Trans>
            </Text>
            <Text mt="xl" fontSize={2}>
              <Trans>
                Please feel free to contact us{" "}
                <Text
                  fontSize={2}
                  fontFamily="heading"
                  color="primary"
                  onPress={async () => {
                    try {
                      await Linking.openURL(
                        `mailto:support@testframe.app?body=
Device info: ${Platform.OS} ${Platform.Version ?? ""}
App version: ${config.version}`,
                      )
                    } catch (err) {
                      await Linking.openURL(
                        `${config.web.url}${makeLanguageUrlPrefix(
                          languageCode,
                        )}/support`,
                      )
                    }
                  }}
                >
                  support@testframe.app
                </Text>
              </Trans>
            </Text>
          </Pressable>
        </ScrollView>

        <Button
          m="xl"
          variant="primary-outline"
          label={t`Close`}
          onPress={onClose}
        />
      </Pressable>
    </Pressable>
  )
}
