import { i18n } from "@lingui/core"
import React from "react"
import { useWindowDimensions } from "react-native"
import { useInfiniteQuery } from "react-query"
import { useTheme } from "styled-components/native"
import {
  ActivityIndicator,
  Container,
  ErrorBody,
  FastImage,
  FlatList,
  NavBar,
  RefreshControl,
  Row,
  Text,
  TopNavBar,
  TouchableOpacity,
  View,
} from "../../components/ui"
import { Breakpoint, useBreakpoints } from "../../hooks"
import { TEST_REPORT_REASONS_OPTIONS } from "../../lib/constants"
import { formatRelativeDate } from "../../lib/helpers"
import { useApi } from "../../providers"

export function AdminTestReportListScreen({ navigation, route }) {
  const api = useApi()
  const theme = useTheme()
  const { width } = useWindowDimensions()
  const { getBreakpointValue, breakpoints, currentBreakpointIndex } =
    useBreakpoints()

  const {
    fetchNextPage,
    hasNextPage,
    refetch: refetchReports,
    data: reportsData,
    isError: reportsIsError,
    error: reportsError,
    isLoading: reportsIsLoading,
  } = useInfiniteQuery(
    [
      "adminTestReportList",
      { userId: route.params?.userId, testId: route.params?.testId },
    ],
    ({ pageParam = 1 }) =>
      api.getAdminTestReportList({
        page: pageParam,
        query: {
          userIdEq: route.params?.userId,
          testIdEq: route.params?.testId,
        },
      }),
    {
      getNextPageParam: (lastPage, allPages) => lastPage.meta.next ?? undefined,
      getPreviousPageParam: (firstPage, allPages) =>
        firstPage.meta.prev ?? undefined,
    },
  )

  const reportsCount = reportsData?.pages[0]?.meta?.count ?? 0
  const allReports = reportsData?.pages?.flatMap((p) => p.data) ?? []

  const numColumns = getBreakpointValue([2, 2, 3, 4])
  const pictureSize =
    ((breakpoints[currentBreakpointIndex - 1] ?? width) -
      theme.space.xl * 2 -
      theme.space.md * (numColumns - 1)) /
    numColumns

  return (
    <>
      <Breakpoint values={["lg", "xl"]}>
        <TopNavBar />
      </Breakpoint>

      <Container breakpoint="xl">
        <Row>
          <NavBar title="Test Reports" />
        </Row>
      </Container>

      <Container flex={1} breakpoint="xl">
        <Row flex={1}>
          <View flex={1} justifyContent="center">
            {reportsIsLoading ? (
              <ActivityIndicator />
            ) : reportsIsError ? (
              <ErrorBody error={reportsError} reload={refetchReports} />
            ) : (
              <FlatList
                key={numColumns}
                numColumns={numColumns}
                getItemLayout={(data, index) => ({
                  length: pictureSize,
                  offset: pictureSize * index,
                  index,
                })}
                onEndReached={() => hasNextPage && fetchNextPage()}
                refreshControl={
                  <RefreshControl
                    onRefresh={() => refetchReports()}
                    refreshing={false}
                  />
                }
                data={allReports}
                keyExtractor={(item) => item.id}
                renderItem={({ item, index }) => {
                  const mb = allReports.length - 1 === index ? "xl" : 0

                  return (
                    <TestReportItem
                      ml="lg"
                      mb={mb}
                      pictureSize={pictureSize}
                      report={item}
                      onPress={() =>
                        navigation.push("AdminTestReportDetails", {
                          id: item.id,
                        })
                      }
                    />
                  )
                }}
                ListHeaderComponent={
                  <View mx="xl">
                    <Text fontSize={4} fontFamily="heading">
                      Total: {reportsCount}
                    </Text>
                  </View>
                }
              />
            )}
          </View>
        </Row>
      </Container>
    </>
  )
}

function TestReportItem({ report, pictureSize, onPress, ...props }) {
  return (
    <TouchableOpacity
      {...props}
      mt="xl"
      borderRadius={3}
      overflow="hidden"
      onPress={() => onPress(report)}
    >
      <View bg="black">
        <FastImage
          testID="image"
          dataSet={{ private: true }}
          style={{ height: pictureSize, width: pictureSize }}
          source={{
            uri: report.test.photo.url,
          }}
          resizeMode={FastImage.resizeMode.cover}
        />
      </View>
      <View
        position="absolute"
        top={0}
        left={0}
        width="100%"
        bg="background"
        opacity={0.7}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        p="sm"
        px="md"
      >
        <Text fontFamily="bold">
          {i18n._(
            TEST_REPORT_REASONS_OPTIONS.find((t) => t.value === report.reason)
              .label,
          )}
        </Text>
      </View>
      <View
        position="absolute"
        bottom={0}
        left={0}
        width="100%"
        bg="background"
        opacity={0.7}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        p="sm"
        px="md"
      >
        <Text fontFamily="bold" mr="sm">
          ID: {report.id}
        </Text>
        <Text fontFamily="bold">{formatRelativeDate(report.createdAt)}</Text>
      </View>
    </TouchableOpacity>
  )
}
