import React from "react"
import { Text } from "../text"
import { useInputContext } from "./input-context"

export function InputTitle(props) {
  const { inputColor } = useInputContext()
  return <Text color={inputColor} {...props} />
}

InputTitle.defaultProps = {
  fontSize: 2,
}
