import { merge } from "lodash"
import { DefaultTheme, DarkTheme } from "@react-navigation/native"
import { StyleSheet } from "react-native"

const breakpoints = [576, 768, 992, 1200]
breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]

export const bubbleColors = [
  "#4680E0",
  "#E3A775",
  "#C79F9F",
  "#5AA092",
  "#DDDC64",
  "#F1D36B",
  "#7FCDD3",
  "#5041B7",
]

export const allBubbleColors = [
  ...bubbleColors,
  ...bubbleColors,
  ...bubbleColors,
  ...bubbleColors,
  ...bubbleColors,
  ...bubbleColors,
  ...bubbleColors,
  ...bubbleColors,
  ...bubbleColors,
  ...bubbleColors,
  ...bubbleColors,
  ...bubbleColors,
  ...bubbleColors,
  ...bubbleColors,
  ...bubbleColors,
  ...bubbleColors,
  ...bubbleColors,
  ...bubbleColors,
  ...bubbleColors,
  ...bubbleColors,
  ...bubbleColors,
]

export const light = {
  colors: {
    primary: "#E9846A",
    secondary: "#CB6E17",
    business: "#E3A775",
    social: "#5AA092",
    dating: "#C79F9F",
    darkBlue: "#152940",
    text: "#102A43",
    background: "#f3f7f9",
    navigation: "#152940",
    soft: "#ffffff",
    border: "#DDE2E7",
    success: "green",
    error: "red",
    grayPrimary: "#9fa4a9",
    graySecondary: "#aab3bb",
    translucent: "rgba(35,116,175,0.35)",
  },
  bubbleColors,
  space: {
    xs: 0,
    sm: 4,
    md: 8,
    lg: 12,
    xl: 16,
    xxl: 32,
    xxxl: 64,
  },
  breakpoints,
  borderWidths: {
    hairline: StyleSheet.hairlineWidth,
    sm: 1,
    md: 2,
  },
  fonts: {
    regular: "Nunito-Regular",
    bold: "Nunito-Bold",
    heading: "Nunito-SemiBold",
  },
  fontSizes: [12, 14, 16, 18, 20, 22, 24, 32, 48, 64, 72],
  fontWeights: {
    body: 400,
    heading: 600,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  radii: [0, 2, 4, 8, 16],
}

export const dark = merge({}, light, {
  colors: {
    text: "#ffffff",
    background: "#152940",
    navigation: "#121519",
    soft: "#121519",
    border: "#373737",
    error: "red",
    grayPrimary: "#687b8e",
    graySecondary: "#969a9e",
    translucent: "rgba(49,148,220,0.35)",
  },
})

export const themes = {
  light,
  dark,
}

export const getTheme = (mode) => themes[mode] ?? light

export const navigationTheme = {
  light: {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      primary: light.colors.primary,
      background: light.colors.background,
      card: light.colors.background,
      text: light.colors.primary,
      border: light.colors.border,
      notification: light.colors.primary,
    },
  },
  dark: {
    ...DarkTheme,
    colors: {
      ...DarkTheme.colors,
      primary: dark.colors.primary,
      background: dark.colors.background,
      card: dark.colors.background,
      text: dark.colors.primary,
      border: dark.colors.border,
      notification: dark.colors.primary,
    },
  },
}

export function getNavigationTheme(mode) {
  return navigationTheme[mode] ?? navigationTheme.light
}

export function getNativeStackNatigatorOptions(theme) {
  return {
    headerLargeTitle: true,
    headerShown: false,
    title: "Testframe",
    headerLargeStyle: { backgroundColor: theme.colors.background },
    headerStyle: { backgroundColor: theme.colors.background },
    headerTitleStyle: { color: theme.colors.text },
    headerLargeTitleShadowVisible: false,
    headerBackTitleStyle: {
      fontSize: theme.fontSizes[2],
    },
  }
}
