import React from "react"
import { useBreakpoints } from "../../hooks"
import { View } from "./view"

export function Container({ children, breakpoint, ...props }) {
  const { breakpoints, currentBreakpointIndex } = useBreakpoints()

  return (
    <View
      maxWidth={breakpoints[breakpoint]}
      mx={breakpoints[currentBreakpointIndex - 1] ? "auto" : "xs"}
      {...props}
    >
      {children}
    </View>
  )
}

export function Row({ children, ...props }) {
  const { breakpoints, currentBreakpointIndex } = useBreakpoints()

  return (
    <View
      maxWidth="100%"
      width={breakpoints[currentBreakpointIndex - 1] ?? "100%"}
      {...props}
    >
      {children}
    </View>
  )
}

Row.defaultProps = {}

Container.defaultProps = {
  breakpoint: "sm",
}
