import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useRef,
} from "react"
import { ActionSheet } from "./action-sheet"

const ActionSheetServiceContext = createContext(Promise.reject)

export const useActionSheet = () => useContext(ActionSheetServiceContext)

export const ActionSheetProvider = ({ children }) => {
  const [sheetState, setSheetState] = useState(null)
  const [pendingId, setPendingId] = useState(null)
  const [isClosing, setIsClosing] = useState(false)

  const awaitingPromiseRef = useRef()

  const openSheet = useCallback(
    (options) => {
      setSheetState(options)
      return new Promise((resolve, reject) => {
        awaitingPromiseRef.current = { resolve, reject }
      })
    },
    [setSheetState, awaitingPromiseRef],
  )

  const handleClose = async () => {
    if (sheetState.onCancel) {
      try {
        setIsClosing(true)
        await sheetState.onCancel()
      } finally {
        setIsClosing(false)
      }
    }

    if (sheetState.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject()
    }

    setSheetState(null)
  }

  const handleItemPress = async (value) => {
    const item = sheetState.items.find((i) => i.value === value)

    if (item?.onPress) {
      try {
        setPendingId(item.value)
        await item.onPress(item)
      } finally {
        setPendingId(null)
      }
    }

    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve()
    }

    setSheetState(null)
  }

  return (
    <>
      <ActionSheetServiceContext.Provider
        value={openSheet}
        children={children}
      />

      <ActionSheet
        isOpen={Boolean(sheetState)}
        pendingId={pendingId}
        isClosing={isClosing}
        handleItemPress={handleItemPress}
        onClose={handleClose}
        {...sheetState}
      />
    </>
  )
}

ActionSheetProvider.defaultProps = {}
