import { t, Trans } from "@lingui/macro"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import { Linking, StyleSheet } from "react-native"
import { useMutation } from "react-query"
import { useTheme } from "styled-components/native"
import isEmail from "validator/lib/isEmail"
import logoLight from "../assets/logo-light.png"
import logo from "../assets/logo.png"
import {
  Button,
  Container,
  Image,
  Input,
  KeyboardAvoidingView,
  NavBar,
  Row,
  ScrollView,
  Text,
  View,
} from "../components/ui"
import { useSafeAreaOrDefaultInsets } from "../hooks"
import { config } from "../lib/config"
import { DARK_APPEARANCE } from "../lib/constants"
import { getErrorMessage } from "../lib/helpers"
import { mixpanel } from "../lib/mixpanel"
import { Sentry } from "../lib/sentry"
import { useAlert, useApi, useStore } from "../providers"

export function EmailAuthScreen({ navigation, route }) {
  const alert = useAlert()
  const theme = useTheme()
  const { scheme } = useStore()
  const insets = useSafeAreaOrDefaultInsets({ bottom: theme.space.xl })
  const api = useApi()

  const createMagicLinkMutation = useMutation(api.createMagicLink, {
    onError: (err, variables, context) => {
      const { title, description } = getErrorMessage(err)
      alert.current.showNotification({ title, description })

      if (!err.isAxiosError) {
        Sentry.captureException(err)
      }
    },
    onSuccess: (data, variables, context) => {
      mixpanel.track("MagicLink Created")
    },
  })

  const emailForm = useForm({
    defaultValues: {
      email: "",
    },
  })

  function onEmailFormSubmit(data) {
    return createMagicLinkMutation.mutate({
      magicLinkDto: { ...data, inviteCode: route.params?.inviteCode },
    })
  }

  return (
    <>
      <Container flex={1}>
        <Row flex={1}>
          <NavBar title={t`Sign in`} />

          <KeyboardAvoidingView flex={1}>
            <ScrollView px="xl" keyboardShouldPersistTaps="handled">
              <View justifyContent="center">
                <Image
                  mt="xl"
                  mx="auto"
                  style={styles.logo}
                  source={scheme === DARK_APPEARANCE ? logo : logoLight}
                />
              </View>

              {createMagicLinkMutation.isSuccess ? (
                <View mt="xxl">
                  <Text textAlign="center" fontSize={6} fontFamily="heading">
                    <Trans>Check your E-Mail</Trans>
                  </Text>
                  <Text mt="xl" textAlign="center" fontSize={3}>
                    <Trans>
                      We sent an E-Mail to you at{" "}
                      <Text fontFamily="bold" fontSize={3} testID="email">
                        {emailForm.getValues().email}
                      </Text>
                      . It has a magic link that'll sign you in.
                    </Trans>
                  </Text>
                </View>
              ) : (
                <Controller
                  name="email"
                  control={emailForm.control}
                  rules={{
                    required: {
                      value: true,
                      message: t`Value is required`,
                    },
                    validate: {
                      email: (value) => {
                        if (isEmail(value)) return true

                        return t`Value should be an E-Mail`
                      },
                    },
                  }}
                  render={({
                    field: { onChange, onBlur, onFocus, value, name },
                  }) => (
                    <Input
                      mt="xl"
                      error={emailForm.formState.errors[name]?.message}
                    >
                      <Input.Title>
                        <Trans>E-Mail</Trans>
                      </Input.Title>
                      <Input.Text
                        testID="email"
                        dataSet={{ private: true }}
                        maxLength={50}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onChangeText={onChange}
                        value={value}
                        editable={!createMagicLinkMutation.isSuccess}
                        keyboardType="email-address"
                        autoComplete="email"
                        textContentType="emailAddress"
                        autoCapitalize="none"
                      />
                      <Input.Error />
                    </Input>
                  )}
                />
              )}
            </ScrollView>

            {!createMagicLinkMutation.isSuccess && (
              <Button
                mx="xl"
                mt="xl"
                loading={createMagicLinkMutation.isLoading}
                disabled={createMagicLinkMutation.isLoading}
                label={t`Send Magic Link`}
                onPress={emailForm.handleSubmit(onEmailFormSubmit)}
              />
            )}

            <Text textAlign="center" m="xl" mb={insets.bottom}>
              <Trans>
                By signing in you agree to our{" "}
                <Text
                  color="primary"
                  onPress={() => Linking.openURL(`${config.web.url}/terms`)}
                >
                  Terms
                </Text>{" "}
                and{" "}
                <Text
                  color="primary"
                  onPress={() => Linking.openURL(`${config.web.url}/privacy`)}
                >
                  Privacy Policy
                </Text>
                .
              </Trans>
            </Text>
          </KeyboardAvoidingView>
        </Row>
      </Container>
    </>
  )
}

const styles = StyleSheet.create({
  logo: { width: 128, height: 128 },
})
