import React from "react"
import { t, Trans } from "@lingui/macro"
import { useAlert, useStore } from "../providers"
import { View, Input, NavBar, Text } from "../components/ui"

export function PrivacySettingsFormScreen({ navigation }) {
  const alert = useAlert()
  const { allowThirdPartyTracking, storeMutation } = useStore()

  return (
    <>
      <NavBar title={t`Privacy`} />

      <View px="xl" flex={1}>
        <Input mt="xl">
          <Input.Title>
            <Trans>Third party tracking tools</Trans>
          </Input.Title>
          <Input.Switch
            label={t`Allow`}
            onChange={async () => {
              await storeMutation.mutateAsync({
                allowThirdPartyTracking: !allowThirdPartyTracking,
              })

              alert.current.showNotification({
                title: t`Privacy update`,
                description: t`Privacy settings successfully updated.`,
                componentProps: {
                  alertType: "success",
                },
              })
            }}
            value={allowThirdPartyTracking}
          />
        </Input>

        <Text mt="xl" fontSize={2} fontFamily="heading" color="grayPrimary">
          <Trans>
            We are using third-party services for tracking in order to improve
            the experience based on user behavior. You can disable the use of
            third-party services by disabling this checkbox. We always anonymize
            your personal information before it leaves your device.
          </Trans>
        </Text>
      </View>
    </>
  )
}
