import styled from "styled-components/native"
import { Image } from "react-native"
import {
  space,
  layout,
  color,
  flexbox,
  border,
  position,
  shadow,
} from "styled-system"

export const FastImage = styled(Image)`
  ${space}
  ${layout}
  ${color}
  ${flexbox}
  ${border}
  ${position}
  ${shadow}
`

FastImage.defaultProps = {}

FastImage.resizeMode = {
  cover: "cover",
  contain: "contain",
}
