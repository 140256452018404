import React, { useCallback, useContext, useRef } from "react"
import { Platform, StatusBar } from "react-native"
import { NotifierRoot, NotifierWrapper } from "react-native-notifier"
import { useTheme } from "styled-components/native"
import { Container, Icon, Row, Text, View } from "../components/ui"
import { useBreakpoints, useSafeAreaOrDefaultInsets } from "../hooks"
import { useStore } from "./store-provider"

export const AlertContext = React.createContext(null)

export function useAlert() {
  return useContext(AlertContext)
}

const AlertComponent = ({ title, description, alertType }) => {
  const theme = useTheme()
  const insets = useSafeAreaOrDefaultInsets({ top: theme.space.xl })

  const bgColor = {
    error: "error",
    info: "primary",
    success: "social",
  }[alertType]

  const icon = {
    error: "exclamation-circle",
    info: "question-circle",
    success: "check-circle",
  }[alertType]

  return (
    <View bg={bgColor ?? "error"}>
      <Container breakpoint="xl" pt={insets.top} pb="xl">
        <Row px="xl" flexDirection="row" alignItems="center">
          <Icon color="white" name={icon ?? "exclamation-circle"} />
          <View flex={1} ml="xl">
            <Text color="white" fontFamily="bold" fontSize={3}>
              {title}
            </Text>
            <Text color="white" fontSize={2}>
              {description}
            </Text>
          </View>
        </Row>
      </Container>
    </View>
  )
}

AlertComponent.defaultProps = {}

export function AlertProvider({ children }) {
  const alertRef = useRef()
  const theme = useTheme()
  const { scheme } = useStore()
  const { getBreakpointValue } = useBreakpoints()

  const onShown = useCallback(() => {
    const bgColor = {
      error: "error",
      info: "primary",
      success: "social",
    }[alertRef.current.state.componentProps.alertType]
    StatusBar.setBarStyle("light-content")
    if (Platform.OS === "android") {
      StatusBar.setBackgroundColor(theme.colors[bgColor] ?? theme.colors.error)
    }
  }, [theme])

  const onHidden = useCallback(() => {
    const barStyle = scheme === "dark" ? "light-content" : "dark-content"
    const barBgColor = getBreakpointValue([
      theme.colors.background,
      theme.colors.background,
      theme.colors.navigation,
      theme.colors.navigation,
    ])
    StatusBar.setBarStyle(
      getBreakpointValue([
        barStyle,
        barStyle,
        "light-content",
        "light-content",
      ]),
    )
    if (Platform.OS === "android") {
      StatusBar.setBackgroundColor(barBgColor)
    }
  }, [theme, getBreakpointValue, scheme])

  return (
    <NotifierWrapper>
      <AlertContext.Provider value={alertRef} children={children} />

      <NotifierRoot
        onShown={onShown}
        onHidden={onHidden}
        ref={alertRef}
        Component={AlertComponent}
        duration={5000}
      />
    </NotifierWrapper>
  )
}
