import React from "react"
import { t } from "@lingui/macro"
import { Text } from "./text"
import { View } from "./view"
import { Button } from "./button"
import { ScrollView } from "./sroll-view"
import { useTheme } from "styled-components/native"

export function TourGuideTooltip({
  isFirstStep,
  isLastStep,
  handleNext,
  handlePrev,
  handleStop,
  currentStep,
  labels,
}) {
  const theme = useTheme()

  return (
    <ScrollView
      bg="background"
      borderRadius={3}
      contentContainerStyle={{ padding: theme.space.lg }}
    >
      <View>
        <Text maxFontSizeMultiplier={1} fontSize={3}>
          {currentStep.text}
        </Text>
      </View>
      <View
        mt="md"
        flexDirection="row"
        justifyContent={isLastStep ? "flex-end" : "space-between"}
        mx="sm"
      >
        {!isLastStep ? (
          <Button
            variant="link"
            labelProps={{ maxFontSizeMultiplier: 1 }}
            color="graySecondary"
            size="xs"
            label={t`Skip`}
            onPress={handleStop}
          />
        ) : null}
        <View flexDirection="row" justifyContent="flex-end" mx="sm">
          {!isFirstStep ? (
            <Button
              ml="xl"
              variant="link"
              labelProps={{ maxFontSizeMultiplier: 1 }}
              color="graySecondary"
              size="xs"
              label={t`Back`}
              onPress={handlePrev}
            />
          ) : null}
          {!isLastStep ? (
            <Button
              ml="xl"
              variant="link"
              labelProps={{ maxFontSizeMultiplier: 1 }}
              size="xs"
              label={t`Next`}
              onPress={handleNext}
            />
          ) : (
            <Button
              ml="xl"
              variant="link"
              labelProps={{ maxFontSizeMultiplier: 1 }}
              size="xs"
              label={t`Finish`}
              onPress={handleStop}
            />
          )}
        </View>
      </View>
    </ScrollView>
  )
}
