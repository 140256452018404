import React from "react"
import { Text } from "../text"
import { View } from "../view"
import { TouchableOpacity } from "../touchable-opacity"

export function InputSegment({
  disabled,
  value,
  onChange,
  onBlur,
  onFocus,
  items,
  ...props
}) {
  const selectedItem = items.find((i) => i.value === value)

  return (
    <View
      {...props}
      mt="md"
      p={1}
      flexDirection="row"
      borderWidth="hairline"
      borderColor="grayPrimary"
      borderRadius={3}
    >
      {items.map((i) => {
        return (
          <TouchableOpacity
            flex={1}
            alignItems="center"
            borderWidth={selectedItem?.value === i.value ? 1 : 0}
            borderColor="primary"
            borderRadius={3}
            px="sm"
            py="sm"
            key={i.value}
            disabled={disabled}
            activeOpacity={0.7}
            opacity={disabled ? 0.7 : 1}
            onPress={() => {
              if (onFocus) onFocus()

              onChange(i.value)
              if (onBlur) onBlur()
            }}
          >
            <Text fontSize={2} numberOfLines={1}>
              {i.label}
            </Text>
          </TouchableOpacity>
        )
      })}
    </View>
  )
}

InputSegment.defaultProps = {}
