import React from "react"
import {
  SafeAreaProvider,
  initialWindowMetrics,
} from "react-native-safe-area-context"
import { QueryClientProvider } from "react-query"
import { I18nProvider } from "@lingui/react"
import { i18n } from "../lib/i18n"
import { queryClient } from "../lib/query-client"
import { App } from "./app"
import { LaunchCountProvider, StoreProvider } from "../providers"

export function Root() {
  return (
    <SafeAreaProvider initialMetrics={initialWindowMetrics}>
      <QueryClientProvider client={queryClient}>
        <LaunchCountProvider>
          <StoreProvider>
            <I18nProvider i18n={i18n} forceRenderOnLocaleChange={false}>
              <App />
            </I18nProvider>
          </StoreProvider>
        </LaunchCountProvider>
      </QueryClientProvider>
    </SafeAreaProvider>
  )
}
