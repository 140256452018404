import React, { useState } from "react"
import { useTheme } from "styled-components/native"
import { omit } from "lodash"
import { View } from "../view"
import { InputContext } from "./input-context"
import { InputTitle } from "./input-title"
import { InputError } from "./input-error"
import { InputText } from "./input-text"
import { InputSwitch } from "./input-switch"
import { InputPicker } from "./input-picker"
import { InputSegment } from "./input-segment"

function Input({
  error,
  inputColor,
  focusedColor,
  errorColor,
  children,

  ...props
}) {
  const [isFocused, setIsFocused] = useState(false)

  const theme = useTheme()

  inputColor =
    theme.colors[isFocused ? focusedColor : error ? errorColor : inputColor]

  const value = React.useMemo(
    () => ({ isFocused, setIsFocused, inputColor, errorColor, error }),
    [isFocused, setIsFocused, inputColor, errorColor, error],
  )

  return (
    <InputContext.Provider value={value}>
      <View {...omit(props, "end")}>{children}</View>
    </InputContext.Provider>
  )
}

Input.defaultProps = {
  inputColor: "grayPrimary",
  focusedColor: "primary",
  errorColor: "error",
}

Input.Title = InputTitle
Input.Error = InputError
Input.Text = InputText
Input.Switch = InputSwitch
Input.Picker = InputPicker
Input.Segment = InputSegment

export { Input }
