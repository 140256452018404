import React from "react"
import { Platform } from "react-native"
import { useQuery } from "react-query"
import { t, Trans } from "@lingui/macro"
import { useAlert, useApi, useStore } from "../providers"
import { Breakpoint } from "../hooks"
import {
  Input,
  Text,
  Container,
  Row,
  AppBar,
  TopNavBar,
  Button,
} from "../components/ui"

export function CookieSettingsFormScreen({ navigation }) {
  const api = useApi()
  const alert = useAlert()
  const { data: user } = useQuery("currentUser", api.getUser)
  const { allowThirdPartyTracking, storeMutation } = useStore()

  return (
    <>
      {Platform.OS !== "ios" && user && (
        <Breakpoint values={["lg", "xl"]}>
          <TopNavBar />
        </Breakpoint>
      )}

      <Container breakpoint="xl">
        <Row>
          <AppBar
            showBack={Platform.OS !== "ios"}
            title={t`Cookie`}
            navigation={navigation}
          >
            {Platform.OS === "ios" && (
              <Button
                start
                mr="lg"
                label={t`Close`}
                variant="navbar"
                size="xs"
                onPress={navigation.goBack}
              />
            )}
          </AppBar>
        </Row>
      </Container>

      <Container px="xl" flex={1}>
        <Row flex={1}>
          <Input mt="xl">
            <Input.Title>
              <Trans>Essential</Trans>
            </Input.Title>
            <Input.Switch readonly disabled label={t`Allow`} value />
          </Input>

          <Input mt="xl">
            <Input.Title>
              <Trans>Analytics and customization (optional)</Trans>
            </Input.Title>
            <Input.Switch
              label={t`Allow`}
              onValueChange={async () => {
                await storeMutation.mutateAsync({
                  allowThirdPartyTracking: !allowThirdPartyTracking,
                })

                alert.current.showNotification({
                  title: t`Cookie update`,
                  description: t`Cookie settings successfully updated.`,
                  componentProps: {
                    alertType: "success",
                  },
                })

                setTimeout(() => window.location.reload(), 1000)
              }}
              value={allowThirdPartyTracking}
            />
          </Input>

          <Text mt="xl" fontSize={2} fontFamily="heading" color="grayPrimary">
            <Trans>
              We are using third-party services for tracking in order to improve
              the experience based on user behavior. You can disable the use of
              third-party services by disabling this checkbox. We always
              anonymize your personal information before it leaves your device.
            </Trans>
          </Text>
        </Row>
      </Container>
    </>
  )
}
