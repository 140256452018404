import { omit } from "lodash"
import React from "react"
import styled from "styled-components/native"
import {
  border,
  color,
  flexbox,
  layout,
  position,
  shadow,
  space,
  typography,
} from "styled-system"
import { flexGap } from "../../lib/styled-flex-gap"

export const TouchableOpacityComponent = styled.TouchableOpacity`
  ${space}
  ${layout}
  ${color}
  ${typography}
  ${flexbox}
  ${border}
  ${position}
  ${shadow}
  ${flexGap}
`

export function TouchableOpacity(props) {
  return (
    <TouchableOpacityComponent {...omit(props, ["start", "middle", "end"])} />
  )
}
