import React from "react"
import styled, { useTheme } from "styled-components/native"
import {
  space,
  layout,
  color,
  typography,
  flexbox,
  border,
  position,
  shadow,
} from "styled-system"

const StyledTouchableHighlight = styled.TouchableHighlight`
  ${space}
  ${layout}
  ${color}
  ${typography}
  ${flexbox}
  ${border}
  ${position}
  ${shadow}
`

export function TouchableHighlight({ underlayColor, ...props }) {
  const theme = useTheme()

  underlayColor = theme.colors[underlayColor] ?? underlayColor

  return <StyledTouchableHighlight underlayColor={underlayColor} {...props} />
}

TouchableHighlight.defaultProps = {
  underlayColor: "grayPrimary",
}
