import React from "react"
import { Text } from "../text"
import { useInputContext } from "./input-context"

export function InputError(props) {
  const { error, errorColor } = useInputContext()

  return (
    <Text color={errorColor} mt="sm" {...props}>
      {error}
    </Text>
  )
}
