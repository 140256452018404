import { i18n } from "@lingui/core"
import { plural, t, Trans } from "@lingui/macro"
import { useFocusEffect } from "@react-navigation/native"
import { shuffle, sortBy } from "lodash"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { StyleSheet, useWindowDimensions } from "react-native"
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query"
import { TourGuideZone, useTourGuideController } from "rn-tourguide"
import { useTheme } from "styled-components/native"
import {
  callIfShouldAskPushNotificationsPermissions,
  useModal,
} from "../components/modal-stack"
import {
  ActivityIndicator,
  Button,
  Container,
  ErrorBody,
  FastImage,
  FlatList,
  Icon,
  Input,
  LinkButton,
  LinkContainer,
  NavBar,
  ProgressBar,
  RefreshControl,
  Row,
  Text,
  TopNavBar,
  useActionSheet,
  useDialog,
  View,
} from "../components/ui"
import { EnergyBar } from "../components/user"
import { Breakpoint, useBreakpoints } from "../hooks"
import { config } from "../lib/config"
import {
  ACTIVE_TEST,
  ALL_TEST_OPTIONS,
  DATING_TEST,
  DATING_TEST_SCORE_OPTIONS,
  DATING_TEST_WOULD_DATE_OPTION,
  ERROR_TEST_OPTION,
  INACTIVE_TEST,
  MALE,
  TEST_TYPE_OPTIONS,
  TEST_TYPES,
  TESTER_ENERGY_COST,
} from "../lib/constants"
import {
  formatNumber,
  getDatingScoreFromFeedbacksCount,
  getErrorMessage,
  getStarProps,
} from "../lib/helpers"
import { mixpanel } from "../lib/mixpanel"
import { Sentry } from "../lib/sentry"
import { allBubbleColors, light } from "../lib/theme"
import { useAlert, useApi, useStore } from "../providers"

export function TestListScreen({ navigation }) {
  const scrollRef = useRef()
  const api = useApi()
  const theme = useTheme()
  const { width } = useWindowDimensions()
  const openTestSheet = useActionSheet()
  const alert = useAlert()
  const { getBreakpointValue, breakpoints, currentBreakpointIndex } =
    useBreakpoints()
  const { openModal } = useModal()
  const queryClient = useQueryClient()
  const openTestDeleteDialog = useDialog()

  const [filter, setFilter] = useState({
    typeIn: TEST_TYPES,
    sorts: "created_at desc",
  })

  const {
    storeMutation,
    hadTestsWalkthrough,
    lastPushNotificationPermissionsAskedAt,
  } = useStore()

  const testListScreenTourGuideKey = "testListScreenTourGuideKey"

  const {
    canStart: canStartTourGuide,
    start: startTourGuide,
    eventEmitter: tourGuideEventEmitter,
  } = useTourGuideController(testListScreenTourGuideKey)

  const { data: user } = useQuery("currentUser", api.getUser)

  const {
    fetchNextPage,
    hasNextPage,
    refetch,
    data: testsData,
    isError,
    error,
    isLoading,
  } = useInfiniteQuery(
    ["testList", { filter }],
    ({ pageParam = 1 }) => api.getTestList({ page: pageParam, query: filter }),
    {
      getNextPageParam: (lastPage, allPages) => lastPage.meta.next ?? undefined,
      getPreviousPageParam: (firstPage, allPages) =>
        firstPage.meta.prev ?? undefined,
    },
  )

  const deactivateTestMutation = useMutation(api.deactivateTest, {
    onError: (err, variables, context) => {
      const { title, description } = getErrorMessage(err)
      alert.current.showNotification({ title, description })

      if (!err.isAxiosError) {
        Sentry.captureException(err)
      }
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("currentUser")
      queryClient.setQueriesData(["testList"], (queryData) => {
        return {
          ...queryData,
          pages: queryData.pages.map((page) => ({
            ...page,
            data: page.data.map((t) => (t.id === data.id ? data : t)),
          })),
        }
      })

      alert.current.showNotification({
        title: t`Test deactivation`,
        description: t`Test successfully paused.`,
        componentProps: {
          alertType: "success",
        },
      })

      mixpanel.track("Test Paused")
    },
  })

  const deleteTestMutation = useMutation(api.deleteTest, {
    onError: (err, variables, context) => {
      const { title, description } = getErrorMessage(err)
      alert.current.showNotification({ title, description })

      if (!err.isAxiosError) {
        Sentry.captureException(err)
      }
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("currentUser")
      queryClient.invalidateQueries("testList")

      alert.current.showNotification({
        title: t`Test deletion`,
        description: t`Test successfully deleted.`,
        componentProps: {
          alertType: "success",
        },
      })

      mixpanel.track("Test Deleted")
    },
  })

  const handleTestLongPress = useCallback(
    (test) => {
      return openTestSheet({
        items: [
          test.state === ACTIVE_TEST && {
            value: "addReviews",
            label: t`Add reviews`,
            onPress: () => {
              navigation.navigate("TestActivationForm", { id: test.id })
            },
          },
          {
            value: "state",
            label: test.state === ACTIVE_TEST ? t`Pause` : t`Activate`,
            onPress: async () => {
              if (test.state === ACTIVE_TEST) {
                await deactivateTestMutation.mutateAsync(test.id)
              } else {
                navigation.navigate("TestActivationForm", { id: test.id })
              }
            },
          },
          {
            value: "delete",
            label: t`Delete`,
            onPress: () => {
              openTestDeleteDialog({
                title: t`Are you sure?`,
                description: t`Test will be deleted and can't be restored.`,
                buttons: [
                  {
                    label: t`Delete`,
                    onPress: (value) => deleteTestMutation.mutate(test.id),
                  },
                  {
                    label: t`Cancel`,
                    variant: "primary-outline",
                  },
                ],
              })
            },
          },
        ].filter(Boolean),
      })
    },
    [
      deleteTestMutation,
      deactivateTestMutation,
      openTestSheet,
      openTestDeleteDialog,
      navigation,
    ],
  )

  const count = testsData?.pages[0]?.meta?.count ?? 0
  const allTests = testsData?.pages?.flatMap((p) => p.data) ?? []

  const numColumns = getBreakpointValue([2, 2, 3, 4])
  const pictureSize =
    ((breakpoints[currentBreakpointIndex - 1] ?? width) -
      theme.space.xl * 2 -
      theme.space.md * (numColumns - 1)) /
    numColumns

  useEffect(() => {
    function handleStop() {
      storeMutation.mutate({ hadTestsWalkthrough: true })

      if (count > 0) {
        setTimeout(
          () =>
            callIfShouldAskPushNotificationsPermissions(
              { lastPushNotificationPermissionsAskedAt, storeMutation },
              () => openModal({ name: "PushNotificationsPermissionsModal" }),
            ),
          1000,
        )
      }
    }

    tourGuideEventEmitter?.on("stop", handleStop)
    return () => tourGuideEventEmitter?.off("stop", handleStop)
  }, [
    tourGuideEventEmitter,
    lastPushNotificationPermissionsAskedAt,
    storeMutation,
    openModal,
    count,
  ])

  useFocusEffect(
    useCallback(() => {
      let timeout
      if (
        !config.detox &&
        canStartTourGuide &&
        hadTestsWalkthrough === false &&
        count > 0
      ) {
        timeout = setTimeout(() => startTourGuide(), 1000)
      }

      return () => {
        if (timeout) {
          clearTimeout(timeout)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canStartTourGuide, hadTestsWalkthrough, count]),
  )

  const energyBar = <EnergyBar mx="xl" />
  // const rateUs = <RateUsBar mb="xl" />

  return (
    <>
      <Breakpoint values={["lg", "xl"]}>
        <TopNavBar />
      </Breakpoint>

      <Container breakpoint="xl">
        <Row>
          <NavBar title={t`My tests`} showBack={false}>
            <TourGuideZone
              start
              text={t`These are your coins. Buy coins to get a guaranteed number of reviews faster. One review costs one coin.`}
              zone={3}
              tourKey={testListScreenTourGuideKey}
              isTourGuide={count > 0}
            >
              <LinkButton
                start
                label={
                  t`Coins` +
                  `: ${formatNumber(user?.coins ?? 0, {
                    notation: "compact",
                  })}`
                }
                variant="navbar"
                to={{ screen: "CoinsPurchaseForm" }}
              />
            </TourGuideZone>

            {getBreakpointValue([false, false, true, true]) ? (
              <View flex={3} middle>
                <TourGuideZone
                  text={t`This is your energy bar. Energy is used to get reviews on your photos. To earn energy, review others.`}
                  zone={2}
                  tourKey={testListScreenTourGuideKey}
                  isTourGuide={count > 0}
                >
                  {energyBar}
                </TourGuideZone>
              </View>
            ) : null}

            <LinkButton
              end
              label={t`New test`}
              variant="navbar"
              testID="newTestLink"
              to={{ screen: "SelectPhoto" }}
            />

            {getBreakpointValue([false, false, true, true]) && count > 0 ? (
              <Input end>
                <Input.Picker
                  items={[
                    {
                      value: "created_at desc",
                      label: t`Creation date`,
                    },
                    {
                      value: "last_review_at desc",
                      label: t`Last review date`,
                    },
                    {
                      value: "dating_score desc",
                      label: t`Dating score`,
                    },
                  ]}
                  onChange={(val) =>
                    setFilter({
                      ...filter,
                      typeIn:
                        val === "dating_score desc"
                          ? [DATING_TEST]
                          : TEST_TYPES,
                      sorts: val,
                    })
                  }
                  value={filter.sorts}
                  renderInput={({ onPress }) => (
                    <Button
                      size="xs"
                      ml="xl"
                      label={t`Sort`}
                      variant="navbar"
                      testID="sortButton"
                      onPress={onPress}
                    />
                  )}
                />
              </Input>
            ) : null}
          </NavBar>

          <Breakpoint values={["sm", "md"]} middle>
            <View mb="xl" flexDirection="row" alignItems="center">
              <View flex={1}>
                <TourGuideZone
                  text={t`This is your energy bar. Energy is used to get reviews on your photos. To earn energy, review others.`}
                  zone={2}
                  tourKey={testListScreenTourGuideKey}
                  isTourGuide={count > 0}
                >
                  {energyBar}
                </TourGuideZone>
              </View>
              {count > 0 && (
                <Input>
                  <Input.Picker
                    items={[
                      {
                        value: "created_at desc",
                        label: t`Creation date`,
                      },
                      {
                        value: "last_review_at desc",
                        label: t`Last review date`,
                      },
                      {
                        value: "dating_score desc",
                        label: t`Dating score`,
                      },
                    ]}
                    onChange={(val) =>
                      setFilter({
                        ...filter,
                        typeIn:
                          val === "dating_score desc"
                            ? [DATING_TEST]
                            : TEST_TYPES,
                        sorts: val,
                      })
                    }
                    value={filter.sorts}
                    renderInput={({ onPress }) => (
                      <Button
                        size="xs"
                        mr="xl"
                        label={t`Sort`}
                        variant="navbar"
                        testID="sortButton"
                        onPress={onPress}
                      />
                    )}
                  />
                </Input>
              )}
            </View>
          </Breakpoint>
        </Row>

        {/* <Row>
          <View mx="xl">{rateUs}</View>
        </Row> */}
      </Container>

      <View flex={1} justifyContent="center">
        {isLoading ? (
          <ActivityIndicator />
        ) : isError ? (
          <ErrorBody error={error} reload={refetch} />
        ) : (
          <FlatList
            key={numColumns}
            ref={scrollRef}
            onEndReached={() => hasNextPage && fetchNextPage()}
            refreshControl={
              <RefreshControl onRefresh={() => refetch()} refreshing={false} />
            }
            contentContainerStyle={{
              paddingBottom: theme.space.xl,
              width: breakpoints[currentBreakpointIndex - 1] ?? "100%",
              ...styles.list.contentContainerStyle,
            }}
            ListEmptyComponent={
              <View flex={1} justifyContent="center">
                <View position="absolute" top={0} left={0}>
                  <Text fontSize={6} fontFamily="heading">
                    <Trans>Welcome to Testframe!</Trans>
                  </Text>
                  <Text mt="xl" fontSize={3} fontFamily="heading">
                    <Trans>Let's test your first photo.</Trans>
                  </Text>
                  <Text fontSize={3} fontFamily="heading">
                    <Trans>Press "New test" to get started.</Trans>
                  </Text>
                </View>

                <LinkContainer
                  mx="auto"
                  bg="primary"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius={3}
                  height={150}
                  width={150}
                  to={{ screen: "SelectPhoto" }}
                >
                  <Icon mb="md" color="white" name="images" />
                  <Text fontFamily="bold" fontSize={2} color="white">
                    <Trans>New test</Trans>
                  </Text>
                </LinkContainer>
              </View>
            }
            numColumns={numColumns}
            getItemLayout={(data, index) => ({
              length: pictureSize,
              offset: pictureSize * index,
              index,
            })}
            data={allTests}
            renderItem={({ item, index }) => {
              const mt = index > numColumns - 1 ? "xl" : 0
              const ml = index % numColumns !== 0 ? "md" : 0

              return (
                <TestItem
                  mt={mt}
                  ml={ml}
                  index={index}
                  pictureSize={pictureSize}
                  user={user}
                  test={item}
                  tourGuideKey={testListScreenTourGuideKey}
                  to={{
                    screen: "TestDetails",
                    params: { id: item.id },
                  }}
                  onLongPress={handleTestLongPress}
                />
              )
            }}
            keyExtractor={(item) => item.id}
          />
        )}
      </View>
    </>
  )
}

function TestItem({
  test,
  pictureSize,
  to,
  onLongPress,
  user,
  index,
  tourGuideKey,
  ...props
}) {
  const api = useApi()
  const shuffledBubbleColors = useMemo(() => shuffle(allBubbleColors), [])
  const {
    data: testStatistics,
    isLoading,
    isError,
  } = useQuery(["test", test.id, "statistics"], () =>
    api.getTestStatistics(test.id),
  )

  const datingScore = testStatistics
    ? getDatingScoreFromFeedbacksCount(testStatistics)
    : 0

  return (
    <LinkContainer
      {...props}
      width={pictureSize}
      borderRadius={3}
      overflow="hidden"
      to={to}
      onLongPress={() => onLongPress(test)}
    >
      <View bg="black">
        <TourGuideZone
          text={t`This is your test. Earn energy to get reviews on your photos or buy coins to get a guaranteed number of reviews faster.`}
          zone={1}
          tourKey={tourGuideKey}
          isTourGuide={index === 0}
        >
          <FastImage
            testID="image"
            dataSet={{ private: true }}
            opacity={
              test.state === ACTIVE_TEST &&
              ((user?.energy ?? TESTER_ENERGY_COST) >= TESTER_ENERGY_COST ||
                test.reviewsAvailable > 0)
                ? 1
                : 0.7
            }
            style={{ height: pictureSize, width: pictureSize }}
            source={{
              uri: test.photo.url,
            }}
            resizeMode={FastImage.resizeMode.cover}
          />
        </TourGuideZone>
        {test.state === INACTIVE_TEST && (
          <>
            <View
              position="absolute"
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="center"
            >
              <Icon
                name="pause-circle"
                fontSize={8}
                color="white"
                opacity={0.7}
              />
            </View>

            <View
              position="absolute"
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="flex-end"
              px="md"
            >
              <Text mb="lg" color="white" fontFamily="heading" fontSize={2}>
                <Trans>Paused</Trans>
              </Text>
            </View>
          </>
        )}
        {test.state === ACTIVE_TEST &&
          (user?.energy ?? TESTER_ENERGY_COST) < TESTER_ENERGY_COST &&
          test.reviewsAvailable < 1 &&
          testStatistics && (
            <>
              <View
                position="absolute"
                width="100%"
                height="100%"
                justifyContent="center"
                alignItems={
                  testStatistics.reviewCount > 0 ? "flex-start" : "center"
                }
                px="xl"
                pt="xl"
              >
                <Text mb="sm" color="white" fontFamily="heading" fontSize={2}>
                  <Trans>Not getting reviews</Trans>
                </Text>
                {testStatistics.reviewCount > 0 && (
                  <Text color="white" fontFamily="heading" numberOfLines={4}>
                    <Trans>Buy coins or earn energy to get reviews</Trans>
                  </Text>
                )}
              </View>
            </>
          )}
      </View>
      <View
        position="absolute"
        top={0}
        left={0}
        width="100%"
        bg="background"
        opacity={0.7}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        p="sm"
        px="md"
      >
        {testStatistics && (
          <Text fontFamily="bold" mr="sm">
            {testStatistics.reviewCount >= 0 &&
              plural(testStatistics.reviewCount, {
                zero: "# reviews",
                one: "# review",
                two: "# reviews",
                few: "# reviews",
                many: "# reviews",
                other: "# reviews",
              })}
          </Text>
        )}
        <Text fontFamily="bold">
          {i18n._(
            (
              TEST_TYPE_OPTIONS.find((t) => t.value === test.type) ??
              ERROR_TEST_OPTION
            ).label,
          )}
        </Text>
      </View>
      <View
        px="lg"
        pb="lg"
        height={187}
        borderColor="border"
        borderWidth={2}
        borderRadius={3}
        borderTopWidth={0}
        borderTopLeftRadius={0}
        borderTopRightRadius={0}
        justifyContent="center"
      >
        {isLoading ? (
          <ActivityIndicator />
        ) : isError ? (
          <Text>
            <Trans>Error loading data...</Trans>
          </Text>
        ) : testStatistics.reviewCount > 0 ? (
          <View height="100%">
            {test.type === DATING_TEST && (
              <View mt="xl" flexDirection="row" justifyContent="space-around">
                {DATING_TEST_SCORE_OPTIONS.map((o, idx) => {
                  const index = idx + 1

                  return (
                    <View key={o.value}>
                      <Icon name="star" color="business" fontSize={6} />
                      <Icon
                        {...getStarProps(datingScore, index)}
                        position="absolute"
                        color="business"
                        fontSize={6}
                      />
                    </View>
                  )
                })}
              </View>
            )}
            {sortBy(
              Object.entries(testStatistics.feedbackCounts)
                .map(([value, count]) => ({ value, count }))
                .filter((value) => !value.value.includes("score_")),
              ["count"],
            )
              .reverse()
              .map(({ value, count }) => ({
                option: ALL_TEST_OPTIONS.find((o) => o.value === value),
                value,
                count,
              }))
              .filter(({ option }) => Boolean(option))
              .slice(0, test.type === DATING_TEST ? 3 : 4)
              .map(({ value, count, option }, idx) => {
                return (
                  <View key={value} mt="lg">
                    <Text numberOfLines={1} fontSize={1}>
                      {i18n._(option.label, {
                        gender:
                          option.value === DATING_TEST_WOULD_DATE_OPTION
                            ? test.reviewerGender ?? test.gender ?? MALE
                            : test.gender ?? MALE,
                      })}{" "}
                      {formatNumber(count / testStatistics.reviewCount, {
                        style: "percent",
                        maximumFractionDigits: 0,
                      })}
                    </Text>
                    <ProgressBar
                      color={shuffledBubbleColors[idx]}
                      mt="sm"
                      percent={(count / testStatistics.reviewCount) * 100}
                    />
                  </View>
                )
              })}
          </View>
        ) : (
          <View mt="xl" alignItems={"flex-start"} justifyContent="center">
            <Text fontSize={2} fontFamily="heading">
              <Trans>No reviews</Trans>
            </Text>
            {(user?.energy ?? TESTER_ENERGY_COST) < TESTER_ENERGY_COST * 3 ? (
              <Text fontFamily="heading" mt={3}>
                <Trans>Buy coins or earn energy to get reviews</Trans>
              </Text>
            ) : (
              <Text fontFamily="heading" mt={3}>
                <Trans>Buy coins to get reviews faster</Trans>
              </Text>
            )}
          </View>
        )}
      </View>
    </LinkContainer>
  )
}

const styles = StyleSheet.create({
  list: {
    contentContainerStyle: {
      flexGrow: 1,
      maxWidth: "100%",
      marginLeft: "auto",
      marginRight: "auto",
      paddingHorizontal: light.space.xl,
    },
  },
})
