import React from "react"
import { useTheme } from "styled-components/native"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import { getNativeStackNatigatorOptions } from "../lib/theme"
import {
  AdminScreen,
  AdminTestListScreen,
  AdminTestDetailsScreen,
  AdminReviewListScreen,
  AdminReviewScreen,
  AdminUserListScreen,
  AdminUserDetailsScreen,
  AdminTestReportListScreen,
  AdminTestReportDetailsScreen,
  AdminReviewReportListScreen,
} from "../screens/admin"

const AdminStack = createNativeStackNavigator()

export function AdminStackNavigator() {
  const theme = useTheme()

  return (
    <AdminStack.Navigator screenOptions={getNativeStackNatigatorOptions(theme)}>
      <AdminStack.Screen name="Admin" component={AdminScreen} />
      <AdminStack.Screen name="AdminUserList" component={AdminUserListScreen} />
      <AdminStack.Screen
        name="AdminUserDetails"
        component={AdminUserDetailsScreen}
      />
      <AdminStack.Screen name="AdminTestList" component={AdminTestListScreen} />
      <AdminStack.Screen
        name="AdminTestDetails"
        component={AdminTestDetailsScreen}
      />
      <AdminStack.Screen
        name="AdminReviewList"
        component={AdminReviewListScreen}
      />
      <AdminStack.Screen name="AdminReview" component={AdminReviewScreen} />
      <AdminStack.Screen
        name="AdminTestReportList"
        component={AdminTestReportListScreen}
      />
      <AdminStack.Screen
        name="AdminTestReportDetails"
        component={AdminTestReportDetailsScreen}
      />
      <AdminStack.Screen
        name="AdminReviewReportList"
        component={AdminReviewReportListScreen}
      />
    </AdminStack.Navigator>
  )
}
