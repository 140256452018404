import React from "react"
import { Platform } from "react-native"
import styled, { useTheme } from "styled-components/native"
import { useInputContext } from "./input-context"
import { View } from "../view"
import { Text } from "../text"
import {
  space,
  layout,
  color,
  typography,
  flexbox,
  border,
  position,
  shadow,
} from "styled-system"

export const StyledSwitch = styled.Switch`
  ${color}
  ${typography}
  ${border}
  ${position}
  ${flexbox}
  ${space}
  ${layout}
  ${shadow}
`

export function InputSwitch({
  onBlur,
  onFocus,
  borderBottomWidth,
  label,
  fontSize,
  fontWeight,
  value,
  ...props
}) {
  const theme = useTheme()
  const { inputColor, setIsFocused } = useInputContext()

  const colorProps = {}

  if (Platform.OS === "ios") {
    colorProps.trackColor = { true: theme.colors.primary }
    colorProps.thumbColor = theme.colors.background
  }

  if (Platform.OS === "web") {
    colorProps.thumbColor = theme.colors.primary
    colorProps.trackColor = theme.colors.soft
    colorProps.activeThumbColor = theme.colors.primary
    colorProps.activeTrackColor = theme.colors.primary
  }

  if (Platform.OS === "android") {
    colorProps.trackColor = {
      true: theme.colors.primary,
      false: theme.colors.soft,
    }
    colorProps.thumbColor = theme.colors.primary
  }

  return (
    <View
      py={props.py}
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      borderBottomWidth={borderBottomWidth}
    >
      <Text numberOfLines={1} fontSize={fontSize}>
        {label}
      </Text>
      <StyledSwitch
        {...props}
        {...colorProps}
        py="xs"
        value={value}
        selectionColor={inputColor}
        borderColor={inputColor}
        placeholderTextColor={theme.colors.grayPrimary}
        onFocus={(...args) => {
          setIsFocused(true)
          if (onFocus) return onFocus(...args)
        }}
        onBlur={(...args) => {
          setIsFocused(false)
          if (onBlur) return onBlur(...args)
        }}
      />
    </View>
  )
}

InputSwitch.defaultProps = {
  borderBottomWidth: 1,
  fontSize: 3,
  py: "md",
}
