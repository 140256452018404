import React from "react"
import { t } from "@lingui/macro"
import { Button, Text, View } from "."
import {
  CANCEL_ERROR,
  CLIENT_ERROR,
  CONNECTION_ERROR,
  NETWORK_ERROR,
  SERVER_ERROR,
  TIMEOUT_ERROR,
  UNKNOWN_ERROR,
} from "../../lib/constants"

export function ErrorBody({ error, reload }) {
  let title = t`Oops! Something went wrong`
  let subTitle = t`App crashed because of an error. We will fix it as soon as possible.`

  switch (error?.problem) {
    case NETWORK_ERROR:
    case TIMEOUT_ERROR:
    case CONNECTION_ERROR:
    case CANCEL_ERROR:
    case UNKNOWN_ERROR:
      title = t`Network error!`
      subTitle = t`Please check your internet connection.`
      break

    case SERVER_ERROR:
      switch (error?.response?.status) {
        case 502:
        case 503:
        case 504:
          title = t`Houston, we have a problem!`
          subTitle = t`Server is temporarily unavailable.`
          break
      }
      break

    case CLIENT_ERROR:
      switch (error?.response?.status) {
        case 404:
          title = t`Oops! You're lost`
          subTitle = t`The resource you are looking for was not found.`
          break
        case 401:
          title = t`Oops! You're not authenticated`
          subTitle = t`Please login.`
          break
        case 408:
          title = t`Oops! Network request timed out`
          subTitle = t`Please try again.`
          break
        case 429:
          title = t`Oops! You sent too many requests`
          subTitle = t`Please wait a while and come back.`
          break
      }
      break
  }

  if (error?.code === "appMinVersion") {
    title = t`Oops! Outdated application`
    subTitle = t`The app has not been updated for a while. Please update the app.`
  }

  return (
    <View
      flex={1}
      alignItems="center"
      justifyContent="center"
      px="lg"
      bg="background"
    >
      <Text fontSize={5} fontFamily="bold">
        {title}
      </Text>
      <Text mt="md" fontSize={2}>
        {subTitle}
      </Text>
      {error?.isAxiosError && reload && (
        <Button label={t`Reload`} variant="link" onPress={reload} />
      )}
    </View>
  )
}
