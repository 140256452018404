import React from "react"
import styled, { useTheme } from "styled-components/native"
import { StyleSheet } from "react-native"
import { useInputContext } from "./input-context"
import {
  space,
  layout,
  color,
  typography,
  flexbox,
  border,
  position,
  shadow,
} from "styled-system"

export const StyledTextInput = styled.TextInput`
  ${color}
  ${typography}
  ${border}
  ${position}
  ${flexbox}
  ${space}
  ${layout}
  ${shadow}
`

export function InputText({ onBlur, onFocus, ...props }) {
  const theme = useTheme()
  const { inputColor, setIsFocused } = useInputContext()

  return (
    <StyledTextInput
      {...props}
      maxFontSizeMultiplier={1.3}
      selectionColor={inputColor}
      borderColor={inputColor}
      placeholderTextColor={theme.colors.grayPrimary}
      onFocus={(...args) => {
        setIsFocused(true)
        if (onFocus) return onFocus(...args)
      }}
      onBlur={(...args) => {
        setIsFocused(false)
        if (onBlur) return onBlur(...args)
      }}
    />
  )
}

InputText.defaultProps = {
  fontWeight: "normal",
  fontFamily: "regular",
  color: "text",
  borderBottomWidth: StyleSheet.hairlineWidth,
  fontSize: 2,
  py: "sm",
  px: "xs",
}
