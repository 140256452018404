import { t } from "@lingui/macro"
import browserImageSize from "browser-image-size"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useDropzone } from "react-dropzone"
import { PHOTO_UPLOAD_CONSTRAINTS } from "../../lib/constants"
import { getErrorMessage } from "../../lib/helpers"
import { useAlert } from "../../providers"

export async function validateImageFile(image, alert) {
  if (!image) {
    alert.current.showNotification({
      title: t`Error`,
      description: t`Unable to open image.`,
    })
    return
  }

  if (image.errors?.[0]) {
    if (image.errors[0].code === "file-too-large") {
      alert.current.showNotification({
        title: t`Error`,
        description: t`Image size must be less than or equal to 15 Megabyte.`,
      })
    } else {
      alert.current.showNotification({
        title: t`Error`,
        description: image.errors[0].message,
      })
    }
    return
  }

  if (image.size > PHOTO_UPLOAD_CONSTRAINTS.maxFileSize) {
    alert.current.showNotification({
      title: t`Error`,
      description: t`Image size must be less than or equal to 15 Megabyte.`,
    })
    return
  }

  if (!PHOTO_UPLOAD_CONSTRAINTS.acceptedMimeTypes.includes(image.type)) {
    alert.current.showNotification({
      title: t`Error`,
      description: t`Image must be one of the following types: image/png, image/jpg, image/jpeg.`,
    })
    return
  }

  const size = await browserImageSize(image)

  if (size.width < PHOTO_UPLOAD_CONSTRAINTS.minWidth) {
    alert.current.showNotification({
      title: t`Error`,
      description: t`Image width must be greater than or equal to 300 pixel.`,
    })
    return
  }

  if (size.height < PHOTO_UPLOAD_CONSTRAINTS.minHeight) {
    alert.current.showNotification({
      title: t`Error`,
      description: t`Image height must be greater than or equal to 300 pixel.`,
    })
    return
  }

  if (size.width > PHOTO_UPLOAD_CONSTRAINTS.maxWidth) {
    alert.current.showNotification({
      title: t`Error`,
      description: t`Image width must be less than or equal to 8000 pixel.`,
    })
    return
  }

  if (size.height > PHOTO_UPLOAD_CONSTRAINTS.maxHeight) {
    alert.current.showNotification({
      title: t`Error`,
      description: t`Image height must be less than or equal to 8000 pixel.`,
    })
    return
  }

  return image
}

export function ImagePickerContainer({
  children,
  autorun,
  onAutorun,
  options,
}) {
  const awaitingPromiseRef = useRef()
  const alert = useAlert()
  const [isRunned, setIsRunned] = useState(false)
  const { getRootProps, getInputProps, open } = useDropzone({
    multiple: false,
    maxSize: PHOTO_UPLOAD_CONSTRAINTS.maxFileSize,
    accept: {
      "image/*": [".png", ".jpeg", ".jpg"],
    },
    onError: (err) => awaitingPromiseRef?.current?.reject(err),
    onDrop: async ([acceptedFile], [rejectedFile]) => {
      try {
        if (rejectedFile) {
          return awaitingPromiseRef?.current?.resolve(
            await validateImageFile(rejectedFile, alert),
          )
        }

        if (acceptedFile) {
          const image = await validateImageFile(acceptedFile, alert)

          if (image) {
            return awaitingPromiseRef?.current?.resolve({
              file: image,
              path: URL.createObjectURL(image),
            })
          }
        }

        return awaitingPromiseRef?.current?.resolve()
      } catch (err) {
        const { title, description } = getErrorMessage(err)
        alert.current.showNotification({ title, description })

        awaitingPromiseRef?.current?.resolve()

        if (!err.isAxiosError) {
          throw err
        }
      }
    },
    ...options,
  })

  const getImage = useCallback(() => {
    open()

    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject }
    })
  }, [open])

  useEffect(() => {
    if (autorun && onAutorun && !isRunned) {
      setIsRunned(true)
      onAutorun({ getImage })
    }
  }, [autorun, onAutorun, getImage, isRunned])

  return (
    <div {...getRootProps({ className: "dropzone" })}>
      {children({ getImage })} <input {...getInputProps()} />
    </div>
  )
}

ImagePickerContainer.defaultProps = {}
