import { i18n } from "@lingui/core"
import { t, Trans } from "@lingui/macro"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import { StyleSheet } from "react-native"
import { useMutation, useQueryClient } from "react-query"
import { useTheme } from "styled-components/native"
import { useSafeAreaOrDefaultInsets } from "../../hooks"
import {
  PERSON_POSITION_ALONE,
  PERSON_POSITION_MIDDLE,
  PERSON_POSITION_OPTIONS,
} from "../../lib/constants"
import { getErrorMessage } from "../../lib/helpers"
import { mixpanel } from "../../lib/mixpanel"
import { Sentry } from "../../lib/sentry"
import { useAlert, useApi } from "../../providers"
import { AppBar, Button, Image, Input, View } from "../ui"

export function PhotoNewModal({ image, onClose }) {
  const api = useApi()
  const theme = useTheme()

  const insets = useSafeAreaOrDefaultInsets({ bottom: theme.space.xl })
  const alert = useAlert()
  const queryClient = useQueryClient()

  const form = useForm({
    defaultValues: {
      multiplePeople: "no",
      position: PERSON_POSITION_MIDDLE,
    },
  })

  const multiplePeople = form.watch("multiplePeople")

  const uploadMutation = useMutation(api.createPhoto, {
    onError: (err, variables, context) => {
      const { title, description } = getErrorMessage(err)
      alert.current.showNotification({ title, description })

      if (!err.isAxiosError) {
        Sentry.captureException(err)
      }
    },
    onSuccess: async (data, variables, context) => {
      queryClient.invalidateQueries("photoList")

      onClose(data)

      alert.current.showNotification({
        title: t`Photo upload`,
        description: t`Photo successfully uploaded.`,
        componentProps: {
          alertType: "success",
        },
      })

      mixpanel.track("Photo Created")
    },
  })

  function onSubmit({ multiplePeople, position }) {
    if (multiplePeople === "no") {
      position = PERSON_POSITION_ALONE
    }
    return uploadMutation.mutate({ image, position })
  }

  return (
    <View bg="background" flex={1}>
      <AppBar title={t`Photo`} pt={insets.top + theme.space.xl}>
        <Button
          start
          mr="lg"
          label={t`Cancel`}
          variant="navbar"
          size="xs"
          disabled={uploadMutation.isLoading}
          onPress={() => onClose()}
        />
      </AppBar>

      <View flex={1} mx="xl" alignItems="center" justifyContent="center">
        <Image
          testID="image"
          dataSet={{ private: true }}
          resizeMode="contain"
          style={styles.image}
          source={{ uri: image.path }}
        />
      </View>

      <View mx="xl" pb={insets.bottom}>
        <Controller
          name="multiplePeople"
          control={form.control}
          render={({ field: { onChange, onBlur, onFocus, value, name } }) => {
            return (
              <Input mt="xl" error={form.formState.errors[name]?.message}>
                <Input.Title>
                  <Trans>Multiple people</Trans>
                </Input.Title>
                <Input.Segment
                  items={[
                    {
                      value: "yes",
                      label: t`Yes`,
                    },
                    {
                      value: "no",
                      label: t`No`,
                    },
                  ]}
                  onChange={onChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  value={value}
                />
                <Input.Error />
              </Input>
            )
          }}
        />

        {multiplePeople === "yes" && (
          <Controller
            name="position"
            control={form.control}
            render={({ field: { onChange, onBlur, onFocus, value, name } }) => {
              return (
                <Input mt="xl" error={form.formState.errors[name]?.message}>
                  <Input.Title>
                    <Trans>Position</Trans>
                  </Input.Title>
                  <Input.Segment
                    items={PERSON_POSITION_OPTIONS.map((o) => ({
                      ...o,
                      label: i18n._(o.label),
                    }))}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    value={value}
                  />
                  <Input.Error />
                </Input>
              )
            }}
          />
        )}

        <Button
          mt="xl"
          disabled={uploadMutation.isLoading}
          loading={uploadMutation.isLoading}
          label={t`Upload`}
          onPress={form.handleSubmit(onSubmit)}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  image: { width: "100%", height: "100%" },
})
