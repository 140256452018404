import AsyncStorage from "@react-native-async-storage/async-storage"
import { isBefore, startOfToday, subDays, subHours } from "date-fns"
import React, { useContext, useEffect, useMemo } from "react"
import { Platform, useColorScheme } from "react-native"
import { useMutation, useQuery, useQueryClient } from "react-query"
import {
  SOCIAL_TEST,
  SYSTEM_APPEARANCE,
  SYSTEM_LANGUAGE,
} from "../lib/constants"
import { getLanguageCode } from "../lib/helpers"
import { i18n } from "../lib/i18n"
import { Sentry } from "../lib/sentry"
import { getNavigationTheme, getTheme } from "../lib/theme"

export const StoreContext = React.createContext(null)

export function useStore() {
  return useContext(StoreContext)
}

export const appStoreReviewPassedDate = new Date("2023-11-21T10:00:00.000Z")

export const appStoreReviewPassed =
  isBefore(appStoreReviewPassedDate, new Date()) || Platform.OS !== "ios"
// export const appStoreReviewPassed = true

const defaultStore = {
  appearance: SYSTEM_APPEARANCE,
  language: SYSTEM_LANGUAGE,
  hadReviewWalkthrough: false,
  hadTestsWalkthrough: false,
  selectedTestType: appStoreReviewPassed ? null : SOCIAL_TEST,
  authToken: "",
  showInviteFriend: true,
  showRateUsV2: true,
  autoTranslate: true,
  allowThirdPartyTracking: Platform.OS !== "web" ? true : null,
  lastAppReviewPromptAt: subDays(startOfToday(), 12),
  lastPushNotificationPermissionsAskedAt: subHours(new Date(), 1),
}

function resolveLanguageCode(language) {
  return language
    ? language === SYSTEM_LANGUAGE
      ? getLanguageCode()
      : language
    : getLanguageCode()
}

export function StoreProvider({ children }) {
  const queryClient = useQueryClient()
  const colorScheme = useColorScheme()

  const {
    data: store,
    isError,
    error,
    isLoading,
  } = useQuery("store", async () => {
    let store = await AsyncStorage.getItem("store")

    if (!store) {
      store = {
        ...defaultStore,
      }

      return store
    }

    try {
      store = { ...defaultStore, ...JSON.parse(store) }
    } catch (err) {
      Sentry.captureException(err)
      store = defaultStore
    }

    store.lastAppReviewPromptAt = new Date(store.lastAppReviewPromptAt)
    store.lastPushNotificationPermissionsAskedAt = new Date(
      store.lastPushNotificationPermissionsAskedAt,
    )

    return store
  })

  const storeMutation = useMutation(
    (val) => {
      const newStore = { ...store, ...val }
      queryClient.setQueryData("store", () => newStore)
      return AsyncStorage.setItem("store", JSON.stringify(newStore))
    },
    {
      onError: (err, variables, context) => {
        Sentry.captureException(err)
      },
      onSuccess: async (data, variables, context) => {},
    },
  )

  const languageCode = useMemo(
    () => resolveLanguageCode(store?.language),
    [store?.language],
  )

  const { theme, navigationTheme, scheme } = useMemo(() => {
    const scheme = store?.appearance
      ? store?.appearance === SYSTEM_APPEARANCE
        ? colorScheme
        : store?.appearance
      : colorScheme

    const theme = getTheme(scheme)
    const navigationTheme = getNavigationTheme(scheme)

    return { scheme, theme, navigationTheme }
  }, [store?.appearance, colorScheme])

  useEffect(() => {
    if (store?.language) {
      i18n.activate(languageCode)
    }
  }, [languageCode, store?.language])

  useEffect(() => {
    if (store?.authToken === "") {
      queryClient.removeQueries({
        predicate: (query) => query.queryKey !== "store",
      })
    }
  }, [store?.authToken, queryClient])

  if (isLoading) {
    return null
  }

  if (isError) {
    throw error
  }

  const {
    appearance,
    language,
    hadReviewWalkthrough,
    hadTestsWalkthrough,
    selectedTestType,
    authToken,
    showInviteFriend,
    showRateUsV2,
    autoTranslate,
    lastAppReviewPromptAt,
    lastPushNotificationPermissionsAskedAt,
    allowThirdPartyTracking,
  } = store

  return (
    <StoreContext.Provider
      value={{
        storeMutation,
        store,
        scheme,
        theme,
        navigationTheme,
        appearance,
        language,
        languageCode,
        selectedTestType,
        authToken,
        hadReviewWalkthrough,
        hadTestsWalkthrough,
        showInviteFriend,
        showRateUsV2,
        autoTranslate,
        lastAppReviewPromptAt,
        lastPushNotificationPermissionsAskedAt,
        allowThirdPartyTracking,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
