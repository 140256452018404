import React from "react"
import styled from "styled-components/native"
import { space } from "styled-system"
import { TouchableOpacity } from "./touchable-opacity"
import { View } from "./view"

export function ChipComponent({
  loading,
  disabled,
  children,
  style,
  size,
  onPress,
  ...passThroughProps
}) {
  const ContainerComponent = onPress ? TouchableOpacity : View

  return (
    <ContainerComponent
      disabled={disabled}
      {...passThroughProps}
      onPress={onPress}
      activeOpacity={0.7}
      opacity={disabled ? 0.7 : 1}
      style={style}
    >
      {children}
    </ContainerComponent>
  )
}

export const Chip = styled(ChipComponent)`
  ${space}
`

Chip.defaultProps = {
  bg: "border",
  py: "md",
  px: "md",
  borderRadius: 4,
  disabled: false,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
}
