import { i18n } from "@lingui/core"
import { useNavigation } from "@react-navigation/native"
import React, { useState } from "react"
import { useWindowDimensions } from "react-native"
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query"
import { useTheme } from "styled-components/native"
import {
  ActivityIndicator,
  BottomSheet,
  Button,
  Chip,
  Container,
  ErrorBody,
  FlatList,
  Icon,
  Input,
  NavBar,
  RefreshControl,
  Row,
  Text,
  TopNavBar,
  useDialog,
  View,
} from "../../components/ui"
import {
  Breakpoint,
  useBreakpoints,
  useSafeAreaOrDefaultInsets,
} from "../../hooks"
import {
  ALL_TEST_OPTIONS,
  DATING_TEST_SCORE_OPTIONS,
  ERROR_TEST_OPTION,
  MALE,
} from "../../lib/constants"
import { formatRelativeDate, getErrorMessage } from "../../lib/helpers"
import { Sentry } from "../../lib/sentry"
import { useAlert, useApi } from "../../providers"

export function AdminReviewListScreen({ route }) {
  const alert = useAlert()
  const api = useApi()
  const queryClient = useQueryClient()
  const theme = useTheme()
  const insets = useSafeAreaOrDefaultInsets({ bottom: theme.space.xl })
  const { breakpoints, currentBreakpointIndex, getBreakpointValue } =
    useBreakpoints()
  const { width } = useWindowDimensions()

  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [filter, setFilter] = useState({
    userIdNotNull: true,
    sorts: "created_at desc",
  })

  const {
    fetchNextPage,
    hasNextPage,
    refetch: refetchReviews,
    data: reviewsData,
    isError: reviewsIsError,
    error: reviewsError,
    isLoading: reviewsIsLoading,
  } = useInfiniteQuery(
    ["adminReviewList", { userId: route.params?.userId, filter }],
    ({ pageParam = 1 }) =>
      api.getAdminReviewList({
        page: pageParam,
        query: {
          userIdEq: route.params?.userId,
          ...filter,
        },
      }),
    {
      getNextPageParam: (lastPage, allPages) => lastPage.meta.next ?? undefined,
      getPreviousPageParam: (firstPage, allPages) =>
        firstPage.meta.prev ?? undefined,
    },
  )

  const deleteReviewMutation = useMutation(api.deleteAdminReview, {
    onError: (err, variables, context) => {
      const { title, description } = getErrorMessage(err)
      alert.current.showNotification({ title, description })

      if (!err.isAxiosError) {
        Sentry.captureException(err)
      }
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("adminReviewList")
    },
  })

  const reviewsCount = reviewsData?.pages[0]?.meta?.count ?? 0
  const allReviews = reviewsData?.pages?.flatMap((p) => p.data) ?? []

  const numColumns = getBreakpointValue([1, 1, 2, 2])

  const reviewCardSize =
    ((breakpoints[currentBreakpointIndex - 1] ?? width) -
      theme.space.xl * 2 -
      theme.space.md * (numColumns - 1)) /
    numColumns

  return (
    <>
      <Breakpoint values={["lg", "xl"]}>
        <TopNavBar />
      </Breakpoint>

      <Container breakpoint="xl">
        <Row>
          <NavBar title="Reviews">
            <Button
              end
              size="xs"
              variant="navbar"
              label="Filter"
              onPress={() => setIsFilterOpen(true)}
            />
          </NavBar>
        </Row>
      </Container>

      <BottomSheet
        isOpen={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        onDone={() => setIsFilterOpen(false)}
        title="Filter"
      >
        <View
          style={{
            paddingHorizontal: theme.space.xl,
            paddingBottom: insets.bottom,
          }}
        >
          <Input mt="xl">
            <Input.Switch
              label="Include admin"
              onValueChange={(val) =>
                setFilter({
                  ...filter,
                  userIdNotNull: val === false ? true : undefined,
                })
              }
              value={!filter.userIdNotNull}
            />
            <Input.Error />
          </Input>

          <Input mt="xl">
            <Input.Title>Sorts</Input.Title>
            <Input.Picker
              items={[
                {
                  value: "created_at desc",
                  label: "Created Desc",
                },
                {
                  value: "created_at asc",
                  label: "Created Asc",
                },
              ]}
              onChange={(val) =>
                setFilter({
                  ...filter,
                  sorts: val,
                })
              }
              value={filter.sorts}
            />
            <Input.Error />
          </Input>
        </View>
      </BottomSheet>

      <Container flex={1} breakpoint="xl">
        <Row flex={1}>
          <View flex={1} justifyContent="center">
            {reviewsIsLoading ? (
              <ActivityIndicator />
            ) : reviewsIsError ? (
              <ErrorBody error={reviewsError} reload={refetchReviews} />
            ) : (
              <FlatList
                key={numColumns}
                onEndReached={() => hasNextPage && fetchNextPage()}
                refreshControl={
                  <RefreshControl
                    onRefresh={() => refetchReviews()}
                    refreshing={false}
                  />
                }
                numColumns={numColumns}
                data={allReviews}
                keyExtractor={(item) => item.id}
                renderItem={({ item, index }) => {
                  const mb = allReviews.length - 1 === index ? "xl" : 0
                  const ml = index % numColumns !== 0 ? "md" : "xl"

                  return (
                    <ReviewItem
                      mb={mb}
                      ml={ml}
                      review={item}
                      reviewCardSize={reviewCardSize}
                      onDeletePress={() =>
                        deleteReviewMutation.mutate({ id: item.id })
                      }
                    />
                  )
                }}
                ListHeaderComponent={
                  <View mx="xl">
                    <Text fontSize={4} fontFamily="heading">
                      Total: {reviewsCount}
                    </Text>
                  </View>
                }
              />
            )}
          </View>
        </Row>
      </Container>
    </>
  )
}

function ReviewItem({ review, onDeletePress, reviewCardSize, ...props }) {
  const navigation = useNavigation()
  const openReviewDeleteDialog = useDialog()

  const datingFeedback = review.feedbacks.find((f) =>
    f.value.includes("score_"),
  )
  let datingScore

  if (datingFeedback) {
    datingScore = parseInt(datingFeedback.value.split("score_")[1], 10)
  }

  return (
    <View
      mt="xl"
      mx="xl"
      bg="soft"
      px="xl"
      pb="xl"
      borderRadius={3}
      width={reviewCardSize}
      {...props}
    >
      <View flexDirection="row" justifyContent="space-between" mt="lg">
        {review.userId && (
          <Button
            size="xs"
            variant="link"
            label={`User (${review.userId})`}
            onPress={() =>
              navigation.push("AdminUserDetails", { id: review.userId })
            }
          />
        )}

        <Button
          size="xs"
          variant="link"
          label={`Test (${review.testId})`}
          onPress={() =>
            navigation.push("AdminTestDetails", { id: review.testId })
          }
        />

        <Button
          size="xs"
          variant="link"
          label="Delete"
          onPress={() =>
            openReviewDeleteDialog({
              title: "Are you sure?",
              description: "Review will be deleted and can't be restored",
              buttons: [
                {
                  label: "Delete",
                  onPress: (value) => onDeletePress({ id: review.id }),
                },
                {
                  label: "Cancel",
                  variant: "primary-outline",
                },
              ],
            })
          }
        />
      </View>

      {Boolean(review.comment) && (
        <Text testID="comment" dataSet={{ private: true }} mt="xl">
          {review.comment}
        </Text>
      )}

      {Boolean(datingScore) && (
        <View flexDirection="row" mt="xl">
          {DATING_TEST_SCORE_OPTIONS.map((o, idx) => {
            const index = idx + 1

            return (
              <Icon
                key={idx}
                name="star"
                solid={datingScore >= index}
                color="business"
                fontSize={6}
              />
            )
          })}
        </View>
      )}

      <View pt="xl" flexDirection="row" flexWrap="wrap" flexGap="lg">
        {review.feedbacks.map((f, idx) => {
          const option =
            ALL_TEST_OPTIONS.find((o) => o.value === f.value) ??
            ERROR_TEST_OPTION

          return f.value.includes("score_") ? null : (
            <Chip key={idx} px="lg" py="sm" bg="translucent">
              <Text fontFamily="bold">
                {i18n._(option.label, { gender: MALE })}
              </Text>
            </Chip>
          )
        })}
      </View>
      <View mt="xl" flexDirection="row" justifyContent="space-between">
        <Text color="grayPrimary">ID {review.id}</Text>
        <Text color="grayPrimary">{formatRelativeDate(review.createdAt)}</Text>
      </View>
    </View>
  )
}
