import { arg, defineMessage, select } from "@lingui/macro"
export const CLIENT_ERROR = "CLIENT_ERROR"
export const SERVER_ERROR = "SERVER_ERROR"
export const TIMEOUT_ERROR = "TIMEOUT_ERROR"
export const CONNECTION_ERROR = "CONNECTION_ERROR"
export const NETWORK_ERROR = "NETWORK_ERROR"
export const UNKNOWN_ERROR = "UNKNOWN_ERROR"
export const CANCEL_ERROR = "CANCEL_ERROR"

export const TIMEOUT_ERROR_CODES = ["ECONNABORTED"]
export const NODEJS_CONNECTION_ERROR_CODES = [
  "ENOTFOUND",
  "ECONNREFUSED",
  "ECONNRESET",
]

export const DARK_APPEARANCE = "dark"
export const LIGHT_APPEARANCE = "light"
export const SYSTEM_APPEARANCE = "system"
export const APPEARANCES = [
  DARK_APPEARANCE,
  LIGHT_APPEARANCE,
  SYSTEM_APPEARANCE,
]
export const APPEARANCE_OPTIONS = [
  {
    value: SYSTEM_APPEARANCE,
    label: defineMessage({ message: "System" }),
  },
  {
    value: LIGHT_APPEARANCE,
    label: defineMessage({ message: "Light" }),
  },
  {
    value: DARK_APPEARANCE,
    label: defineMessage({ message: "Dark" }),
  },
]

export const EN_LANGUAGE = "en"
export const DE_LANGUAGE = "de"
export const RU_LANGUAGE = "ru"
export const SYSTEM_LANGUAGE = "system"
export const LANGUAGES = [
  EN_LANGUAGE,
  DE_LANGUAGE,
  RU_LANGUAGE,
  SYSTEM_LANGUAGE,
]
export const LANGUAGE_OPTIONS = [
  {
    value: SYSTEM_LANGUAGE,
    label: defineMessage({ message: "System" }),
  },
  {
    value: EN_LANGUAGE,
    label: defineMessage({ message: "English" }),
  },
  {
    value: DE_LANGUAGE,
    label: defineMessage({ message: "German (Deutsch)" }),
  },
  {
    value: RU_LANGUAGE,
    label: defineMessage({ message: "Russian (Русский)" }),
  },
]

export const USER_ROLE = "user"
export const ZUSER_ROLE = "zuser"
export const ADMIN_ROLE = "admin"
export const MANAGER_ROLE = "manager"

export const USER_ROLES = [USER_ROLE, ZUSER_ROLE, ADMIN_ROLE, MANAGER_ROLE]

export const USER_ROLE_OPTIONS = [
  {
    value: USER_ROLE,
    label: "User",
  },
  {
    value: ZUSER_ROLE,
    label: "Zuser",
  },
  {
    value: ADMIN_ROLE,
    label: "Admin",
  },
  {
    value: MANAGER_ROLE,
    label: "Manager",
  },
]

export const MALE = "male"
export const FEMALE = "female"
export const NON_BINARY = "non_binary"

export const GENDERS = [MALE, FEMALE, NON_BINARY]

export const GENDER_OPTIONS = [
  {
    value: MALE,
    label: defineMessage({ message: "Male" }),
  },
  {
    value: FEMALE,
    label: defineMessage({ message: "Female" }),
  },
  {
    value: NON_BINARY,
    label: defineMessage({ message: "Non-binary" }),
  },
]

export const STRAIGHT = "straight"
export const GAY = "gay"
export const LESBIAN = "lesbian"
export const BISEXUAL = "bisexual"
export const ASEXUAL = "asexual"
export const DEMISEXUAL = "demisexual"
export const PANSEXUAL = "pansexual"
export const QUEER = "queer"
export const QUESTIONING = "questioning"

export const ORIENTATIONS = [
  STRAIGHT,
  GAY,
  LESBIAN,
  BISEXUAL,
  ASEXUAL,
  DEMISEXUAL,
  PANSEXUAL,
  QUEER,
  QUESTIONING,
]

export const ORIENTATION_OPTIONS = [
  { value: STRAIGHT, label: defineMessage({ message: "Straight" }) },
  { value: GAY, label: defineMessage({ message: "Gay" }) },
  { value: LESBIAN, label: defineMessage({ message: "Lesbian" }) },
  { value: BISEXUAL, label: defineMessage({ message: "Bisexual" }) },
  { value: ASEXUAL, label: defineMessage({ message: "Asexual" }) },
  { value: DEMISEXUAL, label: defineMessage({ message: "Demisexual" }) },
  { value: PANSEXUAL, label: defineMessage({ message: "Pansexual" }) },
  { value: QUEER, label: defineMessage({ message: "Queer" }) },
  { value: QUESTIONING, label: defineMessage({ message: "Questioning" }) },
]

export const PERSON_POSITION_ALONE = "alone"
export const PERSON_POSITION_LEFT = "left"
export const PERSON_POSITION_RIGHT = "right"
export const PERSON_POSITION_MIDDLE = "middle"

export const PERSON_POSITIONS = [
  PERSON_POSITION_ALONE,
  PERSON_POSITION_LEFT,
  PERSON_POSITION_RIGHT,
  PERSON_POSITION_MIDDLE,
]

export const PERSON_POSITION_OPTIONS = [
  {
    value: PERSON_POSITION_LEFT,
    label: defineMessage({ message: "On the left" }),
  },
  {
    value: PERSON_POSITION_MIDDLE,
    label: defineMessage({ message: "In the middle" }),
  },
  {
    value: PERSON_POSITION_RIGHT,
    label: defineMessage({ message: "On the right" }),
  },
]

export const PHOTO_UPLOAD_CONSTRAINTS = {
  minWidth: 300,
  minHeight: 300,
  maxWidth: 8000,
  maxHeight: 8000,
  maxFileSize: 15000000, // 15Mb
  acceptedMimeTypes: ["image/png", "image/jpg", "image/jpeg"],
}

export const DATING_TEST = "DatingTest"
export const BUSINESS_TEST = "BusinessTest"
export const SOCIAL_TEST = "SocialTest"

export const TEST_TYPES = [DATING_TEST, BUSINESS_TEST, SOCIAL_TEST]

export const TEST_TYPE_OPTIONS = [
  {
    value: DATING_TEST,
    label: defineMessage({ message: "Dating" }),
    icon: "heart",
  },
  {
    value: BUSINESS_TEST,
    label: defineMessage({ message: "Business" }),
    icon: "briefcase",
  },
  {
    value: SOCIAL_TEST,
    label: defineMessage({ message: "Social" }),
    icon: "images",
  },
]

export const ACTIVE_TEST = "active"
export const INACTIVE_TEST = "inactive"

export const TEST_STATES = [ACTIVE_TEST, INACTIVE_TEST]

export const TEST_STATE_OPTIONS = [
  {
    value: ACTIVE_TEST,
    label: defineMessage({ message: "Active" }),
  },
  {
    value: INACTIVE_TEST,
    label: defineMessage({ message: "Paused" }),
  },
]

export const REVIEWER_ENERGY_COST = 10
export const TESTER_ENERGY_COST = 50
export const MAX_REVIEWS_AVAILABLE = 10
export const MAX_TESTS_FOR_ENERGY = 10
export const MAX_ENERGY = MAX_REVIEWS_AVAILABLE * TESTER_ENERGY_COST

export const DATING_TEST_SCORE_OPTIONS = [
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 4 },
  { value: 5 },
]

export const DATING_TEST_WOULD_DATE_OPTION = "would_date"

// console.log(
//   [...SOCIAL_TEST_OPTIONS, ...SOCIAL_TEST_MORE_OPTIONS]
//     .map((o) => o.value)
//     .join("\n"),
// )

export const TECHNICAL = "technical"
export const OFFENSIVE = "offensive"
export const NON_PERSON = "non_person"

export const REPORT_REASONS = [TECHNICAL, OFFENSIVE, NON_PERSON]

export const REPORT_REASONS_OPTIONS = [
  {
    value: TECHNICAL,
    label: defineMessage({ message: "Technical" }),
    description: defineMessage({ message: "Image is not shown." }),
  },
  {
    value: NON_PERSON,
    label: defineMessage({ message: "Not a person" }),
    description: defineMessage({
      message: "There are no people in the image.",
    }),
  },
  {
    value: OFFENSIVE,
    label: defineMessage({ message: "Offensive" }),
    description: defineMessage({
      message: "Image has a violent, rude, illegal or pornographic content.",
    }),
  },
]

export const TEST_REPORT_REASONS = [TECHNICAL, OFFENSIVE, NON_PERSON]

export const TEST_REPORT_REASONS_OPTIONS = [
  {
    value: TECHNICAL,
    label: defineMessage({ message: "Technical" }),
    description: defineMessage({ message: "Image is not shown." }),
  },
  {
    value: NON_PERSON,
    label: defineMessage({ message: "Not a person" }),
    description: defineMessage({
      message: "There are no people in the image.",
    }),
  },
  {
    value: OFFENSIVE,
    label: defineMessage({ message: "Offensive" }),
    description: defineMessage({
      message: "Image has a violent, rude, illegal or pornographic content.",
    }),
  },
]

export const ERROR_TEST_OPTION = {
  value: "error",
  label: defineMessage({
    message: "Error",
  }),
}

export const GENERAL_IMPRESSIONS_TEST_OPTIONS = [
  {
    value: "likeable",
    label: defineMessage({
      message: select(arg("gender"), {
        male: "Likeable",
        female: "Likeable",
        non_binary: "Likeable",
      }),
    }),
  },
  {
    value: "friendly",
    label: defineMessage({
      message: select(arg("gender"), {
        male: "Friendly",
        female: "Friendly",
        non_binary: "Friendly",
      }),
    }),
  },
  {
    value: "confident",
    label: defineMessage({
      message: select(arg("gender"), {
        male: "Confident",
        female: "Confident",
        non_binary: "Confident",
      }),
    }),
  },
  {
    value: "authentic",
    label: defineMessage({
      message: select(arg("gender"), {
        male: "Authentic",
        female: "Authentic",
        non_binary: "Authentic",
      }),
    }),
  },
  {
    value: "cute",
    label: defineMessage({
      message: select(arg("gender"), {
        male: "Cute",
        female: "Cute",
        non_binary: "Cute",
      }),
    }),
  },
  {
    value: "stylish",
    label: defineMessage({
      message: select(arg("gender"), {
        male: "Stylish",
        female: "Stylish",
        non_binary: "Stylish",
      }),
    }),
  },
  {
    value: "ambitious",
    label: defineMessage({
      message: select(arg("gender"), {
        male: "Ambitious",
        female: "Ambitious",
        non_binary: "Ambitious",
      }),
    }),
  },
  {
    value: "serious",
    label: defineMessage({
      message: select(arg("gender"), {
        male: "Serious",
        female: "Serious",
        non_binary: "Serious",
      }),
    }),
  },
  {
    value: "sincere",
    label: defineMessage({
      message: select(arg("gender"), {
        male: "Sincere",
        female: "Sincere",
        non_binary: "Sincere",
      }),
    }),
  },
  {
    value: "charismatic",
    label: defineMessage({
      message: select(arg("gender"), {
        male: "Charismatic",
        female: "Charismatic",
        non_binary: "Charismatic",
      }),
    }),
  },
  {
    value: "good_smile",
    label: defineMessage({
      message: "Nice smile",
    }),
  },
  {
    value: "forced_smile",
    label: defineMessage({
      message: "Forced smile",
    }),
  },
  {
    value: "good_eyes",
    label: defineMessage({
      message: "Beautiful eyes",
    }),
  },
  {
    value: "good_hair",
    label: defineMessage({
      message: "Beautiful hair",
    }),
  },
  {
    value: "good_angle",
    label: defineMessage({
      message: "Nice angle",
    }),
  },
  {
    value: "good_background",
    label: defineMessage({
      message: "Nice background",
    }),
  },
  {
    value: "unrelated_background",
    label: defineMessage({
      message: "Unrelated background",
    }),
  },
  {
    value: "aggressive",
    label: defineMessage({
      message: select(arg("gender"), {
        male: "Aggressive",
        female: "Aggressive",
        non_binary: "Aggressive",
      }),
    }),
  },
  {
    value: "uncertain",
    label: defineMessage({
      message: select(arg("gender"), {
        male: "Uncertain",
        female: "Uncertain",
        non_binary: "Uncertain",
      }),
    }),
  },
  {
    value: "arrogant",
    label: defineMessage({
      message: select(arg("gender"), {
        male: "Arrogant",
        female: "Arrogant",
        non_binary: "Arrogant",
      }),
    }),
  },
  {
    value: "bland",
    label: defineMessage({
      message: select(arg("gender"), {
        male: "Bland",
        female: "Bland",
        non_binary: "Bland",
      }),
    }),
  },
  {
    value: "artificial",
    label: defineMessage({
      message: select(arg("gender"), {
        male: "Artificial",
        female: "Artificial",
        non_binary: "Artificial",
      }),
    }),
  },
  {
    value: "intense",
    label: defineMessage({
      message: select(arg("gender"), {
        male: "Intense",
        female: "Intense",
        non_binary: "Intense",
      }),
    }),
  },
  {
    value: "sad",
    label: defineMessage({
      message: select(arg("gender"), {
        male: "Sad",
        female: "Sad",
        non_binary: "Sad",
      }),
    }),
  },
  {
    value: "timid",
    label: defineMessage({
      message: select(arg("gender"), {
        male: "Timid",
        female: "Timid",
        non_binary: "Timid",
      }),
    }),
  },
  {
    value: "tired",
    label: defineMessage({
      message: select(arg("gender"), {
        male: "Tired",
        female: "Tired",
        non_binary: "Tired",
      }),
    }),
  },
  {
    value: "uncomfortable",
    label: defineMessage({
      message: select(arg("gender"), {
        male: "Uncomfortable",
        female: "Uncomfortable",
        non_binary: "Uncomfortable",
      }),
    }),
  },
]

export const GENERAL_RECOMMENDATIONS_TEST_OPTIONS = [
  {
    value: "avoid_selfies",
    label: defineMessage({
      message: "Avoid selfies",
    }),
  },
  {
    value: "face_not_visible",
    label: defineMessage({
      message: "Can't see the face",
    }),
  },

  {
    value: "smile_more",
    label: defineMessage({
      message: "Smile more",
    }),
  },
  {
    value: "smile_less",
    label: defineMessage({
      message: "Smile less",
    }),
  },
  {
    value: "different_clothes",
    label: defineMessage({
      message: "Different clothes",
    }),
  },
  {
    value: "different_expression",
    label: defineMessage({
      message: "Different expression",
    }),
  },
  {
    value: "different_hair",
    label: defineMessage({
      message: "Different hair",
    }),
  },
  {
    value: "different_pose",
    label: defineMessage({
      message: "Different pose",
    }),
  },
  {
    value: "straighten_posture",
    label: defineMessage({
      message: "Straighten posture",
    }),
  },
  {
    value: "lack_of_eye_contact",
    label: defineMessage({
      message: "Lack of eye contact",
    }),
  },
  {
    value: "remove_sunglases",
    label: defineMessage({
      message: "Remove sunglasses",
    }),
  },
  {
    value: "different_background",
    label: defineMessage({
      message: "Different background",
    }),
  },
  {
    value: "bad_angle",
    label: defineMessage({
      message: "Change angle",
    }),
  },
  {
    value: "too_blurry",
    label: defineMessage({
      message: "Blurry",
    }),
  },
  {
    value: "too_bright",
    label: defineMessage({
      message: "Too bright",
    }),
  },
  {
    value: "too_dark",
    label: defineMessage({
      message: "Too dark",
    }),
  },
  {
    value: "too_many_shadows",
    label: defineMessage({
      message: "Too many shadows",
    }),
  },
  {
    value: "color_issues",
    label: defineMessage({
      message: "Color issues",
    }),
  },
  {
    value: "too_many_filters_effects",
    label: defineMessage({
      message: "Too many filters or effects",
    }),
  },
  {
    value: "too_many_people",
    label: defineMessage({
      message: "Too many people",
    }),
  },
  {
    value: "different_crop",
    label: defineMessage({
      message: "Crop differently",
    }),
  },
  {
    value: "too_close_up",
    label: defineMessage({
      message: "Too close-up",
    }),
  },
  {
    value: "too_far_away",
    label: defineMessage({
      message: "Too far away",
    }),
  },
]

export const DATING_IMPRESSIONS_TEST_OPTIONS = [
  {
    value: DATING_TEST_WOULD_DATE_OPTION,
    label: defineMessage({
      message: select(arg("gender"), {
        male: "Would date",
        female: "Would date",
        non_binary: "Would date",
      }),
    }),
  },
  {
    value: "smart",
    label: defineMessage({
      message: select(arg("gender"), {
        male: "Smart",
        female: "Smart",
        non_binary: "Smart",
      }),
    }),
  },
  {
    value: "attractive",
    label: defineMessage({
      message: select(arg("gender"), {
        male: "Attractive",
        female: "Attractive",
        non_binary: "Attractive",
      }),
    }),
  },
  {
    value: "trustworthy",
    label: defineMessage({
      message: select(arg("gender"), {
        male: "Trustworthy",
        female: "Trustworthy",
        non_binary: "Trustworthy",
      }),
    }),
  },
  {
    value: "interesting",
    label: defineMessage({
      message: select(arg("gender"), {
        male: "Interesting",
        female: "Interesting",
        non_binary: "Interesting",
      }),
    }),
  },
  {
    value: "brutal",
    label: defineMessage({
      message: select(arg("gender"), {
        male: "Brutal",
        female: "Brutal",
        non_binary: "Brutal",
      }),
    }),
  },
  {
    value: "funny",
    label: defineMessage({
      message: select(arg("gender"), {
        male: "Funny",
        female: "Funny",
        non_binary: "Funny",
      }),
    }),
  },
]

export const DATING_RECOMMENDATIONS_TEST_OPTIONS = [
  {
    value: "less_nudity",
    label: defineMessage({
      message: "Less nudity",
    }),
  },
]

export const SOCIAL_IMPRESSIONS_TEST_OPTIONS = [
  {
    value: "creative",
    label: defineMessage({
      message: select(arg("gender"), {
        male: "Creative",
        female: "Creative",
        non_binary: "Creative",
      }),
    }),
  },
  {
    value: "interesting",
    label: defineMessage({
      message: select(arg("gender"), {
        male: "Interesting",
        female: "Interesting",
        non_binary: "Interesting",
      }),
    }),
  },
  {
    value: "happy",
    label: defineMessage({
      message: select(arg("gender"), {
        male: "Happy",
        female: "Happy",
        non_binary: "Happy",
      }),
    }),
  },
  {
    value: "funny",
    label: defineMessage({
      message: select(arg("gender"), {
        male: "Funny",
        female: "Funny",
        non_binary: "Funny",
      }),
    }),
  },
]

export const SOCIAL_RECOMMENDATIONS_TEST_OPTIONS = []

export const BUSINESS_IMPRESSIONS_TEST_OPTIONS = [
  {
    value: "intelligent",
    label: defineMessage({
      message: select(arg("gender"), {
        male: "Intelligent",
        female: "Intelligent",
        non_binary: "Intelligent",
      }),
    }),
  },
  {
    value: "professional",
    label: defineMessage({
      message: select(arg("gender"), {
        male: "Professional",
        female: "Professional",
        non_binary: "Professional",
      }),
    }),
  },
  {
    value: "unprofessional",
    label: defineMessage({
      message: select(arg("gender"), {
        male: "Unprofessional",
        female: "Unprofessional",
        non_binary: "Unprofessional",
      }),
    }),
  },
  {
    value: "trustworthy",
    label: defineMessage({
      message: select(arg("gender"), {
        male: "Trustworthy",
        female: "Trustworthy",
        non_binary: "Trustworthy",
      }),
    }),
  },
  {
    value: "leading",
    label: defineMessage({
      message: select(arg("gender"), {
        male: "Leading",
        female: "Leading",
        non_binary: "Leading",
      }),
    }),
  },
  {
    value: "competitive",
    label: defineMessage({
      message: select(arg("gender"), {
        male: "Competitive",
        female: "Competitive",
        non_binary: "Competitive",
      }),
    }),
  },
]

export const BUSINESS_RECOMMENDATIONS_TEST_OPTIONS = [
  {
    value: "too_social",
    label: defineMessage({
      message: "Too social",
    }),
  },
]

export const ALL_IMPRESSIONS_TEST_OPTIONS = [
  ...GENERAL_IMPRESSIONS_TEST_OPTIONS,
  ...DATING_IMPRESSIONS_TEST_OPTIONS,
  ...SOCIAL_IMPRESSIONS_TEST_OPTIONS,
  ...BUSINESS_IMPRESSIONS_TEST_OPTIONS,
]
export const ALL_RECOMMENDATIONS_TEST_OPTIONS = [
  ...GENERAL_RECOMMENDATIONS_TEST_OPTIONS,
  ...DATING_RECOMMENDATIONS_TEST_OPTIONS,
  ...SOCIAL_RECOMMENDATIONS_TEST_OPTIONS,
  ...BUSINESS_RECOMMENDATIONS_TEST_OPTIONS,
]

export const ALL_TEST_OPTIONS = [
  ...ALL_IMPRESSIONS_TEST_OPTIONS,
  ...ALL_RECOMMENDATIONS_TEST_OPTIONS,
]

export const ALL_IMPRESSIONS_TEST_OPTIONS_BY_TYPE = {
  [DATING_TEST]: [
    ...DATING_IMPRESSIONS_TEST_OPTIONS,
    ...GENERAL_IMPRESSIONS_TEST_OPTIONS,
  ],
  [BUSINESS_TEST]: [
    ...BUSINESS_IMPRESSIONS_TEST_OPTIONS,
    ...GENERAL_IMPRESSIONS_TEST_OPTIONS,
  ],
  [SOCIAL_TEST]: [
    ...SOCIAL_IMPRESSIONS_TEST_OPTIONS,
    ...GENERAL_IMPRESSIONS_TEST_OPTIONS,
  ],
}

export const ALL_RECOMMENDATIONS_TEST_OPTIONS_BY_TYPE = {
  [DATING_TEST]: [
    ...DATING_RECOMMENDATIONS_TEST_OPTIONS,
    ...GENERAL_RECOMMENDATIONS_TEST_OPTIONS,
  ],
  [BUSINESS_TEST]: [
    ...BUSINESS_RECOMMENDATIONS_TEST_OPTIONS,
    ...GENERAL_RECOMMENDATIONS_TEST_OPTIONS,
  ],
  [SOCIAL_TEST]: [
    ...SOCIAL_RECOMMENDATIONS_TEST_OPTIONS,
    ...GENERAL_RECOMMENDATIONS_TEST_OPTIONS,
  ],
}
