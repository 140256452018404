import { t, Trans } from "@lingui/macro"
import React from "react"
import { Linking, Platform, StyleSheet } from "react-native"
import { useTheme } from "styled-components/native"
import aiheadshotgeneratorLogo from "../assets/aiheadshotgenerator/rounded-icon.png"
import meterableLogo from "../assets/meterable-logo.png"
import {
  Container,
  Icon,
  Image,
  ListItem,
  NavBar,
  Row,
  ScrollView,
  Text,
  TopNavBar,
  View,
} from "../components/ui"
import { Breakpoint, useBreakpoints } from "../hooks"

export function OurOtherAppsScreen({ navigation }) {
  const theme = useTheme()
  const { getBreakpointValue } = useBreakpoints()

  const listItemContainerPropsTemplate = {
    width: "49%",
    p: "xl",
    mt: "xl",
    borderColor: "grey",
    borderWidth: 1,
    borderRadius: 3,
  }

  const listItemContainerProps = getBreakpointValue([
    {},
    {},
    { ...listItemContainerPropsTemplate },
    { ...listItemContainerPropsTemplate },
  ])

  const listItemProps = {
    borderBottomWidth: getBreakpointValue([1, 1, 0, 0]),
  }

  return (
    <>
      <Breakpoint values={["lg", "xl"]}>
        <TopNavBar />
      </Breakpoint>

      <Container flex={1} breakpoint="xl">
        <Row flex={1}>
          <NavBar title={t`Our applications`} />

          <ScrollView>
            <View
              mt={getBreakpointValue([
                theme.space.xs,
                theme.space.xs,
                -theme.space.xl,
                -theme.space.xl,
              ])}
              px={getBreakpointValue(["xs", "xs", "xl", "xl"])}
              flexDirection={getBreakpointValue([
                "column",
                "column",
                "row",
                "row",
              ])}
              flexWrap={getBreakpointValue([
                "nowrap",
                "nowrap",
                "wrap",
                "wrap",
              ])}
              justifyContent="space-between"
            >
              <ListItem
                containerProps={listItemContainerProps}
                {...listItemProps}
                onPress={() =>
                  Linking.openURL(
                    Platform.OS === "android"
                      ? "https://play.google.com/store/apps/details?id=com.dimonnwc3.meterable"
                      : Platform.OS === "ios"
                      ? "https://apps.apple.com/app/meterable/id1584696105"
                      : "https://meterable.app",
                  )
                }
              >
                <Image start source={meterableLogo} style={styles.logo} />
                <View middle ml="xxl">
                  <Text fontSize={3} fontWeight="heading" ml="md">
                    Meterable
                  </Text>
                  <Text fontSize={2} ml="md" color="gray">
                    <Trans>Energy consumption tracker</Trans>
                  </Text>
                </View>
                <Icon end fontSize={2} name="chevron-right" color="grey" />
              </ListItem>

              <ListItem
                containerProps={listItemContainerProps}
                {...listItemProps}
                onPress={() =>
                  Linking.openURL(
                    Platform.OS === "android"
                      ? "https://play.google.com/store/apps/details?id=com.dimonnwc3.ai.headshot.generator"
                      : Platform.OS === "ios"
                      ? "https://apps.apple.com/app/aiheadshotgenerator/id6467744940"
                      : "https://aiheadshotgenerator.app",
                  )
                }
              >
                <Image
                  start
                  source={aiheadshotgeneratorLogo}
                  style={styles.logo}
                />
                <View middle ml="xxl">
                  <Text fontSize={3} fontWeight="heading" ml="md">
                    AI Headshot Generator
                  </Text>
                  <Text fontSize={2} ml="md" color="gray">
                    <Trans>Generate professional photos</Trans>
                  </Text>
                </View>
                <Icon end fontSize={2} name="chevron-right" color="grey" />
              </ListItem>
            </View>
          </ScrollView>
        </Row>
      </Container>
    </>
  )
}

const styles = StyleSheet.create({
  logo: { width: 54, height: 54 },
})
