import React, { useContext, useLayoutEffect, useEffect, useState } from "react"
import { useAlert, useStore } from "."
import { Api, http } from "../lib/api"
import { EN_LANGUAGE } from "../lib/constants"
import { getErrorMessage } from "../lib/helpers"

export const ApiContext = React.createContext()

export function useApi() {
  return useContext(ApiContext)
}

export function ApiProvider({ children }) {
  const [api, setApi] = useState(null)
  const alert = useAlert()
  const { languageCode } = useStore()
  const { storeMutation, authToken } = useStore()

  useLayoutEffect(() => {
    const interceptor = http.interceptors.response.use(
      async (response) => response,
      (err) => {
        if (err?.response?.status === 401) {
          const message = err.response.data?.error

          if (message) {
            const { title } = getErrorMessage(err)
            alert.current.showNotification({ title, description: message })
          }

          if (authToken) {
            storeMutation.mutate({ authToken: "" })
          }
        }
        throw err
      },
    )

    const newApi = new Api({
      http,
      token: authToken,
    })

    setApi(newApi)

    return () => http.interceptors.response.eject(interceptor)
  }, [authToken, storeMutation, alert])

  useEffect(() => {
    if (api) {
      api.setDefaultHeader("Accept-Language", languageCode ?? EN_LANGUAGE)
    }
  }, [languageCode, api])

  if (!api) {
    return null
  }

  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>
}
