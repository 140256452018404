import React, { useState } from "react"
import { Platform } from "react-native"
import { variant, space, color, fontFamily, fontSize } from "styled-system"
import styled from "styled-components/native"
import { useLinkProps } from "@react-navigation/native"
import { TouchableOpacity } from "./touchable-opacity"
import { Text } from "./text"
import { View } from "./view"

export function LinkContainer({ to, action, children, style, size, ...rest }) {
  const { onPress, ...props } = useLinkProps({ to, action })

  const [isHovered, setIsHovered] = useState(false)

  if (Platform.OS === "web") {
    const webButtonStyle = {
      transitionDuration: "150ms",
      opacity: isHovered ? 0.5 : 1,
    }

    return (
      <View
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        {...props}
        {...rest}
        onClick={onPress}
        style={[style, webButtonStyle]}
      >
        {children}
      </View>
    )
  }

  return (
    <TouchableOpacity {...props} {...rest} style={style} onPress={onPress}>
      {children}
    </TouchableOpacity>
  )
}

export function LinkButtonComponent({
  to,
  action,
  label,
  style,
  size,
  ...rest
}) {
  const color = style[0].color
  const fontFamily = style[0].fontFamily
  const fontSize = style[0].fontSize

  return (
    <LinkContainer to={to} action={action} {...rest} style={style}>
      <Text
        color={color}
        fontSize={fontSize}
        fontFamily={fontFamily}
        numberOfLines={1}
      >
        {label}
      </Text>
    </LinkContainer>
  )
}

export const LinkButton = styled(LinkButtonComponent)`
  ${space}
  ${fontFamily}
  ${fontSize}
  ${variant({
    variants: {
      primary: {
        color: "white",
        bg: "primary",
        fontFamily: "bold",
        fontSize: 3,
      },
      "primary-outline": {
        color: "primary",
        borderColor: "primary",
        borderWidth: 1,
        fontFamily: "bold",
        fontSize: 3,
      },
      navbar: {
        color: "primary",
        fontFamily: "bold",
        fontSize: 2,
      },
    },
  })}
  ${variant({
    prop: "size",
    variants: {
      xs: {
        fontSize: 2,
      },
      sm: {
        height: 28,
        fontSize: 2,
      },
      md: {
        height: 38,
        fontSize: 3,
      },
      lg: {
        height: 48,
        fontSize: 3,
      },
    },
  })}
  ${color}
`

LinkButton.defaultProps = {
  variant: "primary",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 3,
}
