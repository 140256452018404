import axios from "axios"
import humps from "humps"
import { compare } from "compare-versions"
import { in400s, in500s } from "./helpers"
import { config } from "../lib/config"
import {
  CANCEL_ERROR,
  CLIENT_ERROR,
  CONNECTION_ERROR,
  NETWORK_ERROR,
  NODEJS_CONNECTION_ERROR_CODES,
  SERVER_ERROR,
  TIMEOUT_ERROR,
  TIMEOUT_ERROR_CODES,
  UNKNOWN_ERROR,
} from "./constants"

export function versionInterceptor(response) {
  const minClientVersion = response.headers["x-min-client-version"]
  if (minClientVersion && compare(config.version, minClientVersion, "<")) {
    const err = new Error("App version is less than minimal required")
    err.code = "appMinVersion"
    throw err
  }

  return response
}

export function errorInterceptor(error) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    error.response.data = humps.camelizeKeys(error.response.data)
    error.problem = getProblemFromStatus(error.response.status)
    throw error
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an
    // instance of http.ClientRequest in node.js
    error.problem = getProblemFromError(error)
    throw error
  } else {
    // Something happened in setting up the request that triggered an Error
    error.problem = getProblemFromError(error)
    throw error
  }
}

const getProblemFromStatus = (status) => {
  if (in400s(status)) {
    return CLIENT_ERROR
  }

  if (in500s(status)) {
    return SERVER_ERROR
  }

  return UNKNOWN_ERROR
}

const getProblemFromError = (error) => {
  if (error.message === "Network Error") return NETWORK_ERROR
  if (axios.isCancel(error)) return CANCEL_ERROR

  if (TIMEOUT_ERROR_CODES.includes(error.code)) {
    return TIMEOUT_ERROR
  }

  if (NODEJS_CONNECTION_ERROR_CODES.includes(error.code)) {
    return CONNECTION_ERROR
  }

  return UNKNOWN_ERROR
}
