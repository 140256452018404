import { system } from "@styled-system/core"

const config = {
  flexGap: {
    property: "gap",
    scale: "space",
  },
  flexColumnGap: {
    property: "columnGap",
    scale: "space",
  },
  flexRowGap: {
    property: "rowGap",
    scale: "space",
  },
}

export const flexGap = system(config)
