import axios from "axios"
import axiosRetry from "axios-retry"
import qs from "qs"
import { Platform } from "react-native"
import { errorInterceptor, versionInterceptor } from "./axios-interceptors"
import { config } from "./config"

export const http = axios.create({
  baseURL: config.api.url,
  timeout: 15000,
  paramsSerializer: {
    serialize: function (params) {
      return qs.stringify(params, { arrayFormat: "brackets" })
    },
  },
  headers: {
    "X-Platform": Platform.OS,
    "X-Client-Version": config.version,
  },
})

axiosRetry(http, { retries: 3, retryDelay: axiosRetry.exponentialDelay })

http.interceptors.response.use(versionInterceptor, errorInterceptor)

export class Api {
  constructor({ http, token }) {
    this.http = http

    if (token) {
      this.setDefaultHeader("Authorization", `Bearer ${token}`)
    }
  }

  setDefaultHeader = (header, value) => {
    http.defaults.headers[header] = value
  }

  removeDefaultHeader = (header) => {
    delete http.defaults.headers[header]
  }

  createOneTimePassword = async ({ oneTimePasswordDto }) => {
    const res = await this.http({
      method: "POST",
      url: "/api/v1/one_time_passwords",
      data: oneTimePasswordDto,
    })

    return res.data
  }

  createMagicLink = async ({ magicLinkDto }) => {
    const res = await this.http({
      method: "POST",
      url: "/api/v1/magic_links",
      data: magicLinkDto,
    })

    return res.data
  }

  getUser = async () => {
    const res = await this.http({
      method: "GET",
      url: "/api/v1/user",
    })

    return res.data
  }

  createUser = async ({ userDto }) => {
    const res = await this.http({
      method: "POST",
      url: "/api/v1/users",
      data: userDto,
    })

    return { data: res.data, isNew: res.status === 201 }
  }

  createAppleUser = async ({ userDto }) => {
    const res = await this.http({
      method: "POST",
      url: "/api/v1/apple_user",
      data: userDto,
    })

    return { data: res.data, isNew: res.status === 201 }
  }

  createGoogleUser = async ({ userDto }) => {
    const res = await this.http({
      method: "POST",
      url: "/api/v1/google_user",
      data: userDto,
    })

    return { data: res.data, isNew: res.status === 201 }
  }

  updateUser = async ({ userDto }) => {
    const res = await this.http({
      method: "PUT",
      url: "/api/v1/user",
      data: userDto,
    })

    return res.data
  }

  deleteUser = async () => {
    const res = await this.http({
      method: "DELETE",
      url: "/api/v1/user",
    })

    return res.data
  }

  getNotificationSettings = async () => {
    const res = await this.http({
      method: "GET",
      url: "/api/v1/notification_settings",
    })

    return res.data
  }

  updateNotificationSettings = async ({ notificationSettingsDto }) => {
    const res = await this.http({
      method: "PUT",
      url: "/api/v1/notification_settings",
      data: notificationSettingsDto,
    })

    return res.data
  }

  getPhotoList = async ({ page = 1, items = 50 }) => {
    const res = await this.http({
      method: "GET",
      url: "/api/v1/photos",
      params: {
        page,
        items,
      },
    })

    return res.data
  }

  getPhoto = async (id) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/photos/${id}`,
    })

    return res.data
  }

  createPhoto = async ({ image, position }) => {
    const data = new FormData()

    if (image.file && Platform.OS === "web") {
      data.append("image", image.file)
    } else {
      const parts = image.path.split("/")
      const filename = parts[parts.length - 1]

      data.append("image", {
        name: image.filename ?? filename ?? "unknown",
        type: image.mime,
        uri: image.path,
      })
    }

    data.append("position", position)

    const res = await this.http({
      method: "POST",
      url: "/api/v1/photos",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data,
    })

    return res.data
  }

  deletePhoto = async (id) => {
    const res = await this.http({
      method: "DELETE",
      url: `/api/v1/photos/${id}`,
    })

    return res.data
  }

  getTestList = async ({ page = 1, items = 50, query = {} }) => {
    const res = await this.http({
      method: "GET",
      url: "/api/v1/tests",
      params: {
        page,
        items,
        q: query,
      },
    })

    return res.data
  }

  createDatingTest = async ({ testDto }) => {
    const res = await this.http({
      method: "POST",
      url: "/api/v1/dating_tests",
      data: testDto,
    })

    return res.data
  }

  createBusinessTest = async ({ testDto }) => {
    const res = await this.http({
      method: "POST",
      url: "/api/v1/business_tests",
      data: testDto,
    })

    return res.data
  }

  createSocialTest = async ({ testDto }) => {
    const res = await this.http({
      method: "POST",
      url: "/api/v1/social_tests",
      data: testDto,
    })

    return res.data
  }

  getTest = async (id) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/tests/${id}`,
    })

    return res.data
  }

  activateTest = async ({ id, coins }) => {
    const res = await this.http({
      method: "POST",
      url: `/api/v1/tests/${id}/activate`,
      data: { coins },
    })

    return res.data
  }

  deactivateTest = async (id) => {
    const res = await this.http({
      method: "POST",
      url: `/api/v1/tests/${id}/deactivate`,
    })

    return res.data
  }

  deleteTest = async (id) => {
    const res = await this.http({
      method: "DELETE",
      url: `/api/v1/tests/${id}`,
    })

    return res.data
  }

  getTestReviewList = async ({ testId, page = 1, items = 50 }) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/tests/${testId}/reviews`,
      params: { page, items },
    })

    return res.data
  }

  getTestStatistics = async (id) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/tests/${id}/statistics`,
    })

    return res.data
  }

  getNextTest = async (type) => {
    const res = await this.http({
      method: "GET",
      url: "/api/v1/next_test",
      params: {
        type,
      },
    })

    return res.data
  }

  createReview = async ({ reviewDto }) => {
    const res = await this.http({
      method: "POST",
      url: "/api/v1/reviews",
      data: reviewDto,
    })

    return res.data
  }

  createTestReport = async ({ testReportDto }) => {
    const res = await this.http({
      method: "POST",
      url: "/api/v1/test_reports",
      data: testReportDto,
    })

    return res.data
  }

  createTestSkip = async ({ testSkipDto }) => {
    const res = await this.http({
      method: "POST",
      url: "/api/v1/test_skips",
      data: testSkipDto,
    })

    return res.data
  }

  createReviewReport = async ({ reviewReportDto }) => {
    const res = await this.http({
      method: "POST",
      url: "/api/v1/review_reports",
      data: reviewReportDto,
    })

    return res.data
  }

  updateCoins = async () => {
    const res = await this.http({
      method: "POST",
      url: "/api/v1/user/coins",
    })

    return res.data
  }

  createStripeCheckoutSession = async ({ stripeCheckoutSessionDto }) => {
    const res = await this.http({
      method: "POST",
      url: "/api/v1/stripe_checkout_sessions",
      data: stripeCheckoutSessionDto,
    })

    return res.data
  }

  deleteCoupon = async (token) => {
    const res = await this.http({
      method: "DELETE",
      url: `/api/v1/coupons/${token}`,
    })

    return res.data
  }

  getInviteList = async ({ page = 1, items = 10 }) => {
    const res = await this.http({
      method: "GET",
      url: "/api/v1/invites",
      params: {
        page,
        items,
      },
    })

    return res.data
  }

  createTranslation = async ({ translationDto }) => {
    const res = await this.http({
      method: "POST",
      url: "/api/v1/translations",
      data: translationDto,
    })

    return res.data
  }

  getAdminUserList = async ({ page = 1, items = 100, query = {} }) => {
    const res = await this.http({
      method: "GET",
      url: "/api/v1/admin/users",
      params: {
        page,
        items,
        q: query,
      },
    })

    return res.data
  }

  getAdminUser = async (id) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/admin/users/${id}`,
    })

    return res.data
  }

  updateAdminUser = async ({ id, userDto }) => {
    const res = await this.http({
      method: "PUT",
      url: `/api/v1/admin/users/${id}`,
      data: userDto,
    })

    return res.data
  }

  deleteAdminUser = async (id) => {
    const res = await this.http({
      method: "DELETE",
      url: `/api/v1/admin/users/${id}`,
    })

    return res.data
  }

  getAdminTestList = async ({ page = 1, items = 100, query = {} }) => {
    const res = await this.http({
      method: "GET",
      url: "/api/v1/admin/tests",
      params: {
        page,
        items,
        q: query,
      },
    })

    return res.data
  }

  getAdminTest = async (id) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/admin/tests/${id}`,
    })

    return res.data
  }

  deleteAdminTest = async (id) => {
    const res = await this.http({
      method: "DELETE",
      url: `/api/v1/admin/tests/${id}`,
    })

    return res.data
  }

  getAdminTestStatistics = async (id) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/admin/tests/${id}/statistics`,
    })

    return res.data
  }

  getAdminNextTest = async ({ query = {} }) => {
    const res = await this.http({
      method: "GET",
      url: "/api/v1/admin/next_test",
      params: {
        q: query,
      },
    })

    return res.data
  }

  getAdminReviewList = async ({ page = 1, items = 100, query = {} }) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/admin/reviews`,
      params: { page, items, q: query },
    })

    return res.data
  }

  createAdminReview = async ({ reviewDto }) => {
    const res = await this.http({
      method: "POST",
      url: "/api/v1/admin/reviews",
      data: reviewDto,
    })

    return res.data
  }

  deleteAdminReview = async ({ id }) => {
    const res = await this.http({
      method: "DELETE",
      url: `/api/v1/admin/reviews/${id}`,
    })

    return res.data
  }

  getAdminReportList = async ({ page = 1, items = 100, query = {} }) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/admin/reports`,
      params: { page, items, q: query },
    })

    return res.data
  }

  getAdminReport = async (id) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/admin/reports/${id}`,
    })

    return res.data
  }

  getAdminTestReportList = async ({ page = 1, items = 100, query = {} }) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/admin/test_reports`,
      params: { page, items, q: query },
    })

    return res.data
  }

  getAdminTestReport = async (id) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/admin/test_reports/${id}`,
    })

    return res.data
  }

  getAdminReviewReportList = async ({ page = 1, items = 100, query = {} }) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/admin/review_reports`,
      params: { page, items, q: query },
    })

    return res.data
  }

  getAdminReviewReport = async (id) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/admin/review_reports/${id}`,
    })

    return res.data
  }
}
