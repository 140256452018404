import { omit } from "lodash"
import React from "react"
import styled from "styled-components/native"
import {
  border,
  color,
  flexbox,
  layout,
  position,
  shadow,
  space,
} from "styled-system"
import { flexGap } from "../../lib/styled-flex-gap"

export const ViewComponent = styled.View`
  ${space}
  ${layout}
  ${color}
  ${flexbox}
  ${border}
  ${position}
  ${shadow}
  ${flexGap}
`

export function View(props) {
  return <ViewComponent {...omit(props, ["start", "middle", "end"])} />
}

View.defaultProps = {
  borderColor: "border",
}
