import { useCallback } from "react"
import { useWindowDimensions } from "react-native"
import { useTheme } from "styled-components/native"

export function Breakpoint({ values, children }) {
  const { currentBreakpoint, breakpoints } = useBreakpoints()

  const bps = values.map((v) => breakpoints[v])

  return bps.includes(currentBreakpoint) ? children : null
}

export function useBreakpoints() {
  const theme = useTheme()
  const { width } = useWindowDimensions()

  const currentBreakpoint =
    theme.breakpoints.find((b) => width < b) ??
    theme.breakpoints[theme.breakpoints.length - 1]
  let currentBreakpointIndex = theme.breakpoints.findIndex((b) => width < b)

  if (currentBreakpointIndex === -1) {
    currentBreakpointIndex = theme.breakpoints.length - 1
  }

  const getBreakpointValue = useCallback(
    (values) => {
      if (!Array.isArray(values)) return values

      return [...values].find(
        (value, idx) => value !== undefined && idx === currentBreakpointIndex,
      )
    },
    [currentBreakpointIndex],
  )

  return {
    currentBreakpoint,
    currentBreakpointIndex,
    breakpoints: theme.breakpoints,
    getBreakpointValue,
  }
}
