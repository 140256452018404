import React from "react"
import { Platform } from "react-native"
import styled from "styled-components/native"
import {
  space,
  layout,
  color,
  flexbox,
  border,
  position,
  shadow,
} from "styled-system"

export const StyledKeyboardAvoidingView = styled.KeyboardAvoidingView`
  ${space}
  ${layout}
  ${color}
  ${flexbox}
  ${border}
  ${position}
  ${shadow}
`

export function KeyboardAvoidingView(props) {
  return (
    <StyledKeyboardAvoidingView
      behavior={Platform.select({ android: undefined, ios: "padding" })}
      {...props}
    />
  )
}

KeyboardAvoidingView.defaultProps = {}
