import React from "react"
import { Trans, t } from "@lingui/macro"
import { Controller, useForm } from "react-hook-form"
import { Button, Input, View } from "../ui"
import { useStore } from "../../providers"

export function DeveloperAuthForm({ ...props }) {
  const { storeMutation } = useStore()
  const form = useForm({
    defaultValues: {
      token: "",
    },
  })

  function onSubmit({ token }) {
    return storeMutation.mutate({ authToken: token })
  }

  return (
    <View {...props}>
      <Controller
        name="token"
        control={form.control}
        rules={{
          required: {
            value: true,
            message: t`Value is required`,
          },
        }}
        render={({ field: { onChange, onBlur, onFocus, value, name } }) => (
          <Input error={form.formState.errors[name]?.message}>
            <Input.Title>
              <Trans>Token</Trans>
            </Input.Title>
            <Input.Text
              testID="devAuthInput"
              onFocus={onFocus}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
            />
          </Input>
        )}
      />

      <Button
        mt="xl"
        testID="devAuthSubmit"
        label={t`Developer`}
        disabled={storeMutation.isLoading}
        loading={storeMutation.isLoading}
        onPress={form.handleSubmit(onSubmit)}
      />
    </View>
  )
}
