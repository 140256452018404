import FontAwesome from "react-native-vector-icons/dist/FontAwesome5"
import styled from "styled-components/native"

import {
  space,
  layout,
  color,
  typography,
  flexbox,
  border,
  position,
  shadow,
} from "styled-system"

export const Icon = styled(FontAwesome)`
  ${space}
  ${layout}
  ${color}
  ${typography}
  ${flexbox}
  ${border}
  ${position}
  ${shadow}
`

Icon.defaultProps = {
  fontSize: 6,
  color: "text",
}
