import React, { useEffect, useMemo, useState } from "react"
import { StyleSheet } from "react-native"
import { useTheme } from "styled-components/native"
import { useSafeAreaOrDefaultInsets } from "../../../hooks"
import { BottomSheet } from "../bottom-sheet"
import { FlatList } from "../flat-list"
import { Icon } from "../icon"
import { Pressable } from "../pressable"
import { Text } from "../text"
import { TouchableHighlight } from "../touchable-highlight"
import { View } from "../view"
import { useInputContext } from "./input-context"
import { StyledTextInput } from "./input-text"

function PickerListItem({ item, index, selected, items, onPress }) {
  return (
    <TouchableHighlight
      overflow="hidden"
      borderTopRightRadius={index === 0 ? 3 : 0}
      borderTopLeftRadius={index === 0 ? 3 : 0}
      borderBottomRightRadius={index === items.length - 1 ? 3 : 0}
      borderBottomLeftRadius={index === items.length - 1 ? 3 : 0}
      underlayColor="primary"
      onPress={() => onPress(item.value)}
    >
      <View
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        p="lg"
        px="xl"
        bg="background"
      >
        <View flexDirection="row" alignItems="center">
          <Text fontSize={2} numberOfLines={1}>
            {item.label}
          </Text>
        </View>

        {selected && <Icon name="check" fontSize={3} color="primary" />}
      </View>
    </TouchableHighlight>
  )
}

export function PickerModal({
  items,
  visible,
  title,
  value,
  multi,
  onCancel,
  onDone,
}) {
  const theme = useTheme()
  const insets = useSafeAreaOrDefaultInsets({ bottom: theme.space.xl })
  const [selectedValue, setSelectedValue] = useState(value)

  useEffect(() => {
    setSelectedValue(value)
  }, [value, setSelectedValue])

  const selectValue = useMemo(
    () =>
      multi
        ? setSelectedValue
        : (val) => {
            setSelectedValue(val)
            onDone(val)
          },
    [multi, setSelectedValue, onDone],
  )

  return (
    <BottomSheet
      isOpen={visible}
      title={title}
      onClose={onCancel}
      onDone={
        multi
          ? () => {
              setSelectedValue(value)
              onDone(selectedValue)
            }
          : null
      }
      flex={1}
    >
      <FlatList
        px="xl"
        borderLeftWidth={2}
        borderRightWidth={2}
        borderColor="soft"
        contentContainerStyle={{
          paddingTop: theme.space.xl,
          paddingBottom: insets.bottom,
        }}
        data={items}
        keyExtractor={(i) => i.value}
        ItemSeparatorComponent={() => <View borderBottomWidth="hairline" />}
        renderItem={({ item, index }) => {
          return (
            <PickerListItem
              item={item}
              index={index}
              items={items}
              multi={multi}
              selected={
                multi
                  ? selectedValue.includes(item.value)
                  : selectedValue === item.value
              }
              onPress={(val) =>
                multi
                  ? selectValue(
                      selectedValue.includes(val)
                        ? selectedValue.filter((sv) => sv !== val)
                        : [...selectedValue, val],
                    )
                  : selectValue(val)
              }
            />
          )
        }}
      />
    </BottomSheet>
  )
}

export function InputPicker({
  onBlur,
  onFocus,
  onChange,
  value,
  title,
  items,
  multi,
  renderInput,
  ...props
}) {
  const { inputColor, isFocused, setIsFocused } = useInputContext()

  function onPress() {
    setIsFocused(true)
    if (onFocus) return onFocus()
  }

  return (
    <Pressable onPress={onPress}>
      <PickerModal
        title={title}
        items={items}
        multi={multi}
        visible={isFocused}
        value={value}
        onCancel={() => {
          setIsFocused(false)
          if (onBlur) return onBlur()
        }}
        onDone={(val) => {
          onChange(val)
          setIsFocused(false)
          if (onBlur) return onBlur()
        }}
      />
      {renderInput ? (
        renderInput({ inputColor, value, onPress, ...props })
      ) : (
        <StyledTextInput
          {...props}
          caretHidden
          editable={false}
          value={
            multi
              ? value
                  .map((v) => items.find((i) => i.value === v)?.label)
                  .join(", ")
              : items.find((i) => i.value === value)?.label
          }
          pointerEvents="none"
          selectionColor={inputColor}
          borderColor={inputColor}
        />
      )}
    </Pressable>
  )
}

InputPicker.defaultProps = {
  color: "text",
  borderBottomWidth: StyleSheet.hairlineWidth,
  fontSize: 2,
  py: "sm",
  px: "xs",
}
