import { t, Trans } from "@lingui/macro"
import React, { useCallback } from "react"
import { StyleSheet, useWindowDimensions } from "react-native"
import { useInfiniteQuery } from "react-query"
import { useTheme } from "styled-components/native"
import { useModal } from "../../components/modal-stack"
import { ImagePickerContainer } from "../../components/photo"
import {
  ActivityIndicator,
  Button,
  Container,
  ErrorBody,
  FastImage,
  FlatList,
  Icon,
  LinkContainer,
  NavBar,
  RefreshControl,
  Row,
  StepBar,
  Text,
  TopNavBar,
  TouchableOpacity,
  View,
} from "../../components/ui"
import { Breakpoint, useBreakpoints } from "../../hooks"
import { mixpanel } from "../../lib/mixpanel"
import { useApi } from "../../providers"

export function SelectPhotoScreen({ navigation }) {
  const api = useApi()
  const theme = useTheme()
  const { openModal } = useModal()
  const { getBreakpointValue, breakpoints, currentBreakpointIndex } =
    useBreakpoints()

  const {
    fetchNextPage,
    hasNextPage,
    data: photosData,
    isError,
    error,
    isLoading,
    refetch,
  } = useInfiniteQuery(
    "photoList",
    ({ pageParam = 1 }) => api.getPhotoList({ page: pageParam }),
    {
      getNextPageParam: (lastPage, allPages) => lastPage.meta.next ?? undefined,
      getPreviousPageParam: (firstPage, allPages) =>
        firstPage.meta.prev ?? undefined,
    },
  )

  const { width } = useWindowDimensions()

  const count = photosData?.pages[0]?.meta?.count ?? 0

  const numColumns = getBreakpointValue([3, 3, 4, 5])

  const pictureSize =
    ((breakpoints[currentBreakpointIndex - 1] ?? width) -
      theme.space.xl * 2 -
      theme.space.md * (numColumns - 1)) /
    numColumns

  const allPhotos = photosData?.pages?.flatMap((p) => p.data) ?? []

  const onNewPhotoPress = useCallback(
    (getImage) => {
      return async () => {
        const image = await getImage()

        mixpanel.track("Photo Selected")

        if (image) {
          const photo = await openModal({ name: "PhotoNewModal", image })

          if (photo) {
            navigation.navigate("SelectCategory", { photoId: photo.id })
          }
        }
      }
    },
    [navigation, openModal],
  )

  return (
    <>
      <Breakpoint values={["lg", "xl"]}>
        <TopNavBar />
      </Breakpoint>

      <Container breakpoint="xl" flex={1}>
        <Row flex={1}>
          <NavBar title={t`Photo`}>
            <ImagePickerContainer
              end
              autorun={!isError && !isLoading && count === 0}
              onAutorun={({ getImage }) => onNewPhotoPress(getImage)()}
            >
              {({ getImage }) => (
                <Button
                  label={t`New photo`}
                  variant="navbar"
                  size="xs"
                  onPress={onNewPhotoPress(getImage)}
                />
              )}
            </ImagePickerContainer>
          </NavBar>

          <View flex={1} justifyContent="center">
            {isLoading ? (
              <ActivityIndicator />
            ) : isError ? (
              <ErrorBody error={error} reload={refetch} />
            ) : (
              <>
                <FlatList
                  key={numColumns}
                  contentContainerStyle={{
                    paddingHorizontal: theme.space.xl,
                    paddingBottom: theme.space.xl,
                    ...styles.list.contentContainerStyle,
                  }}
                  numColumns={numColumns}
                  getItemLayout={(data, index) => ({
                    length: pictureSize,
                    offset: pictureSize * index,
                    index,
                  })}
                  ListEmptyComponent={
                    <View flex={1} justifyContent="center">
                      <View position="absolute" top={0} left={0}>
                        <Text fontSize={6} fontFamily="heading">
                          <Trans>Let's upload your first photo!</Trans>
                        </Text>
                        <Text mt="xl" fontSize={3} fontFamily="heading">
                          <Trans>Press "New photo" to start.</Trans>
                        </Text>
                      </View>

                      <ImagePickerContainer>
                        {({ getImage }) => (
                          <TouchableOpacity
                            bg="primary"
                            mx="auto"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius={3}
                            height={150}
                            width={150}
                            onPress={onNewPhotoPress(getImage)}
                          >
                            <Icon mb="md" color="white" name="camera" />
                            <Text fontFamily="bold" fontSize={2} color="white">
                              <Trans>New photo</Trans>
                            </Text>
                          </TouchableOpacity>
                        )}
                      </ImagePickerContainer>
                    </View>
                  }
                  onEndReached={() => hasNextPage && fetchNextPage()}
                  refreshControl={
                    <RefreshControl
                      onRefresh={() => refetch()}
                      refreshing={false}
                    />
                  }
                  data={allPhotos}
                  ListHeaderComponent={
                    count > 0 ? (
                      <Text fontSize={6} fontFamily="heading">
                        <Trans>Choose a photo to test</Trans>
                      </Text>
                    ) : null
                  }
                  renderItem={({ item, index }) => {
                    const mt = index < numColumns ? "xl" : "md"
                    const ml = index % numColumns !== 0 ? "md" : 0

                    return (
                      <LinkContainer
                        mt={mt}
                        ml={ml}
                        testID={`selectPhoto${index}`}
                        to={{
                          screen: "SelectCategory",
                          params: { photoId: item.id },
                        }}
                      >
                        <FastImage
                          testID="image"
                          dataSet={{ private: true }}
                          borderRadius={3}
                          style={{ width: pictureSize, height: pictureSize }}
                          source={{
                            uri: item.url,
                          }}
                          resizeMode={FastImage.resizeMode.cover}
                        />
                      </LinkContainer>
                    )
                  }}
                  keyExtractor={(item) => item.id}
                />
                <StepBar activeStep={1} numberOfSteps={4} m="xl" />
              </>
            )}
          </View>
        </Row>
      </Container>
    </>
  )
}

const styles = StyleSheet.create({
  list: {
    contentContainerStyle: {
      flexGrow: 1,
    },
  },
})
