import { i18n } from "@lingui/core"
import { t, Trans } from "@lingui/macro"
import { clamp } from "lodash"
import React, { useState } from "react"
import { StyleSheet, useWindowDimensions } from "react-native"
import { useQuery } from "react-query"
import { useTheme } from "styled-components/native"
import {
  Button,
  Container,
  FastImage,
  Icon,
  ImagePreview,
  NavBar,
  Pressable,
  Row,
  ScrollView,
  StepBar,
  Text,
  TopNavBar,
  TouchableOpacity,
  View,
} from "../../components/ui"
import { Breakpoint, useBreakpoints, useCreateTest } from "../../hooks"
import { SOCIAL_TEST, TEST_TYPE_OPTIONS } from "../../lib/constants"
import { getTestCategoryColor } from "../../lib/helpers"
import { mixpanel } from "../../lib/mixpanel"
import { useApi } from "../../providers"

export function SelectCategoryScreen({ route, navigation }) {
  const theme = useTheme()
  const api = useApi()
  const [type, setType] = useState(TEST_TYPE_OPTIONS[0].value)
  const { breakpoints, currentBreakpointIndex, getBreakpointValue } =
    useBreakpoints()

  const { width } = useWindowDimensions()

  const [imageVisible, setImageIsVisible] = useState(false)

  const { data: photo } = useQuery(["photo", route.params.photoId], () =>
    api.getPhoto(route.params.photoId),
  )

  const createMutation = useCreateTest(type)

  const horizontalSpaceSize =
    clamp(width, 0, theme.breakpoints.sm) - theme.space.xl * 2
  const imageSize =
    (((breakpoints[currentBreakpointIndex - 1] ?? width) - theme.space.xl * 2) *
      getBreakpointValue([100, 100, 70, 50])) /
      100 -
    getBreakpointValue([
      theme.space.xs,
      theme.space.xs,
      theme.space.xl,
      theme.space.xl,
    ])

  const buttonSize = (horizontalSpaceSize - theme.space.xl * 2) / 3

  return (
    <>
      <Breakpoint values={["lg", "xl"]}>
        <TopNavBar />
      </Breakpoint>

      <Container flex={1} breakpoint="xl">
        <Row flex={1}>
          <NavBar title={t`Category`} />

          <ScrollView px="xl" keyboardShouldPersistTaps="handled">
            <Text fontSize={6} fontFamily="heading">
              <Trans>Select a category for testing</Trans>
            </Text>
            <Text mt="md" fontSize={3} fontFamily="heading">
              <Trans>Each category tests different traits.</Trans>
            </Text>

            <View
              mt="xl"
              flexGap="xl"
              flexDirection={getBreakpointValue([
                "column",
                "column",
                "row",
                "row",
              ])}
            >
              <View
                maxHeight={imageSize}
                flexGap="xl"
                width={getBreakpointValue(["100%", "100%", "30%", "50%"])}
                flexDirection={getBreakpointValue([
                  "row",
                  "row",
                  "column",
                  "column",
                ])}
              >
                {TEST_TYPE_OPTIONS.map((o, idx) => {
                  const isSelected = o.value === type

                  return (
                    <TouchableOpacity
                      flex={1}
                      justifyContent="center"
                      key={o.value}
                      bg={
                        isSelected
                          ? getTestCategoryColor(theme, o.value)
                          : "grayPrimary"
                      }
                      alignItems="center"
                      borderRadius={3}
                      height={buttonSize}
                      onPress={() => setType(o.value)}
                    >
                      <Icon mb="md" color="white" name={o.icon} />
                      <Text fontFamily="bold" color="white">
                        {i18n._(o.label)}
                      </Text>
                    </TouchableOpacity>
                  )
                })}
              </View>

              {photo && (
                <View borderRadius={3}>
                  <Pressable onPress={() => setImageIsVisible(true)}>
                    <FastImage
                      testID="image"
                      dataSet={{ private: true }}
                      style={{ width: imageSize, height: imageSize }}
                      source={{ uri: photo.url }}
                      borderRadius={3}
                      resizeMode={FastImage.resizeMode.cover}
                    />
                  </Pressable>
                  <ImagePreview
                    uri={photo.url}
                    isOpen={imageVisible}
                    onClose={() => setImageIsVisible(false)}
                  />
                  <View style={styles.lightbox} opacity={0.4}>
                    <Button
                      width={38}
                      color="text"
                      bg="background"
                      iconFontSize={4}
                      size="md"
                      icon="expand-arrows-alt"
                      onPress={() => setImageIsVisible(true)}
                    />
                  </View>
                </View>
              )}
            </View>
          </ScrollView>

          <Button
            m="xl"
            mb="xs"
            testID="nextStepButton"
            disabled={createMutation.isLoading}
            loading={createMutation.isLoading}
            onPress={() => {
              mixpanel.track("TestType Selected")

              if (type === SOCIAL_TEST) {
                return createMutation.mutate({ testDto: { photoId: photo.id } })
              }

              navigation.navigate("TestForm", {
                photoId: photo.id,
                testType: type,
              })
            }}
            label={type === SOCIAL_TEST ? t`Save` : t`Next`}
          />

          <StepBar
            activeStep={2}
            numberOfSteps={type === SOCIAL_TEST ? 3 : 4}
            m="xl"
          />
        </Row>
      </Container>
    </>
  )
}

const styles = StyleSheet.create({
  lightbox: { position: "absolute", top: 8, right: 8 },
})
