import { i18n } from "@lingui/core"
import { en, de, ru } from "make-plural/plurals"
import { messages as enMessages } from "../locales/en/messages"
import { messages as deMessages } from "../locales/de/messages"
import { messages as ruMessages } from "../locales/ru/messages"

i18n.loadLocaleData({
  en: { plurals: en },
  de: { plurals: de },
  ru: { plurals: ru },
})

i18n.load({
  en: enMessages,
  de: deMessages,
  ru: ruMessages,
})

export { i18n }
