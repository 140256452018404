import { i18n } from "@lingui/core"
import { t } from "@lingui/macro"
import Clipboard from "@react-native-clipboard/clipboard"
import { format, formatRelative } from "date-fns"
import * as locales from "date-fns/locale"
import humps from "humps"
import * as base64 from "js-base64"
import { inRange, isNil } from "lodash"
import qs from "qs"
import { Platform } from "react-native"
import { getInstallReferrer } from "react-native-device-info"
import {
  findBestAvailableLanguage,
  getNumberFormatSettings,
} from "react-native-localize"
import { config } from "./config"
import {
  BUSINESS_TEST,
  CANCEL_ERROR,
  CLIENT_ERROR,
  CONNECTION_ERROR,
  DATING_TEST,
  EN_LANGUAGE,
  NETWORK_ERROR,
  SERVER_ERROR,
  SOCIAL_TEST,
  TIMEOUT_ERROR,
  UNKNOWN_ERROR,
} from "./constants"

export const in200s = (val) => inRange(val, 200, 300)
export const in400s = (val) => inRange(val, 400, 500)
export const in500s = (val) => inRange(val, 500, 600)
export const statusNil = (status) => (isNil(status) ? undefined : status)

export function isMethod(method, err) {
  return method.toLowerCase() === err?.config?.method
}

export function isStatus(status, err) {
  return status === err?.response?.status
}

export function isProblem(problem, err) {
  return problem === err?.problem
}

export function hasCode(err) {
  return Boolean(err?.code)
}

export function hasProblem(err) {
  return Boolean(err?.problem)
}

const dateFormatLocales = Object.keys(locales)

function getDateFormattingLocale(language) {
  if (language !== EN_LANGUAGE)
    return dateFormatLocales.includes(language) ? language : "enGB"

  let locale =
    findBestAvailableLanguage(["en-US", "en-GB", "en"])?.languageTag ?? "en-GB"

  locale = locale.replace("-", "")

  return dateFormatLocales.includes(locale) ? locale : "enGB"
}

export function getLocale() {
  return (
    findBestAvailableLanguage(["en-US", "en-GB", "en", "ru", "de"])
      ?.languageTag ?? "en-GB"
  )
}

export function getLanguageCode() {
  return getLocale().slice(0, 2)
}

export function normalizeNumber(val, precision = 3) {
  return parseFloat(val.toFixed(precision))
}

export const formNumberTransformer = {
  input: (value) =>
    isNaN(value)
      ? ""
      : formatNumber(value, {
          maximumFractionDigits: 3,
        }),
  output: (value) => {
    const output = normalizeNumber(parseFloat(value.replace(/,/, ".")))
    return output
  },
}

export function formatDate(
  date,
  formatStr = "PP",
  locale = getDateFormattingLocale(i18n.locale),
) {
  if (typeof date === "string") {
    date = new Date(date)
  }

  return format(date, formatStr, {
    locale: locales[locale],
  })
}

export function formatRelativeDate(
  date,
  baseDate = new Date(),
  locale = getDateFormattingLocale(i18n.locale),
) {
  if (typeof date === "string") {
    date = new Date(date)
  }

  return formatRelative(date, baseDate, {
    locale: locales[locale],
  })
}

export function formatNumber(value, options) {
  options = {
    useGrouping: false,
    maximumFractionDigits: 1,
    ...getNumberFormatSettings(),
    ...options,
  }

  return i18n.number(value, options)
}

export function getTestCategoryColor(theme, category) {
  const key = {
    [DATING_TEST]: "dating",
    [BUSINESS_TEST]: "business",
    [SOCIAL_TEST]: "social",
  }[category]

  return theme.colors[key]
}

export function getDatingScoreFromFeedbacksCount(statistics) {
  if (statistics.reviewCount < 1) {
    return 0
  }

  const totalScore = Object.entries(statistics.feedbackCounts).reduce(
    (acc, [k, v]) => {
      if (!k.includes("score_")) return acc

      return acc + parseInt(k.split("score_")[1], 10) * v
    },
    0,
  )

  if (totalScore < 1) {
    return 0
  }

  return totalScore / statistics.reviewCount
}

export function getStarProps(score, idx) {
  const scoreIdx = Math.floor(score)
  if (idx <= scoreIdx) return { name: "star", solid: true }
  if (idx > scoreIdx + 1 || score === idx - 1) return { name: "star" }

  if (score >= idx - 0.5) return { name: "star", solid: true }

  return { name: "star-half", solid: true }
}

export function makeUserInviteCode(user) {
  return base64.encodeURI(`user_id:${user.id}`)
}

export function makeLanguageUrlPrefix(language) {
  return language === "en" ? "" : `/${language}`
}

export function makeUserInviteLink(user) {
  return `${config.app.url}/login?inviteCode=${makeUserInviteCode(user)}`
}

export function isUserCompleteToRateDating(user) {
  // return Boolean(user.gender) && Boolean(user.orientation) && Boolean(user.age)
  return Boolean(user.age)
}

export function validateInviteCode(inviteCode) {
  if (!inviteCode) return

  let key, value
  try {
    ;[key, value] = base64.decode(inviteCode).split(":")
  } catch (err) {
    return
  }

  if (key !== "user_id") return
  if (!Number.isFinite(parseInt(value, 10))) return

  return inviteCode
}

export async function getAndroidInviteCode() {
  const installReferrer = await getInstallReferrer()

  const query = humps.camelizeKeys(
    qs.parse(decodeURIComponent(installReferrer)),
  )

  if (!query) return
  if (query.utmSource !== "testframe") return
  if (query.utmMedium !== "invite") return

  return query.utmCampaign
}

export async function getIOSInviteCode() {
  const text = await Clipboard.getString()

  return text
}

export function getInviteCode() {
  if (Platform.OS === "android") {
    return getAndroidInviteCode()
  }
  if (Platform.OS === "ios") {
    return getIOSInviteCode()
  }
}

export function getTranslationOrOriginalText({ translation, text, language }) {
  if (!translation) return { text, translated: false }

  if (translation.detectedSourceLanguage === language) {
    return { text, translated: false }
  }

  if (!translation.translation) {
    return { text, translated: false }
  }

  return {
    text: translation.translation,
    translated: true,
  }
}

export function getErrorMessage(err) {
  let title = t`Oops! Something went wrong`
  let description = t`Unknown error. We will fix it as soon as possible.`

  if (err?.userInfo?.NSLocalizedDescription) {
    description = err?.userInfo?.NSLocalizedDescription
  }

  switch (err?.problem) {
    case NETWORK_ERROR:
    case TIMEOUT_ERROR:
    case CONNECTION_ERROR:
    case CANCEL_ERROR:
    case UNKNOWN_ERROR:
      title = t`Network error!`
      description = t`Please check your internet connection.`
      break

    case SERVER_ERROR:
      switch (err?.response?.status) {
        case 502:
        case 503:
        case 504:
          title = t`Houston, we have a problem!`
          description = t`Server is temporarily unavailable.`
          break
      }
      break

    case CLIENT_ERROR:
      switch (err?.response?.status) {
        case 400:
          title = t`Oops! Invalid data`
          description = t`Invalid data. Please check your input.`
          break

        case 422:
          title = t`Error`
          description = t`Invalid data. Please check your input.`

          if (err?.response?.data?.error) {
            description = err?.response?.data?.error
          }

          if (err?.response?.data?.errors) {
            if (err.response.data.errors[0]) {
              description = err.response.data.errors[0]
            }

            if (err.response.data.errors.length > 1) {
              description = err.response.data.errors.join("\n")
            }
          }

          break

        case 401:
          title = t`Oops! You're not authenticated`
          description = t`You are not authenticated to perform this action.`
          break

        case 404:
          title = t`Oops! You're lost`
          description = t`You are not authorized to perform this action or resource was not found.`
          break

        case 408:
          title = t`Oops! Network request timed out`
          description = t`Please try again.`
          break

        case 429:
          title = t`Oops! You sent too many requests`
          description = t`Please wait a while and come back.`
          break
      }
      break
  }

  return { title, description }
}
