import { i18n } from "@lingui/core"
import React from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useTheme } from "styled-components/native"
import {
  ActivityIndicator,
  Button,
  Container,
  ErrorBody,
  NavBar,
  Row,
  ScrollView,
  Text,
  TopNavBar,
  View,
  useDialog,
} from "../../components/ui"
import { Breakpoint, useSafeAreaOrDefaultInsets } from "../../hooks"
import { GENDER_OPTIONS, ORIENTATION_OPTIONS } from "../../lib/constants"
import { formatDate, getErrorMessage } from "../../lib/helpers"
import { Sentry } from "../../lib/sentry"
import { useAlert, useApi } from "../../providers"

export function AdminUserDetailsScreen({ navigation, route }) {
  const alert = useAlert()
  const api = useApi()
  const queryClient = useQueryClient()
  const openUserDeleteDialog = useDialog()
  const theme = useTheme()
  const insets = useSafeAreaOrDefaultInsets({ bottom: theme.space.xl })

  const {
    data: user,
    isError: isUserError,
    error: userError,
    isLoading: isUserLoading,
    refetch: refetchUser,
  } = useQuery(["adminUser", route.params.id], () =>
    api.getAdminUser(route.params.id),
  )

  const updateUserMutation = useMutation(api.updateAdminUser, {
    onError: (err, variables, context) => {
      const { title, description } = getErrorMessage(err)
      alert.current.showNotification({ title, description })

      if (!err.isAxiosError) {
        Sentry.captureException(err)
      }
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["adminUser", route.params.id])
      queryClient.setQueriesData(["adminUserList"], (queryData) => {
        return {
          ...queryData,
          pages: queryData.pages.map((page) => ({
            ...page,
            data: page.data.map((t) => (t.id === data.id ? data : t)),
          })),
        }
      })
    },
  })

  const deleteUserMutation = useMutation(api.deleteAdminUser, {
    onError: (err, variables, context) => {
      const { title, description } = getErrorMessage(err)
      alert.current.showNotification({ title, description })

      if (!err.isAxiosError) {
        Sentry.captureException(err)
      }
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("adminUserList")
      navigation.goBack()
    },
  })

  return (
    <>
      <Breakpoint values={["lg", "xl"]}>
        <TopNavBar />
      </Breakpoint>

      <Container breakpoint="xl">
        <Row>
          <NavBar title="User">
            <Button
              end
              size="xs"
              variant="navbar"
              label="Delete"
              onPress={() =>
                openUserDeleteDialog({
                  title: "Are you sure?",
                  description: "User will be deleted and can't be restored",
                  buttons: [
                    {
                      label: "Delete",
                      onPress: (value) => deleteUserMutation.mutate(user.id),
                    },
                    {
                      label: "Cancel",
                      variant: "primary-outline",
                    },
                  ],
                })
              }
            />
          </NavBar>
        </Row>
      </Container>

      <Container flex={1} breakpoint="xl">
        <Row flex={1}>
          <View flex={1} justifyContent="center">
            {isUserLoading ? (
              <ActivityIndicator />
            ) : isUserError ? (
              <ErrorBody
                error={userError}
                reload={() => {
                  refetchUser()
                }}
              />
            ) : (
              <ScrollView
                mx="xl"
                contentContainerStyle={{
                  paddingBottom: insets.bottom,
                }}
                keyboardShouldPersistTaps="handled"
              >
                <View
                  borderColor="border"
                  borderWidth={2}
                  borderRadius={3}
                  p="lg"
                >
                  <Text
                    numberOfLines={1}
                    fontFamily="bold"
                    fontSize={3}
                    testID="name"
                    dataSet={{ private: true }}
                  >
                    ({user.id}) {user.name}
                  </Text>
                  <Text
                    mt="sm"
                    numberOfLines={1}
                    testID="email"
                    dataSet={{ private: true }}
                  >
                    <Text fontFamily="heading">E-Mail: </Text>
                    {user.email}
                  </Text>

                  <View mt="sm" flexDirection="row">
                    {Boolean(user.age) && (
                      <View flex={1} flexDirection="row">
                        <Text fontFamily="heading">Age: </Text>
                        <Text>{user.age}</Text>
                      </View>
                    )}

                    <View flex={1} flexDirection="row">
                      <Text fontFamily="heading">State: </Text>
                      <Text>{user.state}</Text>
                    </View>
                  </View>

                  <View mt="sm" flexDirection="row">
                    {user.gender && (
                      <View flex={1} flexDirection="row">
                        <Text fontFamily="heading">Gender: </Text>
                        <Text>
                          {i18n._(
                            GENDER_OPTIONS.find((o) => o.value === user.gender)
                              .label,
                          )}
                        </Text>
                      </View>
                    )}

                    {user.orientation && (
                      <View flex={1} flexDirection="row">
                        <Text fontFamily="heading">Orientation: </Text>
                        <Text>
                          {i18n._(
                            ORIENTATION_OPTIONS.find(
                              (o) => o.value === user.orientation,
                            ).label,
                          )}
                        </Text>
                      </View>
                    )}
                  </View>
                  <View mt="sm" flexDirection="row">
                    <View flex={1} flexDirection="row">
                      <Text fontFamily="heading">Energy: </Text>
                      <Text>{user.energy}</Text>
                    </View>
                    <View flex={1} flexDirection="row">
                      <Text fontFamily="heading">Coins: </Text>
                      <Text>{user.coins}</Text>
                    </View>
                  </View>
                  <View mt="sm" flexDirection="row">
                    <View flex={1} flexDirection="row">
                      <Text fontFamily="heading">Tests: </Text>
                      <Text>{user.testCount}</Text>
                    </View>
                    <View flex={1} flexDirection="row">
                      <Text fontFamily="heading">Reviews: </Text>
                      <Text>{user.reviewCount}</Text>
                    </View>
                  </View>
                  <View mt="sm" flexDirection="row">
                    <View flex={1} flexDirection="row" flexWrap="wrap">
                      <Text fontFamily="heading">Created: </Text>
                      <Text>{formatDate(user.createdAt)}</Text>
                    </View>
                    {user.lastActivityAt && (
                      <View flex={1} flexDirection="row" flexWrap="wrap">
                        <Text fontFamily="heading">Last active: </Text>
                        <Text>{formatDate(user.lastActivityAt)}</Text>
                      </View>
                    )}
                  </View>
                  {user.spamWarnedAt && (
                    <View mt="sm" flexDirection="row">
                      {user.spamWarnedAt && (
                        <View flex={1} flexDirection="row" flexWrap="wrap">
                          <Text fontFamily="heading">Spam warned: </Text>
                          <Text numberOfLines={1}>
                            {formatDate(user.spamWarnedAt)}
                          </Text>
                        </View>
                      )}
                    </View>
                  )}
                </View>

                <Button
                  mt="xl"
                  variant="primary-outline"
                  label="Tests"
                  onPress={() => {
                    navigation.push("AdminTestList", { userId: user.id })
                  }}
                />
                <Button
                  mt="xl"
                  variant="primary-outline"
                  label="Reviews"
                  onPress={() => {
                    navigation.push("AdminReviewList", { userId: user.id })
                  }}
                />
                <Button
                  mt="xl"
                  variant="primary-outline"
                  label="Reports"
                  onPress={() => {
                    navigation.push("AdminReportList", { userId: user.id })
                  }}
                />
                <Button
                  mt="xl"
                  variant="primary-outline"
                  label="Test Reports"
                  onPress={() => {
                    navigation.push("AdminTestReportList", {
                      userId: user.id,
                    })
                  }}
                />
                <Button
                  mt="xl"
                  variant="primary-outline"
                  label="Review Reports"
                  onPress={() => {
                    navigation.push("AdminReviewReportList", {
                      userId: user.id,
                    })
                  }}
                />

                <Button
                  mt="xl"
                  variant="primary-outline"
                  label={`Review `}
                  onPress={() =>
                    navigation.push("AdminReview", { userId: user.id })
                  }
                />

                <Button
                  mt="xl"
                  variant="primary-outline"
                  label={user.state === "permitted" ? "Ban" : "Unban"}
                  disabled={updateUserMutation.isLoading}
                  loading={updateUserMutation.isLoading}
                  onPress={() =>
                    updateUserMutation.mutate({
                      id: user.id,
                      userDto: {
                        state:
                          user.state === "permitted" ? "banned" : "permitted",
                      },
                    })
                  }
                />

                <Button
                  mt="xl"
                  variant="primary-outline"
                  label={
                    user.spamWarnedAt === null
                      ? "Spam warning"
                      : "Remove spam warning"
                  }
                  disabled={updateUserMutation.isLoading}
                  loading={updateUserMutation.isLoading}
                  onPress={() =>
                    updateUserMutation.mutate({
                      id: user.id,
                      userDto: {
                        spamWarnedAt:
                          user.spamWarnedAt === null ? new Date() : null,
                      },
                    })
                  }
                />
              </ScrollView>
            )}
          </View>
        </Row>
      </Container>
    </>
  )
}
