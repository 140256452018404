import { Trans, t } from "@lingui/macro"
import React from "react"
import { Linking, Platform, useWindowDimensions } from "react-native"
import result1 from "../assets/aiheadshotgenerator/result-man-1.jpeg"
import result2 from "../assets/aiheadshotgenerator/result-man-2.jpeg"
import result3 from "../assets/aiheadshotgenerator/result-man-3.jpeg"
import result4 from "../assets/aiheadshotgenerator/result-man-4.jpeg"
import training1 from "../assets/aiheadshotgenerator/training-man-1.jpeg"
import training2 from "../assets/aiheadshotgenerator/training-man-2.jpeg"
import training3 from "../assets/aiheadshotgenerator/training-man-3.jpeg"
import {
  Button,
  Container,
  Image,
  Row,
  ScrollView,
  Text,
  TopNavBar,
  View,
} from "../components/ui"
import {
  Breakpoint,
  useBreakpoints,
  useSafeAreaOrDefaultInsets,
} from "../hooks"
import { themes } from "../lib/theme"

export function AIHeadshotGeneratorScreen({ navigation }) {
  const { width } = useWindowDimensions()
  const insets = useSafeAreaOrDefaultInsets({ top: "md" })
  const { getBreakpointValue, breakpoints, currentBreakpointIndex } =
    useBreakpoints()

  const numColumns = 3
  const resultNumColumns = 2

  const pictureSize =
    ((breakpoints[currentBreakpointIndex - 3] ??
      breakpoints[currentBreakpointIndex - 2] ??
      breakpoints[currentBreakpointIndex - 1] ??
      width) -
      themes.light.space.lg * 2 -
      themes.light.space.lg * (numColumns - 1)) /
    numColumns

  const resultPictureSize =
    ((breakpoints[currentBreakpointIndex - 3] ??
      breakpoints[currentBreakpointIndex - 2] ??
      breakpoints[currentBreakpointIndex - 1] ??
      width) -
      themes.light.space.lg * 2 -
      themes.light.space.lg * (resultNumColumns - 1)) /
    resultNumColumns

  return (
    <>
      <Breakpoint values={["lg", "xl"]}>
        <TopNavBar />
      </Breakpoint>

      <Container flex={1} breakpoint="sm">
        <Row flex={1}>
          <ScrollView px={getBreakpointValue(["lg", "lg", "xl", "xl"])}>
            <View mt={insets.top}>
              <Text textAlign="center" fontSize={5} fontFamily="heading">
                <Trans>Turn your selfies</Trans>
              </Text>

              <View mt="md" flexDirection="row" flexGap="lg">
                <Image
                  source={training1}
                  borderRadius={3}
                  style={{ width: pictureSize, height: pictureSize }}
                />
                <Image
                  source={training2}
                  borderRadius={3}
                  style={{ width: pictureSize, height: pictureSize }}
                />
                <Image
                  source={training3}
                  borderRadius={3}
                  style={{ width: pictureSize, height: pictureSize }}
                />
              </View>

              <Text
                textAlign="center"
                fontSize={6}
                fontFamily="heading"
                mt="md"
              >
                <Trans>
                  Into professional photos for{" "}
                  <Text color="dating" fontSize={6} fontFamily="bold">
                    Dating
                  </Text>{" "}
                  &{" "}
                  <Text color="business" fontSize={6} fontFamily="bold">
                    Business
                  </Text>
                </Trans>
              </Text>

              <View mt="md" flexDirection="row" flexGap="lg">
                <Image
                  borderRadius={3}
                  source={result1}
                  style={{
                    width: resultPictureSize,
                    height: resultPictureSize,
                  }}
                />
                <Image
                  borderRadius={3}
                  source={result2}
                  style={{
                    width: resultPictureSize,
                    height: resultPictureSize,
                  }}
                />
              </View>
              <View mt="md" flexDirection="row" flexGap="lg">
                <Image
                  borderRadius={3}
                  source={result3}
                  style={{
                    width: resultPictureSize,
                    height: resultPictureSize,
                  }}
                />
                <Image
                  borderRadius={3}
                  source={result4}
                  style={{
                    width: resultPictureSize,
                    height: resultPictureSize,
                  }}
                />
              </View>
            </View>

            <Text mt="sm" textAlign="center" fontSize={2} color="graySecondary">
              <Trans>and many other styles...</Trans>
            </Text>
          </ScrollView>

          <Button
            mx="xl"
            my="xl"
            label={t`Get started`}
            onPress={async () => {
              Linking.openURL(
                Platform.OS === "android"
                  ? "https://play.google.com/store/apps/details?id=com.dimonnwc3.ai.headshot.generator"
                  : Platform.OS === "ios"
                  ? "https://apps.apple.com/app/aiheadshotgenerator/id6467744940"
                  : "https://aiheadshotgenerator.app",
              )
            }}
          />
        </Row>
      </Container>
    </>
  )
}
