import { i18n } from "@lingui/core"
import { plural } from "@lingui/macro"
import React, { useState } from "react"
import { useWindowDimensions } from "react-native"
import { useInfiniteQuery, useQuery } from "react-query"
import { useTheme } from "styled-components/native"
import {
  ActivityIndicator,
  BottomSheet,
  Button,
  Container,
  ErrorBody,
  FastImage,
  FlatList,
  Icon,
  Input,
  NavBar,
  RefreshControl,
  Row,
  Text,
  TopNavBar,
  TouchableOpacity,
  View,
} from "../../components/ui"
import {
  Breakpoint,
  useBreakpoints,
  useSafeAreaOrDefaultInsets,
} from "../../hooks"
import {
  ACTIVE_TEST,
  ERROR_TEST_OPTION,
  INACTIVE_TEST,
  TEST_TYPE_OPTIONS,
  USER_ROLE,
  USER_ROLE_OPTIONS,
} from "../../lib/constants"
import { formatDate } from "../../lib/helpers"
import { useApi } from "../../providers"

export function AdminTestListScreen({ navigation, route }) {
  const api = useApi()
  const theme = useTheme()
  const { width } = useWindowDimensions()
  const insets = useSafeAreaOrDefaultInsets({ bottom: theme.space.xl })
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [filter, setFilter] = useState({
    userRoleIn: [USER_ROLE],
    sorts: "created_at desc",
  })

  const { getBreakpointValue, breakpoints, currentBreakpointIndex } =
    useBreakpoints()

  const {
    fetchNextPage,
    hasNextPage,
    refetch,
    data: testsData,
    isError,
    error,
    isLoading,
  } = useInfiniteQuery(
    ["adminTestList", { userId: route.params?.userId, filter }],
    ({ pageParam = 1 }) =>
      api.getAdminTestList({
        page: pageParam,
        query: {
          userIdEq: route.params?.userId,
          ...filter,
        },
      }),
    {
      getNextPageParam: (lastPage, allPages) => lastPage.meta.next ?? undefined,
      getPreviousPageParam: (firstPage, allPages) =>
        firstPage.meta.prev ?? undefined,
    },
  )

  const count = testsData?.pages[0]?.meta?.count ?? 0
  const allTests = testsData?.pages?.flatMap((p) => p.data) ?? []

  const numColumns = getBreakpointValue([2, 2, 3, 4])
  const pictureSize =
    ((breakpoints[currentBreakpointIndex - 1] ?? width) -
      theme.space.xl * 2 -
      theme.space.md * (numColumns - 1)) /
    numColumns

  return (
    <>
      <Breakpoint values={["lg", "xl"]}>
        <TopNavBar />
      </Breakpoint>

      <Container breakpoint="xl">
        <Row>
          <NavBar title="Tests">
            <Button
              end
              size="xs"
              variant="navbar"
              label="Filter"
              onPress={() => setIsFilterOpen(true)}
            />
          </NavBar>
        </Row>
      </Container>

      <BottomSheet
        isOpen={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        onDone={() => setIsFilterOpen(false)}
        title="Filter"
      >
        <View
          style={{
            paddingHorizontal: theme.space.xl,
            paddingBottom: insets.bottom,
          }}
        >
          <Input mt="xl">
            <Input.Title>Role</Input.Title>
            <Input.Picker
              multi
              items={USER_ROLE_OPTIONS}
              onChange={(val) =>
                setFilter({
                  ...filter,
                  userRoleIn: val,
                })
              }
              value={filter.userRoleIn}
            />
            <Input.Error />
          </Input>

          <Input mt="xl">
            <Input.Title>Sorts</Input.Title>
            <Input.Picker
              items={[
                {
                  value: "created_at desc",
                  label: "Created Desc",
                },
                {
                  value: "created_at asc",
                  label: "Created Asc",
                },
                {
                  value: "reviews_available desc",
                  label: "Reviews available desc",
                },
              ]}
              onChange={(val) =>
                setFilter({
                  ...filter,
                  sorts: val,
                })
              }
              value={filter.sorts}
            />
            <Input.Error />
          </Input>
        </View>
      </BottomSheet>

      <Container flex={1} breakpoint="xl">
        <Row flex={1}>
          <View flex={1} justifyContent="center">
            {isLoading ? (
              <ActivityIndicator />
            ) : isError ? (
              <ErrorBody error={error} reload={refetch} />
            ) : (
              <FlatList
                key={numColumns}
                onEndReached={() => hasNextPage && fetchNextPage()}
                refreshControl={
                  <RefreshControl
                    onRefresh={() => refetch()}
                    refreshing={false}
                  />
                }
                numColumns={numColumns}
                getItemLayout={(data, index) => ({
                  length: pictureSize,
                  offset: pictureSize * index,
                  index,
                })}
                ListHeaderComponent={
                  <View mx="xl">
                    <Text fontSize={4} fontFamily="heading">
                      Total: {count}
                    </Text>
                  </View>
                }
                data={allTests}
                renderItem={({ item, index }) => {
                  const mb = allTests.length - 1 === index ? "xl" : 0

                  return (
                    <TestItem
                      ml="lg"
                      mb={mb}
                      pictureSize={pictureSize}
                      test={item}
                      onPress={() => {
                        navigation.push("AdminTestDetails", { id: item.id })
                      }}
                    />
                  )
                }}
                keyExtractor={(item) => item.id}
              />
            )}
          </View>
        </Row>
      </Container>
    </>
  )
}

function TestItem({ test, pictureSize, onPress, ...props }) {
  const api = useApi()
  const { data: testStatistics } = useQuery(
    ["adminTest", test.id, "statistics"],
    () => api.getAdminTestStatistics(test.id),
  )

  return (
    <TouchableOpacity
      {...props}
      mt="xl"
      borderRadius={3}
      overflow="hidden"
      onPress={() => onPress(test)}
    >
      <View bg="black">
        <FastImage
          testID="image"
          dataSet={{ private: true }}
          opacity={test.state === ACTIVE_TEST ? 1 : 0.7}
          style={{ height: pictureSize, width: pictureSize }}
          source={{
            uri: test.photo.url,
          }}
          resizeMode={FastImage.resizeMode.cover}
        />
        {test.state === INACTIVE_TEST && (
          <View
            position="absolute"
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Icon
              name="pause-circle"
              fontSize={8}
              color="white"
              opacity={0.7}
            />
          </View>
        )}
      </View>
      <View
        position="absolute"
        top={0}
        left={0}
        width="100%"
        bg="background"
        opacity={0.7}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        p="sm"
        px="md"
      >
        {testStatistics && (
          <Text fontFamily="bold" mr="sm">
            {testStatistics.reviewCount >= 0 &&
              plural(testStatistics.reviewCount, {
                zero: "# reviews",
                one: "# review",
                two: "# reviews",
                few: "# reviews",
                many: "# reviews",
                other: "# reviews",
              })}
          </Text>
        )}
        <Text fontFamily="bold">
          {i18n._(
            (
              TEST_TYPE_OPTIONS.find((t) => t.value === test.type) ??
              ERROR_TEST_OPTION
            ).label,
          )}
        </Text>
      </View>
      <View
        position="absolute"
        bottom={0}
        left={0}
        width="100%"
        bg="background"
        opacity={0.7}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        p="sm"
        px="md"
      >
        <Text fontFamily="bold" mr="sm">
          ID: {test.id}
        </Text>
        <Text fontFamily="bold">{formatDate(test.createdAt)}</Text>
        <Text fontFamily="bold">
          Reviews available: {test.reviewsAvailable}
        </Text>
        <Text fontFamily="bold">User energy: {test.user.energy}</Text>
      </View>
    </TouchableOpacity>
  )
}
