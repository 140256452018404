import { t, Trans } from "@lingui/macro"
import { differenceInMinutes } from "date-fns"
import React from "react"
import { Platform } from "react-native"
import OneSignal from "../../lib/onesignal"
import { Button, Pressable, Text, View } from "../ui"

export async function shouldAskPushNotificationsPermissions() {
  if (Platform.OS === "web") {
    const permission = await OneSignal.getNotificationPermission()
    return permission === "default"
  }

  const deviceState = await OneSignal.getDeviceState()

  if (Platform.OS === "android") {
    if (Platform.Version < 33) return false
    return !deviceState.hasNotificationPermission
  }

  if (deviceState.notificationPermissionStatus !== 0) return false

  return true
}

export async function callIfShouldAskPushNotificationsPermissions(
  store,
  callback,
) {
  const { lastPushNotificationPermissionsAskedAt, storeMutation } = store

  const longerThanHour =
    differenceInMinutes(new Date(), lastPushNotificationPermissionsAskedAt) >=
    60

  if (longerThanHour && (await shouldAskPushNotificationsPermissions())) {
    storeMutation.mutate({ lastPushNotificationPermissionsAskedAt: new Date() })
    await callback()
  }
}

export function PushNotificationsPermissionsModal({ onClose }) {
  return (
    <Pressable onPress={onClose} flex={1} justifyContent="center">
      <Pressable bg="background" borderRadius={3} mx="xxl" p="xl">
        <View>
          <Text textAlign="center" fontSize={4} fontFamily="heading">
            <Trans>Enable notifications to get new reviews instantly</Trans>
          </Text>
        </View>

        <Button
          mt="xl"
          size="md"
          label={t`Allow notifications`}
          onPress={() =>
            Platform.OS === "web"
              ? OneSignal.showNativePrompt().then(onClose)
              : OneSignal.promptForPushNotificationsWithUserResponse(
                  (response) => onClose(),
                )
          }
        />
        <Button
          mt="xl"
          size="md"
          variant="primary-outline"
          label={t`Later`}
          onPress={onClose}
        />
      </Pressable>
    </Pressable>
  )
}

PushNotificationsPermissionsModal.modalOptions = {}
