const detox = false

export const config = {
  version: "1.2.75",
  detox,
  web: {
    url: "https://www.testframe.app",
  },
  app: {
    url: "https://web.testframe.app",
  },
  stripe: {
    key: __DEV__
      ? "pk_test_0K6TRd8ZyUg7GVQot4GKYNUr000uadjQ85"
      : "pk_live_CLIokNNyNzXZ7gKofgZCmx1Q00T1p6utX2",
  },
  api: {
    url:
      __DEV__ || detox
        ? "https://macbook-dimonn.tail1ae6d.ts.net:8443"
        : "https://api.testframe.app",
  },
}
